import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Chart from 'chart.js/auto';
import Swal from 'sweetalert2';
import { ContentObserver } from '@angular/cdk/observers';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  campaignOne: FormGroup;
  cajas: any = [];

  sucursales: any;
  sucursal: string = 'TODOS';

  selectores: any;
  selector: string = '001';

  selectores2: any;
  selector2: string = '001';

  //Bandera para spinner
  banderaSpinner: boolean = false;
  espera: any;
  admin: boolean = false;

  @ViewChild('lineChart') lineChart;
  @ViewChild('lineChart2') lineChart2;

  chart1;
  chart2;

  grafico: boolean = false;
  cgrafico2: boolean = false;

  empresa: string = '';
  constructor(
    private servicio: RestauranteService,
    private spinner: NgxSpinnerService
  ) {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    const day = today.getDate();

    this.campaignOne = new FormGroup({
      start: new FormControl(new Date(year, month, 1)),
      end: new FormControl(new Date(year, month, day)),
    });

    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }

    if (JSON.parse(localStorage.getItem('usuario')) !== null) {
      this.admin = JSON.parse(localStorage.getItem('usuario')).admin;
    }

    if (this.admin) {
      this.cargarSucursales();
    } else {
      if (JSON.parse(localStorage.getItem('sucursal')) !== null) {
        this.sucursal = JSON.parse(localStorage.getItem('sucursal')).codigo;
      }
    }

    this.cargarData();
    this.cargarSelectores();
  }
  ngOnInit(): void {}

  ngAfterViewInit() {
    this.obtenerDatosGrafico();
    this.obtenerDatosGrafico2();
  }

  buscarFecha() {
    if (this.campaignOne.value.end) {
      console.log(
        this.campaignOne.value.start.toJSON(),
        this.campaignOne.value.end.toJSON()
      );
      this.cargarData();
      this.obtenerDatosGrafico();
      this.obtenerDatosGrafico2();
    }
  }

  cargarData() {
    //console.log(this.campaignOne.value.start.toJSON());
    let campos = {
      sucursal: this.sucursal,
      empresa: this.empresa,
    };
    this.spinner.show();
    /*this.espera = setInterval(() => {
      let bandera = true;
      this.cajas.forEach((element) => {
        console.log(element.verificador);
        bandera = bandera && element.verificador;
      });
      if (bandera) this.todosCargados();
    }, 100);*/
    this.servicio.dataDashboardCabecera(campos).subscribe((results) => {
      for (let i = 0; i < results.length; i++) {
        results[i].items = [];
        results[i].verificador = false;
        results[i].colapsado = false;
      }

      this.cajas = results;
      //console.log('CAJAS', this.cajas);

      for (let i = 0; i < this.cajas.length; i++) {
        let element = this.cajas[i];

        let busqueda = {
          sucursal: this.sucursal,
          empresa: this.empresa,
          desde: this.formatearFechaQuery(
            this.campaignOne.value.start.toJSON()
          ),
          hasta: this.formatearFechaQuery(
            this.campaignOne.value.end.toJSON(),
            '23:59:59'
          ),
          modulo: element.codigo,
        };
        //console.log('BUSQUEDA : ', busqueda);
        this.servicio.dataDashboardMovimiento(busqueda).subscribe((results) => {
          if (results !== null) {
            this.cajas[i].items = results;
            //console.log(busqueda,results);
          }

          this.cajas[i].verificador = true;
        });
      }
      //console.log("AQUI",this.cajas);
      this.spinner.hide();
    });
  }

  refreshvista() {
    this.cargarData();
    this.obtenerDatosGrafico();
    this.obtenerDatosGrafico2();
  }

  formatearFechaQuery(fecha = new Date(), hora = '00:00:00') {
    let fechaFin = new Date(fecha);
    let fechaTotal = fechaFin.toJSON().slice(0, 10);
    return fechaTotal + ' ' + hora;
  }

  todosCargados() {
    this.spinner.hide();
    clearInterval(this.espera);
    //console.log(this.cajas);
  }

  cargarSucursales() {
    console.log('cargando sucursales de la empresa', this.empresa);
    this.servicio
      .getConfiguraciones('015', this.empresa)
      .subscribe((result) => {
        if (result.length > 0) {
          this.sucursales = result;
        }
      });
  }

  cargarSelectores() {
    this.servicio
      .getConfiguraciones('026', this.empresa)
      .subscribe((result) => {
        if (result.length > 0) {
          this.selectores = result;
          this.selectores2 = result;
        }
      });
  }

  obtenerDatosGrafico() {
    let campos = {
      selector: this.selector,
      desde: this.formatearFechaQuery(this.campaignOne.value.start.toJSON()),
      hasta: this.formatearFechaQuery(
        this.campaignOne.value.end.toJSON(),
        '23:59:59'
      ),
      sucursal: this.sucursal,
      empresa: this.empresa,
    };
    //console.log(campos);
    //this.spinnerDialog.show("Cargando data","Espere por favor",true);
    this.servicio.consultarGrafico(campos).subscribe(
      (result) => {
        if (result['data'].length > 0) {
          this.grafico = true;
          this.grafico1(result);
        } else {
          console.log('SIN DATOS PARA MOSTRAR GRAFICO');
          this.grafico = false;
        }
        //this.spinnerDialog.hide();
      },
      (err) => {
        //console.log(err);

        if (err.name == 'HttpErrorResponse')
          this.alerta(
            'Error de conexión',
            'No se pudo conectar con el servidor',
            'error'
          );
        else this.alerta('Oops', 'algo salió mal', 'error');
      }
    );
  }

  grafico1(data) {
    if (this.chart1) this.chart1.destroy();
    let datasets = [];
    var colors = [
      '#2471A3',
      '#A93226',
      '#884EA0',
      '#17A589',
      '#229954',
      '#D4AC0D',
      '#CA6F1E',
      '#839192',
      '#2E4053',
      '#16A085',
    ];

    for (let i = 0; i < data['data']['length']; i++) {
      let miniData = {
        label: data['data'][i]['etiqueta'],
        data: data['data'][i]['valor'],
        backgroundColor: 'transparent',
        borderColor: colors[i],
        borderWidth: 1,
        pointBackgroundColor: colors[i],
      };
      datasets.push(miniData);
    }
    //console.log(datasets);

    var chartData = {
      labels: data['label'],
      datasets: datasets,
    };

    this.chart1 = new Chart(this.lineChart.nativeElement, {
      type: 'line',
      data: chartData,
      options: {
        elements: {
          line: {
            tension: 0.4,
          },
        },
        spanGaps: true,
        scales: {
          y: {
            type: 'linear',
          },
        },
      },
    });
  }

  obtenerDatosGrafico2() {
    let campos = {
      selector: this.selector2,
      desde: this.formatearFechaQuery(this.campaignOne.value.start.toJSON()),
      hasta: this.formatearFechaQuery(
        this.campaignOne.value.end.toJSON(),
        '23:59:59'
      ),
      sucursal: this.sucursal,
      empresa: this.empresa,
    };
    //console.log(campos);
    //this.spinnerDialog.show("Cargando data","Espere por favor",true);
    this.servicio.consultarGrafico2(campos).subscribe(
      (result) => {
        if (result['data'].length > 0) {
          this.cgrafico2 = true;
          this.grafico2(result);
        } else {
          console.log('SIN DATOS PARA MOSTRAR GRAFICO 2');
          this.cgrafico2 = false;
        }
        //this.spinnerDialog.hide();
      },
      (err) => {
        //console.log(err);

        if (err.name == 'HttpErrorResponse')
          this.alerta(
            'Error de conexión',
            'No se pudo conectar con el servidor',
            'error'
          );
        else this.alerta('Oops', 'algo salió mal', 'error');
      }
    );
  }
  grafico2(data) {
    if (this.chart2) this.chart2.destroy();
    let datasets = [];
    var colors = [
      '#2471A3',
      '#A93226',
      '#884EA0',
      '#17A589',
      '#229954',
      '#D4AC0D',
      '#CA6F1E',
      '#839192',
      '#2E4053',
      '#16A085',
    ];

    for (let i = 0; i < data['data']['length']; i++) {
      let miniData = {
        label: data['data'][i]['etiqueta'],
        data: data['data'][i]['valor'],
        backgroundColor: 'transparent',
        borderColor: colors[i],
        borderWidth: 1,
        pointBackgroundColor: colors[i],
      };
      datasets.push(miniData);
    }
    //console.log(datasets);

    var chartData = {
      labels: data['label'],
      datasets: datasets,
    };
    //console.log(chartData);
    //var ctx = document.getElementById('lineChart')
    //(this.lineChart.nativeElement
    this.chart2 = new Chart(this.lineChart2.nativeElement, {
      type: 'line',
      data: chartData,
      options: {
        elements: {
          line: {
            tension: 0.4,
          },
        },
        spanGaps: true,
        scales: {
          y: {
            type: 'linear',
          },
        },
      },
    });
  }

  async alerta(titulo, message, tipo) {
    Swal.fire({
      title: titulo,
      text: message,
      icon: tipo,
      confirmButtonText: 'Ok',
    });
  }

  colapsarItem(i) {
    //console.log(this.cajas[i].colapsado);
    this.cajas[i].colapsado = !this.cajas[i].colapsado;
  }
}
