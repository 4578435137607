import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ServicioMeseroService } from 'src/app/services/servicio-mesero/servicio-mesero.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-lineas',
  templateUrl: './modal-lineas.component.html',
  styleUrls: ['./modal-lineas.component.css'],
})
export class ModalLineasComponent implements OnInit {
  datos: Object; //<-- Debe tener el mismo nombre de la variable en factura
  productos: any = []; //<--Productos de esta linea traidos desde la DB
  hayProductos: boolean; //<--Verifica si hay lineas
  datosPersistentes: any = []; //<--Datos que van al localStorage
  total: number = 0; //<--Total para este articulo
  public event: EventEmitter<any> = new EventEmitter(); //<-- En este caso lo usamos para avisar al componente padre que cerramos (es como un @Output hijo a padre)

  //Bandera para spinner
  banderaSpinner: boolean = false;

  tipo;
  linea;
  agregoItems = false;
  empresa: string = '';
  constructor(
    public modalReferenciaLineas: BsModalRef,
    private servicio: ServicioMeseroService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
    this.linea = this.datos[0];
    let productos = this.datos[1];

    console.log();
    console.log(this.linea);
    this.spinner.show();
    let campos = {
      codigo: this.linea.codigo,
      usuario: JSON.parse(localStorage.getItem('usuario')).codigo,
      empresa: this.empresa,
    };
    this.servicio.obtenerItemsLinea(campos).subscribe((data) => {
      console.log(data);
      if (data.length === 0) {
        this.hayProductos = false;
      } else {
        this.productos = data;
        this.productos.forEach((producto) => {
          producto.nombre = producto.nombre.trim();
          producto.linea = producto.linea.trim();
          producto.cantidad = 0;
          this.total += producto.precio * producto.cantidad; //Calcula total
        });
        console.log(this.productos);
        this.hayProductos = true;
      }
      this.cargarProductos(productos);
      this.banderaSpinner = true;
    });
    setInterval(() => {
      if (this.banderaSpinner === true) {
        this.spinner.hide();
        this.banderaSpinner = false;
      }
    }, 100);
  }

  restarCantidad(i) {
    if (this.productos[i].cantidad > 0) this.productos[i].cantidad--;
    // let producto = this.buscarProducto(idactual);
  }

  aumentarCantidad(i) {
    this.productos[i].cantidad++;
    this.agregoItems = true;
  }

  aceptarProductos() {
    let nuevosProductos = [];
    this.productos.forEach((producto) => {
      if (producto.cantidad > 0) nuevosProductos.push(producto);
    });
    let info = {
      linea: this.linea.nombre,
      productos: nuevosProductos,
    };
    this.lanzarEvento(info);

    this.modalReferenciaLineas.hide(); //<-- Cierra el modal
  }

  lanzarEvento(data) {
    this.event.emit(data);
  }

  //Cargar datos del localStorage en la temporal
  cargarProductos(pedido) {
    console.log(this.productos);
    console.log(pedido);
    for (let i = 0; i < pedido.length; i++) {
      if (pedido[i].linea == this.linea.nombre) {
        for (let e = 0; e < this.productos.length; e++) {
          if (this.productos[e].codigo == pedido[i].codigo) {
            console.log('SI');
            this.productos[e].cantidad = pedido[i].cantidad;
          }
        }
      }
    }
  }

  alerta(titulo, texto, icono) {
    return Swal.fire({
      icon: icono,
      title: titulo,
      text: texto,
      allowOutsideClick: false,
    });
  }
}
