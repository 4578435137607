<!--Cabecera-->
<div class="row justify-content-center align-items-center my-2">
  <div class="col-9 col-md-10 text-center">
    <h3>
      Registro
      <small class="text-muted">Activo Fijo</small>
    </h3>
  </div>
  <div
    class="col-2 col-md-1 justify-content-center align-items-center"
    (click)="modalReferencia.hide()"
  >
    <button type="button" class="close pull-right" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>

<!--Botones Superiores para Inciar las Transaaciones-->
<div class="container my-1" [hidden]="ocultarBotones">
  <button
    type="button"
    class="btn btn-outline-success btn-sm btn_est m-2"
    (click)="crear()"
  >
    <i class="far fa-plus-square"></i> Crear
  </button>

  <button
    type="button"
    class="btn btn-outline-warning btn-sm btn_est btn_est m-2"
    (click)="actualizar()"
  >
    <i class="fas fa-spinner"></i> Actualizar
  </button>

  <button
    type="button"
    class="btn btn-outline-danger btn-sm btn_est m-2"
    (click)="eliminar()"
  >
    <i class="fas fa-times-circle"></i> Eliminar
  </button>
</div>

<!--Datos del Cliente-->
<div class="container">
  <div class="row justify-content-center align-items-center">
    <div class="col-4 col-md-3 text-md-right">
      <span>Tipo Activo Fijo : </span>
    </div>
    <div class="col-7 col-md-3 my-1">
      <select
        class="form-select form-select-sm"
        aria-label="Default select example"
        [(ngModel)]="tipoActivo"
        [disabled]="campos_bloquear"
      >
        <option *ngFor="let tipo of tiposActivo" [value]="tipo.codigo">
          {{ tipo.nombre }}
        </option>
      </select>
    </div>

    <div class="col-4 col-md-2 text-md-right">
      <span class="mr-4">Serie :</span>
    </div>

    <div class="col-7 col-md-3 my-1">
      <input
        type="text"
        class="form-control form-control-sm"
        placeholder="Ingrese serie"
        style="max-width: 150px"
        [(ngModel)]="serie"
        [disabled]="campos_bloquear"
      />
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Nombre : </span>
    </div>

    <div class="col-11 col-md-8 my-1">
      <input
        type="text"
        class="form-control form-control-sm"
        placeholder="Ingrese el Nombre Completo"
        [(ngModel)]="nombre"
        [disabled]="campos_bloquear"
      />
    </div>

    <div class="col-3 col-md-3 text-md-right">
      <span>Valor : </span>
    </div>
    <div class="col-8 col-md-8 my-1">
      <div class="d-flex">
        <input
          type="numeric"
          class="form-control form-control-sm"
          placeholder="00"
          style="width: 100px; text-align: right"
          appOnlynumber
          [(ngModel)]="valor[0]"
          [disabled]="campos_bloquear"
        />
        <span style="font-size: 24px">.</span>
        <input
          type="numeric"
          class="form-control form-control-sm"
          placeholder="00"
          appOnlynumber
          style="width: 52px"
          [(ngModel)]="valor[1]"
          [disabled]="campos_bloquear"
        />
      </div>
    </div>

    <div class="col-3 col-md-3 text-md-right">
      <span>Fecha Compra : </span>
    </div>
    <div class="col-8 col-md-3 my-1">
      <input
        type="date"
        class="form-control form-control-sm"
        [(ngModel)]="fechaCompra"
        [disabled]="campos_bloquear"
      />
    </div>

    <div class="col-11 col-md-5 text-md-right">
      <div class="d-flex" style="align-items: center">
        <span class="mr-4">Año Compra : </span>
        <select
          class="form-select form-select-sm"
          aria-label="Default select example"
          style="width: 100px"
          [(ngModel)]="yearSelected"
          [disabled]="campos_bloquear"
        >
          <option *ngFor="let year of years" [value]="year">{{ year }}</option>
        </select>
      </div>
    </div>

    <div class="col-3 col-md-3 text-md-right">
      <span>Habilitado : </span>
    </div>
    <div class="col-8 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-8">
          <div class="form-check form-switch" style="padding-left: 2.5rem">
            <input
              class="form-check-input"
              type="checkbox"
              [(ngModel)]="habilitado"
              [disabled]="campos_bloquear"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Comentario : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-8">
          <textarea
            class="form-control form-control-sm"
            rows="3"
            [(ngModel)]="comentario"
            [disabled]="campos_bloquear"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Botones Inferiores de Transaccion-->
<div class="container text-right my-1">
  <button
    type="button"
    class="btn btn-outline-success btn-sm btn_est m-2"
    (click)="guardarActivoFijo()"
    *ngIf="tipo_consulta == 'create'"
  >
    <i class="fas fa-check-circle"></i> Guardar
  </button>
  <button
    type="button"
    class="btn btn-outline-success btn-sm btn_est m-2"
    (click)="actualizarActivoFijo()"
    *ngIf="tipo_consulta == 'update'"
  >
    <i class="fas fa-check-circle"></i> Actualizar
  </button>

  <button
    type="button"
    class="btn btn-outline-danger btn-sm btn_est m-2"
    (click)="cancelar()"
  >
    <i class="fas fa-times-circle"></i> Cancelar
  </button>
</div>

<app-spinner></app-spinner>
