<div class="modal-header">
  <h4 class="modal-title" id="titulo">
    {{ titulo }} - <span class="text-muted">{{ selector }}</span>
  </h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="modalReferencia.hide()"
  >
    <span aria-hidden="true">&times; </span>
  </button>
</div>
<div class="container">
  <canvas #lineChart2></canvas>
</div>
