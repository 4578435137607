<div
  class="row justify-content-center align-items-center pantalla"
  style="background-color: #ecf0f1"
>
  <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
    <div
      class="card align-items-center"
      style="border: 1px solid #2ecc71; background-color: #2980b9"
    >
      <img class="card-img-top logo my-5" src="assets/user.svg" alt="logo" />
      <P class="lblempresa">NOVA GROUP</P>
      <div class="card-body" style="background-color: #fff; width: 100%">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-10">
              <mat-form-field
                appearance="fill"
                class="no-padding"
                style="width: 100%"
              >
                <mat-label>Seleccione un mesero</mat-label>

                <mat-select
                  panelClass="my-select-panel-class"
                  [(value)]="mesero"
                >
                  <div style="display: flex; justify-content: center">
                    <mat-form-field
                      class="example-full-width busqueda"
                      style="padding-top: 10px"
                    >
                      <mat-label>Buscar</mat-label>
                      <input
                        matInput
                        [(ngModel)]="busqueda"
                        (keyup)="onKey()"
                        (keydown)="$event.stopPropagation()"
                      />
                    </mat-form-field>
                  </div>

                  <mat-option
                    *ngFor="let mesero of meseros"
                    [value]="mesero.codigo"
                    >{{ mesero.nombre }}</mat-option
                  >
                </mat-select>
              </mat-form-field>

              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1"
                    ><i class="fas fa-unlock-alt"></i
                  ></span>
                </div>
                <input
                  type="password"
                  class="form-control form-control-sm"
                  placeholder="Clave"
                  aria-label="Clave"
                  aria-describedby="basic-addon1"
                  name="password"
                  [(ngModel)]="password"
                  required
                  pattern="[A-Za-z0-9]+"
                  (keyup.enter)="ingresa()"
                />
              </div>

              <div class="mb-3">
                <div class="row">
                  <div class="col-6">
                    <button
                      class="btn btn-outline-success w-100"
                      (click)="mainLogin()"
                    >
                      <i class="far fa-arrow-alt-circle-left"></i> Regresar
                    </button>
                  </div>
                  <div class="col-6">
                    <button
                      class="btn btn-outline-success w-100"
                      (click)="ingresa()"
                    >
                      Ingresar <i class="far fa-check-circle"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-spinner></app-spinner>
