import { Component, OnInit, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-grafico-zoom',
  templateUrl: './grafico-zoom.component.html',
  styleUrls: ['./grafico-zoom.component.css'],
})
export class GraficoZoomComponent implements OnInit {
  datos: Object; //<-- Debe tener el mismo nombre de la variable en factura
  @ViewChild('lineChart2') lineChart2;
  chart1;
  datosGrafico: any;
  titulo = '';
  selector = '';
  constructor(public modalReferencia: BsModalRef) {}

  ngOnInit(): void {
    this.datosGrafico = this.datos[0];
    this.titulo = this.datos[1];
    this.selector = this.datos[2];
  }

  ngAfterViewInit() {
    this.chart1 = new Chart(this.lineChart2.nativeElement, this.datosGrafico);
  }
}
