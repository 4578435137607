<div class="container-fluid">
  <div class="row mb-3">
    <div class="col-12" style="text-align: center">
      <h3>
        Informe de
        <small class="text-muted">Perdida Ganancia</small>
      </h3>
    </div>
  </div>
</div>
<div class="container mb-3">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-6 d-flex justify-content-center">
          <mat-form-field class="example-form-field mr-2" appearance="fill">
            <mat-label>Rango de fecha</mat-label>
            <mat-date-range-input
              [formGroup]="campaignOne"
              [rangePicker]="campaignOnePicker"
            >
              <input
                matStartDate
                placeholder="Inicio"
                formControlName="start"
              />
              <input
                matEndDate
                placeholder="Fin"
                formControlName="end"
                (dateChange)="buscarFecha()"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="campaignOnePicker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
          </mat-form-field>
        </div>
        <div class="col-12 col-md-6 col-lg-6 d-flex justify-content-center">
          <select
            class="form-select"
            aria-label="Default select example"
            [(ngModel)]="sucursal"
            style="height: 40px; width: 220px"
            (change)="cargarModulos()"
          >
            <option value="TODOS">Todos</option>
            <option
              *ngFor="let sucursal of sucursales"
              [value]="sucursal.codigo.trim()"
            >
              {{ sucursal.nombre }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-md-6 col-lg-6 mb-3">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">{{ titulos[0] }}</h5>
          <app-informe-card #tarjeta1> </app-informe-card>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-6 mb-3">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">{{ titulos[1] }}</h5>
          <app-informe-card #tarjeta2> </app-informe-card>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-6 mb-3">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">{{ titulos[2] }}</h5>
          <app-informe-card #tarjeta3> </app-informe-card>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-6 mb-3">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">{{ titulos[3] }}</h5>
          <app-informe-card #tarjeta4> </app-informe-card>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container mb-5">
  <div class="d-flex" style="justify-content: flex-end">
    <span class="mr-3" style="font-weight: bold; font-size: 20px"
      >Utilidad Bruta:
    </span>
    <span style="font-size: 20px">{{ utilidad_bruta.toFixed(2) }}</span>
  </div>

  <div>
    <h5>Activos Fijos</h5>
    <select
      class="form-select"
      aria-label="Default select example"
      style="width: 200px"
      [(ngModel)]="selectorTabla"
      (change)="cargarTabla()"
    >
      <option *ngFor="let selector of selectores" [value]="selector.orden">
        {{ selector.nombre }}
      </option>
    </select>

    <table class="table">
      <thead class="thead-dark">
        <tr>
          <th
            scope="col"
            *ngFor="let dato of cabecera"
            [style.text-align]="dato.align"
          >
            {{ dato.alias }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let mov of movimiento">
          <td *ngFor="let dato of cabecera" [style.text-align]="dato.align">
            <div>{{ mov[dato.campo.trim()] }}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
