<!--Cabecera del modal-->
<div class="row justify-content-center align-items-center my-2">
  <div class="col-9 col-md-10 text-center">
    <h3>
      {{ titulo }}
      <small class="text-muted">{{ subtitulo }}</small>
    </h3>
  </div>
  <div
    class="col-2 col-md-1 justify-content-center align-items-center"
    (click)="modalReferencia.hide()"
  >
    <button type="button" class="close pull-right" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>

<!--Botones de Transacciones-->
<div class="container my-1" [hidden]="ocultarBotones" *ngIf="usuario.admin">
  <button
    type="button"
    class="btn btn-outline-success btn-sm btn_est m-2"
    (click)="crearProducto()"
  >
    <i class="far fa-plus-square"></i> Crear
  </button>

  <button
    type="button"
    class="btn btn-outline-warning btn-sm btn_est btn_est m-2"
    (click)="actualizarProducto()"
  >
    <i class="fas fa-spinner"></i> Actualizar
  </button>

  <button
    type="button"
    class="btn btn-outline-danger btn-sm btn_est m-2"
    (click)="confirmaBorrar()"
  >
    <i class="fas fa-times-circle"></i> Eliminar
  </button>
</div>

<!--Datos del Producto-->
<div class="container mb-4">
  <div class="row justify-content-center align-items-center">
    <div class="col-11 col-md-3 text-md-right" [hidden]="ocultarCodigo">
      <span>Código : </span>
    </div>
    <div class="col-11 col-md-8 my-1" [hidden]="ocultarCodigo">
      <div class="row">
        <div class="col-12 col-lg-8">
          <input
            disabled
            type="text"
            class="form-control form-control-sm"
            placeholder="Ingrese el código"
            [(ngModel)]="producto.codigo"
          />
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Nombre : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-8">
          <input
            [disabled]="habilitarEstado"
            type="text"
            class="form-control form-control-sm"
            placeholder="Ingrese el nombre"
            [(ngModel)]="producto.nombre"
          />
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Precio : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-4">
          <input
            [disabled]="habilitarEstado"
            type="numeric"
            class="form-control form-control-sm text-right"
            placeholder="0,00"
            [(ngModel)]="producto.precio"
          />
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>% Tarifa : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-4">
          <input
            [disabled]="habilitarEstado"
            type="numeric"
            class="form-control form-control-sm text-right"
            placeholder="0"
            [(ngModel)]="producto.porc_tarifa"
            appOnlynumber
          />
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Línea : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-8">
          <select
            class="form-select form-select-sm"
            [disabled]="habilitarEstado"
            name="linea"
            [(ngModel)]="lineaEscogida"
          >
            <option *ngFor="let linea of lineas" [value]="linea.codigo">
              {{ linea.nombre }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Grupo : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-8">
          <select
            class="form-select form-select-sm"
            [disabled]="habilitarEstado"
            name="grupo"
            [(ngModel)]="grupoEscogido"
          >
            <option *ngFor="let grupo of grupos" [value]="grupo.codigo">
              {{ grupo.nombre }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Unidad medida: </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-8">
          <select
            class="form-select form-select-sm"
            [disabled]="habilitarEstado"
            name="grupo"
            [(ngModel)]="producto.medida"
          >
            <option *ngFor="let und of unidades" [value]="und.codigo">
              {{ und.nombre }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Permitir Compra: </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-8">
          <div class="form-check form-switch" style="padding-left: 2.5em">
            <input
              class="form-check-input"
              [disabled]="habilitarEstado"
              type="checkbox"
              id="producto_compra"
              [(ngModel)]="producto.compra"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Inventario Cocina : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-8">
          <div class="form-check form-switch" style="padding-left: 2.5em">
            <input
              class="form-check-input"
              [disabled]="habilitarEstado"
              type="checkbox"
              id="producto_inv_cocina"
              [(ngModel)]="producto.inv_cocina"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Mostrar en Aplicaciones : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-8">
          <div class="form-check form-switch" style="padding-left: 2.5em">
            <input
              class="form-check-input"
              [disabled]="habilitarEstado"
              type="checkbox"
              id="producto_mostrar_app"
              [(ngModel)]="producto.mostrar_app"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Cantidad Viandas : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-4">
          <input
            [disabled]="habilitarEstado"
            type="numeric"
            class="form-control form-control-sm text-right"
            placeholder="0"
            [(ngModel)]="producto.cant_viandas"
            appOnlynumber
          />
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Bloqueado: </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-8">
          <div class="form-check form-switch" style="padding-left: 2.5em">
            <input
              class="form-check-input"
              [disabled]="habilitarEstado"
              type="checkbox"
              id="flexSwitchCheckDefault"
              [(ngModel)]="producto.bloqueado"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Botones Inferiores de las transacciones-->
<div class="container text-right my-1" [hidden]="ocultarBotonesPie">
  <button
    type="button"
    class="btn btn-outline-success btn-sm btn_est m-2"
    (click)="enviarProducto()"
  >
    <i class="fas fa-check-circle"></i> Guardar
  </button>

  <button
    type="button"
    class="btn btn-outline-danger btn-sm btn_est m-2"
    (click)="cancelar()"
  >
    <i class="fas fa-times-circle"></i> Cancelar
  </button>
</div>

<app-spinner></app-spinner>
