import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalService } from '../global.service';

@Injectable({
  providedIn: 'root',
})
export class ServicioCocinaService {
  constructor(private http: HttpClient, public global: GlobalService) {}
  //Confirmar pedido
  confirmarPedido(datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.put<any>(
      this.global.servidor + `/api/pedidos/confirmaPedido`,
      body,
      httpOptions
    );
  }

  //Traer las ordenes de la cocina para una sucursal
  cargarPedidosCocinaSucursal(campos): Observable<any> {
    return this.http.get<any>(
      this.global.servidor +
        `/api/pedidos/pedidosSucursal?empresa=${campos.empresa}&sucursal=${campos.sucursal}`
    );
  }
}
