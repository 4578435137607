import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { ModalCRUDActivoComponent } from '../modal-crud-activo/modal-crud-activo.component';

@Component({
  selector: 'app-registro-activo',
  templateUrl: './registro-activo.component.html',
  styleUrls: ['./registro-activo.component.css'],
})
export class RegistroActivoComponent implements OnInit {
  modalReferencia: BsModalRef;
  activos: any = [];

  busqueda = '';
  empresa: string = '';
  constructor(
    private service: RestauranteService,
    private modalServicio: BsModalService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
    this.cargarDatos();
  }

  cargarDatos(opcion = 1, filtro = '') {
    let datos = {
      opcion: opcion,
      filtro: filtro,
      empresa: this.empresa,
    };
    this.spinner.show();
    this.service.getActivosFijos(datos).subscribe((result) => {
      console.log(result);
      this.activos = result;
      this.spinner.hide();
    });
  }

  buscar() {
    this.cargarDatos(2, this.busqueda);
  }

  modalActivo(tipo, activo = {}) {
    const initialState: any = {
      tipo_consulta: tipo,
      datosActivo: activo,
    };
    this.modalReferencia = this.modalServicio.show(ModalCRUDActivoComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });
    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((res) => {
      console.log('MODAL CERRADO, DATA RECIBIDA', res.data);
      this.busqueda = '';
      this.cargarDatos();
    });
  }
}
