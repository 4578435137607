import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { typeWithParameters } from '@angular/compiler/src/render3/util';

@Component({
  selector: 'app-modal-lineas',
  templateUrl: './modal-lineas.component.html',
  styleUrls: ['./modal-lineas.component.css'],
})
export class ModalLineasComponent implements OnInit {
  datos: Object; //<-- Debe tener el mismo nombre de la variable en factura
  productos: any = []; //<--Productos de esta linea traidos desde la DB
  hayProductos: boolean; //<--Verifica si hay lineas
  datosPersistentes: any = []; //<--Datos que van al localStorage
  total: number = 0; //<--Total para este articulo
  public event: EventEmitter<any> = new EventEmitter(); //<-- En este caso lo usamos para avisar al componente padre que cerramos (es como un @Output hijo a padre)
  rucCliente: any; //<--RUC cliente
  idLinea: any; //<--ID de la linea a abrir
  nomLinea: any; //<--Nombre de la linea a abrir
  //Bandera para spinner
  banderaSpinner: boolean = false;
  agregoItems = false;
  empresa: string = '';

  constructor(
    public modalReferenciaLineas: BsModalRef,
    private servicio: RestauranteService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
    this.idLinea = this.datos[0]; //<--Tomando ID de la linea
    this.rucCliente = this.datos[1]; //<--Tomando RUC de cliente
    this.nomLinea = this.datos[2]; //<--Tomando Nombre de la Linea
    this.spinner.show();
    let campos = {
      codigo: this.idLinea,
      usuario: JSON.parse(localStorage.getItem('usuario')).codigo,
      empresa: this.empresa,
    };
    this.servicio.obtenerItemsLinea(campos).subscribe(
      (data) => {
        if (data.length === 0) {
          this.hayProductos = false;
        } else {
          this.productos = data;
          let linea = this.productos[0].linea.trim(); //<--Recupero la linea
          this.productos.forEach((producto) => {
            producto.nombre = producto.nombre.trim();
            producto.linea = producto.linea.trim();
            producto.medida = producto.medida.trim();
            producto.cantidad = 0;
            producto.descuento = 0;
            this.total = this.total + producto.precio * producto.cantidad;
          });

          //console.log(this.productos);
          this.hayProductos = true;
          //Si hay datos almacenados en localStorage los traigo y actualizo los datos temporales
          this.cargaLocalStorage(linea);
        }
        this.banderaSpinner = true;
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
    setInterval(() => {
      if (this.banderaSpinner === true) {
        this.spinner.hide();
        this.banderaSpinner = false;
      }
    }, 100);
  }

  restarCantidad(idactual) {
    let producto = this.buscarProducto(idactual);
    if (producto.cantidad > 0) {
      producto.cantidad--;
      //Guardando en LocalStorage
      this.guardaLocalStorage(producto, 'resta');
    }
  }

  aumentarCantidad(idactual) {
    let producto = this.buscarProducto(idactual);
    producto.cantidad++;
    //Guardando en LocalStorage
    this.guardaLocalStorage(producto, 'suma');
    this.agregoItems = true;
  }

  //Busca producto en array de productos y lo devuelve
  buscarProducto(id) {
    for (let indice in this.productos) {
      //<-- Itera todos los indices de producto
      let producto = this.productos[indice]; //<-- Toma el i-esimo producto
      if (producto.codigo == id) {
        //Compara el id producto con el id del producto a buscar
        return producto;
      }
    }
  }

  //Cerrar modal y enviar la data a factura
  cerrarModal() {
    this.lanzarEvento();
    /*if (this.agregoItems)
      this.alerta('Éxito', 'Productos agregados', 'success').then(() => {
        this.modalReferenciaLineas.hide(); //<-- Cierra el modal
      });
    else*/
    this.modalReferenciaLineas.hide(); //<-- Cierra el modal
  }

  //Indicamos al padre que hemos devuelto algo, en este caso el modal cerrado
  lanzarEvento() {
    this.event.emit();
  }

  //METODOS DE MANEJO DEL LOCALSTORAGE (MANEJAR CON PRUDENCIA)

  //Guarda en el localStorage
  guardaLocalStorage(producto, operacion) {
    //No existe el objeto en LocalStorage
    if (!localStorage.getItem(this.rucCliente.toString())) {
      //<--No existe nada y creamos el primer item
      let info = {
        linea: producto.linea,
        productos: [
          {
            codigo: producto.codigo,
            nombre: producto.nombre,
            precio: producto.precio,
            cantidad: producto.cantidad,
            porc_tarifa: producto.porc_tarifa,
            medida: producto.medida,
            descuento: producto.descuento,
          },
        ],
      };
      this.datosPersistentes.push(info);
      localStorage.setItem(
        this.rucCliente.toString(),
        JSON.stringify(this.datosPersistentes)
      );
    }
    //Existe el objeto en LocalStorage
    else {
      this.datosPersistentes = JSON.parse(
        localStorage.getItem(this.rucCliente.toString())
      );
      //console.log(this.datosPersistentes);
      let control = false; //<--Iniciamos asumiendo que la linea no existe en localStorage
      this.datosPersistentes.forEach((item) => {
        //console.log(item);
        if (item.linea === producto.linea) {
          control = true; //<--La linea si existe
          let itemsLinea = item.productos;
          let control2 = false; //<--Iniciamos asumiendo que el producto no existe
          itemsLinea.forEach((itemLinea) => {
            if (itemLinea.codigo === producto.codigo) {
              control2 = true;
              itemLinea.cantidad = producto.cantidad;
            }
          });
          //Si control2 es true el producto ya existe y fue procesado arriba, caso contrario lo agregamos aqui
          if (control2 === false) {
            itemsLinea.push({
              codigo: producto.codigo,
              nombre: producto.nombre,
              precio: producto.precio,
              cantidad: producto.cantidad,
              porc_tarifa: producto.porc_tarifa,
              medida: producto.medida,
              descuento: producto.descuento,
            });
          }
        }
      });
      //Si control es true la linea ya existe y fue procesada arriba, caso contrario la agregamos aqui
      if (control === false) {
        let info = {
          linea: producto.linea,
          productos: [
            {
              codigo: producto.codigo,
              nombre: producto.nombre,
              precio: producto.precio,
              cantidad: producto.cantidad,
              porc_tarifa: producto.porc_tarifa,
              medida: producto.medida,
              descuento: producto.descuento,
            },
          ],
        };
        this.datosPersistentes.push(info);
      }
    }
    localStorage.setItem(
      this.rucCliente.toString(),
      JSON.stringify(this.datosPersistentes)
    );
    //Actualiza la variable suma temporal
    if (operacion === 'suma') {
      this.total = Number(this.total) + Number(producto.precio);
    }
    if (operacion === 'resta') {
      this.total = Number(this.total) - Number(producto.precio);
    }
    return;
  }

  //Cargar datos del localStorage en la temporal
  cargaLocalStorage(linea) {
    if (localStorage.getItem(this.rucCliente.toString())) {
      let datosPersistentes = JSON.parse(
        localStorage.getItem(this.rucCliente.toString())
      );
      datosPersistentes.forEach((item) => {
        if (item.linea === linea) {
          let productos = item.productos;
          this.productos.forEach((producto) => {
            productos.forEach((elemento) => {
              if (producto.codigo === elemento.codigo) {
                producto.cantidad = elemento.cantidad;
                this.total = this.total + producto.precio * producto.cantidad;
              }
            });
          });
        }
      });
    }
  }
  alerta(titulo, texto, icono) {
    return Swal.fire({
      icon: icono,
      title: titulo,
      text: texto,
      allowOutsideClick: false,
    });
  }
}
