<div class="modal-header">
  <h4 class="modal-title" id="titulo">Buscar Cliente</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="modalReferencia.hide()"
  >
    <span aria-hidden="true">&times; </span>
  </button>
</div>

<div class="container">
  <div class="mt-3">
    <div class="form-group row">
      <label for="nombre" class="col-sm-5 col-form-label"
        >Ingrese el nombre:
      </label>
      <div class="col-sm-7">
        <input
          type="text"
          class="form-control"
          id="nombre"
          placeholder="Buscar..."
          (keyup.enter)="traerClientes(2, $event)"
          [(ngModel)]="rucCliente"
        />
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div
    class="table table-responsive table-hover table-responsive-sm table-responsive-md table-responsive-xl"
    *ngIf="hayClientes === true; else noItems"
  >
    <table class="table table-sm">
      <thead>
        <tr>
          <th scope="col">Clientes</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let cliente of clientes">
          <td id="{{ cliente.codigo }}" (click)="cerrarModal(cliente)">
            {{ cliente.nombre }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<!--Boton-->
<div class="container text-right my-1" *ngIf="hayClientes === true">
  <button
    type="button"
    class="btn btn-outline-danger btn-sm btn_est m-2"
    (click)="modalReferencia.hide()"
  >
    <i class="fas fa-times-circle"></i> Cancelar
  </button>
</div>

<!--Busqueda sin resultados-->
<ng-template #noItems>
  <ng-container>
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-12 col-md-6 col-lg-4 mt-5" style="text-align: center">
          <h3>
            <small class="text-muted">Búsqueda sin resultados</small>
          </h3>

          <lottie-player
            src="https://assets2.lottiefiles.com/temp/lf20_TOE9MF.json"
            background="transparent"
            speed="1"
            loop
            autoplay
          ></lottie-player>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
