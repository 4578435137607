<!--CABECERA-->
<div class="container-fluid pt-3">
  <div class="row my-2 align-items-center">
    <div class="col-12 d-flex align-items-center">
      <button
        type="button"
        class="btn btn-outline-success btn-sm m-1"
        (click)="cargarMesas()"
      >
        <i class="fas fa-sync"></i>
      </button>

      <h4 class="m-0 pl-2">
        Meseros
        <small class="text-muted">Gestión de Mesas</small>
      </h4>

      <button
        type="button"
        class="btn btn-outline-danger mx-2"
        (click)="mostrarAlertaAddMesa()"
      >
        AGREGAR MESA
      </button>
      <!--<button
        type="button"
        class="btn btn-outline-primary w-100"
        (click)="unir()"
      >
        UNIR MESAS
      </button>-->
    </div>
  </div>
</div>

<div class="container-fluid pt-3">
  <div
    class="d-flex"
    style="
      justify-content: space-between;
      align-items: baseline;
      flex-wrap: wrap;
    "
  >
    <div *ngIf="admin">
      <select
        class="form-select"
        aria-label="Default select example"
        [(ngModel)]="sucursal"
        style="height: 40px; width: 220px"
        *ngIf="admin"
        (change)="cambiarSucursal()"
      >
        <option value="TODOS">Todos</option>
        <option
          *ngFor="let sucursal of sucursales"
          [value]="sucursal.codigo.trim()"
        >
          {{ sucursal.nombre }}
        </option>
      </select>
    </div>
    <!--<div class="botones" *ngIf="pedidos.length > 0">
      <div
        class="text-center mr-2"
        style="
          height: 28px;
          width: 40px;
          border-radius: 7px;
          border-style: solid;
          border-width: 2px;
          border-color: rgb(68, 68, 68);
        "
        [style.background]="estados.creado"
      >
        <p style="margin: 0px">C</p>
      </div>
      <div
        class="text-center mr-2"
        style="
          height: 28px;
          width: 40px;
          border-radius: 7px;
          border-style: solid;
          border-width: 2px;
          border-color: rgb(68, 68, 68);
        "
        [style.background]="estados.unido"
      >
        <p style="margin: 0px">U</p>
      </div>
      <div
        class="text-center mr-2"
        style="
          height: 28px;
          width: 40px;
          border-radius: 7px;
          border-style: solid;
          border-width: 2px;
          border-color: rgb(68, 68, 68);
        "
        [style.background]="estados.dividido"
      >
        <p style="margin: 0px">D</p>
      </div>
    </div>-->
  </div>

  <div class="row mt-4">
    <!--<div class="col-lg-3 mb-4" *ngFor="let pedido of pedidos; index as i">
      <div
        class="card pedido-tarjeta"
        (click)="abrirModalPedido(pedido, i)"
        [style.background]="pedido.estado"
        (mouseover)="cambiaColor(pedido)"
        (mouseout)="sinColor(pedido)"
      >
        <div class="card-body">
          <p>Mesa: {{ pedido.mesa }}</p>
          <p>Cliente: {{ pedido.nomcli }}</p>
          <p>Total: ${{ pedido.total.toFixed(2) }}</p>
          <p>Items: {{ pedido.items }}</p>
        </div>
      </div>
    </div>-->

    <div class="col-lg-3 mb-4" *ngFor="let pedido of pedidos; index as i">
      <div
        class="card pedido-tarjeta tarjeta"
        (click)="abrirModalPedido(pedido, i)"
        style="background-color: rgb(70, 70, 70)"
      >
        <div class="card-body">
          <div class="text-center">
            <span style="font-size: 1.5rem; color: white">{{
              pedido.mesa
            }}</span>
          </div>
          <hr style="color: white; margin-top: 0" />
          <p class="font-weight-bold" style="color: white; margin: 0">
            {{ pedido.nomcli }}
          </p>
          <p
            style="color: white; margin: 0"
            *ngFor="let producto of pedido.productos"
          >
            <span class="font-weight-bold">{{ producto.cantidad }}x</span>
            {{ producto.nombre }}
          </p>
          <div
            class="d-flex"
            style="justify-content: space-between; align-items: baseline"
          >
            <p style="color: white">Total:</p>
            <p style="color: white; font-size: 1.2rem; font-weight: bold">
              $ {{ pedido.total.toFixed(2) }}
            </p>
          </div>
          <div class="text-right"></div>
        </div>
      </div>
    </div>
  </div>
</div>
