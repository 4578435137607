<!--Cabecera-->
<div class="row justify-content-center align-items-center my-2">
  <div class="col-9 col-md-10 text-center">
    <h3>
      Tabla de
      <small class="text-muted">Depreciación</small>
    </h3>
  </div>
  <div
    class="col-2 col-md-1 justify-content-center align-items-center"
    (click)="modalReferencia.hide()"
  >
    <button type="button" class="close pull-right" aria-label="Close">
      <i class="fas fa-times"></i>
    </button>
  </div>
</div>

<div class="container-md">
  <div class="d-flex mx-3" style="justify-content: space-between">
    <div>
      <div class="d-flex">
        <span class="font-weight-bold mr-2">Descripcion :</span>
        <p style="margin: 0">{{ datosActivo.nombre }}</p>
      </div>

      <div class="d-flex">
        <span class="font-weight-bold mr-2">Valor :</span>
        <p style="margin: 0">{{ datosActivo.valor }}</p>
      </div>
    </div>
    <div>
      <p class="font-weight-bold" style="margin: 0">
        {{ datosActivo.anio_compra }} -
        {{ datosActivo.anio_compra + datosActivo.vigencia }}
      </p>
    </div>
    <div>
      <div class="d-flex">
        <span class="font-weight-bold mr-2">T.AF :</span>
        <p style="margin: 0">{{ datosActivo.nomtipoactivo }}</p>
      </div>

      <div class="d-flex">
        <span class="font-weight-bold mr-2">Vig :</span>
        <p style="margin: 0">{{ datosActivo.vigencia }} años</p>
      </div>

      <div class="d-flex">
        <span class="font-weight-bold mr-2">Dev:</span>
        <span>{{ datosActivo.porc }}%</span>
      </div>
    </div>
  </div>
  <table class="table">
    <thead>
      <tr>
        <th scope="col">Años</th>
        <th scope="col">Valor</th>
        <th scope="col">Porcentaje</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let valor of tabla">
        <td>{{ valor.anio }}</td>
        <td>{{ valor.valor.toFixed(2) }}</td>
        <td>{{ valor.porc }}%</td>
      </tr>
    </tbody>
  </table>
</div>
