<div class="modal-header">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-2 text-center" *ngIf="tipoModal == 'Modificar'">
        <button class="btn btn-outline-primary btn-sm" (click)="agregarItem()">
          <i class="fas fa-plus mr-2"></i>Agregar
        </button>
      </div>
      <div class="col-12 col-md-10">
        <h4 class="modal-title" id="titulo">
          Receta de {{ datos["nombre"] }} # {{ datos["cantidad"] }}
        </h4>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-md-3" *ngFor="let receta of recetas; index as i">
      <div class="card my-3">
        <div class="card-header" *ngIf="tipoModal == 'Modificar'">
          <button
            class="btn btn-outline-danger btn-sm w-100"
            (click)="eliminarItem(i)"
          >
            <i class="far fa-trash-alt"></i> Quitar Item
          </button>
        </div>
        <div class="card-body">
          <div class="d-flex" style="justify-content: space-between">
            <div>
              <div *ngFor="let ingrediente of receta; index as e">
                <div class="form-check">
                  <input
                    id="{{ i }}{{ e }}"
                    class="form-check-input"
                    type="checkbox"
                    [(ngModel)]="ingrediente.solicitado"
                  />
                  <label for="{{ i }}{{ e }}" class="form-check-label">
                    {{ ingrediente.nombre }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="text-center mb-3">
  <button class="btn btn-outline-success" (click)="cerrarModal()">
    <i class="far fa-check-circle"></i>
    Aceptar
  </button>
</div>
