<!--Cabecera-->
<div class="row justify-content-center align-items-center my-2">
  <div class="col-9 col-md-10 text-center">
    <h3>
      Nuevo
      <small class="text-muted">{{ tco.nombre }}</small>
    </h3>
  </div>
  <div
    class="col-2 col-md-1 justify-content-center align-items-center"
    (click)="modalReferencia.hide()"
  >
    <button type="button" class="close pull-right" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>

<div class="container">
  <div class="row justify-content-center align-items-center">
    <div class="col-11 col-md-3 text-md-right">
      <span>Nombre : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <input
        type="text"
        class="form-control form-control-sm"
        id="nombre"
        placeholder="Ingrese el nombre"
        [(ngModel)]="nombre"
      />
    </div>
  </div>
  <div *ngIf="tco.codigo.trim() == '023'">
    <div class="row justify-content-center align-items-center">
      <div class="col-11 col-md-3 text-md-right">
        <span> Valor: </span>
      </div>
      <div class="col-11 col-md-8 my-1 d-flex">
        <div class="d-flex mr-3" style="align-items: baseline">
          <input
            type="numeric"
            class="form-control form-control-sm"
            [(ngModel)]="usuario.quincena[0]"
            style="max-width: 100px; text-align: right"
            appOnlynumber
          />
          <div class="mx-1" style="font-size: 24px">.</div>
          <input
            type="numeric"
            class="form-control form-control-sm"
            [(ngModel)]="usuario.quincena[1]"
            style="max-width: 80px"
            appOnlynumber
          />
        </div>
        <select
          class="form-select form-select-sm"
          aria-label="Default select example"
          [(ngModel)]="usuario.sucursal"
        >
          <option *ngFor="let und of unidades" [value]="und.codigo.trim()">
            {{ und.nombre }}
          </option>
        </select>
      </div>
    </div>

    <div class="row justify-content-center align-items-center">
      <div class="col-11 col-md-3 text-md-right">
        <span> Valor: </span>
      </div>
      <div class="col-11 col-md-8 my-1 d-flex">
        <div class="d-flex mr-3" style="align-items: baseline">
          <input
            type="numeric"
            class="form-control form-control-sm"
            [(ngModel)]="usuario.findemes[0]"
            style="max-width: 100px; text-align: right"
            appOnlynumber
          />
          <div class="mx-1" style="font-size: 24px">.</div>
          <input
            type="numeric"
            class="form-control form-control-sm"
            [(ngModel)]="usuario.findemes[1]"
            style="max-width: 80px"
            appOnlynumber
          />
        </div>

        <select
          class="form-select form-select-sm"
          aria-label="Default select example"
          [(ngModel)]="usuario.perfil"
        >
          <option *ngFor="let und of unidades" [value]="und.codigo.trim()">
            {{ und.nombre }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div *ngIf="tco.codigo.trim() == '011'">
    <div class="row justify-content-center align-items-center">
      <div class="col-11 col-md-3 text-md-right">
        <span>Clave : </span>
      </div>
      <div class="col-11 col-md-8 my-1">
        <input
          [disabled]="habilitarEstado"
          type="text"
          class="form-control form-control-sm"
          placeholder="Ingrese la clave"
          [(ngModel)]="usuario.clave"
        />
      </div>
    </div>

    <div class="row justify-content-center align-items-center">
      <div class="col-11 col-md-3 text-md-right">
        <span>Cédula : </span>
      </div>
      <div class="col-11 col-md-8 my-1">
        <input
          type="numeric"
          class="form-control form-control-sm"
          placeholder="Ingrese la cédula"
          appOnlynumber
          [(ngModel)]="usuario.ruc"
        />
      </div>
    </div>

    <div class="row justify-content-center align-items-center">
      <div class="col-11 col-md-3 text-md-right">
        <span>Dirección : </span>
      </div>
      <div class="col-11 col-md-8 my-1">
        <input
          type="text"
          class="form-control form-control-sm"
          placeholder="Ingrese la dirección"
          [(ngModel)]="usuario.direccion"
        />
      </div>
    </div>

    <div class="row justify-content-center align-items-center">
      <div class="col-11 col-md-3 text-md-right">
        <span>Teléfono: </span>
      </div>
      <div class="col-11 col-md-8 my-1">
        <input
          type="numeric"
          class="form-control form-control-sm"
          placeholder="Ingrese el teléfono"
          appOnlynumber
          [(ngModel)]="usuario.telefono"
        />
      </div>
    </div>

    <div class="row justify-content-center align-items-center">
      <div class="col-11 col-md-3 text-md-right">
        <span>Correo: </span>
      </div>
      <div class="col-11 col-md-8 my-1">
        <input
          type="text"
          class="form-control form-control-sm"
          placeholder="Ingrese el correo"
          [(ngModel)]="usuario.correo"
        />
      </div>
    </div>

    <div class="row justify-content-center align-items-center">
      <div class="col-11 col-md-3 text-md-right">
        <span>Quincena: </span>
      </div>
      <div class="col-11 col-md-8 my-1">
        <div class="d-flex" style="align-items: baseline">
          <input
            type="numeric"
            class="form-control form-control-sm"
            [(ngModel)]="usuario.quincena[0]"
            style="max-width: 100px; text-align: right"
            appOnlynumber
          />
          <div class="mx-1" style="font-size: 24px">.</div>
          <input
            type="numeric"
            class="form-control form-control-sm"
            [(ngModel)]="usuario.quincena[1]"
            style="max-width: 80px"
            appOnlynumber
          />
        </div>
      </div>
    </div>

    <div class="row justify-content-center align-items-center">
      <div class="col-11 col-md-3 text-md-right">
        <span>Fin de mes: </span>
      </div>
      <div class="col-11 col-md-8 my-1">
        <div class="d-flex" style="align-items: baseline">
          <input
            type="numeric"
            class="form-control form-control-sm"
            [(ngModel)]="usuario.findemes[0]"
            style="max-width: 100px; text-align: right"
            appOnlynumber
          />
          <div class="mx-1" style="font-size: 24px">.</div>
          <input
            type="numeric"
            class="form-control form-control-sm"
            [(ngModel)]="usuario.findemes[1]"
            style="max-width: 80px"
            appOnlynumber
          />
        </div>
      </div>
    </div>

    <div class="row justify-content-center align-items-center">
      <div class="col-11 col-md-3 text-md-right">
        <span>Fecha de ingreso: </span>
      </div>
      <div class="col-11 col-md-8 my-1">
        <input
          type="date"
          class="form-control form-control-sm"
          [(ngModel)]="usuario.fecha_ingreso"
        />
      </div>
    </div>

    <div class="row justify-content-center align-items-center">
      <div class="col-11 col-md-3 text-md-right">
        <span>Sucursal : </span>
      </div>
      <div class="col-11 col-md-8 my-1">
        <select
          class="form-select form-select-sm"
          aria-label="Default select example"
          [(ngModel)]="usuario.sucursal"
        >
          <option
            *ngFor="let sucursal of sucursales"
            [value]="sucursal.codigo.trim()"
          >
            {{ sucursal.nombre }}
          </option>
        </select>
      </div>
    </div>

    <div class="row justify-content-center align-items-center">
      <div class="col-11 col-md-3 text-md-right">
        <span>Tipo Usuario: </span>
      </div>
      <div class="col-11 col-md-8 my-1">
        <select
          class="form-select form-select-sm"
          aria-label="Default select example"
          [(ngModel)]="usuario.perfil"
        >
          <option *ngFor="let usr of perfiles" [value]="usr.codigo.trim()">
            {{ usr.nombre }}
          </option>
        </select>
      </div>
    </div>

    <div class="row justify-content-center align-items-center">
      <div class="col-11 col-md-3 text-md-right">
        <span>Admin : </span>
      </div>
      <div class="col-11 col-md-8 my-1">
        <div class="mr-2"></div>
        <div class="form-check form-switch" style="padding-left: 2.5rem">
          <input
            [disabled]="habilitarEstado"
            class="form-check-input"
            type="checkbox"
            id="flexSwitchCheckDefault"
            [(ngModel)]="admin"
          />
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="tco.codigo.trim() == '005'">
    <div class="row justify-content-center align-items-center my-1">
      <div class="col-11 col-md-3 text-md-right">
        <span>Vigencia : </span>
      </div>
      <div class="col-5 col-md-5">
        <input
          type="numeric"
          class="form-control form-control-sm"
          id="nombre"
          placeholder="0"
          [(ngModel)]="vigencia"
          appOnlynumber
        />
      </div>
      <div class="col-3 col-md-3 text-md-left">
        <span>Años </span>
      </div>
    </div>

    <div class="row justify-content-center align-items-center">
      <div class="col-11 col-md-3 text-md-right">
        <span>Depreciación: </span>
      </div>
      <div class="col-11 col-md-8 my-1">
        <div class="d-flex" style="align-items: baseline">
          <input
            type="numeric"
            class="form-control form-control-sm"
            id="nombre"
            placeholder="0"
            [(ngModel)]="porc[0]"
            style="max-width: 100px; text-align: right"
            appOnlynumber
          />
          <div class="mx-1" style="font-size: 24px">.</div>
          <input
            type="numeric"
            class="form-control form-control-sm"
            id="nombre"
            placeholder="0"
            [(ngModel)]="porc[1]"
            style="max-width: 80px"
            appOnlynumber
          />

          <div>%</div>
        </div>
      </div>
    </div>
  </div>

  <!--Botones de Transacciones-->
  <div class="container text-right my-1">
    <button
      id="btn_grabar"
      type="button"
      class="btn btn-outline-success btn-sm btn_est m-2"
      (click)="enviarItem()"
    >
      <i class="fas fa-check-circle"></i> Grabar
    </button>
    <button
      type="button"
      class="btn btn-outline-danger btn-sm btn_est m-2"
      (click)="modalReferencia.hide()"
    >
      <i class="fas fa-times-circle"></i> Cancelar
    </button>
  </div>
</div>
