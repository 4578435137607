import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalDistribuidorComponent } from '../distribuidores/modales/modal-distribuidor/modal-distribuidor.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-distribuidores',
  templateUrl: './distribuidores.component.html',
  styleUrls: ['./distribuidores.component.css'],
})
export class DistribuidoresComponent implements OnInit {
  //Referencia del modal
  modalReferencia: BsModalRef;
  //Datos traidos de la DB
  distribuidores: any = [];
  //Verifica que hay Distribuidor
  hayDistribuidor: boolean;
  //Variable de evento de pagina
  p: Number = 1;
  //Bandera para spinner
  banderaSpinner: boolean = false;
  //Bandera para saber si son datos filtrados o todos
  banderaDistribuidores: boolean; //<-- true todos y false filtro
  empresa: string = '';
  sucursal: string;

  constructor(
    private router: Router,
    private service: RestauranteService,
    private modalServicio: BsModalService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    if (!localStorage.getItem('usuario')) {
      this.router.navigate(['login']);
    }
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
    if (JSON.parse(localStorage.getItem('sucursal')) !== null) {
      this.sucursal = JSON.parse(localStorage.getItem('sucursal'))['codigo'];
    }

    console.log('consulta de Distribuidores');
    this.getDistribuidores();
    //Verifica bandera para ocultar spinner
    setInterval(() => {
      if (this.banderaSpinner === true) {
        this.spinner.hide();
        this.banderaSpinner = false;
      }
    }, 100);
  }

  //Trae todos los Distribuidores de la DB
  getDistribuidores() {
    this.spinner.show();
    let consulta = {
      texto: ' ',
      empresa: this.empresa,
    };
    console.log(consulta);
    this.service.getDistribuidores(consulta).subscribe((data) => {
      if (data.length === 0) {
        this.hayDistribuidor = false;
      } else {
        this.distribuidores = data;
        this.hayDistribuidor = true;
      }
      this.banderaSpinner = true;
      this.banderaDistribuidores = true; //<--Los datos actuales son todos
    });
  }

  //Abre el modal
  abrirModalDetallesDistribuidor(distribuidor) {
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<--Datos enviados desde componente hacia modal
        distribuidor,
        'Distribuidor',
      ],
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo

    this.modalReferencia = this.modalServicio.show(ModalDistribuidorComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });

    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((res) => {
      console.log('MODAL CERRADO, DATA RECIBIDA', res.data);
      if (res.data[0] === 1) {
        //Crea el elemento en el temporal y en la DB
        this.creaItemArray(res.data[1]);
      }
      if (res.data[0] === 2) {
        //Actualiza el elemento en el temporal y en la DB
        this.actualizaItemArray(res.data[1]);
      }
      if (res.data[0] === 3) {
        //Elimina el elemento en el temporal y en la DB
        this.eliminaItemArray(res.data[1]);
      }
    });
  }

  //Busca Distribuidor en array de Distribuidores y lo devuelve
  buscarDistribuidor(id) {
    for (let indice in this.distribuidores) {
      //<-- Itera todos los indices de distribuidor
      let distribuidor = this.distribuidores[indice]; //<-- Toma el i-esimo distribuidor
      if (distribuidor.codigo == id) {
        //Compara el id distribuidor con el id del distribuidor a buscar
        return distribuidor;
      }
    }
  }

  //Crea el elemento en el array
  creaItemArray(item) {
    this.spinner.show();
    this.service.creaDistribuidor(item).subscribe((data) => {
      this.getDistribuidores();
      this.banderaSpinner = true;
    });
  }

  //Actualiza elemento en el array
  actualizaItemArray(item) {
    this.spinner.show();
    this.service.actualizaDistribuidor(item.cedula, item).subscribe((data) => {
      console.log(item);
      if (data.data === 'Dato actualizado exitosamente') {
        this.getDistribuidores();
      }
      this.banderaSpinner = true;
    });
  }

  //Borra elemento del array
  eliminaItemArray(item) {
    this.spinner.show();
    this.service.eliminaDistribuidor(item.cedula).subscribe((data) => {
      if (data.data === 'Dato eliminado exitosamente') {
        this.getDistribuidores();
      }
      this.banderaSpinner = true;
    });
  }

  //Buscar Distribuidor en API
  busquedaDistribuidor(event) {
    let texto = event.target.value; //<--Obtenemos el texto
    if (texto != '') {
      //<-- Si no está vacío coge el texto y busca
      if (texto.length > 3) {
        this.spinner.show();
        this.service.buscarDistribuidor(texto).subscribe((data) => {
          if (data.length === 0) {
            this.hayDistribuidor = false;
          } else {
            this.distribuidores = data;
            this.p = 1;
          }
          this.banderaSpinner = true;
          this.banderaDistribuidores = false; //<--Los datos actuales son filtrados
        });
      }
    } else {
      //<--Si esta vacio trae todos los registros
      if (this.banderaDistribuidores === false) {
        this.getDistribuidores();
      }
    }
  }
}
