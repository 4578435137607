<div class="text-center mt-3"><h3>El pedido ha sido confirmado</h3></div>
<div class="mx-5 my-3">
  <span class="font-weight-bold">
    {{ pedidoCocina.tipo }} - {{ pedidoCocina.numero }}
  </span>
  <div *ngFor="let item of pedidoCocina.items">
    <span class="font-weight-bold">{{ item.cantidad }}x</span>
    {{ item.nombre }}
  </div>
</div>
<div class="mb-3" style="display: flex; justify-content: center">
  <button
    type="button"
    class="btn btn-outline-info"
    (click)="cerrarModal('Ok')"
  >
    [Intro] Ok ({{ tiempo }})
  </button>
</div>
