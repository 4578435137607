import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalProductosComponent } from '../../bibliotecas/productos/modales/modal-productos/modal-productos.component';
import { ModalProductoaddComponent } from '../../bibliotecas/productos/modales/modal-productoadd/modal-productoadd.component';
import { ModalRecetaComponent } from '../../modal-receta/modal-receta.component';
import { ModalFiltroComponent } from '../../modal-filtro/modal-filtro.component';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.css'],
})
export class ProductoComponent implements OnInit {
  //Referencia del modal
  modalReferencia: BsModalRef;
  //Datos traidos de la DB
  productos: any = [];
  //Valida que haya productos
  hayProductos: boolean;
  //Variable de evento de pagina
  p: Number = 1;
  //Bandera para spinner
  banderaSpinner: boolean = false;
  //Bandera para saber si son datos filtrados o todos
  banderaClientes: boolean; //<-- true todos y false filtro
  datosUm: any = []; //<--Datos de las unidades de medida
  //Nombres a pintar en el HTML con sus alias, opciones para el modal de filtrado
  nombresAlias: any = [
    { nombre: 'Nombre', alias: 'nombre' },
    { nombre: 'Precio', alias: 'precio' },
    { nombre: 'Tarifa', alias: 'porc_tarifa' },
    { nombre: 'Linea', alias: 'nomlin' },
    { nombre: 'Unidad', alias: 'nommed' },
    { nombre: 'Grupo', alias: 'nomgru' },
  ];
  //Indica si se ha aplicado el filtrado
  filtroAplicado: boolean = false;

  constructor(
    private service: RestauranteService,
    private modalServicio: BsModalService,
    private spinner: NgxSpinnerService
  ) {
    this.getUND(); //<--Trayendo unidades desde la DB
  }

  empresas: any;
  empresa: string;

  admin: boolean = false;
  ngOnInit(): void {
    this.admin = JSON.parse(localStorage.getItem('usuario')).admin;

    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
    this.getProductos();
    //Verifica bandera para ocultar spinner
    setInterval(() => {
      if (this.banderaSpinner === true) {
        this.spinner.hide();
        this.banderaSpinner = false;
      }
    }, 100);

    if (this.admin) this.cargarEmpresas();
  }

  cargarEmpresas() {
    this.service.getConfiguraciones('019', this.empresa).subscribe((result) => {
      console.log(result);
      if (result.length > 0) {
        this.empresas = result;
      }
    });
  }

  //Trae todos los productos de la DB
  getProductos() {
    this.spinner.show();
    let campos = {
      usuario: JSON.parse(localStorage.getItem('usuario')).codigo,
      empresa: this.empresa,
    };
    console.log(campos);
    this.service.getProductos(campos).subscribe((data) => {
      if (data.length === 0) {
        this.hayProductos = false;
      } else {
        console.log(data);
        this.productos = data;
        console.log(this.productos);
        this.productos.forEach((producto) => {
          producto.nombre = producto.nombre.trim();
          producto.nomlin = producto.nomlin.trim();
          producto.nomgru = producto.nomgru.trim();
          producto.linea = producto.linea.trim();
          producto.grupo = producto.grupo.trim();
          producto.medida = producto.medida.trim();
          producto.precio = parseFloat(producto.precio).toFixed(2);
          producto.porc_tarifa = parseFloat(producto.porc_tarifa).toFixed(2);
          producto.bloqueado = producto.bloqueado;
          producto.compra = producto.compra;
          producto.inv_cocina = producto.inv_cocina;
          producto.mostrar_app = producto.mostrar_app;
          producto.cant_viandas = producto.cant_viandas;
        });
        this.hayProductos = true;
      }
      this.banderaSpinner = true;
      this.banderaClientes = true; //<--Los datos actuales son todos
    });
  }

  //Obtiene todas las unidades de medida
  getUND() {
    this.service.getConfiguraciones('008', this.empresa).subscribe((data) => {
      this.datosUm = data;
      this.datosUm.forEach((datoUm) => {
        datoUm.codigo = datoUm.codigo.trim();
        datoUm.nombre = datoUm.nombre.trim();
      });
    });
  }

  //Abre el modal
  abrirModalDetalles(idactual) {
    let producto = this.buscarProducto(idactual); //<-- Atrapa el producto
    console.log('dataq enviada a modal', producto);
    const initialState: any = {
      //<--Lo que va a recibir el modal

      datos: [
        //<-- Datos enviados desde componente hacia el modal
        producto,
      ],
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferencia = this.modalServicio.show(ModalProductosComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });
    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((res) => {
      console.log('MODAL CERRADO, DATA DEL PRODUCTO RECIBIDA: ', res.data);
      if (res.data[0] === 1) {
        //Crea el elemento en el temporal y en la DB
        this.creaItemArray(this.productos, res.data[1]);
      }
      if (res.data[0] === 2) {
        //Actualiza el elemento en el temporal y en la DB
        this.actualizaItemArray(this.productos, res.data[1]);
      }
      if (res.data[0] === 3) {
        //Borra el elemento del temporal y en la DB
        this.eliminaItemArray(this.productos, res.data[1]);
      }
    });
  }

  //Abre el modal
  abrirModalAgregarProducto() {
    //Este modal no recibe datos
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo

    this.modalReferencia = this.modalServicio.show(ModalProductoaddComponent, {
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });
    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((res) => {
      console.log('MODAL CERRADO, DATA DEL PRODUCTO RECIBIDA: ', res.data);
      this.creaItemArrayAdd(this.productos, res.data);
    });
  }

  //Abre el modal receta
  abrirModalReceta(idactual) {
    let producto = this.buscarProducto(idactual); //<-- Atrapa el producto
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<-- Datos enviados desde componente hacia el modal
        producto,
        this.datosUm,
      ],
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferencia = this.modalServicio.show(ModalRecetaComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-xxl',
    });
    //Al cerrar el modal no recibo nada aca
  }

  //Busca producto en array de productos y lo devuelve
  buscarProducto(id) {
    for (let indice in this.productos) {
      //<-- Itera todos los indices de producto
      let producto = this.productos[indice]; //<-- Toma el i-esimo producto
      if (producto.codigo == id) {
        //Compara el producto con el id del producto a buscar
        return producto;
      }
    }
  }

  //Agrega elemento a array desde modal producto ADD
  creaItemArrayAdd(arreglo, item) {
    let datos = {
      nombre: item.nombre,
      precio: parseFloat(item.precio).toFixed(2),
      porc_tarifa: parseFloat(item.porc_tarifa).toFixed(2),
      linea: item.linea,
      grupo: item.grupo,
      medida: item.medida,
      bloqueado: item.bloqueado,
      compra: item.compra,
      inv_cocina: item.inv_cocina,
      mostrar_app: item.mostrar_app,
      cant_viandas: item.cant_viandas,
      empresa: this.empresa,
    };
    console.log('creaItemArrayAdd', datos);
    this.spinner.show();
    this.service.creaProducto(datos).subscribe((data) => {
      if (data.codigo) {
        this.getProductos();
      }
      this.banderaSpinner = true;
    });
  }

  //Agrega elemento a array
  creaItemArray(arreglo, item) {
    let datos = {
      nombre: item.nombre,
      precio: parseFloat(item.precio).toFixed(2),
      porc_tarifa: parseFloat(item.porc_tarifa).toFixed(2),
      linea: item.linea.trim(),
      grupo: item.grupo.trim(),
      medida: item.medida.trim(),
      bloqueado: item.bloqueado,
      compra: item.compra,
      inv_cocina: item.inv_cocina,
      mostrar_app: item.mostrar_app,
      cant_viandas: item.cant_viandas,
      empresa: this.empresa,
    };
    this.spinner.show();
    this.service.creaProducto(datos).subscribe((data) => {
      if (data.codigo) {
        this.getProductos();
      }
      this.banderaSpinner = true;
    });
  }

  //Actualiza elemento de array
  actualizaItemArray(arreglo, item) {
    item.precio = parseFloat(item.precio).toFixed(2);
    item.porc_tarifa = parseFloat(item.porc_tarifa).toFixed(2);
    const codigo = item.codigo;
    let datos = {
      nombre: item.nombre,
      precio: item.precio,
      porc_tarifa: item.porc_tarifa,
      linea: item.linea.trim(),
      grupo: item.grupo.trim(),
      medida: item.medida.trim(),
      bloqueado: item.bloqueado,
      compra: item.compra,
      inv_cocina: item.inv_cocina,
      mostrar_app: item.mostrar_app,
      cant_viandas: item.cant_viandas,
      empresa: this.empresa,
    };
    console.log(datos);
    this.spinner.show();
    this.service.actualizaProducto(codigo, datos).subscribe((data) => {
      if (data.data === 'Dato actualizado exitosamente') {
        arreglo.forEach((producto) => {
          if (producto.codigo === codigo) {
            this.getProductos();
          }
        });
      }
      this.banderaSpinner = true;
    });
  }

  //Elimina un elemento de un array
  eliminaItemArray(arreglo, item) {
    this.spinner.show();
    this.service.eliminaProducto(item.codigo).subscribe((data) => {
      this.banderaSpinner = true;
      console.log(data);
      this.alerta(
        data[0].titulo.trim(),
        data[0].mensaje.trim(),
        data[0].icono.trim()
      ).then(() => {
        this.getProductos();
      });
    });
  }

  //Buscar producto en API
  busquedaProducto(event) {
    let texto = event.target.value; //<--Obtenemos el texto
    if (texto != '') {
      //<-- Si no está vacío coge el texto y busca
      if (texto.length > 3) {
        this.spinner.show();
        let campos = {
          texto: texto,
          usuario: JSON.parse(localStorage.getItem('usuario')).codigo,
          empresa: this.empresa,
        };
        this.service.buscarProducto(campos).subscribe((data) => {
          console.log('PARA CARGAR', data);
          if (data.length === 0) {
            this.p = 0;
            this.hayProductos = false;
          } else {
            this.productos = data;
            this.productos.forEach((producto) => {
              producto.nombre = producto.nombre.trim();
              producto.nomlin = producto.nomlin.trim();
              producto.nomgru = producto.nomgru.trim();
              producto.linea = producto.linea.trim();
              producto.grupo = producto.grupo.trim();
              producto.medida = producto.medida.trim();
              producto.bloqueado = producto.bloqueado;
              producto.compra = producto.compra;
              producto.inv_cocina = producto.inv_cocina;
              producto.mostrar_app = producto.mostrar_app;
              producto.cant_viandas = producto.cant_viandas;
            });
            this.p = 1;
            this.hayProductos = true;
          }
          this.banderaSpinner = true;
          this.banderaClientes = false; //<--Los datos actuales son filtrados
        });
      }
    } else {
      //<--Si esta vacio trae todos los registros
      if (this.banderaClientes === false) {
        this.getProductos();
      }
    }
  }

  //Abrir modal filtro
  abrirModalFiltro(nombre) {
    //Itero y busco el caso que necesito
    let alias; //<--El alias que necesito
    for (let i = 0; i < this.nombresAlias.length; i++) {
      if (this.nombresAlias[i].nombre === nombre) {
        //<--Si es el nombre escogido para filtrar lo procesa
        alias = this.nombresAlias[i].alias;
        break;
      }
    }
    //Cojo todos los datos referentes al caso escogido y los envio al modal
    //Abre el modal filtro
    let datosModal = []; //<-- Atrapa los datos
    this.productos.forEach((producto) => {
      datosModal.push(producto[alias]); //<--Tomo los parametros que me interesan
    });
    const datosModalUnicos = [...new Set(datosModal)]; //<-- Eliminar duplicados
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<-- Datos enviados desde componente hacia el modal
        nombre,
        datosModalUnicos,
        this.filtroAplicado,
      ],
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferencia = this.modalServicio.show(ModalFiltroComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });
    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((res) => {
      console.log('MODAL CERRADO, DATA RECIBIDA', res.data);
      if (res.data[1] === false) {
        this.quitarFiltro();
        return;
      }
      this.filtroAplicado = res.data[0];
      this.filtrar(res.data[1], alias);
    });
  }

  filtrar(arreglo, alias) {
    let productosFiltrados = [];
    for (let i = 0; i < this.productos.length; i++) {
      if (arreglo.includes(this.productos[i][alias])) {
        productosFiltrados.push(this.productos[i]);
      }
    }
    this.productos = productosFiltrados;
  }

  quitarFiltro() {
    this.getProductos();
    this.filtroAplicado = false;
  }

  alerta(titulo, texto, icono) {
    return Swal.fire({
      icon: icono,
      title: titulo,
      text: texto,
      allowOutsideClick: false,
      timer: 1000,
      showConfirmButton: false,
    });
  }
}
