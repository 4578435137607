<div class="barra-titulo">
  <div class="modal-header">
    <h4 class="modal-title" id="titulo"><b>DIVIDIR MESAS</b></h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalReferencia.hide()"
    >
      <span aria-hidden="true">&times; </span>
    </button>
  </div>
</div>

<div class="container">
  <table class="table table-responsive">
    <thead>
      <tr>
        <th scope="col">Código</th>
        <th scope="col">Nombre</th>
        <th scope="col">Cantidad</th>
        <th scope="col">Precio</th>
        <th scope="col">Subtotal</th>
        <th scope="col" *ngFor="let d of cuerpo[0].divisiones; index as i">
          Mesa {{ i + 1 }}
        </th>
        <th scope="col">Restantes</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of cuerpo">
        <th scope="row">{{ item.codigo }}</th>
        <td>{{ item.nombre }}</td>
        <td>{{ item.cantidad }}</td>
        <td>{{ item.precio.toFixed(2) }}</td>
        <td>{{ (item.cantidad * item.precio).toFixed(2) }}</td>
        <td *ngFor="let division of item.divisiones">
          <div class="form-group">
            <input
              type="numeric"
              class="form-control"
              placeholder="Cant"
              [(ngModel)]="division.valor"
              (keyup)="
                mayorAcantidad(division, item.cantidad);
                sumaMayorTotal(division, item.codigo)
              "
              (click)="borraCero(division)"
              appOnlyNumber
            />
          </div>
        </td>

        <td>
          {{ item.restantes }}
        </td>
      </tr>
    </tbody>
  </table>

  <div class="my-3" style="display: flex; justify-content: space-around">
    <!--(click)="dividir()"-->
    <button type="button" class="btn btn-outline-success">DIVIDIR MESA</button>
    <button type="button" class="btn btn-outline-danger" (click)="cancelar()">
      CANCELAR
    </button>
  </div>
</div>
