<div class="w-100">
  <div class="container-fluid pt-5">
    <div class="row align-items-center justify-content-center">
      <div class="col-lg-6 text-center">
        <h1 [style.color]="empresa.color + 'FA'">
          ! Lamentamos las molestias !
        </h1>
        <h4>Opción en Desarrollo o Mantenimiento</h4>
        <h6>Pronto estará nuevamente disponible nuevamente</h6>
      </div>
      <div class="col-sm-6 col-lg-4">
        <div class="row">
          <lottie-player
            src="https://assets9.lottiefiles.com/packages/lf20_c2x2b3vh.json"
            background="transparent"
            speed="1"
            loop
            autoplay
          ></lottie-player>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid py-5">
    <div class="row">
      <div class="col-12 text-center">
        <img
          src="https://novaexpress.group/img/logos/nova_express_color_rectangulo.svg"
          alt="logo erp"
          style="max-height: 75px"
        />
      </div>
    </div>
  </div>
</div>
