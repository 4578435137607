<!--CABECERA-->
<div class="container-fluid my-2">
    <div class="row align-items-center">
      <div class="col-12 col-md-4 col-lg-3 col-xl-2" *ngIf="admin">
        <mat-form-field appearance="fill" class="no-padding">
          <mat-label>Sucursal</mat-label>
          <mat-select panelClass="my-select-panel-class" [(value)]="sucursal">
            <mat-option
              *ngFor="let sucursal of sucursales"
              [value]="sucursal.codigo.trim()"
              >{{ sucursal.nombre }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col">
        <h4>
          Orden de Producción
          <small class="text-muted"></small>
        </h4>
      </div>
    </div>
  </div>

  <!--BOTONES DE ACCION-->
<div class="container-fluid">
  <div class="row py-4">
    <div class="col-4">
      <button
        type="button"
        class="btn btn-sm btn-outline-success"
        (click)="abrirModalNuevoProducto()"
      >
        <i class="far fa-plus-square"></i> Producto
      </button>
    </div>
    <div class="col-4 text-center">
    <button
    type="button"
    class="btn btn-sm btn-outline-primary"
    (click)="cargarRecetas()"
  >
    <i class="fas fa-sitemap"> Cargar Receta</i>
  </button>
</div>
    <div class="col-4 text-right">
      <button
        type="button"
        class="btn btn-sm btn-outline-danger mx-1"
        (click)="grabarOrden()"
        *ngIf="productos.length > 0"
      >
        <i class="fas fa-cloud-upload-alt"></i> Grabar
      </button>
    </div>
  </div>
</div>
  
  <div class="container-fluid my-1">
    <div class="row ">
      <div class="col-12 col-md-4 py-4">
        <!--PRODUCTOS-->
        <div class="container-fluid mt-2" *ngIf="productos.length > 0">
          <div class="table-responsive">
            <table class="table table-sm table-striped table-hover m-0 p-0">
              <thead>
                <tr>
                  <th scope="col" style="width: 50px; text-align: center"></th>
                  <th style="text-align: center">Producto</th>
                  <th style="width: 100px; text-align: center">Cantidad</th>
                  
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let producto of productos
                      | paginate
                        : {
                            itemsPerPage: 25,
                            currentPage: p,
                            totalItems: productos.count
                          };
                    index as i
                  "
                  id="{{ producto.codigo }}"
                >
                <td style="text-align: center">
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-danger "
                    (click)="borrarItem(producto.codigo)"
                  >
                    <i class="far fa-trash-alt"></i>
                  </button>
                </td>
                  <td>
                    <p class="m-0 p-0">
                      {{ producto.nombre }}
                    </p>
                  </td>
  
                  <td>
                    <input
                      type="numeric"
                      class="form-control form-control-sm text-right"
                      [(ngModel)]="producto.cantidad"
                      (keydown)="limitarDecimales($event, 4, producto.cantidad)"
                      (focusin)="limpiarInput(producto, 'cantidad')"
                      (focusout)="verificarInput(producto, 'cantidad', i)"
                    />
                  </td>
                 
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-8 py-3">
        <!--MOVIMIENTO DE LA COMPRA-->
        <div class="container-fluid mt-2" *ngIf="movimiento.length > 0">
          <div class="table-responsive">
            <table class="table table-sm table-striped table-hover m-0 p-0">
              <thead>
                <tr>
                  <th style="text-align: center">Materia Prima</th>
                  <th style="width: 100px; text-align: center">Cantidad Receta</th>
                  <th style="width: 75px; text-align: center">Medida Receta</th>
                  <th style="width: 100px; text-align: center">Cantidad Compra</th>
                  <th style="width: 130px; text-align: center">Medida Compra</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let item of movimiento
                      | paginate
                        : {
                            itemsPerPage: 25,
                            currentPage: p,
                            totalItems: movimiento.count
                          };
                    index as i
                  "
                  id="{{ item.cod_receta }}"
                >
                  <td>
                    <p class="m-0 p-0">
                      {{ item.nombre }}
                    </p>
                  </td>
                  <td>
                    <p class="m-0 p-0  text-right pr-3">
                      {{ item.cantidad.toFixed(2) }}
                    </p>
                  </td>
                  <td>
                    <p class="m-0 p-0">
                      {{ item.medida_receta }}
                    </p>
                  </td>
                  <td>
                    <p class="m-0 p-0 text-right pr-3">
                      {{ (item.cantidad/ 453.6).toFixed(2) }}
                    </p>
                  </td>
                  <td>
                      <div class="input-group">
                        <select
                          class="custom-select custom-select-sm"
                          name="um"
                          id="um"
                          (change)="actualizaUm($event, item.codigo)"
                        >
                          <option
                            *ngFor="let datoUm of datosUm"
                            value="{{ datoUm.codigo }}"
                          >
                            {{ datoUm.nombre }}
                          </option>
                        </select>
                      </div>
                    
                  </td>
  <!--
                  <td>
                    <input
                      type="numeric"
                      class="form-control form-control-sm text-right"
                      [(ngModel)]="movimiento.cantidad"
                      (keydown)="limitarDecimales($event, 4, movimiento.cantidad)"
                      (focusin)="limpiarInput(movimiento, 'cantidad')"
                      (focusout)="verificarInput(movimiento, 'cantidad', i)"
                    />
                  </td>
                  <td>
                    <div
                      class="input-group"
                      style="width: 150px; text-align: center"
                    >
                      <select
                        class="custom-select custom-select-sm"
                        name="um"
                        id="um"
                        [(ngModel)]="movimiento.umEscogida"
                      >
                        <option *ngFor="let datoTin of datosTin" [ngValue]="datoTin">{{datoTin.nombre}}</option>   comentario 
                        <option
                          *ngFor="let datoUm of datosUm"
                          value="{{ datoUm.codigo }}"
                        >
                          {{ datoUm.nombre }}
                        </option>
                      </select>
                    </div>
                  </td>
                  
                  --> 
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="alert alert-danger text-center" role="alert">
    <p><strong>ERROR DE CONEXION TABLA DE CONVERSIONES</strong></p>
  </div>
  