import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-detalle-inventario',
  templateUrl: './modal-detalle-inventario.component.html',
  styleUrls: ['./modal-detalle-inventario.component.css'],
})
export class ModalDetalleInventarioComponent implements OnInit {
  datos: Object; //<-- Debe tener el mismo nombre de la variable en factura
  titulo: String;
  idFactura: number;
  tipo: any;
  cabecera: any = {};
  cuerpo: any = [];
  numero: number = 0;
  banderaSpinner: boolean = false;
  inhabilitado: boolean = true;
  total: number = 0;
  //Variable de evento de pagina
  p: Number = 1;
  pedido: any;

  sucursal: string = '';

  modalReferenciaLinea: BsModalRef;
  ventana: any;
  datosempresa: any;

  public event: EventEmitter<any> = new EventEmitter();
  admin: any;
  empresa: any;

  constructor(
    public modalReferenciaLineas: BsModalRef,
    private servicio: RestauranteService,
    private spinner: NgxSpinnerService,
    private modalServicio: BsModalService
  ) {}

  ngOnInit(): void {
    this.admin = JSON.parse(localStorage.getItem('usuario')).admin;
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.datosempresa = JSON.parse(localStorage.getItem('empresa'));
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }

    setInterval(() => {
      if (this.banderaSpinner === true) {
        this.spinner.hide();
        this.banderaSpinner = false;
      }
    }, 100);

    this.pedido = this.datos[0];
    console.log(this.pedido);

    this.idFactura = this.pedido.numero;
    this.tipo = this.pedido.tipo;
    this.cargarDatos();
    this.titulo = 'VISTA DE TRANSACCION';
  }

  cargarDatos() {
    this.total = 0
    let datos = {
      opcion: 1,
      idFactura: this.idFactura,
      empresa: JSON.parse(localStorage.getItem('empresa')).codigo,
      sucursal: this.pedido.sucursal,
      modulo: this.pedido.modulo,
      tipo: this.pedido.tipo,
      //empresa: this.pedido.empresa,
    };
    this.spinner.show();
    this.servicio.verFactura(datos).subscribe((data) => {
      console.log('DATA 1 STEVEN', data, data[0].nomtipo);
     
      this.banderaSpinner = true;

      this.cabecera = data[0][0];
      this.titulo = this.cabecera.nomtipo + ' # ' + this.pedido.numero;
      this.cabecera.usuario = JSON.parse(
        localStorage.getItem('usuario')
      ).codigo;
      this.cuerpo = data[1];
      this.numero = this.cuerpo[0].numero;

      this.cuerpo.forEach((producto) => {
        producto.nuevo = false;
        this.total = this.total + producto.cantidad * producto.costo;
      });

      datos['opcion'] = 2;
      this.servicio.verFactura(datos).subscribe((data) => {
        //console.log(data);
        let cuerpo = data[1];
        let mini = {};
        for (let i = 0; i < cuerpo.length; i++) {
          const element = cuerpo[i];
          element.cod_receta = element.codigo;
          element.unidad = element.medida.trim();
          if (mini[element.cod_prod_rec]) {
            if (mini[element.cod_prod_rec][element.num_prod_rec]) {
              mini[element.cod_prod_rec][element.num_prod_rec].push(element);
            } else {
              mini[element.cod_prod_rec][element.num_prod_rec] = [element];
            }
          } else {
            mini[element.cod_prod_rec] = {};
            mini[element.cod_prod_rec][element.num_prod_rec] = [element];
          }
        }
        //console.log(mini);

        this.cuerpo.forEach((element) => {
          let receta = [];
          //console.log(element.cantidad);
          element.cantidad;
          for (let i = 0; i < element.cantidad; i++) {
            //console.log(mini[element.codigo]);
            if (mini[element.codigo]) receta.push(mini[element.codigo][i]);
          }
          element.receta = receta;
        });
        //console.log(this.cuerpo);
      });
    });
  }

  calcularTotales() {
    this.total = 0;
    this.cuerpo.forEach((producto) => {
      this.total += producto.cantidad * producto.costo;
    });
  }

  //steven
  cerrar() {
    this.modalReferenciaLineas.hide();
  }

 

  actualizarFactura() {
    let campos = {
      numero: this.cabecera.numero,
      tipo: this.cabecera.tipo,
      sucursal: this.cabecera.sucursal,
      modulo: this.pedido.modulo,
      empresa: JSON.parse(localStorage.getItem('empresa')).codigo,
    };

    let datos = {
      cabecera: this.cabecera,
      cuerpo: this.cuerpo,
    };
    //console.log(this.cabecera);

    this.spinner.show();
    this.servicio.eliminarFactura(campos).subscribe((data) => {
      //console.log(data);

      this.servicio.efectuarPedido(datos).subscribe((data) => {
        //console.log(data);
        this.spinner.hide();
        this.alerta(
          'Éxito',
          'Factura actualizada correctamente',
          'success'
        ).then(() => {
          this.cerrarModal('OK');
        });
      });
    });
  }

  //Cerrar modal y enviar la data a factura
  cerrarModal(data) {
    this.event.emit(data);
    this.modalReferenciaLineas.hide(); //<-- Cierra el modal
  }

  async eliminarItem(i) {
    let confirmar = await this.alertaConfirmacion(
      '¿Desa eliminar este elemento? '
    );
    if (!confirmar) return;
    this.cuerpo.splice(i, 1);
    this.calcularTotales();
  }

  async alertaConfirmacion(titulo) {
    return Swal.fire({
      title: titulo,
      showDenyButton: true,
      confirmButtonText: 'Si',
      denyButtonText: 'No',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        return true;
      } else if (result.isDenied) {
        return false;
      }
    });
  }

  alerta(titulo, texto, icono) {
    return Swal.fire({
      icon: icono,
      title: titulo,
      text: texto,
      allowOutsideClick: false,
    });
  }

  alertaOpciones(titulo, confirmar, cancelar) {
    return Swal.fire({
      title: titulo,
      showDenyButton: true,

      confirmButtonText: confirmar,
      denyButtonText: cancelar,
    });
  }
  edicion: boolean = false;
  habilitarEdicion() {
    if (this.edicion) {
      this.pedido = this.datos[0];
      //console.log(this.pedido);
      this.idFactura = this.pedido.numero;
      this.tipo = this.pedido.tipo;
      this.titulo = 'Documento # ' + this.idFactura.toString();
      this.cargarDatos();
    }

    this.edicion = !this.edicion;
  }
}
