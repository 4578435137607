import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalListadoProductosComponent } from '../../modal-listado-productos/modal-listado-productos.component';

@Component({
  selector: 'app-informe-dinamico',
  templateUrl: './informe-dinamico.component.html',
  styleUrls: ['./informe-dinamico.component.css'],
})
export class InformeDinamicoComprasComponent implements OnInit {
  modalReferencia: BsModalRef;
  campaignOne: FormGroup;
  minDate: Date;
  maxDate: Date;

  busqueda = '';

  empleado: string = 'TODOS';
  empleados: any;
  empleadosTotal: any;

  motivos: any = [];
  motivo: string = 'todos';

  busco_producto = false;
  producto = '';
  producto_codigo = '';
  productos: any = [];

  //factura: any = ['001', '001', '0000000001'];

  cabecera: any;
  movimiento: any;
  regmov: number = 0;
  opcion: string = '';
  opciones: any;

  admin: boolean = false;
  sucursales: any;
  sucursal: string = 'TODOS';
  empresa: any;
  datosempresa: any;
  //Variable de evento de pagina
  p: Number = 1;
  constructor(
    private servicio: RestauranteService,
    private modalServicio: BsModalService,
    private spinner: NgxSpinnerService
  ) {
    this.minDate = new Date();
    this.maxDate = new Date();

    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    const day = today.getDate();
    console.log(today);

    this.campaignOne = new FormGroup({
      start: new FormControl(new Date(year, month, 1)),
      end: new FormControl(new Date(year, month, day)),
    });
  }

  ngOnInit(): void {
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.datosempresa = JSON.parse(localStorage.getItem('empresa'));
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
    this.cargarEmpleados();

    this.cargarRadioButtons();
    //this.cargarMotivos();
    this.admin = JSON.parse(localStorage.getItem('usuario')).admin;

    this.cargarSucursales();
  }

  cargarRadioButtons() {
    this.servicio.consultarOpciones('1', '004').subscribe((result) => {
      console.log(result);
      result.forEach((element) => {
        if (element.valor == 1) this.opcion = element.orden;
      });
      this.opciones = result;
      this.cargarDatos();
    });
  }

  buscarFecha() {
    if (this.campaignOne.value.end) {
      this.cargarDatos();
    }
  }

  cargarEmpleados() {
    let datos = {
      empresa: this.empresa,
    };
    this.servicio.getProveedores(datos).subscribe((result) => {
      this.empleados = result;
      this.empleadosTotal = result;
    });
  }
  cargarDatos(ruc = '') {
    let procedimiento = 'exec ';
    this.opciones.forEach((opcion) => {
      if (opcion.orden == this.opcion) procedimiento += opcion.procedimiento;
    });

    //this.campaignOne.value.end.toJSON()
    let query1 = `${procedimiento} ${this.opcion},1,
    '${JSON.parse(localStorage.getItem('empresa')).codigo}',
    '${this.sucursal}','${this.empleado}','${ruc}',

   '${this.formatearFechaQuery(
     this.campaignOne.value.start.toJSON()
   )}','${this.formatearFechaQuery(
      this.campaignOne.value.end.toJSON(),
      '23:59:59'
    )}'`;

    let query2 = `${procedimiento} ${this.opcion},2,
    '${JSON.parse(localStorage.getItem('empresa')).codigo}',
    '${this.sucursal}','${this.empleado}','${ruc}',
    '${this.formatearFechaQuery(
      this.campaignOne.value.start.toJSON()
    )}','${this.formatearFechaQuery(
      this.campaignOne.value.end.toJSON(),
      '23:59:59'
    )}'`;

    this.servicio.ejecutarQuery(query1).subscribe((cabeceras) => {
      this.cabecera = cabeceras;
      this.servicio.ejecutarQuery(query2).subscribe((movimientos) => {
        this.movimiento = this.formatearDatos(cabeceras, movimientos);
        this.regmov = movimientos.length;
        this.movimiento = movimientos;
      });
    });
  }

  onKey() {
    console.log(this.busqueda);
    let mini: any = [];
    this.empleadosTotal.forEach((usuario: any) => {
      console.log(usuario);

      if (usuario.nombre.toLowerCase().includes(this.busqueda.toLowerCase()))
        mini.push(usuario);
    });
    this.empleados = mini;
  }

  filtrarEmpleado() {}
  filtrarMotivo() {}

  formatearDatos(cabeceras, movimientos) {
    for (let i = 0; i < cabeceras.length; i++) {
      for (let e = 0; e < movimientos.length; e++) {
        const element = cabeceras[i];
        switch (element.tipodato.trim()) {
          case 'char':
            movimientos[e][element['campo']] =
              movimientos[e][element['campo']].trim();
            break;
          case 'numerico':
            movimientos[e][element['campo']] = parseFloat(
              movimientos[e][element['campo']]
            ).toFixed(parseInt(element['longitud']));
            break;
          case 'date':
            movimientos[e][element['campo']] = this.formatearFechaString(
              movimientos[e][element['campo']]
            );
            break;
          case 'datetime':
            movimientos[e][element['campo']] = this.formatearFechaString(
              movimientos[e][element['campo']],
              true
            );
            break;
          default:
            console.log(element);
            break;
        }
      }
    }

    return movimientos;
  }

  formatearFechaString(fecha = new Date(), hora = false) {
    //new Date().toJSON().slice(0,10).split('-').reverse().join('/')
    let fechaFin = new Date(fecha);
    let fechaString = fechaFin
      .toJSON()
      .slice(0, 10)
      .split('-')
      .reverse()
      .join('/');
    if (hora) {
      //Formatear hora
      return fechaString + ' ' + fechaFin.toJSON().slice(11, 19);
    }
    return fechaString;
  }

  formatearMes(fecha = new Date()) {
    let fechaFormateada = this.formatearFechaString(fecha).split('/');
    let fechaMes = fechaFormateada[2] + fechaFormateada[1];
    console.log(fechaMes);
    return fechaMes;
  }
  /*
  formatearFacturaNumber() {
    if (this.factura[0].length < 3) {
      this.factura[0] =
        '0'.repeat(3 - this.factura[0].length) + this.factura[0];
    }
    if (this.factura[1].length < 3) {
      this.factura[1] =
        '0'.repeat(3 - this.factura[1].length) + this.factura[1];
    }

    if (this.factura[2].length < 10) {
      this.factura[2] =
        '0'.repeat(10 - this.factura[2].length) + this.factura[2];
    }
  }
*/

  cargarSucursales() {
    this.servicio
      .getConfiguraciones('015', this.empresa)
      .subscribe((result) => {
        console.log(result);
        if (result.length > 0) {
          this.sucursales = result;
        }
      });
  }

  abrirModalBuscarProducto() {
    console.log('modal productos');
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<--Datos enviados desde componente hacia modal
        this.producto,
      ],
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferencia = this.modalServicio.show(
      ModalListadoProductosComponent,
      {
        initialState,
        ignoreBackdropClick: true,
      }
    );
    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((res) => {
      console.log(res.data);
      this.producto = res.data.nombre.trim();
      this.producto_codigo = res.data.codigo;
      this.cargarDatos();
    });
  }

  formatearFechaQuery(fecha = new Date(), hora = '00:00:00') {
    let fechaFin = new Date(fecha);
    let fechaTotal = fechaFin.toJSON().slice(0, 10);

    return fechaTotal + ' ' + hora;
  }
}
