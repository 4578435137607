import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { ModalListadoProductosComponent } from 'src/app/components/restaurante/modal-listado-productos/modal-listado-productos.component';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-orden',
  templateUrl: './orden.component.html',
  styleUrls: ['./orden.component.css']
})
export class OrdenComponent implements OnInit {
  
  modalReferencia: BsModalRef;
  banderaSpinner: boolean = false;
  empleados: any = [];
  productos: any = [];
  movimiento: any = [];
  
  admin: boolean = false;
  sucursales: any;
  sucursal: string = 'todos';
  empresa: any;
  datosempresa: any;
  datosUm: any = [];
  

  constructor(
    private router: Router,
    private servicio: RestauranteService,
    private modalServicio: BsModalService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    
    if (!localStorage.getItem('usuario')) {
      this.router.navigate(['login']);
    }

    //REVISAR SI NO AFECTO
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.datosempresa = JSON.parse(localStorage.getItem('empresa'));
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }

    //this.cargarLocalStorage();
    this.getUND();

    this.admin = JSON.parse(localStorage.getItem('usuario')).admin;
    this.sucursal = JSON.parse(localStorage.getItem('sucursal')).codigo;
    this.cargarSucursales();

    if (localStorage.getItem('produccion')) {
      let produccion = JSON.parse(localStorage.getItem('produccion'));
      if (produccion.productos && produccion.productos.length > 0) {
        produccion.productos.forEach((producto) => {
          this.productos.push(producto);
        });
      }
      this.cargarRecetas();
    } else {
      localStorage.setItem('produccion', JSON.stringify({ comentario: '' }));
    }

  }
  grabarOrden(){
    this.alerta(
      'Error de conexión',
      'Se perdió la conexión con el servidor',
      'error'
    );
  }
 
  abrirModalNuevoProducto() {
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<--Datos enviados desde componente hacia modal
        '',
      ],
    };
    this.modalReferencia = this.modalServicio.show(ModalListadoProductosComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });
    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((res) => {
      console.log('MODAL DE PRODUCCION CERRADO');

      //OPERACIONES EN LA TEMPORAL
      let datos = {
        codigo: res.data.codigo,
        nombre: res.data.nombre,
        cantidad: 1,
      };
      let bandera = false; //<--Indica si esta el producto en el temporal
      this.productos.forEach((producto) => {
        //Si el producto ya esta en el arreglo de productos lo modifica
        if (producto.codigo === datos.codigo) {
          producto.nombre = datos.nombre;
          bandera = true; //<--Actualiza control
        }
      });
      //Si el producto no esta en el arreglo de productos lo crea
      if (bandera === false) {
        this.productos.push(datos);
      }
      //OPERACIONES EN LOCAL STORAGE
      //Guarda el producto en localStorage
      let produccion = JSON.parse(localStorage.getItem('produccion'));
      //Si no esta el arreglo de productos lo crea
      if (!produccion.productos) {
        let arregloProductos = [];
        arregloProductos.push(datos);
        produccion.productos = arregloProductos;
        localStorage.setItem('produccion', JSON.stringify(produccion));
      }
      //Si ya esta el arreglo de productos busca en el
      else {
        let control = false; //<--Indica si esta el producto en el produccion
        produccion.productos.forEach((producto) => {
          //Si el producto ya esta en el arreglo de productos lo modifica
          if (datos.codigo === producto.codigo) {
            producto.nombre = datos.nombre;
            control = true; //<--Actualiza control
          }
        });
        //Si el producto no esta en el arreglo de productos lo crea
        if (control === false) {
          produccion.productos.push(datos);
        }
        localStorage.setItem('produccion', JSON.stringify(produccion));
      }
      this.cargarRecetas();
    });
  }


  //Borra un item especifico
  borrarItem(id) {
    Swal.fire({
      title: '¿Desea eliminar este producto?',
      showDenyButton: true,
      confirmButtonText: `Sí`,
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        let tempVacio = false;
        let localVacio = false;
        //Borro el item del temporal
        for (let i = 0; i < this.productos.length; i++) {
          if (this.productos[i].codigo === parseInt(id)) {
            this.productos.splice(i, 1);
            break;
          }
        }
        if (this.productos.length === 0) {
          tempVacio = true;
        }
        //Borro el item del localStorage
        let produccion = JSON.parse(localStorage.getItem('produccion'));
        for (let i = 0; i < produccion.productos.length; i++) {
          if (produccion.productos[i].codigo === parseInt(id)) {
            produccion.productos.splice(i, 1);
            break;
          }
        }
        if (produccion.productos.length === 0) {
          localVacio = true;
        }
        localStorage.setItem('produccion', JSON.stringify(produccion));
        Swal.fire('¡Producto eliminado!', '', 'success');
        this.cargarRecetas();

      }
    });
  }

  //Obtiene todas las unidades de medida
  getUND() {
    this.servicio.getConfiguraciones('008', this.empresa).subscribe((data) => {
      this.datosUm = data;
      console.log('XXXX',data)
      this.datosUm.forEach((datoUm) => {
        datoUm.codigo = datoUm.codigo.trim();
        datoUm.nombre = datoUm.nombre.trim();
      });
    });
  }
    //Actualiza la um para el receta
    actualizaUm(event, codigo) {
      let id = codigo; //<--Recupero el id del producto
      let codigoUm = event.target.value; //<--Codigo de la um
      //Actualizo la um en el temporal
      this.movimiento.forEach((producto) => {
        if (producto.codigo === parseInt(id)) {
          producto.umEscogida = codigoUm;
          for (let i = 0; i < producto.umLista.length; i++) {
            if (producto.umLista[i].codigo === codigoUm) {
              const item = producto.umLista[i];
              producto.umLista.splice(i, 1);
              let nuevaLista = [item];
              producto.umLista.forEach((item) => {
                nuevaLista.push(item);
              });
              producto.umLista = nuevaLista;
              break;
            }
          }
        }
      });
      //Actualizo la um en localStorage
      let inventario = JSON.parse(localStorage.getItem('produccion'));
      inventario.productos.forEach((producto) => {
        if (producto.codigo === parseInt(id)) {
          producto.umEscogida = codigoUm;
          for (let i = 0; i < producto.umLista.length; i++) {
            if (producto.umLista[i].codigo === codigoUm) {
              const item = producto.umLista[i];
              producto.umLista.splice(i, 1);
              let nuevaLista = [item];
              producto.umLista.forEach((item) => {
                nuevaLista.push(item);
              });
              producto.umLista = nuevaLista;
              break;
            }
          }
        }
      });
      localStorage.setItem('inventario', JSON.stringify(inventario));
    }

  cargarSucursales() {
    this.servicio
      .getConfiguraciones('015', this.empresa)
      .subscribe((result) => {
        //console.log(result);
        if (result.length > 0) {
          this.sucursales = result;
        }
      });
  }

  cargarRecetas(){
    this.movimiento=[]
    this.productos.forEach(item => {
        this.Receta(item);
    });
  }
  Receta(producto) {
    return new Promise((resolve, reject) => {
      this.servicio
        .buscarReceta(producto.codigo, 'TODOS')
        .subscribe((result) => {
          console.log('CARGA LA RECETA');
          console.log("RES:SQL",result)

          result.forEach(element => {
            //OPERACIONES EN LA TEMPORAL
          let datos = {
            cod_receta: element.cod_receta,
            cod_prod : element.cod_producto,
            medida_receta : element.medida_receta,
            umLista : element.umLista,
            nombre: element.nombre,
            cantidad: element.cantidad*producto.cantidad,
          };

          let bandera = false; //<--Indica si esta el producto en el temporal

          this.movimiento.forEach((producto) => {
            //Si el producto ya esta en el arreglo de productos lo modifica
            if (producto.cod_receta === datos.cod_receta) {
              producto.nombre = datos.nombre;
              bandera = true; //<--Actualiza control
            }
          });
          //Si el producto no esta en el arreglo de productos lo crea
          if (bandera === false) {
            this.movimiento.push(datos);
          }

        

        });
          
        });
    });
  }

  limpiarInput(item, campo) {
    item[campo] = 0;
    if (isNaN(item[campo])) {
      item[campo] = 0;
    }
    if (item[campo] === undefined) {
      item[campo] = 0;
    }
  }

  verificarInput(item, campo, i) {
    if (isNaN(item[campo])) {
      item[campo] = 0;
    }

    if (item[campo] === '') item[campo] = item[campo + '_ant'];
    if (item[campo] < 0) item[campo] = item[campo + '_ant'];
    item[campo + '_ant'] = item[campo];
  }

  limitarDecimales(event, decimales, valor) {
    console.log(event.key);
    if (event.key == '.') {
      if (decimales === 0) return false;
      else return true;
    }

    if (isNaN(parseInt(event.key))) return true;
    let val = (valor + '').split('.');
    if (val.length > 1) {
      let decima = val[1];
      if (decima.length > decimales - 1) return false;
    }
    return true;
  }

  alerta(titulo, texto, icono) {
    return Swal.fire({
      icon: icono,
      title: titulo,
      text: texto,
      allowOutsideClick: false,
    });
  }

}
