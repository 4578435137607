<!--CABECERA-->
<div class="modal-header" [style.background-color]="datosempresa['color']">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-4">
        <!--
        <button
          *ngIf="!edicion"
          type="button"
          class="btn btn-outline-warning mr-2"
          (click)="habilitarEdicion()"
        >
          <i class="far fa-edit"></i>
        </button>
-->
        <button
          *ngIf="edicion"
          type="button"
          class="btn btn-outline-danger mr-2"
          (click)="habilitarEdicion()"
        >
          <i class="far fa-times-circle"></i>
        </button>
      </div>
      <div class="col-12 col-sm-8">
        <h4 class="modal-title" style="color: white !important">
          {{ titulo }}
        </h4>
      </div>
    </div>
  </div>

  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="cerrar()"
    style="color: white !important"
  >
    <span aria-hidden="true">&times; </span>
  </button>
</div>

<!--DATOS DE la TRANSACCION-->
<div class="container-fluid">
  <div class="row my-2 justify-content-center">
    <div class="col-12 col-md-6 pt-1">
      <div class="card">
        <div class="card-body p-1">
          <div class="input-group input-group-sm my-1">
            <div class="input-group-prepend text-center">
              <span class="input-group-text" id="ruc"
                ><i class="fas fa-id-card-alt"></i
              ></span>
            </div>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="tipo de movimiento"
              [(ngModel)]="cabecera.nomtipmov"
              [disabled]="inhabilitado"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6">
      <div class="card">
        <div class="card-body p-2">
          <h6 class="card-subtitle mb-2 text-muted text-center">Comentario</h6>
          <div class="input-group">
            <textarea
              class="form-control form-control-sm"
              placeholder="Ingresar Comentario"
              [(ngModel)]="cabecera.comentario"
              [disabled]="inhabilitado"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--BOTONES DE ACCIONES-->
  <div class="container-fluid my-2" *ngIf="edicion">
    <div class="row">
      <!--
        <div class="col-6">
          <button
            class="btn btn-sm btn-outline-primary"
            (click)="abrirModalLineas()"
          >
            <i class="fas fa-plus-circle mr-1"></i> Producto
          </button>
        </div>
  -->
      <div class="col-6 text-md-right">
        <button
          class="btn btn-sm btn-outline-success"
          (click)="actualizarFactura()"
        >
          <i class="far fa-check-circle mr-1"></i>Grabar
        </button>
      </div>
    </div>
  </div>

  <app-spinner></app-spinner>
</div>

<!--TABLA-->
<div class="container-fluid p-0">
  <div class="table table-responsive">
    <table class="table table-sm table-hover m-0 p-0">
      <thead class="thead-dark">
        <tr>
          <th
            scope="col"
            style="width: 80px; text-align: center"
            [style.background-color]="datosempresa['color']"
          >
            <p class="text-center m-0 p-0">Codigo</p>
          </th>
          <th scope="col" [style.background-color]="datosempresa['color']">
            <p class="text-center m-0 p-0">Descripción</p>
          </th>
          <th
            scope="col text-right"
            style="width: 125px"
            [style.background-color]="datosempresa['color']"
          >
            <p class="text-right m-0 p-0">Cantidad</p>
          </th>
          <th
            scope="col text-right"
            style="width: 115px"
            [style.background-color]="datosempresa['color']"
          >
            <p class="text-right m-0 p-0">Medida</p>
          </th>
          <th
            scope="col text-right"
            style="width: 115px"
            [style.background-color]="datosempresa['color']"
          >
            <p class="text-right m-0 p-0">Costo</p>
          </th>
          <th
            scope="col text-right"
            style="width: 115px"
            [style.background-color]="datosempresa['color']"
          >
            <p class="text-right m-0 p-0">Subtotal</p>
          </th>
          <th
            scope="col text-center"
            style="width: 25px; text-align: center"
            *ngIf="edicion"
            [style.background-color]="datosempresa['color']"
          ></th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let producto of cuerpo
              | paginate
                : {
                    itemsPerPage: 25,
                    currentPage: p,
                    totalItems: cuerpo.count
                  };
            index as i
          "
          id="{{ producto.codigo }}"
        >
          <td style="text-align: center">
            <p class="text-left m-0 p-0">
              {{ producto.codigo }}
            </p>
          </td>
          <td>
            <p class="text-left m-0 p-0">
              {{ producto.nombre }}
            </p>
          </td>
          <td>
            <p class="text-right m-0 p-0">
              {{ producto.cantidad }}
            </p>
          </td>
          <td>
            <p class="text-right m-0 p-0">
              {{ producto.nommedida }}
            </p>
          </td>
          <td>
            <p class="text-right m-0 p-0">
              {{ producto.costo.toFixed(2) }}
            </p>
          </td>
          <td>
            <p class="text-right m-0 p-0">
              <strong>
                {{ (producto.costo * producto.cantidad).toFixed(2) }}</strong
              >
            </p>
          </td>
          <td *ngIf="edicion">
            <button
              class="btn btn-sm btn-outline-danger"
              (click)="eliminarItem(i)"
            >
              <i class="fas fa-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="modal-footer" [style.background-color]="datosempresa['color']">
  <!--TOTALES-->
  <div class="container-fluid">
    <div class="row justify-content-center justify-content-md-end">
      <div
        class="col-12 col-md-4 text-center text-md-right"
        style="color: white !important"
      >
        <h5>TOTAL : $ {{ total.toFixed(2) }}</h5>
      </div>
    </div>
  </div>
</div>
