declare var require: any; //<-- Para poder importar archivos JS en TS
import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import Swal from 'sweetalert2';
var validadorCedula = require('src/app/validadores/validarCedula.js');
@Component({
  selector: 'app-modal-configuracionadd',
  templateUrl: './modal-configuracionadd.component.html',
  styleUrls: ['./modal-configuracionadd.component.css'],
})
export class ModalConfiguracionaddComponent implements OnInit {
  datos: Object; //<-- Debe tener el mismo nombre de la variable en configuracion
  tco: any;
  public event: EventEmitter<any> = new EventEmitter(); //<-- Para devolver datos a configuracion
  nombre: string = '';

  sucursales: any;
  sucursal: string = '';

  perfiles: any;
  perfil: string = '';

  clave: string = '';
  admin: boolean = false;
  unidades: any;

  usuario: any = {
    ruc: '',
    direccion: '',
    telefono: '',
    correo: '',
    clave: '',
    perfil: '',
    empresa: '',
    sucursal: '',
    quincena: [0, 0],
    findemes: [0, 0],
    fecha_ingreso: '',
  };

  vigencia: number = 0;
  porc: any = [0, 0];
  empresa: any;
  constructor(
    public modalReferencia: BsModalRef,
    private servicio: RestauranteService
  ) {}

  ngOnInit(): void {
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
    this.tco = this.datos[0];
    if (this.tco.codigo.trim() == '023') this.cargarLibras();
    else this.cargarSucursales();
    console.log('DATOS TRAIDOS DESDE CONFIGURACION', this.tco);
  }

  verificarEmpleado() {
    console.log(this.usuario);
    if (this.usuario.clave == '' || this.usuario.clave === 'undefined')
      return 'Por favor ingrese una clave';
    if (this.usuario.perfil == '' || this.usuario.perfil === 'undefined')
      return 'Por favor Seleccione el Perfil Asignado';
    if (this.usuario.sucursal == '' || this.usuario.sucursal === 'undefined')
      return 'Por favor ingrese una sucursal';

    if (this.usuario.ruc.length != 10)
      return 'El campo cédula debe tener 10  digitos.';

    if (validadorCedula.validar_CED(this.usuario.ruc) === 0)
      return 'Por favor, ingrese una cédula válida.';

    if (this.usuario.direccion == '') return 'Por favor, ingrese una direccion';
    if (this.usuario.telefono == '') return 'Por favor ingrese el telefono';
    if (this.usuario.correo == '') return 'Por favor ingrese el correo';

    if (this.usuario.quincena[0] === '' || this.usuario.quincena[1] === '') {
      return 'Ingrese una quincena';
    }
    let valor1 = parseFloat(this.usuario.quincena.join('.'));
    if (valor1 == 0) return 'Ingrese una quincena mayor a 0';

    if (this.usuario.findemes[0] === '' || this.usuario.findemes[1] === '') {
      return 'Ingrese un fin de mes ';
    }
    let valor2 = parseFloat(this.usuario.findemes.join('.'));
    if (valor2 == 0) return 'Ingrese un fin de mes mayor a 0';
    if (this.usuario.fecha_ingreso == '') return 'Ingrese una fecha de ingreso';

    return 'OK';
  }

  verificarUsuario() {
    if (this.usuario.clave == '' || this.usuario.clave === 'undefined')
      return 'Por favor ingrese una clave';
    if (this.usuario.perfil == '' || this.usuario.perfil === 'undefined')
      return 'Por favor Seleccione el Perfil Asignado';
    if (this.usuario.sucursal == '' || this.usuario.sucursal === 'undefined')
      return 'Por favor ingrese una sucursal';

    return 'OK';
  }
  //Guarda el item actualizado
  enviarItem() {
    if (this.nombre == '') {
      //No escogio todo
      Swal.fire('¡Espere!', 'Por favor, llene todos los campos', 'warning');
      return;
    }

    if (this.tco.codigo.trim() == '011') {
      let respuesta = this.verificarEmpleado();
      if (respuesta != 'OK') {
        Swal.fire('¡Espere!', respuesta, 'warning');
        return;
      }
    }
    /* SOLO APLICA CUANDO LOS EMPLEADOS NO SON USUARIOS
    if (this.tco.codigo.trim() == '006') {
      let respuesta = this.verificarUsuario();
      if (respuesta != 'OK') {
        Swal.fire('¡Espere!', respuesta, 'warning');
        return;
      }
    }
*/
    if (this.tco.codigo.trim() == '005') {
      if (isNaN(this.vigencia)) {
        return;
      }
      if (isNaN(this.porc[0]) || isNaN(this.porc[1])) {
        return;
      }
    }

    //return;
    let usuario = {
      ruc: this.usuario.ruc,
      nombre: this.nombre,
      clave: this.usuario.clave,
      direccion: this.usuario.direccion,
      telefono: this.usuario.telefono,
      correo: this.usuario.correo,
      quincena: this.usuario.quincena,
      findemes: this.usuario.findemes,
      fecha_ingreso: this.usuario.fecha_ingreso,
      empresa: this.empresa,
      sucursal: this.usuario.sucursal,
      admin: this.admin,
      vigencia: parseInt(this.vigencia + ''),
      porc: parseFloat(this.porc[0] + '.' + this.porc[1]),
      perfil: this.usuario.perfil,
    };

    console.log(usuario);

    Swal.fire({
      icon: 'success',
      title: '¡Éxito, Agregamos!',
      text: '' + this.nombre + ' en ' + this.tco.nombre,
      showConfirmButton: false,
      timer: 2000,
    }).then(() => {
      this.cerrarModal(usuario);
    });
  }

  cerrarModal(nombre) {
    this.lanzarEvento(nombre); //<-- Envia la data a configuracion
    this.modalReferencia.hide(); //<-- Cierra el modal
  }

  lanzarEvento(nombre) {
    this.event.emit({ data: nombre, res: 200 });
  }

  cargarSucursales() {
    this.servicio
      .getConfiguraciones('015', this.empresa)
      .subscribe((result) => {
        console.log(result);
        if (result.length > 0) {
          this.usuario.sucursal = result[0].codigo.trim();
          this.sucursales = result;
        }
      });
    this.servicio
      .getConfiguraciones('020', this.empresa)
      .subscribe((result) => {
        console.log(result);
        if (result.length > 0) {
          this.usuario.perfil = result[0].codigo.trim();
          this.perfiles = result;
        }
      });
  }

  cargarLibras() {
    this.servicio
      .getConfiguraciones('008', this.empresa)
      .subscribe((result) => {
        console.log(result);
        if (result.length > 0) {
          this.perfil = result[0].codigo.trim();
          this.usuario.sucursal = result[0].codigo.trim();
          this.unidades = result;
        }
      });
  }
}
