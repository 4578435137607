<!--CABECERA-->
<div class="modal-header" [style.background-color]="datosempresa['color']">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-sm-6">
        <h4 class="modal-title" style="color: #f1c40f !important">
          Gasto Diario - {{ nomsucursal }}
        </h4>
      </div>
      <div class="col-12 col-sm-6">
        <h4 class="modal-title" style="color: white !important">
          {{ fecha }}
        </h4>
      </div>
    </div>
  </div>

  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="modalReferencia.hide()"
    style="color: white !important"
  >
    <span aria-hidden="true">&times; </span>
  </button>
</div>

<!-- TABLA-->
<div class="container-fluid p-0">
  <table class="table table-striped table-sm table-hover m-0 p-0">
    <thead class="thead-dark">
      <tr>
        <th
          scope="col"
          *ngFor="let dato of cabecera"
          [style.text-align]="dato.align"
          [style.background-color]="datosempresa['color']"
          class="p-2"
        >
          {{ dato.alias }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let mov of movimiento">
        <td
          [style.text-align]="dato.align"
          *ngFor="let dato of cabecera"
          class="p-2"
        >
          <div>{{ mov[dato.campo.trim()] }}</div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="modal-footer" [style.background-color]="datosempresa['color']">
  <!--TOTALES-->
  <div class="container-fluid">
    <div class="row justify-content-center justify-content-md-end">
      <div class="col-12 text-left" style="color: white !important">
        <h5>TOTAL : $ {{ total.toFixed(2) }}</h5>
      </div>
    </div>
  </div>
</div>
