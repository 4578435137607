import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalNominaEmpleadosComponent } from '../modal-nomina-empleados/modal-nomina-empleados.component';
import Swal from 'sweetalert2';

import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalConfiguracionaddComponent } from '../../modal-configuracionadd/modal-configuracionadd.component';
import { ModalNominaComponent } from '../modal-nomina/modal-nomina.component';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-nomina-empleados',
  templateUrl: './nomina-empleados.component.html',
  styleUrls: ['./nomina-empleados.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class NominaEmpleadosComponent implements OnInit {
  modalReferencia: BsModalRef;
  empleados: any = [];
  tipos: any = [];
  movEscogido: any; //<--Elemento enviado de aqui a la template y de la template aca
  //Banderas para spinner
  banderaSpinner1: boolean = false;
  banderaSpinner2: boolean = false;
  banderaSpinner3: boolean = false;
  escogioUnaOpcion: boolean = true; //<-- Hay una opcion escogida en la dropdown
  empresa: any;
  total: number = 0;
  datosempresa: any;
  constructor(
    private modalServicio: BsModalService,
    private service: RestauranteService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.datosempresa = JSON.parse(localStorage.getItem('empresa'));
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
    this.cargarDatos();

    //Verifica bandera para ocultar spinner
    setInterval(() => {
      if (this.banderaSpinner1 === true) {
        this.spinner.hide();
        this.banderaSpinner1 = false;
      }
      if (this.banderaSpinner2 === true) {
        this.spinner.hide();
        this.banderaSpinner2 = false;
      }
      if (this.banderaSpinner3 === true) {
        this.spinner.hide();
        this.banderaSpinner3 = false;
      }
    }, 100);
  }

  cargarDatos() {
    this.spinner.show();
    this.service.obtenerTiposMovimiento(this.empresa).subscribe((result) => {
      this.tipos = result; //<-- Tipos cargados en la combobox
      this.movEscogido = this.tipos[0].codigo;
      this.banderaSpinner1 = true;
      this.filtraEmpleadosFecha();
    });
  }

  eliminar(indice) {
    Swal.fire({
      title: '¿Desea eliminar a este empleado?',
      showDenyButton: true,
      confirmButtonText: `Sí`,
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.empleados.splice(indice, 1);

        Swal.fire('¡Empleado eliminado con éxito!', '', 'success');
      }
    });
  }

  date = new FormControl(moment());

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.filtraEmpleadosFecha();
  }

  //Abre el modal
  abrirModalNuevo() {
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<-- Datos enviados desde componente hacia el modal
        '',
      ],
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferencia = this.modalServicio.show(ModalNominaComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });
    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((res) => {
      let empleado = {
        empleado: res.data.codigo,
        nomemp: res.data.nombre,
        entero: '00',
        decimal: '00',
        comentario: '',
        quincena: 0, //<-- Por default 0 es fin de mes
        opciones: [
          { id: 0, nombre: 'Fin de mes' },
          { id: 1, nombre: 'Quincena' },
        ],
      };
      this.empleados.push(empleado);
    });
  }

  formatearFechaString(fecha = new Date(), hora = false) {
    //new Date().toJSON().slice(0,10).split('-').reverse().join('/')
    let fechaFin = new Date(fecha);
    let fechaString = fechaFin
      .toJSON()
      .slice(0, 10)
      .split('-')
      .reverse()
      .join('/');
    if (hora) {
      //Formatear hora
      return fechaString;
    }
    return fechaString;
  }

  filtraEmpleadosFecha() {
    this.total = 0;
    this.spinner.show();
    this.escogioUnaOpcion = false;
    this.service
      .obtenerEmpleadosTipoFecha(
        this.movEscogido.trim(),
        this.formatearFechaString(this.date.value._d)
      )
      .subscribe((data) => {
        this.empleados = data;
        console.log(data);

        this.empleados.forEach((empleado) => {
          this.total = this.total + empleado.valor;
          empleado.comentario = empleado.comentario.trim();
          empleado.opciones = [
            { id: 0, nombre: 'Fin de mes' },
            { id: 1, nombre: 'Quincena' },
          ];
          if (this.esEntero(empleado.valor) === true) {
            empleado.entero = empleado.valor.toString();
            empleado.decimal = '00';
          } else {
            let valor = empleado.valor.toString().split('.');
            empleado.entero = valor[0];
            empleado.decimal = valor[1];
          }
        });

        this.banderaSpinner2 = true;
      });
  }

  //Verifica si el numero es entero o decimal
  esEntero(numero) {
    if (numero - Math.floor(numero) == 0) {
      return true; //<--Es entero
    } else {
      return false; //<--Es decimal
    }
  }

  actualizaEntero(empleado) {
    if (empleado.entero === '') {
      empleado.entero = '00';
    }
    empleado.valor = parseFloat(
      parseFloat(empleado.entero + '.' + empleado.decimal).toFixed(2)
    );
    this.calculatotal();
  }

  actualizaDecimal(empleado) {
    if (empleado.decimal === '') {
      empleado.decimal = '00';
    }
    if (empleado.decimal.length > 2) {
      empleado.decimal = empleado.decimal.substring(
        0,
        empleado.decimal.length - 1
      );
    }
    empleado.valor = parseFloat(
      parseFloat(empleado.entero + '.' + empleado.decimal).toFixed(2)
    );
    this.calculatotal();
  }

  calculatotal() {
    this.total = 0;
    this.empleados.forEach((item) => {
      this.total = this.total + item.valor;
    });
  }

  grabar() {
    this.spinner.show();
    let datos = {
      tipo: this.movEscogido,
      periodo: this.formatearFechaString(this.date.value._d),
      empleados: this.empleados,
    };

    this.service.movimientoNomina(datos).subscribe((data) => {
      if (data.data == 'OK') {
        this.empleados = [];
        Swal.fire({
          icon: 'success',
          title: '¡Transacción exitosa!',
          text: 'La operación se ha realizado exitosamente',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: '¡Transacción fallida!',
          text: 'La operación no ha podido completarse',
        });
      }
      this.calculatotal();
      this.banderaSpinner3 = true;
    });
  }

  abrirModalAgregarConfiguracion() {
    let tco = {
      codigo: '011',
      nombre: 'Empleado',
    };
    //Este modal recibe el id del del tco
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<--Datos enviados desde componente hacia modal
        tco, //<--nombre tco
      ],
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferencia = this.modalServicio.show(
      ModalConfiguracionaddComponent,
      {
        initialState,
        ignoreBackdropClick: true,
        class: 'modal-md',
      }
    );

    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((res) => {
      console.log('MODAL CERRADO, DATA RECIBIDA', res.data);

      if (res.data) {
        //Crea el elemento en el temporal y en la DB
        this.creaItemArray(tco, res.data);
      }
    });
  }

  //Crea el elemento en el array
  creaItemArray(tco, item) {
    if (item.fecha_ingreso == '') {
      item.fecha_ingreso = new Date();
    }
    let datos = {
      codigoTco: tco.codigo,
      cedula: item.cedula,
      nombre: item.nombre,
      direccion: item.direccion,
      telefono: item.telefono,
      correo: item.correo,
      quincena: item.quincena.join('.'),
      fin_mes: item.findemes.join('.'),
      fecha_ingreso: this.formatearFechaQuery(item.fecha_ingreso),
      sucursal: item.sucursal,
      clave: item.clave,
      admin: item.admin,
      vigencia: item.vigencia,
      porc: item.porc,
    };
    this.spinner.show();
    this.service.creaItemTCO(datos).subscribe((data) => {
      console.log(data);
      let empleado = {
        empleado: data.codigo.trim(),
        nomemp: data.nombre.trim(),
        entero: '00',
        decimal: '00',
        comentario: '',
        quincena: 0, //<-- Por default 0 es fin de mes
        opciones: [
          { id: 0, nombre: 'Fin de mes' },
          { id: 1, nombre: 'Quincena' },
        ],
      };
      this.empleados.push(empleado);
      this.spinner.hide();
    });
  }

  formatearFechaQuery(fecha = new Date(), hora = '00:00:00') {
    let fechaFin = new Date(fecha);
    let fechaTotal = fechaFin.toJSON().slice(0, 10);
    return fechaTotal + ' ' + hora;
  }
}
