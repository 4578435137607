import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';

@Component({
  selector: 'app-modal-items',
  templateUrl: './modal-items.component.html',
  styleUrls: ['./modal-items.component.css'],
})
export class ModalItemsComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter(); //<-- En este caso lo usamos para avisar al componente padre que cerramos (es como un @Output hijo a padre)
  rucCliente: any; //<--RUC cliente
  idLinea: any; //<--ID de la linea a abrir
  nomLinea: any; //<--Nombre de la linea a abrir
  //Bandera para spinner
  banderaSpinner: boolean = false;
  datosItems: Object; //<-- Debe tener el mismo nombre de la variable en factura
  productos: any = []; //<--Productos de esta linea traidos desde la DB
  hayProductos: boolean; //<--Verifica si hay lineas

  total: Number = 0; //<--Total para este articulo
  empresa: String = '';

  constructor(
    private servicio: RestauranteService,
    public modalReferenciaItems: BsModalRef,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
    this.idLinea = this.datosItems[0]; //<--Tomando ID de la linea
    this.nomLinea = this.datosItems[1]; //<--Tomando Nombre de la Linea
    console.log(this.idLinea);
    this.cargarDatos();

    setInterval(() => {
      if (this.banderaSpinner === true) {
        this.spinner.hide();
        this.banderaSpinner = false;
      }
    }, 100);
  }

  cargarDatos() {
    let campos = {
      codigo: this.idLinea,
      usuario: JSON.parse(localStorage.getItem('usuario')).codigo,
      empresa: this.empresa,
    };
    this.servicio.obtenerItemsLinea(campos).subscribe((data) => {
      console.log(data);
      if (data.length === 0) {
        this.hayProductos = false;
      } else {
        this.hayProductos = true;
        this.productos = data;

        this.productos.forEach((producto) => {
          producto.nombre = producto.nombre.trim();
          producto.linea = producto.linea.trim();
          producto.cantidad = 0;
          this.total =
            Number(this.total) + Number(producto.precio * producto.cantidad); //Calcula total
        });
        console.log(this.productos);
      }
    });
  }

  restarCantidad(id) {
    if (this.productos[id].cantidad > 0) {
      this.productos[id].cantidad--;
    }
  }

  aumentarCantidad(id) {
    this.productos[id].cantidad++;
  }

  //Cerrar modal y enviar la data a factura
  cerrarModal(data) {
    this.event.emit(data);
    this.modalReferenciaItems.hide(); //<-- Cierra el modal
  }

  agregar() {
    console.log(this.productos);
    let mini = [];
    this.productos.forEach((element) => {
      if (element.cantidad > 0) mini.push(element);
    });
    this.cerrarModal(mini);
  }
}
