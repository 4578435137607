<!--Cabecera-->
<div class="row justify-content-center align-items-center my-2">
  <div class="col-9 col-md-10 text-center">
    <h3>
      Busqueda
      <small class="text-muted"> Empleado</small>
    </h3>
  </div>
  <div
    class="col-2 col-md-1 justify-content-center align-items-center"
    (click)="modalReferencia.hide()"
  >
    <button type="button" class="close pull-right" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>

<!--Busqueda-->
<div class="row justify-content-center align-items-center m-3">
  <div class="col-10 col-lg-8 col-xl-6 mt-2 text-center text-md-left">
    <div class="input-group">
      <input
        type="text"
        class="form-control form-control-sm"
        placeholder=" Ingrese el nombre | RUC:"
        aria-describedby="basic-addon1"
        (keyup)="traerClientes(2, $event)"
        [(ngModel)]="rucCliente"
        value="{{ rucCliente }}"
      />
      <div class="input-group-append">
        <span class="input-group-text" id="basic-addon1"
          ><i class="fab fa-searchengin"></i
        ></span>
      </div>
    </div>
  </div>
</div>

<!--Accion de busqueda-->
<div class="container-fluid">
  <div
    class="
      table
      table-responsive
      table-hover
      table-responsive-sm
      table-responsive-md
      table-responsive-xl
    "
    *ngIf="hayClientes === true"
  >
    <table class="table table-sm">
      <thead>
        <tr>
          <th scope="col">Empleado</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let cliente of clientes">
          <td id="{{ cliente.codigo }}" (click)="cerrarModal($event)">
            {{ cliente.nombre }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
