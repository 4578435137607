<div style="display: flex; height: 100%">
  <div class="historial">
    <div class="container" style="height: 100%; overflow: scroll">
      <div class="mt-3" style="display: flex; justify-content: flex-end">
        <button
          type="button"
          class="btn btn-outline-danger"
          style="width: 65px"
        ></button>
      </div>
      <div class="text-center">
        <h3>
          Caja
          <small class="text-muted"> NovaGroup </small>
        </h3>
      </div>

      <div class="mt-3">
        <table class="table">
          <thead class="thead-dark">
            <tr>
              <th scope="col">Tipo</th>
              <th scope="col">Número</th>
              <th scope="col">Texto</th>
              <th scope="col">Cliente</th>

              <th scope="col">Pago</th>
              <th scope="col">Valor</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let pedido of pedidos">
              <td>{{ pedido.tipo }}</td>
              <td>{{ pedido.numero }}</td>
              <td>{{ pedido.texto }}</td>
              <td>{{ pedido.cliente }}</td>
              <td>{{ pedido.pago }}</td>
              <td>{{ pedido.valor }}</td>

              <td>
                <button
                  type="button"
                  class="btn btn-outline-info"
                  (click)="abrirModalPedido()"
                >
                  <i class="fas fa-plus"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="notificaciones" style="height: 100%">
    <div style="height: 33.33%; overflow-y: scroll">
      <div class="card pedido-tarjeta">
        <div class="card-body">
          <p>Mesa:</p>
          <p>Cliente:</p>
        </div>
      </div>
      <div class="card pedido-tarjeta">
        <div class="card-body">
          <p>Mesa:</p>
          <p>Cliente:</p>
        </div>
      </div>
      <div class="card pedido-tarjeta">
        <div class="card-body">
          <p>Mesa:</p>
          <p>Cliente:</p>
        </div>
      </div>
    </div>
    <div style="height: 33.33%; overflow-y: scroll">
      <div class="card pedido-tarjeta">
        <div class="card-body">
          <p>Mesa:</p>
          <p>Cliente:</p>
        </div>
      </div>
    </div>
    <div style="height: 33.33%; overflow-y: scroll">
      <div class="card pedido-tarjeta">
        <div class="card-body">
          <p>Mesa:</p>
          <p>Cliente:</p>
        </div>
      </div>
    </div>
  </div>
</div>
