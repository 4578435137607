<!--CABECERA-->
<div class="container-fluid my-2">
  <div class="row align-items-center">
    <div class="col-12 col-md-4 col-lg-3 col-xl-2" *ngIf="admin">
      <mat-form-field appearance="fill" class="no-padding">
        <mat-label>Sucursal</mat-label>
        <mat-select panelClass="my-select-panel-class" [(value)]="sucursal">
          <mat-option
            *ngFor="let sucursal of sucursales"
            [value]="sucursal.codigo.trim()"
            >{{ sucursal.nombre }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col">
      <h4>
        Apertura de Caja
        <small class="text-muted"></small>
      </h4>
    </div>
  </div>
</div>

<!-- BOTONES DE ACCION-->
<div class="container-fluid">
  <div class="row">
    <div class="col-12 text-right">
      <button
        type="button"
        class="btn btn-sm btn-outline-danger"
        (click)="grabarApertura()"
        *ngIf="productos.length > 0"
      >
        <i class="fas fa-cloud-upload-alt"></i> Grabar
      </button>
    </div>
  </div>
</div>

<div class="container-fluid my-1">
  <div class="row align-items-center justify-content-center">
    <!--MOVIMIENTO DE MONEDAS-->
    <div class="col-12 col-md-4">
      <div class="container-fluid mt-2" *ngIf="monedas.length > 0">
        <div class="table-responsive">
          <table class="table table-sm table-striped table-hover m-0 p-0">
            <thead>
              <tr>
                <th style="width: 250px; text-align: center">Monedas</th>
                <th style="width: 150px; text-align: center">Cantidad</th>
                <th
                  style="width: 150px; text-align: center"
                  [style.background-color]="datosempresa['color']"
                  class="text-light"
                >
                  Subtotal
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let moneda of monedas
                    | paginate
                      : {
                          itemsPerPage: 25,
                          currentPage: p,
                          totalItems: monedas.count
                        };
                  index as i
                "
                id="{{ moneda.codigo }}"
              >
                <td>
                  <p class="m-0 p-0">
                    {{ moneda.nombre }}
                  </p>
                </td>

                <td>
                  <input
                    type="numeric"
                    class="form-control form-control-sm text-right"
                    [(ngModel)]="moneda.inicial"
                    (keydown)="limitarDecimales($event, 4, moneda.inicial)"
                    (focusin)="limpiarInput(moneda, 'inicial')"
                    (focusout)="verificarInput(moneda, 'inicial', i)"
                  />
                </td>
                <td
                  class="text-light"
                  [style.background-color]="datosempresa['color']"
                >
                  <p
                    style="text-align: right; font-size: 1.2rem"
                    class="m-0 p-0"
                  >
                    <strong>{{ moneda.subtotal.toFixed(2) }}</strong>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <table class="table table-clear m-0 p-0">
          <tbody>
            <tr [style.background-color]="datosempresa['color']">
              <td class="text-light">
                <strong
                  class="text-dark m-0 p-0"
                  style="color: white !important"
                  >Total Monedas :
                </strong>
              </td>
              <td class="text-right">
                <strong
                  class="text-dark m-0 p-0"
                  style="color: white !important"
                  >$ {{ totalMonedas.toFixed(2) }}</strong
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!--MOVIMIENTO DE BILLETES-->
    <div class="col-12 col-md-4">
      <div class="container-fluid mt-2" *ngIf="billetes.length > 0">
        <div class="table-responsive">
          <table class="table table-sm table-striped table-hover m-0 p-0">
            <thead>
              <tr>
                <th style="width: 250px; text-align: center">Billetes</th>
                <th style="width: 150px; text-align: center">Cantidad</th>
                <th
                  style="width: 150px; text-align: center"
                  [style.background-color]="datosempresa['color']"
                  class="text-light"
                >
                  Subtotal
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let billete of billetes
                    | paginate
                      : {
                          itemsPerPage: 25,
                          currentPage: p,
                          totalItems: billetes.count
                        };
                  index as i
                "
                id="{{ billete.codigo }}"
              >
                <td>
                  <p class="m-0 p-0">
                    {{ billete.nombre }}
                  </p>
                </td>

                <td>
                  <input
                    type="numeric"
                    class="form-control form-control-sm text-right"
                    [(ngModel)]="billete.inicial"
                    (keydown)="limitarDecimales($event, 4, billete.inicial)"
                    (focusin)="limpiarInput(billete, 'inicial')"
                    (focusout)="verificarInput(billete, 'inicial', i)"
                  />
                </td>
                <td
                  class="text-light"
                  [style.background-color]="datosempresa['color']"
                >
                  <p
                    style="text-align: right; font-size: 1.2rem"
                    class="m-0 p-0"
                  >
                    <strong>{{ billete.subtotal.toFixed(2) }}</strong>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <table class="table table-clear m-0 p-0">
          <tbody>
            <tr [style.background-color]="datosempresa['color']">
              <td class="text-light">
                <strong
                  class="text-dark m-0 p-0"
                  style="color: white !important"
                  >Total Billetes :
                </strong>
              </td>
              <td class="text-right">
                <strong
                  class="text-dark m-0 p-0"
                  style="color: white !important"
                  >$ {{ totalBilletes.toFixed(2) }}</strong
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="col-12 col-md-4 py-2">
      <div class="alert alert-success" role="alert">
        <h4 class="alert-heading text-center">
          Apertura : $ {{ totalGeneral.toFixed(2) }}
        </h4>

        <hr />
        <p>
          Estimado/a Cajero/a
          <br />
          <br />
          Agradecemos profundamente tu dedicación diaria, que impulsa la
          productividad y el éxito de nuestra compañía. Hoy, como siempre,
          confiamos en tu excepcional compromiso. ¡Sigamos avanzando juntos!
        </p>
      </div>
    </div>
    <div class="col-12 py-4">
      <!--MOVIMIENTO DE LA COMPRA-->
      <div class="container-fluid mt-2" *ngIf="productos.length > 0">
        <div class="table-responsive">
          <table class="table table-sm table-striped table-hover m-0 p-0">
            <thead>
              <tr>
                <th style="text-align: center">Materia Prima</th>
                <th style="width: 100px; text-align: center">Cantidad</th>
                <th style="width: 75px; text-align: center">Medida</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let producto of productos
                    | paginate
                      : {
                          itemsPerPage: 25,
                          currentPage: p,
                          totalItems: productos.count
                        };
                  index as i
                "
                id="{{ producto.codigo }}"
              >
                <td>
                  <p class="m-0 p-0">
                    {{ producto.nombre }}
                  </p>
                </td>

                <td>
                  <input
                    type="numeric"
                    class="form-control form-control-sm text-right"
                    [(ngModel)]="producto.inicial"
                    (keydown)="limitarDecimales($event, 4, producto.inicial)"
                    (focusin)="limpiarInput(producto, 'inicial')"
                    (focusout)="verificarInput(producto, 'inicial', i)"
                  />
                </td>
                <td>
                  <div
                    class="input-group"
                    style="width: 150px; text-align: center"
                  >
                    <select
                      class="custom-select custom-select-sm"
                      name="um"
                      id="um"
                      [(ngModel)]="producto.umEscogida"
                    >
                      <!--<option *ngFor="let datoTin of datosTin" [ngValue]="datoTin">{{datoTin.nombre}}</option>    -->
                      <option
                        *ngFor="let datoUm of datosUm"
                        value="{{ datoUm.codigo }}"
                      >
                        {{ datoUm.nombre }}
                      </option>
                    </select>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
