<div class="container-fluid mt-2">
  <div class="row justify-content-center align-items-center mb-3">
    <div class="col-12 text-center text-md-left text-xl-center">
      <h3>
        Kardex
        <small class="text-muted">del producto</small>
      </h3>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row justify-content-center align-items-center mb-3">
    <div class="col-sm-11 col-md-9 col-xl-6">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-md-6">
              <mat-form-field class="example-form-field" appearance="fill">
                <mat-label>Filtro de fecha</mat-label>
                <mat-date-range-input
                  [formGroup]="campaignOne"
                  [rangePicker]="campaignOnePicker"
                >
                  <input
                    matStartDate
                    placeholder="Inicio"
                    formControlName="start"
                  />
                  <input
                    matEndDate
                    placeholder="Fin"
                    formControlName="end"
                    (dateChange)="actualizarData()"
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="campaignOnePicker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker
                  #campaignOnePicker
                ></mat-date-range-picker>
              </mat-form-field>
            </div>
            <div
              class="col-12 col-md-6 d-flex"
              style="align-items: center"
              *ngIf="admin"
            >
              <mat-form-field appearance="fill" class="no-padding">
                <mat-label>Sucursal</mat-label>
                <mat-select
                  panelClass="my-select-panel-class"
                  [(value)]="sucursal"
                  (selectionChange)="cargarData()"
                >
                  <mat-option value="TODOS"> Todos </mat-option>
                  <mat-option
                    *ngFor="let sucursal of sucursales"
                    [value]="sucursal.codigo.trim()"
                    >{{ sucursal.nombre }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid" *ngIf="kardex.length > 0">
  <ul class="list-group">
    <li class="list-group-item kardex-item">
      <div class="row">
        <div class="col-1 style font-weight-bold">Código</div>
        <div class="col-9 style font-weight-bold">Nombre</div>
        <div class="col-1 style font-weight-bold">Stock</div>

        <div class="col-1 style font-weight-bold"></div>
      </div>
    </li>

    <li class="list-group-item" *ngFor="let item of kardex; index as i">
      <div class="row kardex-item" (click)="consultarProducto(item.codigo)">
        <div class="col-1">
          {{ item.codigo }}
        </div>
        <div class="col-9">
          {{ item.nombre }}
        </div>
        <div class="col-1">
          {{ item.stock }}
        </div>

        <div class="col-1">
          <i id="i_{{ item.codigo }}" class="fas fa-chevron-right"></i>
        </div>
      </div>
      <div id="tabla_{{ item.codigo }}" style="display: none">
        <div class="table-responsive">
          <table class="table table-striped table-sm table-hover m-0 p-0">
            <thead>
              <tr>
                <th scope="col">Sucursal</th>
                <th scope="col">Tipo</th>
                <th scope="col">Numero</th>
                <th scope="col">Fecha</th>
                <th scope="col">Cantidad</th>
                <th scope="col">Medida</th>
                <th scope="col">Cantbod</th>
                <th scope="col">Medbod</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td>{{ saldo.tipo }}</td>
                <td>{{ saldo.numero }}</td>
                <td>{{ formatearFechaString(saldo.fecha) }}</td>
                <td class="text-right">{{ saldo.cantidad }}</td>
              </tr>
              <tr
                *ngFor="let producto of productos"
                [class]="producto.cantidad > 0 ? 'fila_ingreso' : ''"
              >
                <td>{{ producto.sucursal }}</td>
                <td>{{ producto.tipo }}</td>
                <td>{{ producto.numero }}</td>
                <td>{{ formatearFechaString(producto.fecha) }}</td>
                <td
                  class="text-right"
                  [style.color]="producto.cantidad < 0 ? 'red' : ''"
                >
                  {{ producto.cantidad }}
                </td>
                <td>{{ producto.medida }}</td>
                <td class="text-right">{{ producto.cantbod }}</td>
                <td>{{ producto.medbod }}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <span class="font-weight-bold">{{ totales.label }}</span>
                </td>
                <td class="font-weight-bold text-right">
                  {{ totales.cantidad }}
                </td>
                <td></td>
                <td class="font-weight-bold text-right">
                  {{ totales.cantbod }}
                </td>
                <td class="font-weight-bold text-right">
                  {{ totales.medbod }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </li>
  </ul>
</div>
<div class="container" *ngIf="kardex.length == 0">
  <div class="row justify-content-center align-items-center">
    <div class="col-6 mt-5" style="text-align: center">
      <lottie-player
        src="https://assets2.lottiefiles.com/temp/lf20_TOE9MF.json"
        background="transparent"
        speed="1"
        loop
        autoplay
      ></lottie-player>
      <h3>
        No hay resultados
        <small class="text-muted"> en Kardex de productos</small>
      </h3>
    </div>
  </div>
</div>

<app-spinner></app-spinner>
