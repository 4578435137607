import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-filtro',
  templateUrl: './modal-filtro.component.html',
  styleUrls: ['./modal-filtro.component.css']
})
export class ModalFiltroComponent implements OnInit {

  datos:Object //<-- Debe tener el mismo nombre de la variable en factura
  nombre: String //<--Nombre de la seleccion para filtrado
  datosModal: any = [] //<--Datos para el modal sin duplicados
  public event:EventEmitter<any> = new EventEmitter() //<-- Para devolver datos a cliente
  filtroAplicado: boolean //<--Indica si se ha aplicado el filtrado

  constructor(public modalReferencia:BsModalRef) { }

  ngOnInit(): void {  
    this.nombre = this.datos[0]
    this.datos[1].forEach(datoModal => {
      this.datosModal.push({valor:datoModal,marcado:false})
    });
    this.filtroAplicado = this.datos[2]
  }

  marcar(nombreDato,event){
    for(let i=0;i<this.datosModal.length;i++){
      if(this.datosModal[i].valor===nombreDato){
        this.datosModal[i].marcado = event.target.checked
      }
    }
  }

  filtrar(){
    let control = false //<-- Si es falso quiere decir que ni un solo elemento fue escogido
    let marcados = []
    this.datosModal.forEach(datoModal => {
      marcados.push(datoModal.marcado)
    });
    if(marcados.includes(true)){
      control = true
      this.filtroAplicado = true
    }
    if(control===false){ 
      Swal.fire(
        '¡Ninguna opción seleccionada!',
        'Debe escoger almenos una opción para realizar el filtrado.',
        'error'
      )
    }
    else{
      this.modalReferencia.hide(); //<-- Cierra el modal
      let datosEscogidos = []
      this.datosModal.forEach(datoModal => {
        if(datoModal.marcado===true){
          datosEscogidos.push(datoModal.valor)
        }
      });
      this.lanzarEvento(datosEscogidos)
    }
  }

  lanzarEvento(item:any){
    this.event.emit({data: [this.filtroAplicado,item],res:200})
  }

  quitarFiltro(){
    this.modalReferencia.hide()
    this.lanzarEvento(false)
  }

}
