<div class="container-fluid my-4">
  <div class="row justify-content-center align-items-center">
    <div class="col-9 col-md-10 text-center">
      <h3>
        Registro de Faltas Empleados
        <small class="text-muted"></small>
      </h3>
    </div>
  </div>
</div>

<div class="container-fluid py-2">
  <div class="row d-flex justify-content-center">
    <div class="col-12 col-md-3">
      <div class="card py-2 text-center">
        <div class="card-content">
          <mat-form-field appearance="fill">
            <mat-label>Año y Mes</mat-label>
            <input matInput [matDatepicker]="dp" [formControl]="date" />
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker
              #dp
              startView="multi-year"
              (yearSelected)="chosenYearHandler($event)"
              (monthSelected)="chosenMonthHandler($event, dp)"
              panelClass="example-month-picker"
            >
            </mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid my-1">
  <div class="row">
    <div class="col-6">
      <button
        class="btn btn-outline-success btn-sm btn_est m-2"
        (click)="abrirModalNuevo()"
      >
        <i class="fas fa-plus mr-2"></i>Empleado
      </button>
    </div>
    <div class="col-6 text-right">
      <button class="btn btn-warning btn-sm btn_est m-2" (click)="grabar()">
        <i class="far fa-save mr-2"></i>Grabar
      </button>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="table-responsive" *ngIf="empleados.length > 0; else vacio">
    <table class="table table-striped table-sm table-hover m-0 p-0">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Código</th>
          <th scope="col" style="min-width: 150px">Nombre</th>
          <th scope="col" style="width: 150px !important; text-align: center">
            Fecha
          </th>
          <th scope="col" style="min-width: 150px">Comentario</th>
          <th scope="col" style="width: 150px">Motivo</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let empleado of empleados; index as i">
          <td>
            <button class="btn btn-outline-danger btn-sm" (click)="eliminar(i)">
              <i class="far fa-trash-alt"></i>
            </button>
          </td>
          <td>{{ empleado.empleado }}</td>
          <td>{{ empleado.nomemp }}</td>
          <td>
            <input
              class="form-control form-control-sm"
              type="date"
              [(ngModel)]="empleado.fecha_falta"
            />
          </td>
          <td>
            <textarea
              class="form-control form-control-sm"
              id="comentario"
              rows="1"
              [(ngModel)]="empleado.comentario"
              >{{ empleado.comentario }}</textarea
            >
          </td>

          <td>
            <select
              class="form-select form-select-sm"
              [(ngModel)]="empleado.tipo_falta"
            >
              <option *ngFor="let motivo of motivos" [value]="motivo.codigo">
                {{ motivo.nombre }}
              </option>
            </select>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #vacio> </ng-template>

<app-spinner></app-spinner>
