declare var require: any; //<-- Para poder importar archivos JS en TS

import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ServicioMeseroService } from 'src/app/services/servicio-mesero/servicio-mesero.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
var validadorCedula = require('src/app/validadores/validarCedula.js');
var validadorRuc = require('src/app/validadores/validarRuc.js');
var validadorCorreo = require('src/app/validadores/validarCorreo.js');
@Component({
  selector: 'app-modal-clienteadd',
  templateUrl: './modal-clienteadd.component.html',
  styleUrls: ['./modal-clienteadd.component.css'],
})
export class ModalClienteaddComponent implements OnInit {
  cliente: any = {
    ruc: '',
    nombre: '',
    direccion: '',
    telefono: null,
    correo: '',
    empresa: '',
    sucursal: '',
  }; //<-- Aqui se guarda el nuevo cliente creado
  public event: EventEmitter<any> = new EventEmitter(); //<-- Para devolver datos a cliente
  banderaSpinner: boolean = false;
  empresa: string = '';
  sucursal: string = '';

  constructor(
    public modalReferencia: BsModalRef,
    private servicio: ServicioMeseroService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
    if (JSON.parse(localStorage.getItem('sucursal')) !== null) {
      this.sucursal = JSON.parse(localStorage.getItem('sucursal'))['codigo'];
    }
    //Verifica bandera para ocultar spinner
    setInterval(() => {
      if (this.banderaSpinner === true) {
        this.spinner.hide();
        this.banderaSpinner = false;
      }
    }, 100);
  }

  //Guarda el cliente creado
  enviarCliente() {
    //Obtiene clientes
    let ruc = this.cliente.ruc;
    let nombre = this.cliente.nombre;
    let direccion = this.cliente.direccion;
    let telefono = this.cliente.telefono;
    let correo = this.cliente.correo;
    //Valida
    if (
      nombre == '' ||
      ruc == '' ||
      direccion == '' ||
      telefono == null ||
      correo == ''
    ) {
      //No escogio todo
      Swal.fire('¡Error!', 'Por favor, llene todos los campos', 'error');
      return;
    }
    if (ruc.length != 10 && ruc.length != 13) {
      //Tam cedula o ruc
      Swal.fire('¡Error!', 'El campo RUC debe tener 10 o 13 digitos.', 'error');
      return;
    }
    if (ruc.length === 10) {
      if (validadorCedula.validar_CED(ruc) === 0) {
        Swal.fire(
          '¡Cédula inválida!',
          'Por favor, ingrese una cédula válida.',
          'error'
        );
        return;
      }
    }
    if (ruc.length === 13) {
      if (validadorRuc.validar_RUC_3(ruc) === 0) {
        Swal.fire(
          '¡RUC inválido!',
          'Por favor, ingrese un RUC válido.',
          'error'
        );
        return;
      }
    }
    if (validadorCorreo.validarEmail(correo) === false) {
      Swal.fire(
        '¡Correo inválido!',
        'Por favor, ingrese un correo válido.',
        'error'
      );
      return;
    }
    //Devuelve el cliente
    this.spinner.show();
    this.servicio.buscarCliente(ruc, this.empresa).subscribe((data) => {
      //Si ya existe
      if (data.length > 0) {
        this.banderaSpinner = true;
        Swal.fire('¡Error!', 'Este RUC ya se encuentra registrado.', 'error');
      } else {
        //Si no existe
        this.cliente.ruc = ruc;
        this.cliente.nombre = nombre;
        this.cliente.direccion = direccion;
        this.cliente.telefono = telefono;
        this.cliente.correo = correo;
        this.banderaSpinner = true;
        Swal.fire('¡Éxito!', 'Operación exitosa.', 'success').then(() => {
          this.cerrarModal();
        });
      }
    });
  }

  cerrarModal() {
    this.lanzarEvento(this.cliente); //<-- Envia la data a cliente
    this.modalReferencia.hide(); //<-- Cierra el modal
  }

  lanzarEvento(item) {
    this.event.emit({ data: item, res: 200 });
  }

  cancelar() {
    this.modalReferencia.hide();
  }
}
