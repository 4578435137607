<div class="row">
  <div class="col-12 col-md-6 col-lg-6">
    <select
      class="form-select"
      aria-label="Default select example"
      [(ngModel)]="selectorGrafico"
      (change)="cargarGraficos()"
    >
      <option
        *ngFor="let selector of selectoresGrafico"
        [value]="selector.orden"
      >
        {{ selector.nombre }}
      </option>
    </select>
    <canvas #lineChart></canvas>
    <div class="d-flex justify-content-center mb-3">
      <button class="btn btn-info btn-sm" (click)="abrirModal()">
        <i class="fas fa-search-plus mr-2"></i> Ver
      </button>
    </div>
  </div>
  <div class="col-12 col-md-6 col-lg-6">
    <select
      class="form-select"
      aria-label="Default select example"
      [(ngModel)]="selectorTabla"
      (change)="cargarTabla()"
    >
      <option *ngFor="let selector of selectoresTabla" [value]="selector.orden">
        {{ selector.nombre }}
      </option>
    </select>

    <table class="table">
      <thead class="thead-dark">
        <tr>
          <th
            scope="col"
            *ngFor="let dato of cabecera"
            [style.text-align]="dato.align"
            style="padding-top: 0; padding-bottom: 0"
          >
            {{ dato.alias }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let mov of movimiento">
          <td
            *ngFor="let dato of cabecera"
            [style.text-align]="dato.align"
            style="padding-top: 0; padding-bottom: 0"
          >
            <div>{{ mov[dato.campo.trim()] }}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="d-flex" style="justify-content: flex-end">
  <p class="mr-3 font-weight-bold" style="font-size: 20px; margin: 0">
    Total :
  </p>
  <p style="font-size: 20px; margin: 0">{{ total.toFixed(2) }}</p>
</div>
<app-spinner></app-spinner>
