import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { ModalInventarioComponent } from 'src/app/components/restaurante/modal-inventario/modal-inventario.component';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-inventario',
  templateUrl: './registro-inventario.component.html',
  styleUrls: ['./registro-inventario.component.css'],
})
export class InventarioComponent implements OnInit {
  datosTin: any = [];
  datosInv: any = [];
  comentario: string = ''; //<--Usa double data binding
  tinEscogido: any; //<--Elemento enviado de aqui a la template y de la template aca
  invEscogido: any; //<--Elemento enviado de aqui a la template y de la template aca
  //Referencia del modal
  modalReferencia: BsModalRef;
  productos: any = []; //<--Para guardar los productos que se van pintando en la template

  datosUm: any = [];

  admin: boolean = false;
  sucursales: any;
  sucursal: string = 'todos';

  empresas: any;
  empresa: string;
  total: number = 0;

  constructor(
    private router: Router,
    private servicio: RestauranteService,
    private modalServicio: BsModalService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    //Trae datos desde localStorage
    if (localStorage.getItem('inventario')) {
      let inventario = JSON.parse(localStorage.getItem('inventario'));
      this.comentario = inventario.comentario;
      if (inventario.productos && inventario.productos.length > 0) {
        inventario.productos.forEach((producto) => {
          this.productos.push(producto);
        });
        console.log(this.productos);
        document.getElementById('tin').setAttribute('disabled', 'true');
        document.getElementById('inv').setAttribute('disabled', 'true');
      }
    } else {
      localStorage.setItem('inventario', JSON.stringify({ comentario: '' }));
    }

    this.getTIN();
    this.getINV();
    this.getUND();
    this.admin = JSON.parse(localStorage.getItem('usuario')).admin;
    this.sucursal = JSON.parse(localStorage.getItem('sucursal')).codigo;
    this.empresa = JSON.parse(localStorage.getItem('empresa')).codigo;
    this.cargarSucursales();
    this.calculatotales();
    this.actualizaComentario();
  }

  //Obtiene todos los TIN
  getTIN() {
    this.servicio.getConfiguraciones('007', this.empresa).subscribe((data) => {
      this.datosTin = data;
      this.tinEscogido = this.datosTin[0];
      this.datosTin.forEach((datoTin) => {
        datoTin.codigo = datoTin.codigo.trim();
        datoTin.nombre = datoTin.nombre.trim();
      });
      if (localStorage.getItem('inventario')) {
        let infoPersistente = JSON.parse(localStorage.getItem('inventario'));
        infoPersistente.codTin = this.datosTin[0].codigo;
        infoPersistente.preg = this.datosTin[0].tipreg;
        localStorage.setItem('inventario', JSON.stringify(infoPersistente));
      } else {
        localStorage.setItem(
          'inventario',
          JSON.stringify({
            codTin: this.datosTin[0].codigo,
            preg: this.datosTin[0].tipreg,
            comentario: '',
          })
        );
      }
      this.spinner.hide();
    });
  }

  //Obtiene todos los INV
  getINV() {
    this.servicio.getConfiguraciones('009', this.empresa).subscribe((data) => {
      this.datosInv = data;
      this.invEscogido = this.datosInv[0];
      this.datosInv.forEach((datoInv) => {
        datoInv.codigo = datoInv.codigo.trim();
        datoInv.nombre = datoInv.nombre.trim();
      });
      if (localStorage.getItem('inventario')) {
        let infoPersistente = JSON.parse(localStorage.getItem('inventario'));
        infoPersistente.codInv = this.datosInv[0].codigo;
        localStorage.setItem('inventario', JSON.stringify(infoPersistente));
      } else {
        localStorage.setItem(
          'inventario',
          JSON.stringify({ codInv: this.datosInv[0].codigo })
        );
      }
      this.spinner.hide();
    });
  }

  //Busca elemento TIN o INV en su array respectivo y lo devuelve
  buscarCliente(id, arreglo) {
    for (let indice in arreglo) {
      //<-- Itera todos los indices
      let elemento = arreglo[indice]; //<-- Toma el i-esimo elemento
      if (elemento.codigo == id) {
        //Compara el id elemento con el id del elemento a buscar
        return elemento;
      }
    }
  }

  actualizaTinPersistente() {
    let infoPersistente = JSON.parse(localStorage.getItem('inventario'));
    infoPersistente.codTin = this.tinEscogido.codigo;
    infoPersistente.preg = this.tinEscogido.tipreg;
    localStorage.setItem('inventario', JSON.stringify(infoPersistente));
  }

  actualizaInvPersistente() {
    let infoPersistente = JSON.parse(localStorage.getItem('inventario'));
    infoPersistente.codInv = this.invEscogido.codigo;
    localStorage.setItem('inventario', JSON.stringify(infoPersistente));
  }

  //Obtiene todas las unidades de medida
  getUND() {
    this.servicio.getConfiguraciones('008', this.empresa).subscribe((data) => {
      this.datosUm = data;
      this.datosUm.forEach((datoUm) => {
        datoUm.codigo = datoUm.codigo.trim();
        datoUm.nombre = datoUm.nombre.trim();
      });
    });
  }

  //Actualiza la um para el producto
  actualizaUm(event, codigo) {
    let id = codigo; //<--Recupero el id del producto
    let codigoUm = event.target.value; //<--Codigo de la um
    //Actualizo la um en el temporal
    this.productos.forEach((producto) => {
      if (producto.codigo === parseInt(id)) {
        producto.umEscogida = codigoUm;
        for (let i = 0; i < producto.umLista.length; i++) {
          if (producto.umLista[i].codigo === codigoUm) {
            const item = producto.umLista[i];
            producto.umLista.splice(i, 1);
            let nuevaLista = [item];
            producto.umLista.forEach((item) => {
              nuevaLista.push(item);
            });
            producto.umLista = nuevaLista;
            break;
          }
        }
      }
    });
    //Actualizo la um en localStorage
    let inventario = JSON.parse(localStorage.getItem('inventario'));
    inventario.productos.forEach((producto) => {
      if (producto.codigo === parseInt(id)) {
        producto.umEscogida = codigoUm;
        for (let i = 0; i < producto.umLista.length; i++) {
          if (producto.umLista[i].codigo === codigoUm) {
            const item = producto.umLista[i];
            producto.umLista.splice(i, 1);
            let nuevaLista = [item];
            producto.umLista.forEach((item) => {
              nuevaLista.push(item);
            });
            producto.umLista = nuevaLista;
            break;
          }
        }
      }
    });
    localStorage.setItem('inventario', JSON.stringify(inventario));
  }

  abrirModalNuevoProducto() {
    //Este modal no recibe datos
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferencia = this.modalServicio.show(ModalInventarioComponent, {
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });
    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((res) => {
      console.log('MODAL DE INVENTARIO CERRADO');
      //Deshabilitar las combobox
      document.getElementById('tin').setAttribute('disabled', 'true');
      document.getElementById('inv').setAttribute('disabled', 'true');
      //OPERACIONES EN LA TEMPORAL
      let datos = {
        codigo: res.data.codigo,
        nombre: res.data.nombre,
        cantidad: 0,
        cantidad_ant: 0,
        costo: 0.0,
        costo_ant: 0.0,

        subtotal: 0.0,
        umEscogida: this.datosUm[0].codigo, //<--Um escogida para este producto
        umLista: this.datosUm.slice(), //<--Lista de la combo para cada producto
      };
      let bandera = false; //<--Indica si esta el producto en el temporal
      this.productos.forEach((producto) => {
        //Si el producto ya esta en el arreglo de productos lo modifica
        if (producto.codigo === datos.codigo) {
          producto.nombre = datos.nombre;
          bandera = true; //<--Actualiza control
        }
      });
      //Si el producto no esta en el arreglo de productos lo crea
      if (bandera === false) {
        this.productos.push(datos);
      }
      //OPERACIONES EN LOCAL STORAGE
      //Guarda el producto en localStorage
      let inventario = JSON.parse(localStorage.getItem('inventario'));
      //Si no esta el arreglo de productos lo crea
      if (!inventario.productos) {
        let arregloProductos = [];
        arregloProductos.push(datos);
        inventario.productos = arregloProductos;
        localStorage.setItem('inventario', JSON.stringify(inventario));
      }
      //Si ya esta el arreglo de productos busca en el
      else {
        let control = false; //<--Indica si esta el producto en el inventario
        inventario.productos.forEach((producto) => {
          //Si el producto ya esta en el arreglo de productos lo modifica
          if (datos.codigo === producto.codigo) {
            producto.nombre = datos.nombre;
            control = true; //<--Actualiza control
          }
        });
        //Si el producto no esta en el arreglo de productos lo crea
        if (control === false) {
          inventario.productos.push(datos);
        }
        localStorage.setItem('inventario', JSON.stringify(inventario));
      }
    });
  }

  //Manda los datos de inventario para guardarlos en la DB
  grabarInventario() {
    let inventario = JSON.parse(localStorage.getItem('inventario'));
    console.log(inventario);
    //Armando cabecera
    let cabecera = {
      codInv: inventario.codInv,
      codTin: inventario.codTin,
      comentario: inventario.comentario,
      preg: inventario.preg,
      sucursal: this.sucursal,
      usuario: JSON.parse(localStorage.getItem('usuario')).codigo,
      empresa: this.empresa,
      total: this.total,
    };
    //Armando cuerpo
    let cuerpo = [];
    inventario.productos.forEach((producto) => {
      cuerpo.push({
        codigo: producto.codigo,
        nombre: producto.nombre,
        cantidad: producto.cantidad,
        costo: producto.costo,
        um: producto.umEscogida,
      });
    });
    let infoFinalInventario = { cabecera, cuerpo };
    this.spinner.show();
    this.servicio.guardarInventario(infoFinalInventario).subscribe((data) => {
      if (data.pedido) {
        Swal.fire({
          icon: 'success',
          title: '¡Transacción exitosa!',
          text: 'El registro de inventario es el #' + data.pedido,
        });
        this.productos = [];
        this.comentario = '';
        let inventario = JSON.parse(localStorage.getItem('inventario'));
        inventario.productos = [];
        inventario.comentario = '';
        localStorage.setItem('inventario', JSON.stringify(inventario));
        document.getElementById('tin').removeAttribute('disabled');
        document.getElementById('inv').removeAttribute('disabled');
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Transacción no realizada.',
          text: 'Ha ocurrido un error inesperado.',
        });
      }
      this.spinner.hide();
    });
  }

  //Borra un item especifico
  borrarItem(id) {
    Swal.fire({
      title: '¿Desea eliminar este producto?',
      showDenyButton: true,
      confirmButtonText: `Sí`,
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        let tempVacio = false;
        let localVacio = false;
        //Borro el item del temporal
        for (let i = 0; i < this.productos.length; i++) {
          if (this.productos[i].codigo === parseInt(id)) {
            this.productos.splice(i, 1);
            break;
          }
        }
        if (this.productos.length === 0) {
          tempVacio = true;
        }
        //Borro el item del localStorage
        let inventario = JSON.parse(localStorage.getItem('inventario'));
        for (let i = 0; i < inventario.productos.length; i++) {
          if (inventario.productos[i].codigo === parseInt(id)) {
            inventario.productos.splice(i, 1);
            break;
          }
        }
        if (inventario.productos.length === 0) {
          localVacio = true;
        }
        localStorage.setItem('inventario', JSON.stringify(inventario));
        if (localVacio === true && tempVacio === true) {
          document.getElementById('tin').removeAttribute('disabled');
          document.getElementById('inv').removeAttribute('disabled');
        }
        Swal.fire('¡Producto eliminado!', '', 'success');
      }
    });
  }

  actualizaComentario() {
    console.log('AQUI COMENTARIO');
    let inventario = JSON.parse(localStorage.getItem('inventario'));
    inventario.comentario = this.comentario;
    localStorage.setItem('inventario', JSON.stringify(inventario));
    let inventario2 = JSON.parse(localStorage.getItem('inventario'));
    console.log('AQUI COMENTARIO 2', inventario2);
  }

  cargarSucursales() {
    this.servicio
      .getConfiguraciones('015', this.empresa)
      .subscribe((result) => {
        console.log(result);
        if (result.length > 0) {
          this.sucursales = result;
        }
      });

    this.servicio
      .getConfiguraciones('019', this.empresa)
      .subscribe((result) => {
        console.log(result);
        if (result.length > 0) {
          this.empresas = result;
        }
      });
  }

  limpiarInput(item, campo) {
    item[campo] = '';
  }

  verificarInput(item, campo) {
    console.log('*****  VERIFICAR');
    if (item[campo] === '') {
      console.log('item[campo] === ', item[campo], item[campo + '_ant']);
      item[campo] = item[campo + '_ant'];
    }

    if (item[campo] < 0) {
      console.log('item[campo] < 0', item[campo], item[campo + '_ant']);
      item[campo] = item[campo + '_ant'];
    }

    console.log('asignacion', item[campo + '_ant'], item[campo]);
    item[campo + '_ant'] = item[campo];

    item.subtotal = item.cantidad * item.costo;

    let inventario = JSON.parse(localStorage.getItem('inventario'));
    inventario.productos = this.productos;
    localStorage.setItem('inventario', JSON.stringify(inventario));

    this.calculatotales();
  }

  calculatotales() {
    this.total = 0;
    this.productos.forEach((producto) => {
      this.total = this.total + producto.subtotal;
    });
  }

  limitarDecimales(event, decimales, valor) {
    console.log(event.key);
    if (event.key == '.') {
      if (decimales === 0) return false;
      else return true;
    }

    if (isNaN(parseInt(event.key))) return true;
    let val = (valor + '').split('.');
    if (val.length > 1) {
      let decima = val[1];
      if (decima.length > decimales - 1) return false;
    }
    return true;
  }
}
