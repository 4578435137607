import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalClientesComponent } from '../../modal-clientes/modal-clientes.component';
import { ModalClienteaddComponent } from '../../modal-clienteadd/modal-clienteadd.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-proveedores',
  templateUrl: './proveedores.component.html',
  styleUrls: ['./proveedores.component.css'],
})
export class ProveedoresComponent implements OnInit {
  //Referencia del modal
  modalReferencia: BsModalRef;
  //Datos traidos de la DB
  clientes: any = [];
  //Verifica que hay cliente
  hayCliente: boolean;
  //Variable de evento de pagina
  p: Number = 1;
  //Bandera para spinner
  banderaSpinner: boolean = false;
  //Bandera para saber si son datos filtrados o todos
  banderaClientes: boolean; //<-- true todos y false filtro
  empresa: string = '';
  sucursal: string = '';

  constructor(
    private router: Router,
    private service: RestauranteService,
    private modalServicio: BsModalService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    if (!localStorage.getItem('usuario')) {
      this.router.navigate(['login']);
    }
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
    if (JSON.parse(localStorage.getItem('sucursal')) !== null) {
      this.sucursal = JSON.parse(localStorage.getItem('sucursal'))['codigo'];
    }
    this.getClientes();
    //Verifica bandera para ocultar spinner
    setInterval(() => {
      if (this.banderaSpinner === true) {
        this.spinner.hide();
        this.banderaSpinner = false;
      }
    }, 100);
  }

  //Trae todos los Proveedores de la DB
  getClientes() {
    this.spinner.show();
    let datos = {
      empresa: this.empresa,
    };
    this.service.getProveedores(datos).subscribe((data) => {
      console.log(data);
      if (data.length === 0) {
        this.hayCliente = false;
      } else {
        this.clientes = data;
        this.clientes.forEach((cliente) => {
          cliente.ruc = cliente.ruc.trim();
          cliente.nombre = cliente.nombre.trim();
          cliente.direccion = cliente.direccion.trim();
          cliente.telefono = cliente.telefono.trim();
          cliente.correo = cliente.correo.trim();
          cliente.empresa = cliente.empresa.trim();
        });
        this.hayCliente = true;
      }
      this.banderaSpinner = true;
      this.banderaClientes = true; //<--Los datos actuales son todos
    });
  }

  //Abre el modal
  abrirModalDetallesCliente(proveedor) {
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<--Datos enviados desde componente hacia modal
        proveedor,
        'Proveedor',
      ],
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferencia = this.modalServicio.show(ModalClientesComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });
    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((res) => {
      console.log('MODAL CERRADO, DATA RECIBIDA', res.data);
      if (res.data[0] === 1) {
        //Crea el elemento en el temporal y en la DB
        this.creaItemArray(res.data[1]);
      }
      if (res.data[0] === 2) {
        //Actualiza el elemento en el temporal y en la DB
        this.actualizaItemArray(res.data[1]);
      }
      if (res.data[0] === 3) {
        //Elimina el elemento en el temporal y en la DB
        this.eliminaItemArray(res.data[1]);
      }
    });
  }

  //Abre el modal
  abrirModalAgregarCliente() {
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<--Datos enviados desde componente hacia modal
        'Proveedor',
      ],
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferencia = this.modalServicio.show(ModalClienteaddComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });
    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((res) => {
      console.log('MODAL CERRADO, DATA RECIBIDA', res.data);
      this.creaItemArray(res.data);
    });
  }

  //Busca cliente en array de clientes y lo devuelve
  buscarCliente(id) {
    for (let indice in this.clientes) {
      //<-- Itera todos los indices de cliente
      let cliente = this.clientes[indice]; //<-- Toma el i-esimo cliente
      if (cliente.codigo == id) {
        //Compara el id cliente con el id del cliente a buscar
        return cliente;
      }
    }
  }

  //Crea el elemento en el array
  creaItemArray(item) {
    let datos = {
      ruc: item.ruc,
      nombre: item.nombre,
      direccion: item.direccion,
      telefono: item.telefono,
      correo: item.correo,
      empresa: this.empresa,
    };
    this.spinner.show();
    this.service.creaProveedor(datos).subscribe((data) => {
      this.getClientes();
      this.banderaSpinner = true;
    });
  }

  //Actualiza elemento en el array
  actualizaItemArray(item) {
    const ruc = item.ruc;
    let datos = {
      ruc: item.ruc,
      nombre: item.nombre,
      direccion: item.direccion,
      telefono: item.telefono,
      correo: item.correo,
      empresa: item.empresa,
    };
    console.log(datos);
    this.spinner.show();
    this.service.actualizaProveedor(ruc, datos).subscribe((data) => {
      if (data.data === 'Dato actualizado exitosamente') {
        this.getClientes();
      }
      this.banderaSpinner = true;
    });
  }

  //Borra elemento del array
  eliminaItemArray(item) {
    console.log(item);
    this.spinner.show();
    this.service.eliminaProveedor(item.ruc).subscribe((data) => {
      if (data.data === 'Dato eliminado exitosamente') {
        this.getClientes();
      }
      this.banderaSpinner = true;
    });
  }

  //Buscar cliente en API
  busquedaCliente(event) {
    let texto = event.target.value; //<--Obtenemos el texto
    if (texto != '') {
      //<-- Si no está vacío coge el texto y busca
      if (texto.length > 3) {
        this.spinner.show();
        this.service.buscarProveedor(texto, this.empresa).subscribe((data) => {
          if (data.length === 0) {
            this.hayCliente = false;
          } else {
            this.clientes = data;
            this.clientes.forEach((cliente) => {
              cliente.ruc = cliente.ruc.trim();
              cliente.nombre = cliente.nombre.trim();
              cliente.direccion = cliente.direccion.trim();
              cliente.telefono = cliente.telefono.trim();
              cliente.correo = cliente.correo.trim();
              cliente.empresa = cliente.empresa.trim();
              this.hayCliente = true;
            });
            this.p = 1;
          }
          this.banderaSpinner = true;
          this.banderaClientes = false; //<--Los datos actuales son filtrados
        });
      }
    } else {
      //<--Si esta vacio trae todos los registros
      if (this.banderaClientes === false) {
        this.getClientes();
      }
    }
  }
}
