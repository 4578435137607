<!--Cabecera del modal-->
<div class="row justify-content-center align-items-center my-2">
  <div class="col-9 col-md-10 text-center">
    <h3>
      Nuevo
      <small class="text-muted">Producto</small>
    </h3>
  </div>
  <div
    class="col-2 col-md-1 justify-content-center align-items-center"
    (click)="modalReferencia.hide()"
  >
    <button type="button" class="close pull-right" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>

<!--Datos del Producto-->
<div class="container">
  <div class="row justify-content-center align-items-center">
    <div class="col-11 col-md-3 text-md-right">
      <span>Nombre : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <input
        type="text"
        class="form-control form-control-sm"
        placeholder="Ingrese el nombre"
        [(ngModel)]="producto.nombre"
      />
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Precio : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-4">
          <input
            type="numeric"
            class="form-control form-control-sm text-right"
            placeholder="0,00"
            [(ngModel)]="producto.precio"
          />
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>% Tarifa : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-4">
          <input
            type="numeric"
            class="form-control form-control-sm text-right"
            placeholder="0"
            [(ngModel)]="producto.porc_tarifa"
            appOnlynumber
          />
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Línea : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-6">
          <select
            class="form-select form-select-sm"
            name="linea"
            [(ngModel)]="lineaEscogida"
          >
            <option *ngFor="let linea of lineas" [ngValue]="linea">
              {{ linea.nombre }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Grupo : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-6">
          <select
            class="form-select form-select-sm"
            name="grupo"
            [(ngModel)]="grupoEscogido"
          >
            <option *ngFor="let grupo of grupos" [ngValue]="grupo">
              {{ grupo.nombre }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Unidad medida: </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-6">
          <select class="form-select form-select-sm" [(ngModel)]="medida">
            <option *ngFor="let und of unidades" [value]="und.codigo">
              {{ und.nombre }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Permitir Compra: </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="form-check form-switch" style="padding-left: 2.5em">
            <input
              class="form-check-input"
              type="checkbox"
              id="flexSwitchCheckDefault"
              [(ngModel)]="producto.compra"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Inventario Cocina: </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="form-check form-switch" style="padding-left: 2.5em">
            <input
              class="form-check-input"
              type="checkbox"
              id="flexSwitchCheckDefault"
              [(ngModel)]="producto.inv_cocina"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Mostrar en Aplicaciones : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-8">
          <div class="form-check form-switch" style="padding-left: 2.5em">
            <input
              class="form-check-input"
              type="checkbox"
              id="producto_mostrar_app"
              [(ngModel)]="producto.mostrar_app"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Cantidad Viandas : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-4">
          <input
            type="numeric"
            class="form-control form-control-sm text-right"
            placeholder="0"
            [(ngModel)]="producto.cant_viandas"
            appOnlynumber
          />
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Bloqueado: </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="form-check form-switch" style="padding-left: 2.5em">
            <input
              class="form-check-input"
              type="checkbox"
              id="flexSwitchCheckDefault"
              [(ngModel)]="producto.bloqueado"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Botones Inferiores de las transacciones-->
<div class="container text-right my-1">
  <button
    type="button"
    class="btn btn-outline-success btn-sm btn_est m-2"
    (click)="enviarProducto()"
  >
    <i class="fas fa-check-circle"></i> Guardar
  </button>

  <button
    type="button"
    class="btn btn-outline-danger btn-sm btn_est m-2"
    (click)="cancelar()"
  >
    <i class="fas fa-times-circle"></i> Cancelar
  </button>
</div>

<app-spinner></app-spinner>
