<div class="container-fluid">
  <div class="row mt-3">
    <div class="col-12 text-center">
      <h3>
        <small class="text-muted">Tablero Principal</small>
      </h3>
    </div>
  </div>
</div>

<div class="container-fluid py-3">
  <div class="row">
    <div class="col-12 col-md-3">
      <div class="row p-0 m-0 mb-2">
        <div class="card">
          <div class="card-body p-2">
            <div class="row">
              <div class="col-12 p-0 text-center text-md-left">
                <mat-form-field
                  style="width: 100%"
                  class="example-form-field mr-2"
                  appearance="fill"
                >
                  <mat-label>Rango de fecha</mat-label>
                  <mat-date-range-input
                    [formGroup]="campaignOne"
                    [rangePicker]="campaignOnePicker"
                  >
                    <input
                      matStartDate
                      placeholder="Inicio"
                      formControlName="start"
                    />
                    <input
                      matEndDate
                      placeholder="Fin"
                      formControlName="end"
                      (dateChange)="buscarFecha()"
                    />
                  </mat-date-range-input>
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="campaignOnePicker"
                  ></mat-datepicker-toggle>
                  <mat-date-range-picker
                    #campaignOnePicker
                  ></mat-date-range-picker>
                </mat-form-field>
              </div>
              <div class="col-12 p-0 text-center text-md-left" *ngIf="admin">
                <mat-form-field
                  appearance="fill"
                  class="no-padding"
                  style="width: 100%"
                >
                  <mat-label>Sucursal</mat-label>
                  <mat-select
                    panelClass="my-select-panel-class"
                    [(value)]="sucursal"
                    (selectionChange)="refreshvista()"
                  >
                    <mat-option value="TODOS"> Todos </mat-option>
                    <mat-option
                      *ngFor="let sucursal of sucursales"
                      [value]="sucursal.codigo.trim()"
                      >{{ sucursal.nombre }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-9">
      <div class="row">
        <div
          class="col-12 col-md-4 mb-2 d-flex justify-content-center"
          *ngFor="let caja of cajas; index as e"
        >
          <div style="width: 100%">
            <div class="contenido-card">
              <div
                class="d-flex item"
                style="justify-content: space-between"
                (click)="colapsarItem(e)"
              >
                <p class="card-title m-0 bloquea-select">
                  <icon
                    [class]="caja.icono"
                    [style.color]="caja.colorico"
                  ></icon>
                  {{ caja.nombre }}
                </p>
                <div
                  class="m-0 p-0 d-flex justify-content-center align-items-center"
                >
                  <i
                    class="fas fa-chevron-up text-dark"
                    *ngIf="caja.colapsado"
                  ></i>
                  <i
                    class="fas fa-chevron-down text-danger"
                    *ngIf="!caja.colapsado"
                  ></i>
                </div>
              </div>

              <div
                class="animacion animacion_contenido contenido_notificacion"
                [style.max-height]="caja.colapsado ? '500px' : '0px'"
              >
                <div class="mt-2">
                  <div
                    *ngFor="let item of caja.items; index as i"
                    class="d-flex"
                    style="justify-content: space-between"
                    [style.font-weight]="item.negrita == 'B' ? 'bold' : ''"
                  >
                    <p class="p-0 m-0">{{ item.etiqueta }}</p>
                    <p class="m-0">
                      <i
                        class="mr-1"
                        [class]="item.icono"
                        [style.color]="item.colorico"
                      ></i
                      >{{ item.valor.toFixed(item.longitud) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid py-3">
  <div class="row">
    <div class="col-12 col-md-6 d-flex justify-content-center">
      <div class="card" style="width: 100%">
        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field appearance="fill" class="no-padding">
              <mat-label>Tipo de Gráfico</mat-label>
              <mat-select
                panelClass="my-select-panel-class"
                [(value)]="selector"
                (selectionChange)="obtenerDatosGrafico()"
              >
                <mat-option
                  *ngFor="let selector of selectores"
                  [value]="selector.codigo.trim()"
                  >{{ selector.nombre }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div
          class="card-body"
          [style]="grafico ? { display: 'block' } : { display: 'none' }"
        >
          <canvas #lineChart></canvas>
        </div>
        <div
          class="text-center justify-content-center align-items-center"
          [style]="grafico ? { display: 'none' } : { display: 'flex' }"
        >
          <lottie-player
            style="max-width: 500px"
            src="https://assets2.lottiefiles.com/temp/lf20_TOE9MF.json"
            background="transparent"
            speed="1"
            loop
            autoplay
          ></lottie-player>
        </div>
        <div
          class="text-center"
          [style]="grafico ? { display: 'none' } : { display: 'block' }"
        >
          <h3>No hay resultados de gráficos</h3>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6 d-flex justify-content-center">
      <div class="card" style="width: 100%">
        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field appearance="fill" class="no-padding">
              <mat-label>Gráfico Empresa</mat-label>
              <mat-select
                panelClass="my-select-panel-class"
                [(value)]="selector2"
                (selectionChange)="obtenerDatosGrafico2()"
              >
                <mat-option
                  *ngFor="let selector2 of selectores"
                  [value]="selector2.codigo.trim()"
                  >{{ selector2.nombre }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div
          class="card-body"
          [style]="cgrafico2 ? { display: 'block' } : { display: 'none' }"
        >
          <canvas #lineChart2></canvas>
        </div>
        <div
          class="text-center justify-content-center align-items-center"
          [style]="cgrafico2 ? { display: 'none' } : { display: 'flex' }"
        >
          <lottie-player
            style="max-width: 500px"
            src="https://assets2.lottiefiles.com/temp/lf20_TOE9MF.json"
            background="transparent"
            speed="1"
            loop
            autoplay
          ></lottie-player>
        </div>
        <div
          class="text-center"
          [style]="cgrafico2 ? { display: 'none' } : { display: 'block' }"
        >
          <h3>No hay resultados de gráficos</h3>
        </div>
      </div>
    </div>
  </div>
</div>

<app-spinner></app-spinner>
