import { Component, EventEmitter, HostListener, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicioCocinaService } from 'src/app/services/servicio-cocina/servicio-cocina.service';

@Component({
  selector: 'app-modal-aceptar-cocina',
  templateUrl: './modal-aceptar-cocina.component.html',
  styleUrls: ['./modal-aceptar-cocina.component.css'],
})
export class ModalAceptarCocinaComponent implements OnInit {
  bloquear = false;
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (!this.bloquear) {
      if (event.key == 'Enter') {
        console.log(event.key);
        this.confirmarPedido();
      }
      if (event.key == '-') this.cerrarModal('Cancelar');
    }
  }
  banderaSpinner: boolean = false;
  datos: Object; //<-- Debe tener el mismo nombre de la variable en factura
  pedidosCocina: any;
  espera: any;

  public event: EventEmitter<any> = new EventEmitter(); //<-- Para devolver datos a cliente
  constructor(
    public modalReferencia: BsModalRef,
    private service: ServicioCocinaService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    //console.log(this.datos[0]);
    this.pedidosCocina = this.datos;
    console.log(this.pedidosCocina);
    setInterval(() => {
      if (this.banderaSpinner === true) {
        this.spinner.hide();
        this.banderaSpinner = false;
      }
    }, 100);
  }
  //Cerrar modal y enviar la data a factura

  cerrarModal(respuesta) {
    this.event.emit({ data: respuesta, res: 200 });
    this.modalReferencia.hide(); //<-- Cierra el modal
  }

  confirmarPedido() {
    this.bloquear = true;
    let n = 0;
    this.spinner.show();
    this.espera = setInterval(() => {
      let bandera = true;
      this.pedidosCocina.forEach((element) => {
        console.log(element.verificador);
        bandera = bandera && element.verificador;
      });
      if (bandera) this.todosEnviados();
    }, 100);
    for (let i = 0; i < this.pedidosCocina.length; i++) {
      let element = this.pedidosCocina[i];
      this.pedidosCocina[i].verificador = false;
      let informacion = {
        empresa: element.empresa,
        sucursal: element.sucursal,
        modulo: element.modulo,
        tipo: element.tipo,
        numero: element.numero,
        envio: '2',
      };
      console.log(informacion);
      this.service.confirmarPedido(informacion).subscribe((data) => {
        console.log(data);
        this.pedidosCocina[i].verificador = true;
      });
    }
  }

  todosEnviados() {
    this.spinner.hide();
    clearInterval(this.espera);
    this.cerrarModal('Aceptar');
  }
}
