<!--CABECERA-->
<div class="container-fluid">
  <div class="row my-2 align-items-center">
    <div class="col-12 d-flex align-items-center">
      <button
        type="button"
        class="btn btn-outline-success btn-sm m-1"
        (click)="cargarDatos()"
      >
        <i class="fas fa-sync"></i>
      </button>

      <h4 class="m-0 pl-2">
        Reporte de Gastos
        <small class="text-muted">Diarios</small>
      </h4>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-md-6 py-1">
      <div class="card">
        <div class="card-body p-2">
          <div class="row">
            <div class="col-12 col-md-6" style="align-items: center">
              <mat-form-field class="example-form-field" appearance="fill">
                <mat-label>Rango de fecha</mat-label>
                <mat-date-range-input
                  [formGroup]="campaignOne"
                  [rangePicker]="campaignOnePicker"
                >
                  <input
                    matStartDate
                    placeholder="Inicio"
                    formControlName="start"
                  />
                  <input
                    matEndDate
                    placeholder="Fin"
                    formControlName="end"
                    (dateChange)="buscarFecha()"
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="campaignOnePicker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker
                  #campaignOnePicker
                ></mat-date-range-picker>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-5 offset-md-1" *ngIf="admin">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Sucursal</mat-label>
                <mat-select
                  panelClass="my-select-panel-class"
                  [(value)]="sucursal"
                  (selectionChange)="cargarDatos()"
                >
                  <mat-option value="TODOS"> Todos </mat-option>
                  <mat-option
                    *ngFor="let sucursal of sucursales"
                    [value]="sucursal.codigo.trim()"
                    >{{ sucursal.nombre }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 py-1">
      <div class="card">
        <div class="card-body p-2">
          <div class="row">
            <div class="col-12 col-md-5">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Tipos de Gasto</mat-label>
                <mat-select
                  panelClass="my-select-panel-class"
                  [(value)]="tipo"
                  (selectionChange)="cargarDatos()"
                >
                  <mat-option value="TODOS"> Todos </mat-option>
                  <mat-option *ngFor="let tip of tipos" [value]="tip.codigo">{{
                    tip.nombre
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-5 offset-md-2">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Servicios de Gastos</mat-label>
                <mat-select
                  panelClass="my-select-panel-class"
                  [(value)]="servicio"
                  (selectionChange)="cargarDatos()"
                >
                  <mat-option value="TODOS"> Todos </mat-option>
                  <mat-option
                    *ngFor="let serv of servicios"
                    [value]="serv.codigo"
                    >{{ serv.nombre }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container mt-3">
  <div class="table-responsive" *ngIf="regmov > 0; else sinRegistros">
    <table class="table table-striped table-sm table-hover m-0 p-0">
      <thead class="thead-dark">
        <tr>
          <th
            scope="col"
            [style.background-color]="datosempresa['color']"
            style="width: 55px"
          ></th>
          <th
            scope="col"
            *ngFor="let dato of cabecera"
            [style.text-align]="dato.align"
            [style.background-color]="datosempresa['color']"
          >
            {{ dato.alias }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let mov of movimiento
              | paginate
                : {
                    id: 'pagination1',
                    itemsPerPage: 25,
                    currentPage: p,
                    totalItems: movimiento.count
                  }
          "
        >
          <td>
            <button
              class="btn btn-sm btn-outline-dark"
              (click)="abrirModalNuevo(mov['fecha'],mov['sucursal'],mov['nomsuc'])"
            >
              <i class="fas fa-eye"></i>
            </button>
          </td>
          <td
            *ngFor="let dato of cabecera"
            [style.text-align]="dato.align"
            class="p-2"
          >
            <div>{{ mov[dato.campo.trim()] }}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="container-fluid" *ngIf="regmov > 0">
  <div class="row justify-content-center align-items-center mt-4 mb-3">
    <div class="col-12 text-center">
      <pagination-controls
        id="pagination1"
        (pageChange)="p = $event"
        autoHide="true"
        responsive="true"
        previousLabel="Anterior"
        nextLabel="Siguiente"
      >
      </pagination-controls>
    </div>
  </div>
</div>

<ng-template #sinRegistros>
  <ng-container>
    <div class="container-fluid">
      <div class="row justify-content-center align-items-center">
        <div class="col-12 col-md-6 col-lg-4 mt-5 text-center">
          <h3>
            <small class="text-muted">Sin Resultados en Consulta</small>
          </h3>

          <lottie-player
            src="https://assets2.lottiefiles.com/temp/lf20_TOE9MF.json"
            background="transparent"
            speed="1"
            loop
            autoplay
          ></lottie-player>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #nada></ng-template>

<app-spinner></app-spinner>
