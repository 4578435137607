import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalFacturaComponent } from '../../modal-factura/modal-factura.component';
import { ModalLineasComponent } from '../../modal-lineas/modal-lineas.component';
import { ModalPedidoRecetaComponent } from '../../modal-pedido-receta/modal-pedido-receta.component';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ModalClienteaddComponent } from '../../modal-clienteadd/modal-clienteadd.component';
import { ModalClientesComponent } from '../../modal-clientes/modal-clientes.component';

import { AperturaComponent } from '../../caja/apertura/apertura.component';
import { CierreComponent } from '../../caja/cierre/cierre.component';

@Component({
  selector: 'app-factura',
  templateUrl: './factura.component.html',
  styleUrls: ['./factura.component.css'],
})
export class FacturaComponent implements OnInit {
  //Referencia del modal
  modalReferencia: BsModalRef;
  //Referencia del modal lineas
  modalReferenciaLineas: BsModalRef;
  //Referencia del modal pedido receta
  ModalReferenciaPedidoReceta: BsModalRef;

  //Cliente traido del modal
  cliente: any = {};
  clienteold: any = {};

  //Lineas traidas de la DB
  lineas: any = [];
  //Datos del localStorage
  datosPersistentes: any = [];
  //Productos desde localStorage
  productos: any = [];
  //Productos del consumidor final
  productosConsumidorFinal: any = [];

  clienteCollapse = 'Datos del Cliente';
  efectivo: any = [0, 0, 0, 0, 0]; //<--Efectivo temporal
  efectivoColor: any = [true, false, false, false, false]; //<--Efectivo temporal

  //efectivo:any = []
  cambio: number = 0; //<--efectivo-total
  efectivoEscogido: number = 0; //<--Valor efectivo escogido
  //Bandera para spinner
  banderaSpinner: boolean = false;
  //RUC de consumidor final
  rucConsumidorFinal: string = '9'.repeat(13);
  nomlinact = '';
  comentario: string = '';
  trans_numero: string = '';

  ventana: any;

  sucursales: any;
  sucursal: string = '';

  admin: boolean = false;
  empresa: any;

  datosempresa: any = {
    nombre: '',
    color: '',
    urlLogo: '',
    cal_artesanal: '',
    acceso: '',
  };

  tiposentregas: any;
  tipoentrega: string = '001';

  serviciosentregas: any;
  servicioentrega: string = '';

  distribuidores: any;
  distribuidoresTotal: any;
  distribuidor: string = '';
  busqueda: string = '';

  formaspagos: any;
  formapago: string = '001';
  strformapago: string = '';

  sumatoria: number = 0;
  descuento: number = 0;
  tarifa0: number = 0;
  tarifa12: number = 0;

  subtotal: number = 0; 
  impuesto: number = 0; 
  total: number = 0; 
  numpedpp: string;

  constructor(
    private router: Router,
    private modalServicio: BsModalService,
    private servicio: RestauranteService,
    private modalServicioLineas: BsModalService,
    private modalServicioPedidoReceta: BsModalService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.limpiacampos();

    if (!localStorage.getItem('usuario')) this.router.navigate(['login']);

    if (localStorage.getItem('empresa'))
      this.datosempresa = JSON.parse(localStorage.getItem('empresa'));
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
    if (JSON.parse(localStorage.getItem('sucursal')) !== null) {
      this.sucursal = JSON.parse(localStorage.getItem('sucursal'))['codigo'];
    }

    setInterval(() => {
      if (this.banderaSpinner === true) {
        this.spinner.hide();
        this.banderaSpinner = false;
      }
    }, 100);

    this.traerConsumidorFinal();
    this.cargarSucursales();

    this.cargarTipoEntrega();
    this.cargarServicioEntrega();
    this.cargarFormaPago();
    this.cargarDistribuidores();
    this.cambio = this.calcularCambio();
    this.admin = JSON.parse(localStorage.getItem('usuario')).admin;
    this.sucursal = JSON.parse(localStorage.getItem('sucursal')).codigo;
  }

  //Abre el modal
  abrirModalBuscarCliente() {
    //Este modal recibe el RUC del cliente que usa data binding componente hacia template
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<--Datos enviados desde componente hacia modal
        this.cliente.ruc,
      ],
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferencia = this.modalServicio.show(ModalFacturaComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-md',
    });
    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((res) => {
      this.cliente = res.data;
      this.clienteCollapse = this.cliente.nombre.trim();
      this.cargarDatosStorage();
    });
  }

  save_tipoentrega() {
    if (this.tipoentrega == '001') this.servicioentrega = '';
  }

  save_formapago() {
    if (this.formapago != '001') {
      this.cambio = 0;
      this.efectivoEscogido = 0;
    }

    for (let clave in this.formaspagos) {
      if (this.formaspagos[clave].codigo.trim() == this.formapago.trim())
        this.strformapago = this.formaspagos[clave].nombre.trim();
    }
  }

  //Abre el modal de linea
  mostrarModalLinea(idactual) {
    for (var i = 0; i < this.lineas.length; i++) {
      if (this.lineas[i].codigo.trim() === idactual.trim())
        this.nomlinact = this.lineas[i].nombre.trim();
    }

    const initialState: any = {
      datos: [idactual, this.cliente.ruc, this.nomlinact],
    };

    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferenciaLineas = this.modalServicioLineas.show(
      ModalLineasComponent,
      {
        initialState,
        ignoreBackdropClick: true,
        class: 'modal-xl',
      }
    );
    //Al cerrar el modal recibe la data
    this.modalReferenciaLineas.content.event.subscribe(() => {
      //console.log('MODAL DE LINEAS CERRADO');
      //Vemos si hay productos y los traemos
      this.cargarDatosStorage();
    });
  }

  //Restar cantidad
  restarCantidad(id) {
    let producto = this.buscarProducto(id);
    if (producto.cantidad > 0) {
      producto.cantidad--;
      this.guardarDatosStorage(producto);
    }
  }

  //Aumentar cantidad
  aumentarCantidad(id) {
    let producto = this.buscarProducto(id);
    producto.cantidad++;
    this.actualizarEfectivo();
    this.guardarDatosStorage(producto);
  }

  //Busca producto en array de productos y lo devuelve
  buscarProducto(id) {
    for (let indice in this.productos) {
      //<-- Itera todos los indices de producto
      let producto = this.productos[indice]; //<-- Toma el i-esimo producto
      if (producto.codigo == id) {
        //Compara el id producto con el id del producto a buscar
        return producto;
      }
    }
  }

  //Realizar la transaccion
  async grabar() {
    this.spinner.show();
    //Llenando cabecera
    let cabecera = {
      ruc: this.cliente.ruc.toString().trim(),
      comentario: this.comentario.trim(),
      trans_banco:'',
      trans_numero: this.trans_numero.trim(),
      sucursal: this.sucursal,
      cambio: this.cambio,
      efectivo: this.efectivoEscogido,
      usuario: JSON.parse(localStorage.getItem('usuario')).codigo,
      empresa: JSON.parse(localStorage.getItem('empresa')).codigo,
      tipo: '001',
      mesa: 0,
      tipoentrega: this.tipoentrega,
      servicioentrega: this.servicioentrega,
      distribuidor: this.distribuidor,
      formapago: this.formapago,
      sumatoria: this.sumatoria,
      descuento: this.descuento,
      tarifa0: this.tarifa0,
      tarifa12: this.tarifa12,
      subtotal: this.subtotal,
      impuesto: this.impuesto,
      total: this.total,
      cu_cupon : '',
      cu_porc : 0,
      cu_valor : 0

    };

    //Llenando cuerpo

    let cuerpo = [];
    this.productos.forEach((element) => {
      if (element.cantidad > 0) cuerpo.push(element);
    });

    await this.verificarRecetas();

    //Construyendo solicitud
    let datos = { cabecera, cuerpo };
    //console.log(datos);

    this.servicio.efectuarPedido(datos).subscribe(
      (data) => {
        //console.log('DATA RECIBIDA DE SQL POR TRANSACCION:', data);

        if (!(Object.keys(data).length === 0)) {
          this.alerta(
            '¡Transacción exitosa!',
            'El numero de la factura es el #' + data.numero,
            'success',
            1500
          ).then(() => {
            //console.log('cliente antes de imprimir', this.cliente);
            if (this.formapago === '008')
              this.solicitarPP(data.numero, this.cliente, this.total);

            this.imprimir(data, this.cliente, cuerpo);

            // LIMPIANDO
            this.productos = [];
            this.limpiacampos();

            this.comentario = '';
            this.tipoentrega = '001';
            this.servicioentrega = '';
            this.formapago = '001';
            this.rucConsumidorFinal = '9'.repeat(13);
            localStorage.removeItem(this.cliente.ruc.toString().trim());
            this.traerConsumidorFinal();
            // FIN DE FACTURADO
          });
        } else {
          this.alerta(
            'Transacción no realizada',
            'Ha ocurrido un error inesperado',
            'error'
          );
        }
        this.banderaSpinner = true;
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
  }

  //Imprimir la factura con su comanda
  imprimir(cabeceraimp, clienteimp, cuerpoimp) {
    this.ventana = window.open('', '', 'fullscreen=yes');
    //console.log('datos imp', cabeceraimp, clienteimp, cuerpoimp);
    this.ventana.document.write(
      this.crearHtmlAImprimir(cabeceraimp, clienteimp, cuerpoimp)
    );
    this.ventana.document.close();
    //this.ventana.focus();
    setTimeout(() => {
      this.ventana.print();
      this.ventana.close();
    }, 800);

    //this.ventana.print();
    //this.ventana.close();
    return;
  }

  //Diseño nuevo para FACTURA
  //Aqui se crea el HTML (la factura con su comanda) a imprimir
  crearHtmlAImprimir(cabecerafac, clientefac, cuerpofac) {
    //Factura
    let htmlParte1 = `<html><head>
      <title>FACTURA # ${cabecerafac.numero} </title>
      <style type="text/css">
      .cabecera, .item { margin:0; padding:0 }
      .detalle {width: 80%;}
      .subtotal {width: 20%;}
      .mitad {width: 50%;}
      .tabla {width: 100%;}
      .resumen {text-align: right;}
      .boton {text-align: center;}
      p {font-size:12px}
      .no-margin{margin:0}
      input {background-color: green; color: white;}
      tr.bordeado td { border-bottom: 1px dotted black; }
      @media print{ .no-print, .no-print * { display: none !important; } }
      </style></head><body><p class="boton"><input type="button" value="Imprimir" onclick="recargar()" class="no-print"></p>`;

    let rucempr = `<p class="cabecera"><b>RUC : ${cabecerafac.ruc_suc}</b></p>
    <p class="cabecera"><b>${cabecerafac.per_suc}</b></p>`;

    if (cabecerafac.ruc_suc.trim().length < 13) rucempr = ``;

    let datempr = `
    <p class="cabecera"><b> Dir :${cabecerafac.dir_suc} </b></p>
    <p class="cabecera"><b> Tel :${cabecerafac.tel_suc} </b></p>
    <p class="cabecera"><b> Email :${cabecerafac.ema_suc} </b></p>
    `;

    if (cabecerafac.ruc_suc.trim().length < 13) datempr = ``;

    let ca_empr = `<p class="cabecera"><b>CALIFICACION ARTESANAL : ${this.datosempresa.cal_artesanal}</b></p>`;

    if (this.datosempresa.cal_artesanal.trim().length < 3) ca_empr = ``;

    let htmlPartefe1 = `
    <p class="cabecera"><b>${cabecerafac.nom_emp} -  ${cabecerafac.nom_suc} # ${cabecerafac.numero} </b></p><hr>
    <p class="cabecera"><b>${cabecerafac.fecha_fac}   ${cabecerafac.hora_fac}</b></p>`;

    let htmlRIMPE = `<p class="cabecera"><b>CONTRIBUYENTE REGIMEN GENERAL</b></p>`;

    if (cabecerafac.ruc_suc.trim().length < 13) htmlRIMPE = ``;

    let htmlPartefe2 = `<p class="cabecera"><b>${cabecerafac.numsri}</b></p>
    <p class="cabecera"><b> ${cabecerafac.autorizacion} </b></p>`;

    if (cabecerafac.autorizacion.length < 10) htmlPartefe2 = ``;

    let htmlParte2 = ` <hr> DATOS DEL CLIENTE <hr><p class="cabecera"><b>RUC : </b> ${cabecerafac.ruc} </p>
      <p class="cabecera"><b>Nombre :</b>  ${clientefac.nombre} </p>`;

    let htmlParte3 = `<p class="cabecera"><b>Dirección :</b> ${clientefac.direccion} </p>
       <p class="cabecera"><b>Teléfono :</b> ${clientefac.telefono} </p>
       <p class="cabecera"><b>Correo : </b> ${clientefac.correo} </p>
       <hr> `;

    let htmlParte4 =
      '<table class="tabla"><tr><th class="detalle">Detalles</th><th class="subtotal">Total</th></tr>';

    cuerpofac.forEach((itemfact) => {
      if (itemfact.cantidad > 0) {
        htmlParte4 =
          htmlParte4 +
          `<tr "><td class="detalle">
          <p class="item">${itemfact.cantidad} X ${
            itemfact.nombre
          } $ ${itemfact.precio.toFixed(2)}</p>
          </td>

          <td class="subtotal resumen">
          <p>
          ${(
            itemfact.cantidad *
            itemfact.precio *
            (1 + itemfact.porc_tarifa / 100)
          ).toFixed(2)}
          </p</td></tr>`;
      }
    });
    htmlParte4 = htmlParte4 + '</table>';

    let htmlParte5 = `<table class="tabla">
      <tr>
        <td class="detalle resumen">
          <p class="item"><b>Total : $</b></p>
        </td>
        <td class="subtotal resumen">
          <p class="item"> ${cabecerafac.total.toFixed(2)}</p>
        </td>
      </tr>
      </table>`;

    let htmlParte6 = `<table class="tabla">
      <tr>
        <td class="detalle resumen">
          <p class="item"><b>Efectivo : $</b></p>
          <p class="item"><b>Cambio : $</b></p>
        </td>
        <td class="subtotal resumen">
          <p class="item"> ${cabecerafac.efectivo.toFixed(2)}</p>
          <p class="item"> ${cabecerafac.cambio.toFixed(2)}</p>
        </td>
      </tr>
      </table>`;

    if (this.formapago != '001') htmlParte6 = '';

    let htmlParte7 = `<table class="tabla">
    <tr>
      <td class="detalle">
        <p class="item"> ${this.comentario}</p>
      </td>
    </tr>
    </table>`;

    let htmlParte8 =
      '<script>function recargar(){window.print()}</script></body></html>';

    let htmlParte9 = `<table class="tabla">
      <tr>
        <td class="detalle resumen">
          <p class="item"><b>Forma de Pago : </b></p>
          <p class="item"><b>Tipo Entrega : </b></p>
          <p class="item"><b>Servicio Entrega : </b></p>
          <p class="item"><b>Distribuidor : </b></p>
        </td>
        <td class="subtotal resumen">
          <p class="item"> ${cabecerafac.nomfp}</p>
          <p class="item"> ${cabecerafac.nomtipo}</p>
          <p class="item"> ${cabecerafac.nomserv}</p>
          <p class="item"> ${cabecerafac.nomdistri}</p>
        </td>
      </tr>
      </table>`;

    return (
      rucempr +
      datempr +
      htmlParte1 +
      htmlPartefe1 +
      ca_empr +
      htmlRIMPE +
      htmlPartefe2 +
      htmlParte2 +
      htmlParte3 +
      htmlParte4 +
      htmlParte5 +
      htmlParte6 +
      htmlParte7 +
      htmlParte8 +
      htmlParte9
    );
  }

  //Borrar producto
  borrarItem(codigo) {
    let producto = this.buscarProducto(codigo);
    if (producto.cantidad > 0) {
      producto.cantidad = 0;
      //Actualizar efectivos
      this.actualizarEfectivo();
      //Guardando en localStorage
      this.guardarDatosStorage(producto);
    }
  }

  pintarEfectivo(numero) {
    this.efectivoColor = [false, false, false, false, false];
    this.efectivoColor[numero - 1] = true;
    switch (numero) {
      case 1:
        this.efectivoEscogido = this.efectivo[0];
        break;
      case 2:
        this.efectivoEscogido = this.efectivo[1];
        break;
      case 3:
        this.efectivoEscogido = this.efectivo[2];
        break;
      case 4:
        this.efectivoEscogido = this.efectivo[3];
        break;
      case 5:
        this.efectivoEscogido = this.efectivo[4];
        break;
    }
    this.actualizarEfectivo();
  }

  actualizarEfectivo() {
    this.efectivo = [0, 0, 0, 0, 0];
    // 1) Primer boton (total)
    this.efectivo[0] = this.total;
    let totalText = this.total.toFixed(2).toString();
    let entero = totalText.split('.')[0];
    let decimal = totalText.split('.')[1];
    // 2) Segundo boton (redondeo por exceso)
    if (parseInt(decimal) > 0) {
      this.efectivo[1] = parseInt(entero) + 1;
    } else {
      this.efectivo[1] = 0;
    }
    // 3) Tercer boton (redondeo decimales)
    let decimalTercerBoton = decimal;
    let enteroTercerBoton = entero;
    if (parseInt(decimalTercerBoton) > 0 && parseInt(decimalTercerBoton) < 25) {
      decimalTercerBoton = '25';
    } else {
      if (
        parseInt(decimalTercerBoton) > 25 &&
        parseInt(decimalTercerBoton) < 50
      ) {
        decimalTercerBoton = '50';
      } else {
        if (
          parseInt(decimalTercerBoton) > 50 &&
          parseInt(decimalTercerBoton) < 75
        ) {
          decimalTercerBoton = '75';
        } else {
          if (parseInt(decimalTercerBoton) > 75) {
            decimalTercerBoton = '00';
            enteroTercerBoton = (parseInt(enteroTercerBoton) + 1).toString();
          }
        }
      }
    }
    this.efectivo[2] = parseFloat(enteroTercerBoton + '.' + decimalTercerBoton);
    // 4) y 5) Cuarto y quinto boton (primer y segundo valor multiplo)
    let enteroCuartoBoton = entero;
    let enteroQuintoBoton = entero;
    if (enteroCuartoBoton.length === 1) {
      enteroCuartoBoton = '10';
      enteroQuintoBoton = '20';
    } else {
      if (enteroCuartoBoton.length === 2) {
        enteroCuartoBoton = (
          parseInt(enteroCuartoBoton[0] + '0') + 10
        ).toString();
        enteroQuintoBoton = (
          parseInt(enteroQuintoBoton[0] + '0') + 20
        ).toString();
      }
      if (enteroCuartoBoton.length === 3) {
        enteroCuartoBoton = (
          parseInt(enteroCuartoBoton[0] + '00') + 10
        ).toString();
        enteroQuintoBoton = (
          parseInt(enteroQuintoBoton[0] + '00') + 20
        ).toString();
      }
      if (enteroCuartoBoton.length === 4) {
        enteroCuartoBoton = (
          parseInt(enteroCuartoBoton[0] + '000') + 10
        ).toString();
        enteroQuintoBoton = (
          parseInt(enteroQuintoBoton[0] + '000') + 20
        ).toString();
      }
    }
    this.efectivo[3] = parseFloat(enteroCuartoBoton + '.00');
    this.efectivo[4] = parseFloat(enteroQuintoBoton + '.00');
    //Borrando duplicados en arreglo efectivo
    this.efectivo = [...new Set(this.efectivo)];
    //Calculamos el cambio
    this.cambio = this.calcularCambio();
  }

  calcularCambio() {
    let cambiocal: number = 0;
    cambiocal = this.efectivoEscogido - this.total;
    if (cambiocal < 0) cambiocal = 0;
    return cambiocal;
  }

  //---METODOS DE MANEJO DEL LOCALSTORAGE (MANEJAR CON PRUDENCIA)---

  //Guardar en el localStorage
  guardarDatosStorage(producto) {
    this.datosPersistentes = JSON.parse(
      localStorage.getItem(this.cliente.ruc.toString().trim())
    );
    //console.log(this.datosPersistentes);
    this.datosPersistentes.forEach((item) => {
      if (item.linea === producto.linea) {
        let itemsLinea = item.productos;
        itemsLinea.forEach((itemLinea) => {
          if (itemLinea.codigo === producto.codigo) {
            itemLinea.cantidad = producto.cantidad;
          }
        });
      }
    });

    localStorage.setItem(
      this.cliente.ruc.toString().trim(),
      JSON.stringify(this.datosPersistentes)
    );
    //Actualiza la variable suma temporal
    this.calculaTotales();
    this.actualizarEfectivo();
    this.pintarEfectivo(1);
    return;
  }

  //Traemos los datos del localStorage
  cargarDatosStorage() {
    this.productos = []; //<-- Traemos los datos para el cliente actual
    this.limpiacampos();

    if (localStorage.getItem(this.cliente.ruc.toString().trim())) {
      this.pasarDatosClienteaotroCliente();
      //Ahora carga los datos persistentes
      this.datosPersistentes = JSON.parse(
        localStorage.getItem(this.cliente.ruc.toString().trim())
      );
      if (this.datosPersistentes != null) {
        this.datosPersistentes.forEach((dato) => {
          dato.productos.forEach((item) => {
            if (item.cantidad > 0) {
              let comentario = item.cantidad + ' Completo';
              this.productos.push({
                codigo: item.codigo,
                nombre: item.nombre,
                precio: item.precio,
                cantidad: item.cantidad,
                linea: dato.linea,
                receta: [],
                comentario,
                porc_tarifa: item.porc_tarifa,
                descuento: item.descuento,
                medida: item.medida,
              });
            }
          });
        });
      } else {
        this.datosPersistentes = [];
        localStorage.setItem(
          this.cliente.ruc.toString().trim(),
          JSON.stringify(this.datosPersistentes)
        );
      }

      this.pintarEfectivo(1);
    } else {
      this.datosPersistentes = [];
      localStorage.setItem(
        this.cliente.ruc.toString().trim(),
        JSON.stringify(this.datosPersistentes)
      );
      this.pasarDatosClienteaotroCliente();
    }
    this.calculaTotales();
    this.actualizarEfectivo();
  }

  limpiacampos() {
    this.efectivoEscogido = 0;
    this.cambio = 0;
    this.sumatoria = 0;
    this.tarifa0 = 0;
    this.tarifa12 = 0;
    this.descuento = 0;
    this.subtotal = 0;
    this.impuesto = 0;
    this.total = 0;
  }

  pasarDatosClienteaotroCliente() {
    if (!(Object.keys(this.clienteold).length === 0)) {
      if (
        this.clienteold.ruc.toString().trim() !=
        this.clienteold.rucanterior.toString().trim()
      ) {
        //<--Solo tiene sentido si es otro cliente
        //Primero actualiza los datos persistentes
        this.datosPersistentes = JSON.parse(
          localStorage.getItem(this.cliente.ruc.toString().trim())
        );
        if (this.datosPersistentes != null) {
          if (localStorage.getItem(this.clienteold.rucanterior.trim())) {
            let datosConsumidorFinal = JSON.parse(
              localStorage.getItem(this.clienteold.rucanterior.trim())
            );

            for (let i = 0; i < datosConsumidorFinal.length; i++) {
              let existeLinea = -1; //<--Indica si la linea existe en datos (indice en consumidor final)
              let linea = datosConsumidorFinal[i].linea; //<--Itera cada una de las lineas de consumidor final
              for (let j = 0; j < this.datosPersistentes.length; j++) {
                if (linea === this.datosPersistentes[j].linea) {
                  existeLinea = j;
                  break;
                }
              }

              //La linea existe
              if (existeLinea > -1) {
                let productosConsumidorFinal =
                  datosConsumidorFinal[i].productos; //<--Productos consumidor final
                let productosCliente =
                  this.datosPersistentes[existeLinea].productos; //<--Productos nuevo cliente
                for (let k = 0; k < productosConsumidorFinal.length; k++) {
                  let existeProducto = -1; //<--Indica si el producto existe en datos (indice en consumidor final)
                  for (let m = 0; m < productosCliente.length; m++) {
                    if (
                      productosConsumidorFinal[k].codigo ===
                      productosCliente[m].codigo
                    ) {
                      //Buscamos si el producto existe
                      existeProducto = m;
                      break;
                    }
                  }
                  //El producto existe
                  if (existeProducto > -1) {
                    productosCliente[existeProducto].cantidad =
                      productosCliente[existeProducto].cantidad +
                      productosConsumidorFinal[k].cantidad;
                  }
                  //El producto no existe
                  else {
                    productosCliente.push(productosConsumidorFinal[k]);
                  }
                }
              }
              //La linea no existe
              else {
                this.datosPersistentes.push(datosConsumidorFinal[i]);
              }
            }
          }
        } else {
          this.datosPersistentes = [];
        }
        localStorage.setItem(
          this.cliente.ruc.toString().trim(),
          JSON.stringify(this.datosPersistentes)
        );
        localStorage.removeItem(this.clienteold.rucanterior.trim());
      }
    }
  }

  //---FNAL ZONA LOCALSTORAGE---

  //Abrir el Collapsable del cliente
  abrirCollapseCliente() {
    if (
      document.getElementById('datos_cliente_icon').className ==
      'fas fa-chevron-up'
    ) {
      document.getElementById('datos_cliente_contenido').style.display = 'none';

      document.getElementById('datos_cliente_icon').className =
        'fas fa-chevron-down';
    } else {
      document.getElementById('datos_cliente_contenido').style.display =
        'block';

      document.getElementById('datos_cliente_icon').className =
        'fas fa-chevron-up';
    }
  }

  //Abrir el Collapsable del cliente
  abrirCollapseTotales() {
    if (
      document.getElementById('datos_totales_icon').className ==
      'fas fa-chevron-up'
    ) {
      document.getElementById('datos_totales_contenido').style.display = 'none';

      document.getElementById('datos_totales_icon').className =
        'fas fa-chevron-down';
    } else {
      document.getElementById('datos_totales_contenido').style.display =
        'block';

      document.getElementById('datos_totales_icon').className =
        'fas fa-chevron-up';
    }
  }

  consultarReceta(i) {
    const initialState: any = {
      datos: this.productos[i],
    };

    this.ModalReferenciaPedidoReceta = this.modalServicioPedidoReceta.show(
      ModalPedidoRecetaComponent,
      {
        initialState,
        ignoreBackdropClick: true,
        class: 'modal-dialog-centered modal-xl',
      }
    );
    //Al cerrar el modal recibe la data
    this.ModalReferenciaPedidoReceta.content.event.subscribe((resultado) => {
      //console.log('MODAL DE PEDIDOS RECETAS CERRADO');
      //console.log(resultado.data);
      this.productos[i] = resultado.data; //<--Actualiza producto
    });
  }

  traerConsumidorFinal() {
    this.spinner.show();
    this.servicio
      .buscarCliente(this.rucConsumidorFinal, this.empresa)
      .subscribe(
        (data) => {
          if (data.length > 0) {
            this.cliente = data[0];
            this.cliente.nombre = this.cliente.nombre.trim();
            this.cliente.direccion = this.cliente.direccion.trim();
            this.cliente.telefono = this.cliente.telefono.trim();
            this.cliente.correo = this.cliente.correo.trim();
          }
          this.banderaSpinner = true;
          this.clienteCollapse = this.cliente.nombre.trim();

          let datos = {
            tipo: '003',
            empresa: JSON.parse(localStorage.getItem('empresa')).codigo,
          };

          this.servicio.getLineas(datos).subscribe((data) => {
            //console.log(data);
            this.lineas = data;
            this.lineas.forEach((linea) => {
              linea.codigo = linea.codigo.trim();
              linea.nombre = linea.nombre.trim();
            });
            this.cargarDatosStorage();
          });
        },
        (err) => {
          this.banderaSpinner = true;
          this.alerta(
            'Error de conexión',
            'Se perdió la conexión con el servidor',
            'error'
          );
          console.log(err);
        }
      );
  }

  //Si consumidor final esta en el input lo borra y guarda sus datos en LS
  borrarConsumidorFinal() {
    if (this.cliente.ruc === this.rucConsumidorFinal) {
      this.productosConsumidorFinal = JSON.parse(
        JSON.stringify(this.productos)
      );
      this.cliente = {};
    }
  }

  calcularValores(id, event) {
    this.limpiacampos();

    let producto = this.buscarProducto(id);
    producto.cantidad = event.target.value;

    this.actualizarEfectivo();

    this.guardarDatosStorage(producto);
  }

  calculaTotales() {
    this.limpiacampos();

    this.productos.forEach((producto) => {
      let val_desc = (producto.precio * producto.descuento) / 100;

      this.sumatoria += producto.cantidad * producto.precio;
      this.descuento += producto.cantidad * val_desc;

      let tar = producto.cantidad * (producto.precio - val_desc);
      this.subtotal += tar;

      if (producto.porc_tarifa == '0') {
        this.tarifa0 += tar;
      } else {
        this.tarifa12 += tar;
      }
    });

    this.impuesto = this.tarifa12 * 0.12;
    this.total = this.subtotal + this.impuesto;
    this.calcularCambio();
  }

  verificarRUC() {
    this.clienteold = this.cliente;
    this.spinner.show();
    this.servicio
      .buscarCliente(this.cliente.ruc, this.empresa)
      .subscribe((data) => {
        this.spinner.hide();
        //Si ya existe
        if (data.length > 0) this.abrirModalBuscarCliente();
        else this.abrirModalAgregarCliente(this.cliente.ruc);
      });
  }

  abrirModalAgregarCliente(ruc = '') {
    const initialState: any = {
      datos: ['Cliente', ruc],
    };
    this.modalReferencia = this.modalServicio.show(ModalClienteaddComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });

    this.modalReferencia.content.event.subscribe((res) => {
      this.creaItemArray(res.data);
      this.cliente = res.data;
      this.clienteCollapse = this.cliente.nombre.trim();
    });
  }

  // modal de correccion

  //Abre el modal
  abrirModalDetallesCliente(cliente) {
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<--Datos enviados desde componente hacia modal
        cliente,
        'Cliente',
      ],
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferencia = this.modalServicio.show(ModalClientesComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });
    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((res) => {
      console.log('MODAL CERRADO, DATA RECIBIDA', res.data);
      if (res.data[0] === 1) {
        //Crea el elemento en el temporal y en la DB
        this.creaItemArray(res.data[1]);
        this.cliente = res.data[1];
        this.clienteCollapse = this.cliente.nombre.trim();
      }

      if (res.data[0] === 2) {
        //Actualiza el elemento en el temporal y en la DB
        this.actualizaItemArray(res.data[1]);
        this.cliente = res.data[1];
        this.clienteCollapse = this.cliente.nombre.trim();
      }
      if (res.data[0] === 3) {
        //Elimina el elemento en el temporal y en la DB
        this.eliminaItemArray(res.data[1], this.empresa);
        this.traerConsumidorFinal();
      }
    });
  }

  //Actualiza elemento en el array
  actualizaItemArray(item) {
    const ruc = item.ruc;
    let datos = {
      ruc: item.ruc,
      nombre: item.nombre,
      direccion: item.direccion,
      telefono: item.telefono,
      correo: item.correo,
      empresa: this.empresa,
      sucursal: this.sucursal,
    };
    let consulta = {
      texto: '',
      empresa: this.empresa,
    };
    this.spinner.show();
    this.servicio.actualizaCliente(ruc, datos).subscribe((data) => {
      if (data.data === 'Dato actualizado exitosamente') {
        //this.getClientes();
      }
      this.banderaSpinner = true;
    });
  }

  //Borra elemento del array
  eliminaItemArray(item, empresa) {
    this.spinner.show();
    this.servicio.eliminaCliente(item.ruc, empresa).subscribe((data) => {
      if (data.data === 'Dato eliminado exitosamente') {
        //this.getClientes();
      }
      this.banderaSpinner = true;
    });
  }

  //Crea el elemento en el array
  creaItemArray(item) {
    let datos = {
      ruc: item.ruc,
      nombre: item.nombre,
      direccion: item.direccion,
      telefono: item.telefono,
      correo: item.correo,
      empresa: this.empresa,
      sucursal: this.sucursal,
    };
    this.spinner.show();
    this.servicio.creaCliente(datos).subscribe(
      (data) => {
        console.log(data);

        this.banderaSpinner = true;
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
  }

  cargarSucursales() {
    this.servicio.getConfiguraciones('015', this.empresa).subscribe(
      (result) => {
        //console.log(result);
        if (result.length > 0) {
          this.sucursales = result;
        }
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
  }

  cargarTipoEntrega() {
    this.servicio.getConfiguraciones('029', this.empresa).subscribe(
      (result) => {
        //console.log(result);
        if (result.length > 0) {
          this.tiposentregas = result;
        }
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
  }

  cargarServicioEntrega() {
    this.servicio.getConfiguraciones('027', this.empresa).subscribe(
      (result) => {
        //console.log(result);
        if (result.length > 0) {
          this.serviciosentregas = result;
        }
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
  }

  cargarFormaPago() {
    this.servicio.getConfiguraciones('028', this.empresa).subscribe(
      (result) => {
        //console.log(result);
        if (result.length > 0) {
          this.formaspagos = result;
          for (let clave in this.formaspagos) {
            if (this.formaspagos[clave].codigo.trim() == this.formapago.trim())
              this.strformapago = this.formaspagos[clave].nombre.trim();
          }
        }
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
  }

  cargarRecetas(producto, resta = 0) {
    return new Promise((resolve, reject) => {
      this.servicio.buscarReceta(producto.codigo, '1').subscribe(
        (result) => {
          //console.log(result);
          for (let e = 0; e < result['length']; e++) {
            result[e]['solicitado'] = true;
          }

          for (let i = 0; i < producto.cantidad - resta; i++) {
            let newResult = [];
            result.forEach((val) => newResult.push(Object.assign({}, val)));
            producto.receta.push(newResult);
          }
          //console.log('intermedio');
          //console.log(producto);
          resolve(producto);
        },
        (err) => {
          this.banderaSpinner = true;
          this.alerta(
            'Error de conexión',
            'Se perdió la conexión con el servidor',
            'error'
          );
          console.log(err);
          reject(err);
        }
      );
    });
  }

  async verificarRecetas() {
    let promesas = [];
    await this.productos.forEach((element) => {
      let longitud = element.receta.length;

      //console.log('2');
      if (element.receta.length > 0) {
        if (element.receta.length > element.cantidad) {
          for (let i = 0; i < longitud - element.cantidad; i++) {
            //console.log(i);
            element.receta.pop();
          }
        } else if (element.receta.length < element.cantidad)
          promesas.push(this.cargarRecetas(element, longitud));
      } else {
        promesas.push(this.cargarRecetas(element));
      }
    });
    //console.log('3');
    await Promise.all(promesas).then((resultados) => {
      //console.log(resultados);

      return;
    });
  }
  aperturacaja() {
    this.servicio.getConfiguraciones('028', this.empresa).subscribe(
      (result) => {
        console.log(result);
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );

    this.alerta('Apertura de CAJA', '¡Buena suerte!', 'success');

  }

    //Abre el modal
    Modalaperturacaja() {
      const initialState: any = { datos: [,],};
      //Asigna la referencia usando el servicio propio del modal
      //Se envia el componente modal, con su estado inicial y con background click inactivo
      this.modalReferencia = this.modalServicio.show(AperturaComponent, {
        initialState,
        ignoreBackdropClick: true,
        class: 'modal-xl',
      });
    }

  cerrarcaja() {
    this.alerta('Cierre de CAJA', '¡Gracias!', 'error');
  }

    //Abre el modal
      Modalcerrarcaja() {
        const initialState: any = { datos: [,],};
        //Asigna la referencia usando el servicio propio del modal
        //Se envia el componente modal, con su estado inicial y con background click inactivo
        this.modalReferencia = this.modalServicio.show(CierreComponent, {
          initialState,
          ignoreBackdropClick: true,
          class: 'modal-xl',
        });
      }

  cargarDistribuidores() {
    let consulta = {
      texto: ' ',
      empresa: this.empresa,
    };

    this.servicio.getDistribuidores(consulta).subscribe(
      (result) => {
        //console.log(result);
        if (result.length > 0) {
          this.distribuidores = result;
          this.distribuidoresTotal = result;
        }
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
  }

  onKey() {
    let mini: any = [];
    this.distribuidoresTotal.forEach((usuario: any) => {
      //console.log(usuario);

      if (usuario.nombre.toLowerCase().includes(this.busqueda.toLowerCase()))
        mini.push(usuario);
    });
    this.distribuidor = mini;
  }

  solicitarPP(numfac, clitmp, ntotal) {
    console.log('PAGO PAYPHONE');

    if (this.cliente.ruc.slice(0, 10) === '9'.repeat(10)) {
      this.alerta(
        'Cliente No Encontrado',
        'cliente no existe en PayPhone',
        'warning'
      );
    } else {
      let campp = {
        empresa: this.empresa,
        sucursal: this.sucursal,
        modulo: '002',
        tipo: '001',

        numero: numfac,

        valor: ntotal,
        telefono: clitmp.telefono,
        email: clitmp.correo,
      };
      console.log('*** PP ', campp);

      this.servicio.pp_cobro_link(campp).subscribe(
        (result) => {
          if (result.length > 0) {
            let climovil =
              '593' + clitmp.telefono.replace(/\s/g, '').substring(1, 10);

            if (climovil.length !== 12) {
              this.alerta(
                'Error en Teléfono',
                'Número de telefono incorrecto ',
                'warning'
              );
            } else {
              let men_wh = {
                mensaje:
                  '👋 Hola, has click aqui : ' +
                  result.trim() +
                  '\npara seguir con el pago *$' +
                  ntotal.toFixed(2) +
                  '*\n\nRecuerda que puedes pagar con Tarjeta de Credito / Debito\n\n' +
                  '*En NovaExpress estamos para servirte.*',
                movil: climovil,
              };
              this.servicio.sms_wh(men_wh).subscribe((result) => {
                console.log(result);
              });
            }

            /* EN EL CASO DE QUE LOS CONSUMIDOR FINAL TAMBIEN COBREN 
          if (this.cliente.ruc.slice(0, 10) === '9'.repeat(10)) {
            Swal.fire({
              icon: 'success',
              title: 'Solicitud Enviada',
              html: `<b>PayPhone ID : </b>  <a href="${result.trim()}" target="_blank" >${result.trim()}</a>
                `,
              allowOutsideClick: false,
            });
          } else {
*/
            Swal.fire({
              icon: 'success',
              title: 'Solicitud Enviada',
              html: `<b>Cliente :</b> ${clitmp.nombre}<br>
              <b>Telefono :</b> ${clitmp.telefono}<br>
              <b>Email :</b> ${clitmp.correo}<br>
              <br>
              <br>
              <b>PayPhone ID : </b>  <a href="${result.trim()}" target="_blank" >${result.trim()}</a>
              `,
              allowOutsideClick: false,
              footer: 'PayPhone ID : ' + result.trim(),
            });
            /*} CONSUMIDOR FINAL  */
          }
        },
        (err) => {
          this.banderaSpinner = true;
          this.alerta(
            'Error de conexión',
            'Se perdió la conexión con el servidor',
            'error'
          );
          console.log(err);
        }
      );
    }
  }

  alerta(titulo, texto, icono, tiempo = undefined) {
    return Swal.fire({
      icon: icono,
      title: titulo,
      text: texto,
      timer: tiempo,
      allowOutsideClick: false,
      showConfirmButton: false,
    });
  }
}
