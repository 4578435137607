<div class="modal-header">
  <h4 class="modal-title" id="titulo">Receta {{ datos[0] }}</h4>
</div>

<div class="container">
  <div class="card my-3" *ngFor="let receta of recetas; index as i">
    <div class="card-body">
      <div *ngFor="let ingrediente of receta; index as e">
        <div class="form-check">
          <input
            id="{{ i }}{{ e }}"
            class="form-check-input"
            type="checkbox"
            [(ngModel)]="ingrediente.solicitado"
          />
          <label for="{{ i }}{{ e }}" class="form-check-label">
            {{ ingrediente.nombre }}
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center mb-3">
    <button class="btn btn-success" (click)="cerrarModal()">Aceptar</button>
  </div>
</div>
