<div class="container-fluid">
  <div class="row justify-content-center align-items-center mb-3">
    <div
      class="col-11 col-md-8 col-xl-4 text-center text-md-left text-xl-center"
    >
      <h3>
        Consultas de
        <small class="text-muted">Activos Fijos</small>
      </h3>
    </div>
  </div>
</div>

<div class="container">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-4">
          <h6>Tipo</h6>
          <select
            class="form-select"
            aria-label="Default select example"
            [(ngModel)]="tipoActivo"
            (change)="cargarFiltros()"
          >
            <option value="todos" selected>Todos</option>
            <option *ngFor="let tipo of tiposActivo" [value]="tipo.codigo">
              {{ tipo.nombre }}
            </option>
          </select>
        </div>

        <div class="col-4">
          <h6>Año</h6>
          <select
            class="form-select"
            aria-label="Default select example"
            [(ngModel)]="yearSelected"
            (change)="cargarFiltros()"
          >
            <option value="todos" selected>Todos</option>
            <option *ngFor="let year of years" [value]="year">
              {{ year }}
            </option>
          </select>
        </div>

        <div class="col-4">
          <h6>Habilitado</h6>
          <select
            class="form-select"
            aria-label="Default select example"
            [(ngModel)]="habilitado"
            (change)="cargarFiltros()"
          >
            <option value="todos" selected>Todos</option>
            <option value="1">Habilitado</option>
            <option value="0">No habilitado</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mt-3 container-fluid">
  <div
    class="table table-responsive table-hover table-responsive-sm table-responsive-md table-responsive-xl"
    *ngIf="activos.length > 0; else sinRegistros"
  >
    <table class="table table-sm">
      <thead>
        <tr>
          <th
            scope="col"
            style="width: 50px !important; text-align: center"
          ></th>

          <th scope="col" style="min-width: 150px">Tipo Activo</th>
          <th scope="col" style="min-width: 250px">Nombre</th>
          <th scope="col" style="min-width: 250px">Serie</th>
          <th scope="col" style="min-width: 250px">Año compra</th>
          <th class="text-center" scope="col" style="min-width: 250px">
            Habilitado
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          *ngFor="
            let activo of activos
              | paginate
                : {
                    itemsPerPage: 25,
                    currentPage: p,
                    totalItems: activos.count
                  }
          "
        >
          <td>
            <button
              type="button"
              class="btn btn-outline-dark btn-sm"
              (click)="modalActivo(activo)"
            >
              <i class="far fa-eye"></i>
            </button>
          </td>

          <td>{{ activo.nomtipoactivo }}</td>
          <td>{{ activo.nombre }}</td>
          <td>{{ activo.serie }}</td>
          <td>{{ activo.anio_compra }}</td>
          <td *ngIf="!activo.habilitado" class="text-center">
            <i class="far fa-times-circle fa-lg" style="color: #4c6ef5"></i>
          </td>
          <td *ngIf="activo.habilitado" class="text-center">
            <i class="far fa-check-circle fa-lg" style="color: #82c91e"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<ng-template #sinRegistros>
  <ng-container>
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-12 col-md-6 col-lg-4 mt-5" style="text-align: center">
          <h3>
            <small class="text-muted">No Hay Resultados</small>
          </h3>

          <lottie-player
            src="https://assets2.lottiefiles.com/temp/lf20_TOE9MF.json"
            background="transparent"
            speed="1"
            loop
            autoplay
          ></lottie-player>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<app-spinner></app-spinner>
