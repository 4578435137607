<div class="row justify-content-center align-items-center my-2">
  <div class="col-9 col-md-10 text-center">
    <h3>
      Receta :
      <small class="text-muted">{{ producto.nombre }}</small>
    </h3>
  </div>
  <div
    class="col-2 col-md-1 justify-content-center align-items-center"
    (click)="modalReferencia.hide()"
  >
    <button type="button" class="close pull-right" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>

<div class="container my-1">
  <button
    type="button"
    class="btn btn-outline-success btn-sm btn_est m-2"
    id="agrega"
    (click)="agregar()"
  >
    <i class="far fa-plus-square"></i> Agregar
  </button>

  <button
    type="button"
    class="btn btn-outline-danger btn-sm btn_est m-2"
    (click)="borrarTodaReceta()"
    *ngIf="items.length > 0"
  >
    <i class="fas fa-times-circle"></i> Eliminar
  </button>
</div>

<div class="container-fluid">
  <div
    class="table table-responsive table-responsive-sm table-responsive-md table-responsive-xl"
    *ngIf="items.length > 0; else noItems"
  >
    <table class="table table-sm">
      <thead>
        <tr>
          <th scope="col">Opcional</th>
          <th scope="col">Ingredientes</th>

          <th scope="col" class="text-right" style="width: 200px">Limpio</th>
          <th scope="col" class="text-right" style="width: 200px">Merma</th>
          <th scope="col" class="text-right" style="width: 200px">Costo</th>
          <th scope="col" style="width: 100px"></th>
        </tr>
      </thead>

      <tbody *ngFor="let item of items; index as i">
        <tr>
          <td class="text-center">
            <mat-checkbox class="example-margin" [(ngModel)]="item.opcional">
            </mat-checkbox>
          </td>
          <td>
            <p style="margin: 0">{{ item.nombre }}</p>
          </td>
          <td class="text-right">
            <p style="margin: 0">{{ item.cantidad.toFixed(6) }}</p>
          </td>
          <td class="text-right">
            <p style="margin: 0">{{ item.merma.toFixed(6) }}</p>
          </td>
          <td class="text-right">
            <p style="color: rgb(4, 8, 247)">
              {{ item.costo_crudo.toFixed(6) }}
            </p>
          </td>
          <td class="text-center">
            <button class="btn btn-outline-primary" (click)="verDetalle(i)">
              Ver
            </button>
          </td>
        </tr>

        <tr *ngIf="item.mostrar">
          <td></td>
          <td></td>
          <td></td>
          <td class="text-right">COSTO PROMEDIO</td>
          <td class="text-right">{{ item.costo_unitario }}</td>
        </tr>
        <tr *ngIf="item.mostrar">
          <td></td>
          <td></td>
          <td class="text-right">%</td>
          <td class="text-right">GRAMOS</td>
          <td class="text-right">COSTO</td>
        </tr>
        <tr *ngIf="item.mostrar" style="border-bottom: hidden">
          <td></td>
          <td class="text-right font-weight-bold">
            <p class="mr-3">LIMPIO</p>
          </td>
          <td class="text-right">{{ item.porcentaje_limpio.toFixed(2) }}</td>
          <td style="width: 150px">
            <input
              type="numeric"
              class="form-control form-sm text-right"
              [(ngModel)]="item.cantidad"
              (input)="calculos(i)"
            />
          </td>
          <td class="text-right">{{ item.costo_limpio.toFixed(6) }}</td>
        </tr>
        <tr *ngIf="item.mostrar" style="border-bottom: hidden">
          <td></td>
          <td class="text-right font-weight-bold">
            <p class="mr-3">MERMA</p>
          </td>
          <td style="width: 150px">
            <input
              type="numeric"
              class="form-control form-sm text-right"
              [(ngModel)]="item.merma"
              (input)="calculos(i)"
            />
          </td>
          <td class="text-right">{{ item.unidad_merma.toFixed(4) }}</td>
          <td class="text-right">{{ item.costo_merma.toFixed(6) }}</td>
        </tr>
        <tr *ngIf="item.mostrar">
          <td></td>
          <td class="text-right font-weight-bold">
            <p class="mr-3">CRUDO</p>
          </td>
          <td class="text-right">{{ item.porcentaje_crudo.toFixed(2) }}</td>
          <td class="text-right">{{ item.unidad_crudo.toFixed(4) }}</td>
          <td class="text-right">
            <p style="color: rgb(4, 8, 247); margin: 0">
              {{ item.costo_crudo.toFixed(6) }}
            </p>
          </td>
        </tr>

        <!--<table>
            <thead>
              <tr>
                <th></th>
                <th>%</th>
                <th>GRAMOS</th>
                <th>COSTO</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>LIMPIO</td>
                <td>62</td>
                <td><input type="number" /></td>
                <td>0.357</td>
              </tr>
              <tr>
                <td>MERMA</td>
                <td><input type="number" /></td>
                <td>62</td>
                <td>0.357</td>
              </tr>
              <tr>
                <td>CRUDO</td>
                <td>62</td>
                <td>62</td>
                <td>0.357</td>
              </tr>
            </tbody>
          </table>-->

        <!--<tr *ngFor="let item of items; index as i">
        <td>
          <button
            type="button"
            class="btn btn-outline-warning btn-sm mr-2"
            id="borra"
            (click)="borrarItem(item.cod_receta)"
          >
            <i class="far fa-trash-alt"></i>
          </button>
          {{ item.cod_receta }}
        </td>

        <td>{{ item.nombre }}</td>
        <td>
          <tr>
            <th>Base</th>
            <th>Desperdicio</th>
            <th>Final</th>
          </tr>
          <tr>
            <td class="d-flex">
              <input
                type="text"
                class="form-control form-sm text-right"
                [(ngModel)]="item.cantidad_base"
                placeholder="0.00"
                (focusout)="validarDecimal(2, i, 'cantidad_base')"
              />
              <select
                class="form-select"
                name="um"
                id="um"
                (change)="actualizaUm($event, item.cod_receta)"
              >
                <option
                  *ngFor="let datoUm of item.umLista"
                  value="{{ datoUm.codigo }}"
                >
                  {{ datoUm.nombre }}
                </option>
              </select>
            </td>
            <td>{{ item.cantidad_desperdicio.toFixed(2) }}</td>
            <td>{{ item.cantidad_final.toFixed(2) }}</td>
          </tr>
        </td>
        <td>
          <tr>
            <th>Utilizable</th>
            <th>Perdida</th>
          </tr>
          <tr>
            <td class="d-flex">
              <input
                type="text"
                class="form-control form-sm text-right"
                [(ngModel)]="item.porcentaje_utilizable"
                placeholder="0.00"
                (focusout)="validarDecimal(2, i, 'porcentaje_utilizable')"
              />
              <span>%</span>
            </td>
            <td>{{ item.porcentaje_perdida.toFixed(2) }}%</td>
          </tr>
        </td>
        <td>
          <tr>
            <th>Base</th>
            <th>Desp</th>
            <th>Receta</th>
          </tr>
          <tr>
            <td>
              <input
                type="text"
                class="form-control form-sm text-right"
                [(ngModel)]="item.costo_base"
                placeholder="0.000000"
                (focusout)="validarDecimal(6, i, 'costo_base')"
              />
            </td>

            <td>{{ item.costo_final.toFixed(6) }}</td>
          </tr>
        </td>
        <td>{{ item.costo_receta.toFixed(2) }}</td>
        <tabindex="-1"
                readonly-->
        <!--<td class="d-inline-flex">
          <input
            type="numeric"
            class="form-control form-sm text-right"
            (input)="actualizaCantidadEntero($event, item.cod_receta)"
            placeholder="{{ item.cantidadEntero }}"
            appOnlynumber
          />
          <input
            type="numeric"
            class="form-control form-sm text-right"
            (input)="actualizaCantidadDecimal($event, item.cod_receta)"
            placeholder="{{ item.cantidadDecimal }}"
            appOnlynumber
          />
        </td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>{{ costoReceta.toFixed(2) }}</td>
      </tr>-->
      </tbody>
    </table>
  </div>
</div>
<div class="container text-right my-1" *ngIf="items.length > 0">
  <button
    type="button"
    class="btn btn-outline-success btn-sm btn_est m-2"
    (click)="grabar()"
  >
    <i class="fas fa-check-circle"></i> Guardar
  </button>

  <button
    type="button"
    class="btn btn-outline-danger btn-sm btn_est m-2"
    (click)="cerrarModal()"
  >
    <i class="fas fa-times-circle"></i> Cancelar
  </button>
</div>

<ng-template #noItems>
  <ng-container>
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-6 mt-5" style="text-align: center">
          <lottie-player
            src="https://assets2.lottiefiles.com/temp/lf20_TOE9MF.json"
            background="transparent"
            speed="1"
            loop
            autoplay
          ></lottie-player>
          <h3>
            No hay Receta
            <small class="text-muted"> Asignada</small>
          </h3>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<app-spinner></app-spinner>
