import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-unir',
  templateUrl: './modal-unir.component.html',
  styleUrls: ['./modal-unir.component.css'],
})
export class ModalUnirComponent implements OnInit {
  datos: object = [];
  numeroMesas: any = []; //<-- Numero total de mesas
  pedidos: any = []; //<-- Arreglo de pedidos
  public event: EventEmitter<any> = new EventEmitter(); //<-- Para devolver datos a cliente
  nSelected: number = 0;

  constructor(public modalReferencia: BsModalRef) {}

  ngOnInit(): void {
    let miniMesas = [];
    let mesas = this.datos[0];
    for (let i = 0; i < mesas.length; i++) {
      let mesa = mesas[i];
      if (mesa.total > 0) {
        mesa.selected = false;
        mesa.indice = i;
        miniMesas.push(mesa);
      }
    }

    this.pedidos = miniMesas;

    //let mesas = JSON.parse(localStorage.getItem('mesas'));
    for (let i = 0; i < mesas.length; i++) {
      let mesa = mesas[i];
      let miniMesa = {
        numero: mesas[i].id,
        feed: '(Sin items)',
        marcado: false,
        activo: true,
      };
      if (mesa.items > 0) miniMesa.feed = mesa.items + ' items';
      this.numeroMesas.push(miniMesa);
    }
  }

  //Manejo de las checkbox
  marcar(numero) {
    //Primero busco la mesas
    for (let i = 0; i < this.numeroMesas.length; i++) {
      //<--Itero el numero de mesas
      if (numero === this.numeroMesas[i].numero) {
        //<--Al encontrar la mesa a marcar o desmarcar
        //Si estaba desmarcada
        if (this.numeroMesas[i].marcado === false) {
          this.numeroMesas[i].marcado = true;
          //Agrego el pedido en la temporal
          let pedido = JSON.parse(localStorage.getItem(numero.toString()));
          pedido.numero = numero;
          this.pedidos.push(pedido);
        }
        //Si estaba marcada
        else {
          this.numeroMesas[i].marcado = false;
          //Elimino el pedido de la temporal
          for (let i = 0; i < this.pedidos.length; i++) {
            if (this.pedidos[i].numero === numero) {
              this.pedidos.splice(i, 1);
            }
          }
        }
      }
    }
  }

  cancelar() {
    this.modalReferencia.hide();
  }

  unir() {
    let mesas = JSON.parse(localStorage.getItem('mesas'));

    let temporal = [];

    let pedidos = [];
    this.pedidos.forEach((pedido) => {
      if (pedido.selected) pedidos.push(pedido);
    });

    for (let i = 0; i < pedidos.length; i++) {
      let mesa = pedidos[i];
      mesa.pedido.forEach((pedido) => {
        let bandera = true;
        temporal.forEach((lineas) => {
          if (lineas.linea == pedido.linea) {
            bandera = false;

            pedido.productos.forEach((pProducto) => {
              let bandera2 = true;
              lineas.productos.forEach((pLineas) => {
                if (pProducto.codigo == pLineas.codigo) {
                  bandera2 = false;
                  pLineas.cantidad += pProducto.cantidad;
                }
              });
              if (bandera2) lineas.productos.push(pProducto);
            });
          }
        });
        if (bandera) temporal.push(pedido);
      });
    }
    console.log(temporal);
    let total = 0;
    let items = 0;
    temporal.forEach((temp) => {
      temp.productos.forEach((producto) => {
        total +=
          producto.cantidad *
          producto.precio *
          (1 + producto.porc_tarifa / 100);
        items++;
      });
    });
    let i = 0;
    let e = 0;
    while (i < mesas.length && e < pedidos.length) {
      console.log(mesas.length);
      if (mesas[i].id == pedidos[e].id) {
        mesas.splice(i, 1);
        e++;
      } else i++;
    }
    console.log(mesas);

    let mesa = {
      id: pedidos[0].id,
      total: total,
      items: items,
      ruc: '9999999999999',
      cliente: 'Consumidor Final',
      estado: 'yellow',
      estadoAdentro: 'orange',
      estadoAfuera: 'yellow',
      pedido: temporal,
    };
    mesas.push(mesa);
    console.log(mesas);
    localStorage.setItem('mesas', JSON.stringify(mesas));
    this.cerrarModal();
    return;
  }

  seleccionar(i) {
    if (this.pedidos[i].selected) {
      this.nSelected--;
      this.pedidos[i].selected = false;
    } else {
      this.nSelected++;

      this.pedidos[i].selected = true;
    }
    console.log(this.nSelected);
  }

  cerrarModal() {
    this.lanzarEvento(); //<-- Envia la data a cliente
    this.modalReferencia.hide(); //<-- Cierra el modal
  }

  lanzarEvento() {
    this.event.emit({ res: 200 });
  }
}
