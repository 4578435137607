<!--CABECERA-->
<div class="modal-header">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-4">
        <button
          type="button"
          class="btn btn-outline-dark mr-2"
          (click)="imprimirFactura()"
        >
          <i class="fas fa-print"></i>
        </button>

        <button
          *ngIf="!edicion"
          type="button"
          class="btn btn-outline-warning mr-2"
          (click)="habilitarEdicion()"
        >
          <i class="far fa-edit"></i>
        </button>

        <button
          *ngIf="edicion"
          type="button"
          class="btn btn-outline-danger mr-2"
          (click)="habilitarEdicion()"
        >
          <i class="far fa-times-circle"></i>
        </button>
      </div>
      <div class="col-12 col-sm-8">
        <h4 class="modal-title">{{ titulo }}</h4>
      </div>
    </div>
  </div>

  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="cerrar()"
  >
    <span aria-hidden="true">&times; </span>
  </button>
</div>

<!--DATOS DEL CLIENTE-->
<div class="container-fluid">
  <div class="row my-2 justify-content-center">
    <div class="col-12 col-md-6 pt-1">
      <div class="card">
        <div class="card-body p-1">
          <h6 class="pl-1">Datos del Cliente</h6>
          <div class="input-group input-group-sm my-1">
            <div class="input-group-prepend text-center">
              <span class="input-group-text" id="ruc"
                ><i class="fas fa-id-card-alt"></i
              ></span>
            </div>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="Cedula | Ruc"
              [(ngModel)]="cabecera.ruc"
              [disabled]="inhabilitado"
            />
          </div>
          <div id="datos_cliente_contenido">
            <div *ngIf="cabecera.nomcli">
              <div class="input-group input-group-sm my-1">
                <div class="input-group-prepend text-center">
                  <span class="input-group-text" id="nombre"
                    ><i class="far fa-id-card"></i
                  ></span>
                </div>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Nombre Completo"
                  [(ngModel)]="cabecera.nomcli"
                  [disabled]="inhabilitado"
                />
              </div>

              <div class="input-group input-group-sm my-1">
                <div class="input-group-prepend text-center">
                  <span class="input-group-text" id="direccion"
                    ><i class="fas fa-map-marked-alt"></i
                  ></span>
                </div>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Direccion"
                  [(ngModel)]="cabecera.direccion"
                  [disabled]="inhabilitado"
                />
              </div>

              <div class="input-group input-group-sm my-1">
                <div class="input-group-prepend text-center">
                  <span class="input-group-text" id="telefono"
                    ><i class="fab fa-whatsapp"></i
                  ></span>
                </div>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Telefonos"
                  [(ngModel)]="cabecera.telefono"
                  [disabled]="inhabilitado"
                />
              </div>

              <div class="input-group input-group-sm my-1">
                <div class="input-group-prepend text-center">
                  <span class="input-group-text" id="telefono"
                    ><i class="fas fa-envelope"></i
                  ></span>
                </div>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Correo Electrónico"
                  [(ngModel)]="cabecera.correo"
                  [disabled]="inhabilitado"
                />
              </div>
              <div class="container">
                <div class="row">
                  <div class="col-12 col-md-6 p-0">
                    <div
                      class="input-group input-group-sm"
                      *ngIf="cabecera.autorizacion.length > 5"
                    >
                      <div class="input-group-prepend text-center">
                        <span class="input-group-text"
                          ><i class="fas fa-coins"></i
                        ></span>
                      </div>
                      <span class="input-group-text">
                        {{ cabecera.serie }} - {{ cabecera.numfac }}</span
                      >
                    </div>
                  </div>
                  <div class="col-12 col-md-6 p-0 d-flex align-items-end">
                    <div
                      class="input-group input-group-sm"
                      *ngIf="
                        cabecera.mensajefe == 'AUTORIZADO' &&
                        cabecera.autorizacion.length > 5
                      "
                    >
                      <div class="input-group-prepend text-center">
                        <span class="input-group-text"
                          ><i class="fas fa-check-circle"></i
                        ></span>
                      </div>
                      <span class="input-group-text">
                        {{ cabecera.mensajefe }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="input-group input-group-sm my-1"
                *ngIf="cabecera.autorizacion.length > 5"
              >
                <div class="input-group-prepend text-center">
                  <span class="input-group-text"
                    ><i class="fas fa-coins"></i
                  ></span>
                </div>
                <span class="input-group-text">
                  {{ cabecera.autorizacion }}</span
                >
              </div>

              <div
                class="input-group input-group-sm my-1"
                *ngIf="
                  cabecera.mensajefe != 'AUTORIZADO' &&
                  cabecera.autorizacion.length > 5
                "
              >
                <div class="input-group-prepend text-center">
                  <span class="input-group-text"
                    ><i class="fas fa-times"></i
                  ></span>
                </div>
                <span class="input-group-text text-wrap text-left">
                  NO AUTORIZADO - ERROR EN SRI O SERVIDOR PRINCIPAL
                </span>
                <span class="input-group-text text-wrap text-left">
                  {{ cabecera.mensajefe }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 pt-1" *ngIf="cabecera.fp_pago_nom != null">
      <div class="card">
        <div class="card-body p-1">
          <h6 class="pl-1">Forma de Pago</h6>
          <div class="input-group input-group-sm my-1">
            <div class="input-group-prepend text-center">
              <span class="input-group-text" id="fp_nom"
                ><i class="fas fa-money-bill-wave"></i
              ></span>
            </div>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="fp_nom"
              [(ngModel)]="cabecera.fp_pago_nom"
              [disabled]="inhabilitado"
            />
          </div>
          <div
            class="input-group input-group-sm my-1"
            *ngIf="cabecera.pp_tel != ''"
          >
            <div class="input-group-prepend text-center">
              <span class="input-group-text" id="pp_tel"
                ><i class="far fa-id-card"></i
              ></span>
            </div>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="pp_tel"
              [(ngModel)]="cabecera.pp_tel"
              [disabled]="inhabilitado"
            />
          </div>
        </div>
      </div>

      <div class="card mt-1">
        <div class="card-body p-1">
          <h6 class="pl-1">Direccion Entrega</h6>
          <div class="input-group input-group-sm my-1">
            <div class="input-group-prepend text-center">
              <span class="input-group-text" id="dir_direccion_fisica"
                ><i class="fas fa-home"></i
              ></span>
            </div>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="dir_direccion_fisica"
              [(ngModel)]="cabecera.dir_direccion_fisica"
              [disabled]="inhabilitado"
            />
          </div>
          <div class="input-group input-group-sm my-1">
            <div class="input-group-prepend text-center">
              <span class="input-group-text" id="dir_observacion"
                ><i class="fas fa-file-invoice-dollar"></i
              ></span>
            </div>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="dir_observacion"
              [(ngModel)]="cabecera.dir_observacion"
              [disabled]="inhabilitado"
            />
          </div>
          <div class="input-group input-group-sm my-1">
            <div class="input-group-prepend text-center">
              <span class="input-group-text" id="dir_telefono"
                ><i class="fas fa-mobile-alt"></i
              ></span>
            </div>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="dir_telefono"
              [(ngModel)]="cabecera.dir_telefono"
              [disabled]="inhabilitado"
            />
          </div>

          <a
            href="https://maps.google.com/maps?q={{ cabecera.dir_latitud }},{{
              cabecera.dir_longitud
            }}"
            target="_blank"
            class="btn btn-outline-primary btn-sm"
            ><i class="fas fa-map-marker-alt"></i> Mapa de Entrega</a
          >
        </div>
      </div>
    </div>
  </div>

  <!--BOTONES DE ACCIONES-->
  <div class="container-fluid my-2" *ngIf="edicion">
    <div class="row">
      <div class="col-6">
        <button
          class="btn btn-sm btn-outline-primary"
          (click)="abrirModalLineas()"
        >
          <i class="fas fa-plus-circle mr-1"></i> Producto
        </button>
      </div>

      <div class="col-6 text-md-right">
        <button
          class="btn btn-sm btn-outline-success"
          (click)="actualizarFactura()"
        >
          <i class="far fa-check-circle mr-1"></i>Grabar
        </button>
      </div>
    </div>
  </div>

  <app-spinner></app-spinner>
</div>

<!--TABLA-->
<div class="container-fluid">
  <div class="table table-responsive">
    <table class="table table-sm table-hover m-0 p-0">
      <thead class="thead-dark">
        <tr>
          <th
            scope="col"
            style="width: 25px; text-align: center"
            *ngIf="edicion"
            [style.background-color]="datosempresa['color']"
          ></th>
          <th
            scope="col"
            style="width: 80px; text-align: center"
            [style.background-color]="datosempresa['color']"
          >
            <p class="text-center m-0 p-0">Codigo</p>
          </th>
          <th scope="col" [style.background-color]="datosempresa['color']">
            <p class="text-center m-0 p-0">Descripción</p>
          </th>
          <th
            scope="col text-right"
            style="width: 125px"
            [style.background-color]="datosempresa['color']"
          >
            <p class="text-right m-0 p-0">Cantidad</p>
          </th>
          <th
            scope="col text-right"
            style="width: 115px"
            [style.background-color]="datosempresa['color']"
          >
            <p class="text-right m-0 p-0">Precio</p>
          </th>
          <th
            scope="col text-right"
            style="width: 115px"
            [style.background-color]="datosempresa['color']"
          >
            <p class="text-right m-0 p-0">Subtotal</p>
          </th>
          <th
            scope="col text-center"
            style="width: 25px; text-align: center"
            *ngIf="edicion"
            [style.background-color]="datosempresa['color']"
          ></th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let producto of cuerpo
              | paginate
                : {
                    itemsPerPage: 25,
                    currentPage: p,
                    totalItems: cuerpo.count
                  };
            index as i
          "
          id="{{ producto.codigo }}"
        >
          <td *ngIf="edicion">
            <button
              class="btn btn-sm btn-outline-success"
              (click)="abrirModalReceta(i)"
            >
              <i class="fas fa-edit"></i>
            </button>
          </td>
          <td style="text-align: center">
            <p class="text-left m-0 p-0">
              {{ producto.codigo }}
            </p>
          </td>
          <td>
            <p class="text-left m-0 p-0">
              {{ producto.nombre }}
            </p>
          </td>
          <td>
            <p class="text-right m-0 p-0">
              {{ producto.cantidad }}
            </p>
          </td>
          <td>
            <p class="text-right m-0 p-0">
              {{ producto.precio.toFixed(2) }}
            </p>
          </td>
          <td>
            <p class="text-right m-0 p-0">
              <strong>
                {{ (producto.precio * producto.cantidad).toFixed(2) }}</strong
              >
            </p>
          </td>
          <td *ngIf="edicion">
            <button
              class="btn btn-sm btn-outline-danger"
              (click)="eliminarItem(i)"
            >
              <i class="fas fa-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="modal-footer">
  <!--TOTALES-->
  <div class="container-fluid">
    <div class="row justify-content-center justify-content-md-end">
      <div class="col-4" *ngIf="cabecera.fp_pago_nom != null">
        <h6>Viandas : {{ cabecera.viandas.toFixed(0) }} UND</h6>
      </div>
      <div class="col-4" *ngIf="cabecera.fp_pago_nom != null">
        <h6>Delivery : $ {{ cabecera.distribuidor.toFixed(2) }}</h6>
      </div>
      <div class="col-4 text-right" *ngIf="cabecera.fp_pago_nom != null">
        <h6>TOTAL : $ {{ cabecera.total.toFixed(2) }}</h6>
      </div>
      <div class="col-4 text-right" *ngIf="cabecera.fp_pago_nom == null">
        <h5>TOTAL : $ {{ total.toFixed(2) }}</h5>
      </div>
    </div>
  </div>
</div>
