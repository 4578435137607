import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { ModalDepreciacionComponent } from '../modal-depreciacion/modal-depreciacion.component';

@Component({
  selector: 'app-consulta-activo',
  templateUrl: './consulta-activo.component.html',
  styleUrls: ['./consulta-activo.component.css'],
})
export class ConsultaActivoComponent implements OnInit {
  modalReferencia: BsModalRef;
  tiposActivo: any = [];
  tipoActivo: string = 'todos';
  years: any = [];
  yearSelected: string = 'todos';
  habilitado: string = 'todos';
  activos: any = [];
  empresa: any;
  constructor(
    private servicio: RestauranteService,
    private modalServicio: BsModalService
  ) {}

  ngOnInit(): void {
    let year = new Date().getFullYear();
    //this.yearSelected = year;
    for (let i = 2016; i < year + 6; i++) {
      this.years.push(i + '');
    }
    this.cargarTiposActivo();
    this.cargarFiltros();
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
  }

  cargarTiposActivo() {
    this.servicio
      .getConfiguraciones('005', this.empresa)
      .subscribe((result) => {
        console.log(result);
        this.tiposActivo = result;
        //if (this.tiposActivo.length > 0)
        //this.tipoActivo = this.tiposActivo[0].codigo;
        console.log(this.tipoActivo);
      });
  }
  cargarFiltros() {
    let datos = {
      tipo: this.tipoActivo,
      anio: this.yearSelected,
      habilitado: this.habilitado,
      empresa: JSON.parse(localStorage.getItem('empresa')).codigo,
    };
    this.servicio.filtrosActivosFijos(datos).subscribe((result) => {
      console.log(result);
      this.activos = result;
    });
  }

  modalActivo(activo = {}) {
    const initialState: any = {
      datosActivo: activo,
    };
    this.modalReferencia = this.modalServicio.show(ModalDepreciacionComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });
    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((res) => {
      console.log('MODAL CERRADO, DATA RECIBIDA', res.data);
    });
  }
}
