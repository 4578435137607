<!-- EMPRESA Y SUCURSAL-->
<div class="container-fluid py-2 py-md-5">
  <div class="row justify-content-center align-items-center">
    <div class="col-12 col-md-4 col-lg-3 d-flex justify-content-center">
      <mat-form-field appearance="fill" class="no-padding">
        <mat-label>Empresa</mat-label>
        <mat-select
          panelClass="my-select-panel-class"
          [(value)]="empresa"
          (selectionChange)="save_empresa()"
        >
          <mat-option *ngFor="let empr of empresas" [value]="empr.codigo.trim()"
            >{{ empr.nombre }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!--
    <div
      class="col-12 col-md-4 col-lg-3 d-flex justify-content-center"
      *ngIf="haysucursales"
    >
      <mat-form-field appearance="fill" class="no-padding">
        <mat-label>Sucursal</mat-label>
        <mat-select
          panelClass="my-select-panel-class"
          [(value)]="sucursal"
          (selectionChange)="save_sucursal()"
        >
          <mat-option
            *ngFor="let sucursal of sucursales"
            [value]="sucursal.codigo.trim()"
            >{{ sucursal.nombre }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
-->
  </div>
</div>

<!-- SELECTORES-->
<div class="container-fluid">
  <div class="row justify-content-center align-items-center">
    <div
      class="col-8 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-3"
      style="max-width: 200px"
      (click)="redireccionamiento('restaurante/login', '006')"
      *ngIf="empresa"
    >
      <div class="card border-danger">
        <div class="card-header bg-transparent border-danger text-center">
          <i
            class="fas fa-hot-tub text-danger my-4"
            style="font-size: 4rem"
          ></i>
        </div>

        <div
          class="card-footer bg-transparent border-danger text-danger text-center"
        >
          <h5><strong>Cocina</strong></h5>
        </div>
      </div>
    </div>

    <div
      class="col-8 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-3"
      style="max-width: 200px"
      (click)="redireccionamiento('restaurante/login', '001')"
    >
      <div class="card border-success">
        <div class="card-header bg-transparent border-success text-center">
          <i
            class="fas fa-book-reader text-success my-4"
            style="font-size: 4rem"
          ></i>
        </div>

        <div
          class="card-footer bg-transparent border-success text-success text-center"
        >
          <h5><strong>Mesero</strong></h5>
        </div>
      </div>
    </div>

    <div
      class="col-8 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-3"
      style="max-width: 200px"
      (click)="redireccionamiento('restaurante/login', '002')"
    >
      <div class="card border-info">
        <div class="card-header bg-transparent border-info text-center">
          <i
            class="fas fa-cash-register text-info my-4"
            style="font-size: 4rem"
          ></i>
        </div>

        <div
          class="card-footer bg-transparent border-info text-info text-center"
        >
          <h5><strong>Caja</strong></h5>
        </div>
      </div>
    </div>

    <div
      class="col-8 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-3"
      style="max-width: 200px"
      (click)="redireccionamiento('restaurante/login', '003')"
    >
      <div class="card border-primary">
        <div class="card-header bg-transparent border-primary text-center">
          <i
            class="fab fa-jsfiddle text-primary my-4"
            style="font-size: 4rem"
          ></i>
        </div>

        <div
          class="card-footer bg-transparent border-primary text-primary text-center"
        >
          <h5><strong>E.R.P.</strong></h5>
        </div>
      </div>
    </div>

    <div
      class="col-8 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-3"
      style="max-width: 200px"
      (click)="redireccionamiento('restaurante/login', '004')"
    >
      <div class="card border-dark">
        <div class="card-header bg-transparent border-dark text-center">
          <i
            class="fas fa-chart-line text-dark my-4"
            style="font-size: 4rem"
          ></i>
        </div>

        <div
          class="card-footer bg-transparent border-dark text-dark text-center"
        >
          <h5><strong>Gerencial</strong></h5>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- DUEÑO -->
<div class="container-fluid py-5">
  <div class="d-flex" style="justify-content: space-between; flex-wrap: wrap">
    <div class="container-fluid">
      <div class="row justify-content-center d-flex align-items-center">
        <div class="col-12 col-md-3 py-2 text-center">
          <img
            src="https://novaexpress.group/img/logos/nova_express_color_rectangulo.svg"
            alt="logo erp"
            style="max-height: 75px"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<app-spinner></app-spinner>
