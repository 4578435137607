import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { ServicioMeseroService } from 'src/app/services/servicio-mesero/servicio-mesero.service';

import { ModalDetalleFacturaComponent } from '../restaurante/ventas/modales/modal-detalle-factura/modal-detalle-factura.component';

@Component({
  selector: 'app-panel-notificaciones',
  templateUrl: './panel-notificaciones.component.html',
  styleUrls: ['./panel-notificaciones.component.css'],
})
export class PanelNotificacionesComponent implements OnInit {
  notificaciones: any = [];
  hidden = false;
  notipedido: any = {};
  sucursal: string = 'TODOS';
  modalReferencia: BsModalRef;
  constructor(
    private servicio: ServicioMeseroService,
    private modalServicio: BsModalService
  ) {}

  ngOnInit(): void {
    if (localStorage.getItem('usuario') && localStorage.getItem('sucursal')) {
      this.sucursal = JSON.parse(localStorage.getItem('sucursal')).codigo;
      this.cargarNotificaciones();
      this.cargarPedidosAPP();
    }
    // cada 30 seg buscara nuevas notificaciones
    setInterval(() => {
      if (localStorage.getItem('usuario') && localStorage.getItem('sucursal')) {
        this.sucursal = JSON.parse(localStorage.getItem('sucursal')).codigo;
        this.cargarNotificaciones();
        this.cargarPedidosAPP();
      }
    }, 1000 * 30);
  }

  cargarNotificaciones() {
    var today = new Date();
    var time =
      today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    //console.log(today, time);
    let datos = {
      empresa: JSON.parse(localStorage.getItem('empresa')).codigo,
      sucursal: JSON.parse(localStorage.getItem('sucursal')).codigo,
      perfil: JSON.parse(localStorage.getItem('usuario')).codigo,
    };
    //console.log(datos);
    this.servicio.obtenerNotificaciones(datos).subscribe((data) => {
      //console.log(data);
      if (data.length > 0) {
        data.forEach((element) => {
          element.abierto = true;
        });
        this.notificaciones = data;
      }
    });
  }

  cargarPedidosAPP() {
    let campos = {
      empresa: JSON.parse(localStorage.getItem('empresa')).codigo,
      sucursal: JSON.parse(localStorage.getItem('sucursal')).codigo,
    };
    this.servicio.obtenerNotiPedidosApp(campos).subscribe((data) => {
      this.notipedido = data[0];
      if (data.length > 0 && this.notipedido != 'undefined') {
        this.sonido();
        this.detalleFactura(this.notipedido);
      }
    });
  }

  detalleFactura(pedido) {
    console.log('MODAL DE SOLO 1 PEDIDO APP ');
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<--Datos enviados desde componente hacia modal
        pedido,
        this.sucursal,
      ],
    };
    this.modalReferencia = this.modalServicio.show(
      ModalDetalleFacturaComponent,
      {
        initialState,
        ignoreBackdropClick: true,
        class: 'modal-lg',
      }
    );
    this.modalReferencia.content.event.subscribe((res) => {
      console.log(res.data);
    });
  }

  ngDoCheck() {
    if (localStorage.getItem('notificacion')) {
      if (localStorage.getItem('notificacion') == 'true') this.hidden = true;
      else this.hidden = false;
    } else {
      this.hidden = true;
    }
  }

  abrirNotificacion(i) {
    this.notificaciones[i].abierto = !this.notificaciones[i].abierto;
  }

  cerrarNotificacion() {
    localStorage.setItem('notificacion', 'true');
  }

  sonido() {
    let audio = new Audio();
    audio.src = '../../../assets/notificacion.mp3';
    audio.load();
    audio.play();
  }
}
