import { Component, OnInit, ViewChild } from '@angular/core';

import { PanelNotificacionesComponent } from '../panel-notificaciones/panel-notificaciones.component';
import { MenuComponent } from '../menu/menu.component';

@Component({
  selector: 'app-barra-menu',
  templateUrl: './barra-menu.component.html',
  styleUrls: ['./barra-menu.component.css'],
})
export class BarraMenuComponent implements OnInit {
  @ViewChild(PanelNotificacionesComponent) notificacion;
  @ViewChild(MenuComponent) child;

  empresa: any = {
    nombre: '',
    color: '',
    urlLogo: '',
    cal_artesanal: '',
    acceso: '',
  };
  sucursal: any = {
    nombre: '',
  };
  usuario: any = {
    nombre: '',
  };

  constructor() {}

  ngOnInit(): void {
    if (localStorage.getItem('empresa'))
      this.empresa = JSON.parse(localStorage.getItem('empresa'));
    if (localStorage.getItem('sucursal'))
      this.sucursal = JSON.parse(localStorage.getItem('sucursal'));
    if (localStorage.getItem('usuario'))
      this.usuario = JSON.parse(localStorage.getItem('usuario'));
  }

  abrirMenu() {
    if (this.child) {
      if (!this.child.hidden) {
        console.log(this.child.hidden);
        this.child.hidden = true;
      }
    }
  }

  cerrarSesion() {
    localStorage.clear();
    //asigno lo guardado temporalemente
    localStorage.setItem('empresa', JSON.stringify(this.empresa));
    localStorage.setItem('sucursal', JSON.stringify(this.sucursal));

    location.href = '/login';
  }

  cerrarNotificacion() {
    if (localStorage.getItem('notificacion')) {
      if (localStorage.getItem('notificacion') == 'true')
        localStorage.setItem('notificacion', 'false');
      else localStorage.setItem('notificacion', 'true');
    }
  }
}
