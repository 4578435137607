import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-pedido-receta',
  templateUrl: './modal-pedido-receta.component.html',
  styleUrls: ['./modal-pedido-receta.component.css'],
})
export class ModalPedidoRecetaComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter(); //<-- Para devolver datos a cliente
  datos: Object[]; //<-- Debe tener el mismo nombre de la variable en producto
  tipoModal = '';
  recetas: any[] = [];
  recetaModelo: any;
  producto: any;
  //total_recetas:any[]=[];
  //Bandera para spinner
  banderaSpinner: boolean = false;

  //modalReferencia se comunica con producto
  //modalReferencia2 y modalServicio se comunican con modal materia prima
  //Constructor siempre se carga antes del OnInit
  constructor(
    public modalReferencia: BsModalRef,
    private servicio: RestauranteService,
    private spinner: NgxSpinnerService
  ) {}

  //OnInit siempre se carga despues del Constructor
  ngOnInit(): void {
    console.log('modal pedido receta', this.datos);
    this.producto = this.datos;

    console.log('arreglo_length', this.producto.receta.length);
    let longitud = this.producto.receta.length;
    if (this.producto.receta.length > 0) {
      this.recetas = this.producto.receta;
      if (this.producto.receta.length > this.producto.cantidad) {
        for (let i = 0; i < longitud - this.producto.cantidad; i++) {
          console.log(i);
          this.recetas.pop();
        }
      } else if (this.producto.receta.length < this.producto.cantidad) {
        this.cargarData(longitud);
      } else if (this.tipoModal == 'Modificar') this.cargarReceta();
    } else {
      this.cargarData();
    }
    setInterval(() => {
      if (this.banderaSpinner === true) {
        this.spinner.hide();
        this.banderaSpinner = false;
      }
    }, 100);
  }

  cargarData(resta = 0) {
    console.log('MODAL ABIERTO, DATOS OBTENIDOS DESDE PRODUCTO: ', this.datos);
    this.spinner.show();
    this.servicio.buscarReceta(this.producto.codigo, '1').subscribe(
      (result) => {
        for (let e = 0; e < result['length']; e++) {
          result[e]['solicitado'] = true;
        }

        for (let i = 0; i < this.producto.cantidad - resta; i++) {
          let newResult = [];
          result.forEach((val) => newResult.push(Object.assign({}, val)));
          this.recetas.push(newResult);
        }
        this.banderaSpinner = true;
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
  }

  cargarReceta() {
    this.spinner.show();
    this.servicio.buscarReceta(this.producto.codigo, '1').subscribe(
      (result) => {
        for (let e = 0; e < result['length']; e++) {
          result[e]['solicitado'] = true;
        }
        this.recetaModelo = result;
        this.banderaSpinner = true;
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
  }

  cerrarModal() {
    let comentario = '';
    let totalCompleta = 0;
    let labels = [];
    let nLabels = [];
    for (let i = 0; i < this.recetas.length; i++) {
      let receta = this.recetas[i];
      let completa = true;
      let recetaLabel = '';

      for (let j = 0; j < receta.length; j++) {
        let ingrediente = receta[j];
        if (!ingrediente.solicitado) {
          completa = false;
          recetaLabel += ingrediente.nombre.trim() + ',';
        }
      }
      if (completa) {
        totalCompleta++;
      } else {
        recetaLabel = recetaLabel.slice(0, -1);
        if (!labels.includes(recetaLabel)) {
          labels.push(recetaLabel);
          nLabels.push(1);
        } else {
          nLabels[labels.indexOf(recetaLabel)]++;
        }
      }
    }
    if (totalCompleta > 0) comentario = totalCompleta + ' Completo|';
    if (labels.length > 0) {
      for (let i = 0; i < labels.length; i++) {
        let label = labels[i];
        comentario += nLabels[i] + ' Sin ' + label + '|';
      }
    }
    comentario = comentario.slice(0, -1);

    this.producto.comentario = comentario;
    this.producto.receta = this.recetas;
    this.lanzarEvento(this.producto); //<-- Envia la data a cliente
    this.modalReferencia.hide(); //<-- Cierra el modal
  }

  lanzarEvento(valor) {
    this.event.emit({ data: valor, res: 200 });
  }

  agregarItem() {
    let newResult = [];
    console.log(this.recetaModelo);
    this.recetaModelo.forEach((val) => newResult.push(Object.assign({}, val)));
    this.recetas.push(newResult);
  }

  async eliminarItem(i) {
    let confirmar = await this.alertaConfirmacion(
      '¿Desa eliminar este elemento? '
    );
    if (!confirmar) return;
    this.recetas.splice(i, 1);
  }

  async alertaConfirmacion(titulo) {
    return Swal.fire({
      title: titulo,
      showDenyButton: true,
      confirmButtonText: 'Si',
      denyButtonText: 'No',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        return true;
      } else if (result.isDenied) {
        return false;
      }
    });
  }

  alerta(titulo, texto, icono) {
    return Swal.fire({
      icon: icono,
      title: titulo,
      text: texto,
      allowOutsideClick: false,
    });
  }
}
