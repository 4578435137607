import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServicioMeseroService } from 'src/app/services/servicio-mesero/servicio-mesero.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login-mesero',
  templateUrl: './login-mesero.component.html',
  styleUrls: ['./login-mesero.component.css'],
})
export class LoginMeseroComponent implements OnInit {
  meseros: any; //<--Traer datos desde API
  mesero = '';
  meseroTotal: any;
  password: any = '';
  sucursalEscogida: any = '';
  busqueda: string = '';
  //Banderas para spinner
  banderaSpinner: boolean = false;
  banderaSpinner1: boolean = false;
  banderaSpinner2: boolean = false;

  constructor(
    private router: Router,
    private servicio: ServicioMeseroService,
    private spinner: NgxSpinnerService
  ) {
    //<--Constructor ejecuta antes que onInit
    this.spinner.show();

    let data = {
      sucursal: JSON.parse(localStorage.getItem('sucursal')).codigo,
      perfil: localStorage.getItem('pagina'),
      empresa: JSON.parse(localStorage.getItem('empresa')).codigo,
    };
    this.servicio.loginEmpleados(data).subscribe((data) => {
      this.meseros = data;
      this.meseroTotal = data;

      this.spinner.hide();
    });
  }

  ngOnInit(): void {
    this.sucursalEscogida = JSON.parse(localStorage.getItem('sucursal')).codigo;
  }

  ingresa() {
    //Validacion
    if (this.mesero === '') {
      this.alerta(
        'Campos obligatorios',
        'Por favor ingrese el mesero',
        'warning'
      );
      return;
    }
    if (this.password === '') {
      this.alerta(
        'Campos obligatorios',
        'Por favor ingrese la contraseña',
        'warning'
      );
      return;
    }

    //Datos de cliente, sucursal y numero pedido
    this.spinner.show();
    let data = {
      usuario: this.mesero,
      password: this.password,
      empresa: JSON.parse(localStorage.getItem('empresa')).codigo,
      sucursal: JSON.parse(localStorage.getItem('sucursal')).codigo,
      perfil: localStorage.getItem('pagina'),
    };
    this.servicio.login(data).subscribe((data) => {
      console.log(data);
      if (data.length > 0) {
        let nombre;
        for (let i = 0; i < this.meseros.length; i++) {
          if (this.mesero === this.meseros[i].codigo)
            nombre = this.meseros[i].nombre;
          break;
        }
        localStorage.setItem(
          'usuario',
          JSON.stringify({
            codigo: this.mesero,
            nombre,
          })
        );
        this.alerta('Éxito', 'Sesión iniciada', 'success').then(() => {
          //this.router.navigate(['mesero']);
          location.href = 'mesero';
        });
      } else {
        this.alerta(
          '¡Mesero inexistente!',
          'Por favor, ingrese credenciales válidas.',
          'error'
        );
      }
      this.spinner.hide();
    });
  }

  mainLogin() {
    //localStorage.clear();
    localStorage.removeItem('pagina');
    this.router.navigate(['/']);
  }

  onKey() {
    console.log(this.busqueda);
    let mini: any = [];
    this.meseroTotal.forEach((usuario: any) => {
      console.log(usuario);

      if (usuario.nombre.toLowerCase().includes(this.busqueda.toLowerCase()))
        mini.push(usuario);
    });
    this.meseros = mini;
  }

  alerta(titulo, texto, icono) {
    return Swal.fire({
      icon: icono,
      title: titulo,
      text: texto,
      allowOutsideClick: false,
    });
  }
}
