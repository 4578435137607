<div class="barra-titulo">
  <div class="modal-header">
    <h4 class="modal-title" id="titulo"><b>UNIR MESAS</b></h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalReferencia.hide()"
    >
      <span aria-hidden="true">&times; </span>
    </button>
  </div>
</div>

<div class="container my-3">
  <div class="row">
    <div class="col-3 mb-4" *ngFor="let pedido of pedidos; index as i">
      <div
        class="card pedido-tarjeta"
        (click)="seleccionar(i)"
        [style.background]="pedido.selected ? '#5E95F0' : pedido.estado"
      >
        <div class="card-body">
          <p>Mesa: {{ pedido.id }}</p>
          <p>Cliente: {{ pedido.cliente }}</p>
          <p>Total: ${{ pedido.total.toFixed(2) }}</p>
          <p>Items: {{ pedido.items }}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="pedidos.length > 0">
    <div class="col-6">
      <button
        type="button"
        class="btn btn-outline-success w-100"
        (click)="unir()"
        [disabled]="nSelected < 2"
      >
        UNIR MESAS
      </button>
    </div>
    <div class="col-6">
      <button
        type="button"
        class="btn btn-outline-danger w-100"
        (click)="cancelar()"
      >
        CANCELAR
      </button>
    </div>
  </div>
</div>

<ng-template #sinMesas>No ha creado ninguna mesa.</ng-template>
