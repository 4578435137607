import { Component, OnInit } from '@angular/core';
import { ModalNominaComponent } from '../modal-nomina/modal-nomina.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormControl } from '@angular/forms';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import Swal from 'sweetalert2';

import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { NgxSpinnerService } from 'ngx-spinner';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-reporte-empleado',
  templateUrl: './reporte-empleado.component.html',
  styleUrls: ['./reporte-empleado.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ReporteEmpleadoComponent implements OnInit {
  //Referencia del modal
  modalReferencia: BsModalRef;
  //Cliente traido del modal
  cliente: any = {};
  //Info de ingresos y egresos
  info: any = [];
  //Banderas para spinner
  banderaSpinner: boolean = false;
  quincena: Number = 0;
  quincenas: any = [
    { id: 0, nombre: 'Fin de mes' },
    { id: 1, nombre: 'Quincena' },
  ];
  ingresos: any = [];
  egresos: any = [];
  totalIngresos: Number = 0;
  totalEgresos: Number = 0;

  constructor(
    private modalServicio: BsModalService,
    private service: RestauranteService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    console.log(this.formatearFechaString(this.date.value._d));
    setInterval(() => {
      if (this.banderaSpinner === true) {
        this.spinner.hide();
        this.banderaSpinner = false;
      }
    }, 100);
  }

  //Abre el modal
  abrirModalBuscarEmpleado() {
    //Este modal recibe el RUC del empleado que usa data binding componente hacia template
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<--Datos enviados desde componente hacia modal
        this.cliente.ruc,
      ],
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferencia = this.modalServicio.show(ModalNominaComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });
    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((res) => {
      this.cliente = res.data;
      console.log(this.cliente);
    });
  }

  //Metodos para manejo de fechas
  date = new FormControl(moment());

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }

  formatearFechaString(fecha = new Date(), hora = false) {
    //new Date().toJSON().slice(0,10).split('-').reverse().join('/')
    let fechaFin = new Date(fecha);
    let fechaString = fechaFin
      .toJSON()
      .slice(0, 10)
      .split('-')
      .reverse()
      .join('/');
    if (hora) {
      //Formatear hora
      return fechaString;
    }
    return fechaString;
  }

  consultar() {
    this.spinner.show();
    if (this.cliente.nombre) {
      this.ingresos = [];
      this.egresos = [];
      this.totalIngresos = 0;
      this.totalEgresos = 0;
      this.service
        .obtenerEmpleadosTipoFechaQuincena(
          this.cliente.codigo.trim(),
          this.formatearFechaString(this.date.value._d),
          this.quincena
        )
        .subscribe((data) => {
          this.info = data;
          console.log(this.info);
          if (this.info.length === 0) {
            Swal.fire({
              icon: 'error',
              title: '¡Sin resultados!',
              text: 'No hay ingresos ni egresos para este empleado en este periodo.',
            });
          } else {
            for (let i = 0; i < this.info.length; i++) {
              if (this.info[i].tipreg === 1) {
                this.ingresos.push({
                  nombre: this.info[i].nombre.trim(),
                  valor: this.info[i].valor,
                });
                this.totalIngresos = this.totalIngresos + this.info[i].valor;
              } else {
                this.egresos.push({
                  nombre: this.info[i].nombre.trim(),
                  valor: this.info[i].valor,
                });
                this.totalEgresos = this.totalEgresos + this.info[i].valor;
              }
            }
          }
          this.banderaSpinner = true;
        });
    } else {
      Swal.fire({
        icon: 'error',
        title: '¡Por favor, escoja un empleado!',
        text: 'No ha seleccionado ningún empleado.',
      });
      this.banderaSpinner = true;
    }
  }
}
