import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalService } from '../global.service';

@Injectable({
  providedIn: 'root',
})
export class RestauranteService {
  constructor(private http: HttpClient, public global: GlobalService) {}

  //Logeo
  login(datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<any>(
      this.global.servidor + '/api/usuarios/login',
      body,
      httpOptions
    );
  }

  loginEmpleados(datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<any>(
      this.global.servidor + '/api/usuarios/loginEmpleados',
      body,
      httpOptions
    );
  }

  upd_Clave(datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<any>(
      this.global.servidor + '/api/configuraciones/upd_Clave',
      body,
      httpOptions
    );
  }

  pp_cobro_link(datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<any>(
      this.global.servidor + '/api/pasarelas/pp_cobro_link',
      body,
      httpOptions
    );
  }

  //SMS WHATSAPP
  sms_wh(datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<any>(
      this.global.servidor + '/api/pasarelas/sms_wh',
      body,
      httpOptions
    );
  }

  //Cargar configuraciones LINEA y GRUPO omitiendo un elemento
  getConfiguracionesOmision(tipo, codigo): Observable<any> {
    return this.http.get<any>(
      this.global.servidor +
        '/api/configuraciones/filtroOmisionTipo/' +
        tipo +
        '/' +
        codigo
    );
  }

  //Cargar configuraciones LINEA, GRUPO, etc
  getConfiguraciones(tipo, empresa): Observable<any> {
    return this.http.get<any>(
      //      `${this.global.servidor}/api/configuraciones/filtroPorTipo/${tipo}/${empresa}`
      `${this.global.servidor}/api/configuraciones/filtroPorTipo?tipo=${tipo}&empresa=${empresa}`
    );
  }

  //Cargar configuraciones LINEA, GRUPO, etc
  /*
  getConfiguracionesLineas(campos): Observable<any> {
    return this.http.get<any>(
      `${this.global.servidor}/api/configuraciones/filtroPorTipo?tipo=${campos.tipo}`
    );
  }
  */

  //Cargar configuraciones LINEA, GRUPO, etc
  getLineas(campos): Observable<any> {
    return this.http.get<any>(
      `${this.global.servidor}/api/configuraciones/lineas?tipo=${campos.tipo}&empresa=${campos.empresa}`
    );
  }

  //CRUD de productos
  getProductos(campos): Observable<any> {
    return this.http.get<any>(
      this.global.servidor +
        `/api/productos/getProductos?usuario=${campos.usuario}&empresa=${campos.empresa}`
    );
  }

  // obtiene productos para inventario de cocina
  getProdcocina(campos): Observable<any> {
    return this.http.get<any>(
      this.global.servidor +
        `/api/productos/getProdcocina?usuario=${campos.usuario}&empresa=${campos.empresa}`
    );
  }

  getAyudantes(tipo, empresa): Observable<any> {
    return this.http.get<any>(
      `${this.global.servidor}/api/configuraciones/Ayudantes`
    );
  }

  getDivisas(tipdiv, ctipo): Observable<any> {
    return this.http.get<any>(
      this.global.servidor +
        `/api/configuraciones/Divisas?tipdiv=${tipdiv}&tipo=${ctipo}`
    );
  }

  creaProducto(datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<any>(
      this.global.servidor + '/api/productos/crearProducto',
      body,
      httpOptions
    );
  }

  actualizaProducto(id, datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.put<any>(
      this.global.servidor + '/api/productos/actualizarProducto/' + id,
      body,
      httpOptions
    );
  }

  eliminaProducto(id): Observable<any> {
    return this.http.delete<any>(
      this.global.servidor + '/api/productos/eliminarProducto/' + id
    );
  }

  buscarProducto(campos): Observable<any> {
    return this.http.get<any>(
      this.global.servidor +
        `/api/productos/buscarProducto?texto=${campos.texto}&usuario=${campos.usuario}&empresa=${campos.empresa}`
    );
  }

  //Envia un texto y bota todos los items relacionados
  obtenerItemsPorTexto(campos): Observable<any> {
    const body = JSON.stringify(campos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.http.get<any>(
      this.global.servidor +
        `/api/productos/obtenerItemsPorTexto?opcion=${campos.opcion}&texto=${campos.texto}&usuario=${campos.usuario}&empresa=${campos.empresa}`
    );
  }

  //CRUD de clientes
  getClientes(campos): Observable<any> {
    return this.http.get<any>(
      this.global.servidor +
        `/api/usuarios/obtenerClientes?texto=${campos.texto}&empresa=${campos.empresa}`
    );
  }

  creaCliente(datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<any>(
      this.global.servidor + '/api/usuarios/crearCliente',
      body,
      httpOptions
    );
  }

  actualizaCliente(id, datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.put<any>(
      this.global.servidor + '/api/usuarios/actualizarCliente/' + id,
      body,
      httpOptions
    );
  }

  eliminaCliente(id, empresa): Observable<any> {
    return this.http.delete<any>(
      this.global.servidor +
        '/api/usuarios/eliminarCliente/' +
        id +
        '/' +
        empresa
    );
  }

  buscarCliente(texto, empresa): Observable<any> {
    return this.http.get<any>(
      this.global.servidor +
        '/api/usuarios/buscarCliente/' +
        texto +
        '/' +
        empresa
    );
  }

  //CRUD de cuentas
  getCuentas(campos): Observable<any> {
    return this.http.get<any>(
      this.global.servidor +
        `/api/cuentas/getCuentas?texto=${campos.texto}&empresa=${campos.empresa}`
    );
  }

  creaCuenta(datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<any>(
      this.global.servidor + '/api/cuentas/crearCuenta',
      body,
      httpOptions
    );
  }

  actualizaCuenta(id, datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.put<any>(
      this.global.servidor + '/api/cuentas/actualizarCuenta/' + id,
      body,
      httpOptions
    );
  }

  eliminaCuenta(id, empresa): Observable<any> {
    return this.http.delete<any>(
      this.global.servidor + '/api/cuentas/eliminarCuenta/' + id + '/' + empresa
    );
  }

  buscarCuenta(campos): Observable<any> {
    return this.http.get<any>(
      this.global.servidor +
        `/api/cuentas/buscarCuenta?texto=${campos.texto}&usuario=${campos.usuario}&empresa=${campos.empresa}`
    );
  }


  buscarCuentaSig(campos): Observable<any> {
    return this.http.get<any>(
      this.global.servidor +
        `/api/cuentas/buscarCuentaSig?texto=${campos.texto}&usuario=${campos.usuario}&empresa=${campos.empresa}`
    );
  }


  //CRUD de Distribuidores
  getDistribuidores(campos): Observable<any> {
    return this.http.get<any>(
      this.global.servidor +
        `/api/distribuidores/getDistribuidores?texto=${campos.texto}&empresa=${campos.empresa}`
    );
  }

  creaDistribuidor(datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<any>(
      this.global.servidor + '/api/distribuidores/crearDistribuidor',
      body,
      httpOptions
    );
  }

  actualizaDistribuidor(id, datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.put<any>(
      this.global.servidor + '/api/distribuidores/actualizarDistribuidor/' + id,
      body,
      httpOptions
    );
  }

  eliminaDistribuidor(id): Observable<any> {
    return this.http.delete<any>(
      this.global.servidor + '/api/distribuidores/eliminarDistribuidor/' + id
    );
  }

  buscarDistribuidor(texto): Observable<any> {
    return this.http.get<any>(
      this.global.servidor + '/api/distribuidores/buscarDistribuidor/',
      texto
    );
  }

  //CRUD de proveedores
  getProveedores(campos): Observable<any> {
    return this.http.get<any>(
      this.global.servidor +
        `/api/usuarios/obtenerProveedores?empresa=${campos.empresa}`
    );
  }

  creaProveedor(datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<any>(
      this.global.servidor + '/api/usuarios/crearProveedor',
      body,
      httpOptions
    );
  }

  actualizaProveedor(id, datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.put<any>(
      this.global.servidor + '/api/usuarios/actualizarProveedor/' + id,
      body,
      httpOptions
    );
  }

  eliminaProveedor(id): Observable<any> {
    return this.http.delete<any>(
      this.global.servidor + '/api/usuarios/eliminarProveedor/' + id
    );
  }

  buscarProveedor(texto, empresa): Observable<any> {
    return this.http.get<any>(
      this.global.servidor +
        '/api/usuarios/buscarProveedor/' +
        texto +
        '/' +
        empresa
    );
  }

  //Obtener todos los items de una linea
  obtenerItemsLinea(campos): Observable<any> {
    return this.http.get<any>(
      this.global.servidor +
        `/api/configuraciones/itemsLinea?codigo=${campos.codigo}&usuario=${campos.usuario}&empresa=${campos.empresa}`
    );
  }

  //Guardar inventario en DB
  guardarInventario(datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<any>(
      this.global.servidor + '/api/inventario/guardarInventario',
      body,
      httpOptions
    );
  }

  //Guardar inventario COCINA en DB
  guardarInvCocina(datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<any>(
      this.global.servidor + '/api/inventario/guardarInvCocina',
      body,
      httpOptions
    );
  }

  //Guardar inventario en DB
  guardarCompra(datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<any>(
      this.global.servidor + '/api/inventario/guardarCompra',
      body,
      httpOptions
    );
  }

  //Guardar receta en DB
  guardarReceta(datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<any>(
      this.global.servidor + '/api/recetas/guardarReceta',
      body,
      httpOptions
    );
  }

  //Buscar receta en DB
  buscarReceta(id, opc): Observable<any> {
    return this.http.get<any>(
      this.global.servidor + '/api/recetas/buscarReceta/' + id + '/' + opc
    );
  }

  //Eliminar receta en DB
  eliminarReceta(id, idReceta): Observable<any> {
    return this.http.delete<any>(
      this.global.servidor + '/api/recetas/borrarReceta/' + id + '/' + idReceta
    );
  }

  //Eliminar todas las recetas en DB
  eliminarTodaReceta(idReceta): Observable<any> {
    return this.http.delete<any>(
      this.global.servidor + '/api/recetas/borrarTodaReceta/' + idReceta
    );
  }

  dataDashboard(campos): Observable<any> {
    let texto = `tipo=${campos.tipo}&desde=${campos.desde}&hasta=${campos.hasta}&empresa=${campos.empresa}&sucursal=${campos.sucursal}`;
    return this.http.get<any>(this.global.servidor + '/api/dashboard?' + texto);
  }

  dataDashboardCabecera(campos): Observable<any> {
    let texto = `sucursal=${campos.sucursal}&empresa=${campos.empresa}`;
    return this.http.get<any>(
      this.global.servidor + '/api/dashboard/cabecera?' + texto
    );
  }

  dataDashboardMovimiento(campos): Observable<any> {
    let texto = `modulo=${campos.modulo}&desde=${campos.desde}&hasta=${campos.hasta}&sucursal=${campos.sucursal}&empresa=${campos.empresa}`;
    return this.http.get<any>(
      this.global.servidor + '/api/dashboard/movimientos?' + texto
    );
  }

  obtenerDireccionIpPublica(): Observable<any> {
    return this.http.get<any>('https://api.ipify.org/?format=json');
  }

  //CRUD de TCO
  creaItemTCO(datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<any>(
      this.global.servidor + '/api/configuraciones/crearItemTco',
      body,
      httpOptions
    );
  }

  actualizaItemTCO(datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.put<any>(
      this.global.servidor + '/api/configuraciones/actualizarItemTco',
      body,
      httpOptions
    );
  }

  eliminaItemTCO(codigoTco, codigo): Observable<any> {
    return this.http.delete<any>(
      this.global.servidor +
        '/api/configuraciones/eliminarItemTco/' +
        codigoTco +
        '/' +
        codigo
    );
  }

  buscarItemTco(codigoTco, texto): Observable<any> {
    return this.http.get<any>(
      this.global.servidor +
        '/api/configuraciones/buscarItemTco/' +
        codigoTco +
        '/' +
        texto
    );
  }

  buscarMovimientoProductoVentas(campos): Observable<any> {
    return this.http.get<any>(
      this.global.servidor +
        `/api/reportes/movimientosPedidoVentas/lista?desde=${campos.desde}&hasta=${campos.hasta}&producto=${campos.producto}&sucursal=${campos.sucursal}&empresa=${campos.empresa}`
    );
  }

  obtenerDiarioVentas(campos): Observable<any> {
    return this.http.get<any>(
      this.global.servidor +
        `/api/reportes/diarioVentas/lista?desde=${campos.desde}&hasta=${campos.hasta}&sucursal=${campos.sucursal}&empresa=${campos.empresa}&usuario=${campos.usuario}&orden=${campos.orden}&opcion=${campos.opcion}&producto=${campos.producto}&cliente=${campos.cliente}`
    );
  }

  obtenerDiarioPedidos(campos): Observable<any> {
    return this.http.get<any>(
      this.global.servidor +
        `/api/reportes/diarioPedidos/lista?desde=${campos.desde}&hasta=${campos.hasta}&sucursal=${campos.sucursal}&empresa=${campos.empresa}&usuario=${campos.usuario}&orden=${campos.orden}&opcion=${campos.opcion}&producto=${campos.producto}&cliente=${campos.cliente}`
    );
  }

  obtenerKardexCompleto(campos): Observable<any> {
    return this.http.get<any>(
      this.global.servidor +
        `/api/reportes/kardexProducto/lista?tipo=1&desde=${campos.desde}&hasta=${campos.hasta}&codigo=0&sucursal=${campos.sucursal}&empresa=${campos.empresa}`
    );
  }

  obtenerKardexProducto(campos): Observable<any> {
    return this.http.get<any>(
      this.global.servidor +
        `/api/reportes/kardexProducto/lista?tipo=2&desde=${campos.desde}&hasta=${campos.hasta}&codigo=${campos.codigo}&sucursal=${campos.sucursal}&empresa=${campos.empresa}`
    );
  }

  //CRUD de factura

  //Realizar un pedido
  efectuarPedido(datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<any>(
      this.global.servidor + '/api/pedidos/registrarPedido',
      body,
      httpOptions
    );
  }

  //ACEPTA NOTIFICACION UN PEDIDO NOTIFICADO EN LA WEB
  aceptaNotiPedido(datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<any>(
      this.global.servidor + '/api/pedidos/aceptaNotiPedido',
      body,
      httpOptions
    );
  }

  //Visualizar detalle de un pedido
  verFactura(campos): Observable<any> {
    let datos = {
      empresa: campos.empresa,
      sucursal: campos.sucursal,
      modulo: campos.modulo,
      tipo: campos.tipo,
      numero: campos.idFactura,
      distribuidor: '',
      latitud: '',
      longitud: '',
    };
    return this.http.post<any>(
      this.global.servidor + `/api/pedidos/verPedido`,
      datos
    );
  }

  //Borrar un pedido
  eliminarFactura(campos): Observable<any> {
    return this.http.delete<any>(
      this.global.servidor +
        `/api/pedidos/eliminarPedido?numero=${campos.numero}&tipo=${campos.tipo}&sucursal=${campos.sucursal}&empresa=${campos.empresa}&modulo=${campos.modulo}`
    );
  }

  //Borrar un pedido
  eliminarFactura2(campos): Observable<any> {
    return this.http.delete<any>(
      this.global.servidor +
        `/api/pedidos/eliminarPedido2?empresa=${campos.empresa}&sucursal=${campos.sucursal}&modulo=${campos.modulo}&tipo=${campos.tipo}&numero=${campos.numero}`
    );
  }

  obtenerTiposMovimiento(empresa): Observable<any> {
    return this.http.get<any>(
      this.global.servidor + `/api/configuraciones/filtroPorTipo/010/${empresa}`
    );
  }

  //Movimientos sobre empleados
  obtenerEmpleados(empresa): Observable<any> {
    return this.http.get<any>(
      this.global.servidor + `/api/configuraciones/filtroPorTipo/011/${empresa}`
    );
  }

  buscarEmpleado(texto): Observable<any> {
    return this.http.get<any>(
      this.global.servidor + `/api/configuraciones/buscarEmpleado/${texto}`
    );
  }

  obtenerEmpleadosTipoFecha(movimiento, fecha): Observable<any> {
    return this.http.get<any>(
      this.global.servidor +
        `/api/configuraciones/filtroEmpleadosFecha?movimiento=${movimiento}&fecha=${fecha}`
    );
  }

  movimientoNomina(datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<any>(
      this.global.servidor + '/api/usuarios/movimientoNomina',
      body,
      httpOptions
    );
  }

  registroFaltas(datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<any>(
      this.global.servidor + '/api/usuarios/registroFaltas',
      body,
      httpOptions
    );
  }

  obtenerFaltas(fecha): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.get<any>(
      `${this.global.servidor}/api/usuarios/obtenerFaltas?fecha=${fecha}`,
      httpOptions
    );
  }

  obtenerEmpleadosTipoFechaQuincena(codigo, fecha, quincena): Observable<any> {
    return this.http.get<any>(
      this.global.servidor +
        `/api/configuraciones/filtroEmpleadosFechaQuincena?codigo=${codigo}&fecha=${fecha}&quincena=${quincena}`
    );
  }

  registrarActivoFijo(datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<any>(
      this.global.servidor + '/api/activoFijo/registrar',
      body,
      httpOptions
    );
  }

  eliminarActivoFijo(datos): Observable<any> {
    return this.http.delete<any>(
      this.global.servidor + '/api/activoFijo/eliminar/' + datos.id
    );
  }

  actualizarActivoFijo(datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.put<any>(
      this.global.servidor + '/api/activoFijo/actualizar',
      body,
      httpOptions
    );
  }

  getActivosFijos(datos): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.get<any>(
      `${this.global.servidor}/api/activoFijo/obtenerActivos?opcion=${datos.opcion}&filtro=${datos.filtro}&empresa=${datos.empresa}`,
      httpOptions
    );
  }

  filtrosActivosFijos(datos): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.get<any>(
      `${this.global.servidor}/api/activoFijo/filtros?tipo=${datos.tipo}&anio=${datos.anio}&habilitado=${datos.habilitado}&empresa=${datos.empresa}`,
      httpOptions
    );
  }

  consultarGrafico(campos) {
    return this.http.get(
      `${this.global.servidor}/api/dashboard/graficoDashboard?selector=${campos.selector}&desde=${campos.desde}&hasta=${campos.hasta}&sucursal=${campos.sucursal}&empresa=${campos.empresa}`
    );
  }

  consultarGrafico2(campos) {
    return this.http.get(
      `${this.global.servidor}/api/dashboard/graficoDashboard2?selector=${campos.selector}&desde=${campos.desde}&hasta=${campos.hasta}&sucursal=${campos.sucursal}&empresa=${campos.empresa}`
    );
  }

  getGastos(datos): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.get<any>(
      `${this.global.servidor}/api/gastos/obtener?opcion=${datos.opcion}&desde=${datos.desde}&hasta=${datos.hasta}&filtro=${datos.filtro}&empresa=${datos.empresa}&sucursal=${datos.sucursal}`,
      httpOptions
    );
  }

  eliminarGastos(datos): Observable<any> {
    return this.http.delete<any>(
      this.global.servidor + '/api/gastos/eliminar/' + datos.id
    );
  }

  actualizarGastos(datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.put<any>(
      this.global.servidor + '/api/gastos/actualizar',
      body,
      httpOptions
    );
  }
  registrarGastos(datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<any>(
      this.global.servidor + '/api/gastos/registrar',
      body,
      httpOptions
    );
  }

  reporteFaltas(campos) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.get<any>(
      `${this.global.servidor}/api/usuarios/reportes/faltas?tipo=${campos.tipo}&tabla=${campos.tabla}&desde=${campos.desde}&hasta=${campos.hasta}&empleado=${campos.empleado}&motivo=${campos.motivo}&empresa='${campos.empresa}'`,
      httpOptions
    );
  }

  reporteGastos(campos) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.get<any>(
      `${this.global.servidor}/api/gastos/reportes?tipo=${campos.tipo}&tabla=${campos.tabla}&empresa=${campos.empresa}&sucursal=${campos.sucursal}&tipo_gasto=${campos.tipo_gasto}&servicio_gasto=${campos.servicio_gasto}&hasta=${campos.hasta}&desde=${campos.desde}`,
      httpOptions
    );
  }

  ejecutarQuery(query) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.get<any>(
      `${this.global.servidor}/api/dashboard/ejecutarQuery?query=` + query,
      httpOptions
    );
  }

  consultarOpciones(opcion, modulo) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.get<any>(
      `${this.global.servidor}/api/dashboard/consultarOpciones?opcion=${opcion}&modulo=${modulo}`,
      httpOptions
    );
  }

  informe_dashboard(datos) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.get<any>(
      `${this.global.servidor}/api/informes/grafico?modulo=${datos.modulo}&desde=${datos.desde}&hasta=${datos.hasta}&sucursal=${datos.sucursal}&selector=${datos.selector}&tabla=${datos.tabla}&contenido=${datos.contenido}`,
      httpOptions
    );
  }

  obtenerNombreCI(documento) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.get<any>(
      `${this.global.servidor}/api/usuarios/obtenerNombre?documento=${documento}`,
      httpOptions
    );
  }
}
