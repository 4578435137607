import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { ModalLineasComponent } from 'src/app/components/restaurante/reportes/modal-lineas/modal-lineas.component';
import { ModalPedidoRecetaComponent } from '../../../modal-pedido-receta/modal-pedido-receta.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-detalle-factura',
  templateUrl: './modal-detalle-factura.component.html',
  styleUrls: ['./modal-detalle-factura.component.css'],
})
export class ModalDetalleFacturaComponent implements OnInit {
  datos: Object; //<-- Debe tener el mismo nombre de la variable en factura
  titulo: String;
  idFactura: number;
  tipo: any;
  cabecera: any = {};
  cuerpo: any = [];
  numero: number = 0;
  banderaSpinner: boolean = false;
  inhabilitado: boolean = true;
  total: number = 0;
  //Variable de evento de pagina
  p: Number = 1;
  pedido: any;

  sucursal: string = '';

  modalReferenciaLinea: BsModalRef;
  ventana: any;
  datosempresa: any;

  public event: EventEmitter<any> = new EventEmitter();
  admin: any;
  empresa: any;

  constructor(
    public modalReferenciaLineas: BsModalRef,
    private servicio: RestauranteService,
    private spinner: NgxSpinnerService,
    private modalServicio: BsModalService
  ) {}

  ngOnInit(): void {
    this.admin = JSON.parse(localStorage.getItem('usuario')).admin;
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.datosempresa = JSON.parse(localStorage.getItem('empresa'));
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }

    setInterval(() => {
      if (this.banderaSpinner === true) {
        this.spinner.hide();
        this.banderaSpinner = false;
      }
    }, 100);
    this.pedido = this.datos[0];
    this.idFactura = this.pedido.numero;
    this.tipo = this.pedido.tipo;
    this.cargarDatos();

    this.titulo = this.pedido.nomdoc.trim() + ' # ' + this.idFactura.toString();
    if (this.pedido.mesa > 0) {
      this.titulo =
        this.titulo + ' - -   MESA # ' + this.pedido.mesa.toString();
    }
  }

  cargarDatos() {
    this.total = 0;
    let datos = {
      opcion: 1,
      idFactura: this.idFactura,
      empresa: JSON.parse(localStorage.getItem('empresa')).codigo,
      sucursal: this.pedido.sucursal,
      modulo: this.pedido.modulo,
      tipo: this.pedido.tipo,
      //empresa: this.pedido.empresa,
    };
    this.spinner.show();
    this.servicio.verFactura(datos).subscribe((data) => {
      console.log(data);
      this.banderaSpinner = true;

      this.cabecera = data[0][0];
      this.cabecera.usuario = JSON.parse(
        localStorage.getItem('usuario')
      ).codigo;
      this.cuerpo = data[1];
      this.numero = this.cuerpo[0].numero;

      this.cuerpo.forEach((producto) => {
        producto.nuevo = false;
        this.total = this.total + producto.cantidad * producto.precio;
      });

      datos['opcion'] = 2;
      this.servicio.verFactura(datos).subscribe((data) => {
        //console.log(data);
        let cuerpo = data[1];
        let mini = {};
        for (let i = 0; i < cuerpo.length; i++) {
          const element = cuerpo[i];
          element.cod_receta = element.codigo;
          element.unidad = element.medida.trim();
          if (mini[element.cod_prod_rec]) {
            if (mini[element.cod_prod_rec][element.num_prod_rec]) {
              mini[element.cod_prod_rec][element.num_prod_rec].push(element);
            } else {
              mini[element.cod_prod_rec][element.num_prod_rec] = [element];
            }
          } else {
            mini[element.cod_prod_rec] = {};
            mini[element.cod_prod_rec][element.num_prod_rec] = [element];
          }
        }
        //console.log(mini);

        this.cuerpo.forEach((element) => {
          let receta = [];
          //console.log(element.cantidad);
          element.cantidad;
          for (let i = 0; i < element.cantidad; i++) {
            //console.log(mini[element.codigo]);
            if (mini[element.codigo]) receta.push(mini[element.codigo][i]);
          }
          element.receta = receta;
        });
        //console.log(this.cuerpo);
      });
    });
  }

  calcularTotales() {
    this.total = 0;
    this.cuerpo.forEach((producto) => {
      this.total += producto.cantidad * producto.precio;
    });
  }

  //Imprimir la factura con su comanda
  imprimirFactura() {
    this.ventana = window.open('', '', 'fullscreen=yes');
    this.ventana.document.write(this.crearHtmlAImprimir());
    this.ventana.document.close();
    setTimeout(() => {
      this.ventana.print();
      this.ventana.close();
    }, 800);

    return;
  }

  //Imprimir la factura con su comanda
  imprimirComanda() {
    this.ventana = window.open('', '', 'fullscreen=yes');
    this.ventana.document.write(this.crearHTMLComanda());
    this.ventana.document.close();
    //this.ventana.focus();
    return;
  }

  //steven
  cerrar() {
    if (this.pedido.noti_caja == 0) {
      this.spinner.show();
      console.log('ENVIAR A SQL NOTI_CAJA = 1');
      let campos = {
        empresa: this.pedido.empresa,
        sucursal: this.pedido.sucursal,
        modulo: this.pedido.modulo,
        numero: this.pedido.numero,
        tipo: this.pedido.tipo,
      };

      this.servicio.aceptaNotiPedido(campos).subscribe((data) => {
        //console.log(data);
        this.spinner.hide();
      });
    }

    this.modalReferenciaLineas.hide();
  }

  crearHtmlAImprimir() {
    //Factura
    let htmlParte1 = `<html><head>
      <title>Compra # ${this.cabecera.numero} </title>
      <style type="text/css">
      .cabecera, .item { margin:0; padding:0 }
      .detalle {width: 80%;}
      .subtotal {width: 20%;}
      .mitad {width: 50%;}
      .tabla {width: 100%;}
      .resumen {text-align: right;}
      .boton {text-align: center;}
      p {font-size:12px}
      .no-margin{
        margin:0
      }
      .imagen-logo{
        background-color: ${JSON.parse(localStorage.getItem('empresa')).color};
        border-radius: 10px;
      }
      input {background-color: green; color: white;}
      tr.bordeado td { border-bottom: 1px dotted black; }
      @media print{ .no-print, .no-print * { display: none !important; } }
      </style></head>`;

    let htmlParte2 = `<body><p class="boton"><input type="button" value="Imprimir" onclick="recargar()" class="no-print"></p>

      <p  class="cabecera"><b>Factura # ${this.cabecera.numero}   </b> </p>
      <p  class="cabecera"><b>RUC :   </b> ${this.cabecera.ruc} </p>
      <p  class="cabecera"><b>Nombre :</b>  ${this.cabecera.nomcli} </p>`;

    let htmlParte3 = `<p class="cabecera"><b>Dirección :</b> ${this.cabecera.direccion} </p>
       <p class="cabecera"><b>Teléfono :</b> ${this.cabecera.telefono} </p>
       <p class="cabecera"><b>Correo : </b> ${this.cabecera.correo} </p>
       <hr> `;

    let htmlParte4 =
      '<table class="tabla"><tr><th class="detalle">Detalles</th><th class="subtotal">Total</th></tr>';
    this.cuerpo.forEach((producto) => {
      if (producto.cantidad > 0) {
        htmlParte4 =
          htmlParte4 +
          `<tr class="bordeado"><td class="detalle">
          <p class="item">${producto.nombre}</p>
          <p class="item"> ${producto.cantidad}  x  ${producto.precio.toFixed(
            2
          )}  =  ${(producto.cantidad * producto.precio).toFixed(2)}  |
            ${(
              (producto.cantidad * producto.precio * producto.porc_tarifa) /
              100
            ).toFixed(2)}
          </p></td>

          <td class="subtotal resumen">
          <p>
          ${(
            producto.cantidad *
            producto.precio *
            (1 + producto.porc_tarifa / 100)
          ).toFixed(2)}
          </p</td></tr>`;
      }
    });
    htmlParte4 = htmlParte4 + '</table>';

    let htmlParte5 = `<table class="tabla">
      <tr>
        <td class="detalle resumen">
          <p class="item"><b>Subtotal : $</b></p>
          <p class="item"><b>Impuesto : $</b></p>
          <p class="item"><b>Total : $</b></p>
          <p class="item"><b>Efectivo : $</b></p>
          <p class="item"><b>Cambio : $</b></p>
        </td>
        <td class="subtotal resumen">
          <p class="item"> ${this.cabecera.subtotal.toFixed(2)}</p>
          <p class="item"> ${this.cabecera.impuesto.toFixed(2)}</p>
          <p class="item"> ${this.cabecera.total.toFixed(2)}</p>
          <p class="item"> ${this.cabecera.efectivo.toFixed(2)}</p>
          <p class="item"> ${this.cabecera.cambio.toFixed(2)}</p>
        </td>
      </tr>
      </table>`;

    let htmlParte8 =
      '<script>function recargar(){window.print()}</script></body></html>';
    return (
      htmlParte1 +
      htmlParte2 +
      htmlParte3 +
      htmlParte4 +
      htmlParte5 +
      htmlParte8
    );
  }

  crearHTMLComanda() {
    let htmlParte1 = `<html><head>
    <title>Factura # ${this.cabecera.numero} </title>
    <style type="text/css">
    .cabecera, .item { margin:0; padding:0 }
    .detalle {width: 80%;}
    .subtotal {width: 20%;}
    .mitad {width: 50%;}
    .tabla {width: 100%;}
    .resumen {text-align: right;}
    .boton {text-align: center;}
    p {font-size:12px}
    .no-margin{
      margin:0
    }
    input {background-color: green; color: white;}
    tr.bordeado td { border-bottom: 1px dotted black; }
    @media print{ .no-print, .no-print * { display: none !important; } }
    </style></head>
    <body>
    <p class="boton"><input type="button" value="Imprimir" onclick="recargar()" class="no-print"></p>
    `;

    let cuerpo = [];
    this.cuerpo.forEach((element) => {
      if (element.cantidad > 0) cuerpo.push(element);
    });
    let htmlParte2 = `<hr>
      <p style='font-size:18px'><b>
      Factura # ${this.cabecera.numero} </b></p>
      <p style='font-size:18px'><b>
      Orden # ${this.pedido.numfac}
       </b></p>
       <table><tr><th>Nombre</th><th>Detalles</th></tr> </p>`;

    cuerpo.forEach((producto) => {
      let comentario = producto.comentario.split('|');
      //console.log(producto.comentario);
      //Comentario separado
      let comentarioApintar = '<div>';
      for (let i = 0; i < comentario.length; i++) {
        comentarioApintar =
          comentarioApintar + '<p class="item">' + comentario[i].trim();
        +'</p>';
      }
      comentarioApintar = comentarioApintar + '</div>';

      //Agarro el primer item
      htmlParte2 =
        htmlParte2 +
        '  <tr class="bordeado"><td><b> ' +
        producto.cantidad +
        ' x </b> ' +
        producto.nombre +
        '</td><td> ' +
        comentarioApintar +
        '</td></tr>';
    });

    let htmlParte3 = ``;
    if (this.cabecera.comentario.trim() != '')
      htmlParte3 = `<p style="font-weight:bold"> Comentario </p> <p> ${this.cabecera.comentario} </p>`;

    let htmlParte4 =
      '<script>function recargar(){window.print()}</script></body></html>';
    return htmlParte1 + htmlParte2 + htmlParte3 + htmlParte4;
  }

  //Abre el modal
  abrirModalLineas() {
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<--Datos enviados desde componente hacia modal
      ],
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferenciaLinea = this.modalServicio.show(ModalLineasComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });
    //Al cerrar el modal recibe la data
    this.modalReferenciaLinea.content.event.subscribe((res) => {
      //console.log(res);
      //console.log(this.cuerpo);
      res.forEach((elemento) => {
        let data = {
          cantidad: elemento.cantidad,
          codigo: elemento.codigo,
          nombre: elemento.nombre,
          nuevo: true,
          numero: this.numero,
          numreg: this.cuerpo.length + 1,
          porc_tarifa: elemento.porc_tarifa,
          precio: elemento.precio,
          subtotal: elemento.precio * elemento.cantidad,
          tipo: elemento.linea,
          receta: [],
        };
        this.cuerpo.push(data);
      });

      this.calcularTotales();
    });
  }

  //Abre el modal
  abrirModalReceta(i) {
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: this.cuerpo[i], //<--Datos enviados desde componente hacia modal
      tipoModal: 'Modificar',
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferenciaLinea = this.modalServicio.show(
      ModalPedidoRecetaComponent,
      {
        initialState,
        ignoreBackdropClick: true,
        class: 'modal-dialog-centered modal-xl',
      }
    );
    //Al cerrar el modal recibe la data
    this.modalReferenciaLinea.content.event.subscribe((res) => {
      let data = res.data;
      data['cantidad'] = data['receta'].length;
      this.cuerpo[i] = res.data;
      this.calcularTotales();
    });
  }

  actualizarFactura() {
    let campos = {
      numero: this.cabecera.numero,
      tipo: this.cabecera.tipo,
      sucursal: this.cabecera.sucursal,
      modulo: this.pedido.modulo,
      empresa: JSON.parse(localStorage.getItem('empresa')).codigo,
    };

    let datos = {
      cabecera: this.cabecera,
      cuerpo: this.cuerpo,
    };
    //console.log(this.cabecera);

    this.spinner.show();
    this.servicio.eliminarFactura(campos).subscribe((data) => {
      //console.log(data);

      this.servicio.efectuarPedido(datos).subscribe((data) => {
        //console.log(data);
        this.spinner.hide();
        this.alerta(
          'Éxito',
          'Factura actualizada correctamente',
          'success'
        ).then(() => {
          this.cerrarModal('OK');
        });
      });
    });
  }

  //Cerrar modal y enviar la data a factura
  cerrarModal(data) {
    this.event.emit(data);
    this.modalReferenciaLineas.hide(); //<-- Cierra el modal
  }

  async eliminarItem(i) {
    let confirmar = await this.alertaConfirmacion(
      '¿Desa eliminar este elemento? '
    );
    if (!confirmar) return;
    this.cuerpo.splice(i, 1);
    this.calcularTotales();
  }

  async alertaConfirmacion(titulo) {
    return Swal.fire({
      title: titulo,
      showDenyButton: true,
      confirmButtonText: 'Si',
      denyButtonText: 'No',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        return true;
      } else if (result.isDenied) {
        return false;
      }
    });
  }

  alerta(titulo, texto, icono) {
    return Swal.fire({
      icon: icono,
      title: titulo,
      text: texto,
      allowOutsideClick: false,
    });
  }

  alertaOpciones(titulo, confirmar, cancelar) {
    return Swal.fire({
      title: titulo,
      showDenyButton: true,

      confirmButtonText: confirmar,
      denyButtonText: cancelar,
    });
  }
  edicion: boolean = false;
  habilitarEdicion() {
    if (this.edicion) {
      this.pedido = this.datos[0];
      //console.log(this.pedido);
      this.idFactura = this.pedido.numero;
      this.tipo = this.pedido.tipo;
      this.titulo = 'Documento # ' + this.idFactura.toString();
      this.cargarDatos();
    }

    this.edicion = !this.edicion;
  }
}
