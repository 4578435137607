import { Component, EventEmitter, OnInit } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-depreciacion',
  templateUrl: './modal-depreciacion.component.html',
  styleUrls: ['./modal-depreciacion.component.css'],
})
export class ModalDepreciacionComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter(); //<-- Para devolver datos a cliente
  tabla: any = [];

  constructor(public modalReferencia: BsModalRef) {}

  datosActivo: any;

  ngOnInit(): void {
    console.log(this.datosActivo);
    this.calcularDepreciacion();
  }

  calcularDepreciacion() {
    let valor = this.datosActivo.valor;
    let depreciacion = this.datosActivo.valor * (this.datosActivo.porc / 100);
    let porcentaje = 0;
    for (
      let i = this.datosActivo.anio_compra;
      i < this.datosActivo.anio_compra + this.datosActivo.vigencia + 1;
      i++
    ) {
      let registro = {
        anio: i,
        valor: valor,
        porc: porcentaje,
      };
      this.tabla.push(registro);
      valor -= depreciacion;
      porcentaje += this.datosActivo.porc;
    }
    console.log(this.tabla);
  }
}
