import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';

import Swal from 'sweetalert2';

import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { NgxSpinnerService } from 'ngx-spinner';
//import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-reporte-movimiento-empleados',
  templateUrl: './reporte-movimiento-empleados.component.html',
  styleUrls: ['./reporte-movimiento-empleados.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ReporteMovimientoEmpleadosComponent implements OnInit {
  minDate: Date;
  maxDate: Date;

  busqueda = '';

  empleado: string = 'todos';
  empleados: any;
  empleadosTotal: any;

  cabecera: any;
  movimiento: any;

  tipoMov: string = 'TODOS';
  tiposMov: any;

  opcion: string = '';
  opciones: any;
  empresa: any;
  constructor(
    private service: RestauranteService,
    private spinner: NgxSpinnerService
  ) {
    this.minDate = new Date();
    this.maxDate = new Date();
  }

  ngOnInit(): void {
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
    this.cargarEmpleados();
    this.cargarTiposMovimientos();
    this.cargarOpciones();
  }

  dateInit = new FormControl(moment());
  dateEnd = new FormControl(moment());

  chosenYearHandlerBegin(normalizedYear: Moment) {
    const ctrlValue = this.dateInit.value;
    ctrlValue.year(normalizedYear.year());
    this.dateInit.setValue(ctrlValue);
  }

  chosenMonthHandlerBegin(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.dateInit.value;
    ctrlValue.month(normalizedMonth.month());
    this.dateInit.setValue(ctrlValue);

    datepicker.close();
    this.minDate = new Date(this.dateInit.value._d);
    this.cargarDatos();
  }

  chosenYearHandlerEnd(normalizedYear: Moment) {
    const ctrlValue = this.dateEnd.value;
    ctrlValue.year(normalizedYear.year());
    this.dateEnd.setValue(ctrlValue);
  }

  cargarTiposMovimientos() {
    this.service.getConfiguraciones('010', this.empresa).subscribe((result) => {
      console.log(result);
      this.tiposMov = result;
    });
  }

  cargarOpciones() {
    this.service.consultarOpciones('2', '003').subscribe((result) => {
      console.log(result);
      result.forEach((element) => {
        if (element.valor == 1) this.opcion = element.orden;
      });
      this.opciones = result;
      this.cargarDatos();
    });
  }

  chosenMonthHandlerEnd(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.dateEnd.value;
    ctrlValue.month(normalizedMonth.month());
    this.dateEnd.setValue(ctrlValue);
    datepicker.close();
    this.maxDate = new Date(this.dateEnd.value._d);
    this.cargarDatos();
  }

  cargarEmpleados() {
    this.service.getConfiguraciones('011', this.empresa).subscribe((result) => {
      console.log(result);
      this.empleados = result;
      this.empleadosTotal = result;
    });
  }

  cargarDatos(ruc = '') {
    let desde = this.formatearMes(this.dateInit.value._d);
    let hasta = this.formatearMes(this.dateEnd.value._d);
    let procedimiento = 'exec ';
    this.opciones.forEach((opcion) => {
      if (opcion.orden == this.opcion) procedimiento += opcion.procedimiento;
    });

    //this.campaignOne.value.end.toJSON()
    let empresa = JSON.parse(localStorage.getItem('empresa')).codigo;
    let sucursal = JSON.parse(localStorage.getItem('sucursal')).codigo;
    let query1 = `${procedimiento} ${this.opcion},1,'${desde}','${hasta}','${empresa}','${sucursal}','${this.empleado}','${this.tipoMov}'`;
    let query2 = `${procedimiento} ${this.opcion},2,'${desde}','${hasta}','${empresa}','${sucursal}','${this.empleado}','${this.tipoMov}'`;
    console.log(procedimiento);

    console.log(query1);

    this.service.ejecutarQuery(query1).subscribe((cabeceras) => {
      console.log(cabeceras);

      this.cabecera = cabeceras;

      this.service.ejecutarQuery(query2).subscribe((movimientos) => {
        console.log(movimientos);

        for (let i = 0; i < cabeceras.length; i++) {
          for (let e = 0; e < movimientos.length; e++) {
            const element = cabeceras[i];
            switch (element.tipodato.trim()) {
              case 'char':
                movimientos[e][element['campo']] =
                  movimientos[e][element['campo']].trim();
                break;
              case 'numerico':
                movimientos[e][element['campo']] = parseFloat(
                  movimientos[e][element['campo']]
                ).toFixed(parseInt(element['longitud']));
                break;
              default:
                console.log(element);
                break;
            }
          }
        }

        this.movimiento = movimientos;
      });
    });
  }

  onKey() {
    console.log(this.busqueda);
    let mini: any = [];
    this.empleadosTotal.forEach((usuario: any) => {
      console.log(usuario);

      if (usuario.nombre.toLowerCase().includes(this.busqueda.toLowerCase()))
        mini.push(usuario);
    });
    this.empleados = mini;
  }

  formatearFechaString(fecha = new Date(), hora = false) {
    //new Date().toJSON().slice(0,10).split('-').reverse().join('/')
    let fechaFin = new Date(fecha);
    let fechaString = fechaFin
      .toJSON()
      .slice(0, 10)
      .split('-')
      .reverse()
      .join('/');
    if (hora) {
      //Formatear hora
      return fechaString + ' ' + fechaFin.toJSON().slice(11, 19);
    }
    return fechaString;
  }

  formatearMes(fecha = new Date()) {
    let fechaFormateada = this.formatearFechaString(fecha).split('/');
    let fechaMes = fechaFormateada[2] + fechaFormateada[1];
    console.log(fechaMes);
    return fechaMes;
  }
}
