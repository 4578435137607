import { Component, EventEmitter, HostListener, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-alerta',
  templateUrl: './modal-alerta.component.html',
  styleUrls: ['./modal-alerta.component.css'],
})
export class ModalAlertaComponent implements OnInit {
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == 'Enter') this.cerrarModal('Ok');
  }
  datos: Object; //<-- Debe tener el mismo nombre de la variable en factura
  tiempo = 5;
  tiempoMensaje;

  pedidoCocina: any;
  public event: EventEmitter<any> = new EventEmitter();
  constructor(public modalReferencia: BsModalRef) {}

  ngOnInit(): void {
    this.pedidoCocina = this.datos[0];
    console.log(this.pedidoCocina);
  }
  ngAfterViewInit() {
    this.tiempoMensaje = setInterval(() => {
      this.tiempo--;
      console.log(this.tiempo);
      if (this.tiempo == 0) this.cerrarModal('Cerró');
    }, 1000);
  }

  cerrarModal(respuesta) {
    clearInterval(this.tiempoMensaje);
    this.event.emit({ data: respuesta, res: 200 });
    this.modalReferencia.hide(); //<-- Cierra el modal
  }
}
