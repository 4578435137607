import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalNominaComponent } from '../modal-nomina/modal-nomina.component';
import Swal from 'sweetalert2';

import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { NgxSpinnerService } from 'ngx-spinner';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-faltas',
  templateUrl: './faltas.component.html',
  styleUrls: ['./faltas.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class FaltasComponent implements OnInit {
  modalReferencia: BsModalRef;
  empleados: any = [];
  motivos: any = [];
  motivo: string = '';
  empresa: any;
  constructor(
    private modalServicio: BsModalService,
    private service: RestauranteService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
    this.cargarMotivos();
    this.cargarDatos();
  }

  date = new FormControl(moment());

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.cargarDatos();
  }

  cargarDatos() {
    let periodo = this.formatearFechaString(this.date.value._d);
    this.service.obtenerFaltas(periodo).subscribe((result) => {
      console.log(result);
      for (let i = 0; i < result.length; i++) {
        result[i].fecha_falta = this.formatearFechaDatepicker(
          result[i].fecha_falta
        );
      }

      this.empleados = result;
    });
  }

  //Abre el modal
  abrirModalNuevo() {
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<-- Datos enviados desde componente hacia el modal
        '',
      ],
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo

    this.modalReferencia = this.modalServicio.show(ModalNominaComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });
    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((res) => {
      let empleado = {
        empleado: res.data.codigo,
        nomemp: res.data.nombre,
        comentario: '',
        fecha_falta: this.formatearFechaDatepicker(),
        tipo_falta: this.motivos[0].codigo,
      };
      console.log(empleado);
      this.empleados.push(empleado);
    });
  }

  cargarMotivos() {
    this.service.getConfiguraciones('012', this.empresa).subscribe((result) => {
      console.log(result);
      if (result.length > 0) this.motivo = result[0].codigo;
      result.forEach((element) => {
        let mini = {
          codigo: element.codigo,
          nombre: element.nombre,
        };
        this.motivos.push(mini);
      });
    });
  }

  grabar() {
    this.spinner.show();
    let datos = {
      periodo: this.formatearFechaString(this.date.value._d),
      empleados: this.empleados,
    };

    for (let i = 0; i < datos.empleados.length; i++) {
      datos.empleados[i].fecha_falta = this.formatearFechaQuery(
        datos.empleados[i].fecha_falta
      );
    }
    console.log(datos);

    this.service.registroFaltas(datos).subscribe((data) => {
      if (data.exito) {
        this.cargarDatos();
        Swal.fire({
          icon: 'success',
          title: '¡Transacción exitosa!',
          text: 'La operación se ha realizado exitosamente',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: '¡Transacción fallida!',
          text: 'La operación no ha podido completarse',
        });
      }
      this.spinner.hide();
    });
  }

  formatearFechaQuery(fecha = new Date()) {
    let fechaFin = new Date(fecha);
    let fechaTotal = fechaFin.toJSON().slice(0, 19);
    return fechaTotal;
  }

  formatearFechaDatepicker(fecha = new Date()) {
    let fechaFin = new Date(fecha);
    let fechaTotal = fechaFin.toJSON().slice(0, 10);
    return fechaTotal;
  }

  formatearFechaString(fecha = new Date(), hora = false) {
    //new Date().toJSON().slice(0,10).split('-').reverse().join('/')
    let fechaFin = new Date(fecha);
    let fechaString = fechaFin
      .toJSON()
      .slice(0, 10)
      .split('-')
      .reverse()
      .join('/');
    if (hora) {
      //Formatear hora
      return fechaString;
    }
    return fechaString;
  }

  eliminar(indice) {
    Swal.fire({
      title: '¿Desea eliminar a este empleado?',
      showDenyButton: true,
      confirmButtonText: `Sí`,
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.empleados.splice(indice, 1);

        Swal.fire('¡Empleado eliminado con éxito!', '', 'success');
      }
    });
  }
}
