import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalConfiguracionComponent } from '../modal-configuracion/modal-configuracion.component';
import { ModalConfiguracionaddComponent } from '../modal-configuracionadd/modal-configuracionadd.component';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.css'],
})
export class ConfiguracionComponent implements OnInit {
  //Referencia del modal
  modalReferencia: BsModalRef;
  //Datos leidos al cargar el componente
  datosTco: any;
  //TCO escogido
  tcoEscogido: any;
  //Bandera para spinner
  banderaSpinner: boolean = false;
  //Comentario
  comentario: String;
  //Items traidos desde la DB para el tipo escogido
  items: any = [];
  //Verifica que hay item
  hayItem: boolean;
  //Bandera para saber si son datos filtrados o todos
  banderaItems: boolean; //<-- true todos y false filtro
  //Variable de evento de pagina
  p: Number = 1;
  empresa: any;

  constructor(
    private servicio: RestauranteService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private modalServicio: BsModalService
  ) {}

  ngOnInit(): void {
    if (!localStorage.getItem('usuario')) {
      this.router.navigate(['login']);
    }
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
    console.log('empresa obtenida', this.empresa);
    this.spinner.show();
    this.servicio.getConfiguraciones('001', this.empresa).subscribe((data) => {
      this.datosTco = data;
      this.datosTco.forEach((datoTco) => {
        datoTco.codigo = datoTco.codigo.trim();
        datoTco.nombre = datoTco.nombre.trim();
      });
      this.tcoEscogido = this.datosTco[0];
      this.getCONF(this.tcoEscogido.codigo);
    });
    setInterval(() => {
      if (this.banderaSpinner === true) {
        this.spinner.hide();
        this.banderaSpinner = false;
      }
    }, 100);
  }

  //Traer los items de un tipo escogido
  getCONF(tcoEscogido) {
    this.spinner.show();
    this.servicio
      .getConfiguraciones(tcoEscogido, this.empresa)
      .subscribe((data) => {
        console.log(data);
        if (data.length === 0) {
          this.hayItem = false;
        } else {
          this.items = data;
          this.items.forEach((item) => {
            item.codigo = item.codigo.trim();
            item.nombre = item.nombre.trim();
            item.clave = item.clave.trim();
            item.findemes = item.findemes.toFixed('2').split('.');
            item.quincena = item.quincena.toFixed('2').split('.');
          });
          this.hayItem = true;
        }
        this.banderaSpinner = true;
        this.banderaItems = true; //<--Los datos actuales son todos
      });
  }

  //Actualiza en base a la opcion escogida en la combobox para hacer una nueva llamada a la API
  actualizaTcoEscogido() {
    let id = this.tcoEscogido.codigo.trim();
    this.getCONF(id);
  }

  abrirModalAgregarConfiguracion() {
    let nombreTco = this.tcoEscogido;
    console.log(nombreTco);
    //Este modal recibe el id del del tco
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<--Datos enviados desde componente hacia modal
        nombreTco, //<--nombre tco
      ],
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferencia = this.modalServicio.show(
      ModalConfiguracionaddComponent,
      {
        initialState,
        ignoreBackdropClick: true,
        class: 'modal-md',
      }
    );

    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((res) => {
      console.log('MODAL CERRADO, DATA RECIBIDA', res.data);

      if (res.data) {
        //Crea el elemento en el temporal y en la DB
        this.creaItemArray(this.items, res.data);
      }
    });
  }

  abrirModalOpciones(item) {
    item.codigoTco = this.tcoEscogido.codigo.trim();
    item.nombreTco = this.tcoEscogido.nombre.trim();
    //Este modal recibe el id del item referente al tco
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<--Datos enviados desde componente hacia modal
        item, //<--item escogido
      ],
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferencia = this.modalServicio.show(
      ModalConfiguracionComponent,
      {
        initialState,
        ignoreBackdropClick: true,
        class: 'modal-md',
      }
    );

    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((res) => {
      console.log('MODAL CERRADO, DATA RECIBIDA', res.data);
      if (res.data[0] === 1) {
        //Actualiza el elemento en el temporal y en la DB
        this.actualizaItemArray(res.data[1]);
      }
      if (res.data[0] === 2) {
        //Elimina el elemento en el temporal y en la DB
        this.eliminaItemArray(res.data[1]);
      }
    });
  }

  //Busca cliente en array de items y lo devuelve

  crearUsuario(item) {
    let datos = {
      codigoTco: this.tcoEscogido.codigo,
      nombre: item.nombre,
      sucursal: item.sucursal,
      clave: item.clave,
      admin: item.admin,
    };
    this.spinner.show();
    this.servicio.creaItemTCO(datos).subscribe((data) => {
      if (data.codigo) {
        item.codigo = data.codigo;
        this.items.push(item);
      }
      this.banderaSpinner = true;
    });
  }

  //Crea el elemento en el array
  creaItemArray(arreglo, item) {
    if (item.fecha_ingreso == '') {
      item.fecha_ingreso = new Date();
    }

    let datos = {
      codigoTco: this.tcoEscogido.codigo,
      ruc: item.ruc,
      nombre: item.nombre,
      direccion: item.direccion,
      telefono: item.telefono,
      correo: item.correo,
      quincena: item.quincena.join('.'),
      fin_mes: item.findemes.join('.'),
      fecha_ingreso: this.formatearFechaQuery(item.fecha_ingreso),
      empresa: this.empresa,
      sucursal: item.sucursal,
      clave: item.clave,
      admin: item.admin,
      vigencia: item.vigencia,
      porc: item.porc,
      perfil: item.perfil,
    };
    this.spinner.show();
    this.servicio.creaItemTCO(datos).subscribe((data) => {
      if (data.codigo) {
        item.codigo = data.codigo;
        arreglo.push(item);
      }
      this.banderaSpinner = true;
    });
  }

  //Actualiza elemento en el array
  actualizaItemArray(item) {
    let datos = {
      codigoTco: this.tcoEscogido.codigo,
      codigo: item.codigo,
      ruc: item.ruc,
      nombre: item.nombre,
      direccion: item.direccion,
      telefono: item.telefono,
      correo: item.correo,
      quincena: item.quincena.join('.'),
      fin_mes: item.findemes.join('.'),
      fecha_ingreso: this.formatearFechaQuery(item.fecha_ingreso),
      sucursal: item.sucursal,
      clave: item.clave,
      admin: item.admin,
      vigencia: item.vigencia,
      porc: item.porc,
      perfil: item.perfil,
    };
    this.spinner.show();
    //ACTUALIZAR EN DB
    this.servicio.actualizaItemTCO(datos).subscribe((data) => {
      if (data.data === 'Dato actualizado exitosamente') {
        this.getCONF(this.tcoEscogido.codigo);
      }
      this.banderaSpinner = true;
    });
  }

  //Borra elemento del array
  eliminaItemArray(item) {
    console.log(item);
    this.spinner.show();
    this.servicio
      .eliminaItemTCO(this.tcoEscogido.codigo, item.codigo)
      .subscribe((data) => {
        console.log(data);
        if (data.data === 'Dato eliminado exitosamente') {
          this.getCONF(this.tcoEscogido.codigo);
        }
        this.banderaSpinner = true;
      });
  }

  //Busqueda de item en la API
  busquedaItem() {
    let texto = this.comentario;
    console.log(texto);
    if (texto != '') {
      //<-- Si no está vacío coge el texto y busca
      if (texto.length > 3) {
        this.spinner.show();
        this.servicio
          .buscarItemTco(this.tcoEscogido.codigo, texto)
          .subscribe((data) => {
            if (data.length === 0) {
              this.hayItem = false;
            } else {
              this.items = data;
              this.items.forEach((item) => {
                item.codigo = item.codigo.trim();
                item.nombre = item.nombre.trim();
                this.hayItem = true;
              });
              this.p = 1;
            }
            this.banderaSpinner = true;
            this.banderaItems = false; //<--Los datos actuales son filtrados
          });
      }
    } else {
      //<--Si esta vacio trae todos los registros
      if (this.banderaItems === false) {
        this.getCONF(this.tcoEscogido.codigo);
      }
    }
  }

  cargarConfiguracion(index) {
    if (this.tcoEscogido.nombre != '001 - Configuraciones') return;
    this.tcoEscogido = this.datosTco[index];
    console.log(this.datosTco[index]);
    this.getCONF(this.datosTco[index].codigo);
  }

  formatearFechaQuery(fecha = new Date(), hora = '00:00:00') {
    let fechaFin = new Date(fecha);
    let fechaTotal = fechaFin.toJSON().slice(0, 10);
    return fechaTotal + ' ' + hora;
  }
}
