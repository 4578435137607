declare var require: any; //<-- Para poder importar archivos JS en TS

import { Component, OnInit, EventEmitter } from '@angular/core';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalListadoCuentasComponent } from '../modal-listado-cuentas/modal-listado-cuentas.component';
import Swal from 'sweetalert2';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-cuentas',
  templateUrl: './modal-cuentas.component.html',
  styleUrls: ['./modal-cuentas.component.css'],
})
export class ModalCuentasComponent implements OnInit {
  datos: Object; //<-- Debe tener el mismo nombre de la variable en cuenta
  cuenta: any = {}; //<-- Aqui se guardan los datos traidos desde cuenta
  cuentaCrear: any = {
    empresa: '',
    codigo: '',
    sucursal: '',
    superior: '',
    nombre: '',
    tipo: '',
    tipcta: '',
    clase: '',
    bloqueado: false,
  }; //<-- Aqui se guarda el nuevo cuenta creado
  cuentaDatosOriginales: any; //<--Aqui se copian los datos que estan en cuenta

  public event: EventEmitter<any> = new EventEmitter(); //<-- Para devolver datos a cuenta
  habilitarEstado: boolean = true; //<-- Si es true en la template se deshabilitan los inputs
  operacion: Number; //<--Indica si es 1 crear, 2 actualizar o 3 borrar
  titulo: String = 'Detalle : ';
  subtitulo: String = 'Cuenta Contable';
  ocultarBotonesPie: boolean = true;
  ocultarBotones: boolean = false;
  ocultarCodigo: boolean = false;
  banderaSpinner: boolean = false;
  tipo: string = '';
  empresa: string = '';
  sucursal: string = '';

  tiposcuentas: any;
  tipos: any;
  clases: any;
  busqmodalReferencia: BsModalRef<ModalListadoCuentasComponent>;

  constructor(
    public modalReferencia: BsModalRef,
    private modalServicio: BsModalService,
    private servicio: RestauranteService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
    if (JSON.parse(localStorage.getItem('sucursal')) !== null) {
      this.sucursal = JSON.parse(localStorage.getItem('sucursal'))['codigo'];
    }
    this.cuenta = this.datos[0];
    this.tipo = this.datos[1];
    this.subtitulo = this.cuenta.nombre;

    console.log('DATOS TRAIDOS DESDE CUENTA', this.cuenta);

    this.cuentaDatosOriginales = JSON.parse(JSON.stringify(this.cuenta));

    this.cargarClase();
    this.cargarTipo();
    this.cargarTipoCuenta();
  }

  actualizarCuenta() {
    this.habilitarEstado = false;
    this.operacion = 2;
    this.titulo = 'Actualizar : ';
    this.subtitulo = this.cuenta.nombre;
    this.ocultarBotonesPie = false;
    this.ocultarCodigo = false;
    this.ocultarBotones = true;
    //Obtiene Cuentas
    this.cuenta = JSON.parse(JSON.stringify(this.cuentaDatosOriginales));
  }

  crearCuenta() {
    this.habilitarEstado = false;
    this.operacion = 1;
    this.titulo = 'Nueva : ';
    this.subtitulo = 'Cuenta Contable';
    this.ocultarBotones = true;
    this.ocultarBotonesPie = false;
    this.ocultarCodigo = true;
    //Obtiene cuenta
    this.cuenta = JSON.parse(JSON.stringify(this.cuentaCrear));
  }

  enviarCuenta() {
    //Obtiene cuenta
    let codigo = this.cuenta.codigo;
    let superior = this.cuenta.superior;
    let nombre = this.cuenta.nombre;

    //Valida
    if (codigo == '' || superior == '' || nombre == '') {
      //No escogio todo
      Swal.fire('¡Error!', 'Por favor, llene todos los campos', 'error');
      return;
    }

    //<-- Al crear o actualizar

    if (this.operacion === 1 || this.operacion === 2) {
      if (this.operacion === 1) {
        //Devuelve el Cuenta
        this.spinner.show();
        if (this.tipo == 'Cuenta') {
          this.servicio.buscarCuenta(codigo).subscribe((data) => {
            //Si ya existe
            if (data.length > 0) {
              Swal.fire(
                '¡Error!',
                'Este CODIGO ya se encuentra registrado.',
                'error'
              );
            } else {
              Swal.fire('¡Éxito!', 'Operación exitosa.', 'success').then(() => {
                this.cerrarModal(this.operacion); //<-- 1 significa crear y 2 actualizar
              });
            }
            this.spinner.hide();
          });
        }
      } else if (this.operacion == 2) {
        Swal.fire('¡Éxito!', 'Operación exitosa.', 'success').then(() => {
          this.cerrarModal(this.operacion); //<-- 1 significa crear y 2 actualizar
        });
      }
    }
  }

  confirmaBorrar() {
    Swal.fire({
      icon: 'error',
      title: '¿Deseas Eliminar?',
      text: 'A: ' + this.cuenta.nombre.trim(),
      showDenyButton: true,
      confirmButtonText: `Sí`,
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.operacion = 3;
        Swal.fire('¡Cuenta eliminado!', '', 'success').then(() => {
          this.cerrarModal(this.operacion); //<-- 3 significa eliminar
        });
      }
    });
  }

  cerrarModal(valor) {
    if (valor === 1) {
      //<-- Al crear
      this.lanzarEvento(this.cuenta, valor); //<-- Envia la data a Cuenta
    } else {
      if (valor === 2 || valor === 3) {
        //<-- Al actualizar o borrar
        this.lanzarEvento(this.cuenta, valor); //<-- Envia la data a Cuenta
      }
    }
    this.modalReferencia.hide(); //<-- Cierra el modal
  }

  lanzarEvento(item, valor) {
    this.event.emit({ data: [valor, item], res: 200 });
  }

  cancelar() {
    this.titulo = 'Detalle :';
    //Obtiene Cuenta y rellena los campos
    this.habilitarEstado = true;
    this.ocultarBotonesPie = true;
    this.ocultarCodigo = false;
    this.ocultarBotones = false;
    this.cuenta = JSON.parse(JSON.stringify(this.cuentaDatosOriginales));
    this.subtitulo = this.cuenta.nombre;
  }

  cargarTipo() {
    this.servicio
      .getConfiguraciones('033', this.empresa)
      .subscribe((result) => {
        console.log(result);
        if (result.length > 0) {
          this.tipos = result;
        }
      });
  }

  cargarTipoCuenta() {
    this.servicio
      .getConfiguraciones('031', this.empresa)
      .subscribe((result) => {
        console.log(result);
        if (result.length > 0) {
          this.tiposcuentas = result;
        }
      });
  }

  cargarClase() {
    this.servicio
      .getConfiguraciones('032', this.empresa)
      .subscribe((result) => {
        console.log(result);
        if (result.length > 0) {
          this.clases = result;
        }
      });
  }

  //Buscar cuenta en API
  busquedaCuenta() {
    let texto = this.cuenta.superior;
    console.log(texto);
    if (texto != '') {
      if (texto.length > 0) {
        console.log('modal listado de cuentas');
        const initialState: any = {
          datos: [texto],
        };
        this.busqmodalReferencia = this.modalServicio.show(
          ModalListadoCuentasComponent,
          {
            initialState,
            ignoreBackdropClick: true,
            class: 'modal-xl',
          }
        );
        //Al cerrar el modal recibe la data
        this.busqmodalReferencia.content.event.subscribe((res) => {
          this.cuenta.superior = res.data['codigo'];
          this.cuenta.nom_superior = res.data['nombre'];
          this.buscarCuentaSig(res.data['codigo']);
        });
      }
    }
  }

  buscarCuentaSig(cod_superior){
    let consulta = {
      texto: cod_superior,
      usuario: JSON.parse(localStorage.getItem('usuario')).codigo,
      empresa: this.empresa,
    };
    console.log(consulta);
    this.servicio.getCuentas(consulta).subscribe((data) => {
      console.log('DATA BUSCARCUENTASIG',data)
      if (data.length === 0) {
        this.cuenta.codigo = data.codigo
      } else {
        this.cuenta.codigo = cod_superior+'001'
      }
    });
  }
}
