<div class="mt-2">
  <div class="container mb-3">
    <div class="row justify-content-center align-items-center">
      <div
        class="col-11 col-md-8 col-xl-8 text-center text-md-left text-xl-center"
      >
        <h3>
          Biblioteca
          <small class="text-muted"> de Distribuidores</small>
        </h3>
      </div>

      <div class="col-11 col-md-8 col-xl-3 text-center text-md-left">
        <div class="input-group">
          <input
            type="text"
            class="form-control form-control-sm"
            placeholder="Buscar Nombre | Codigo"
            aria-label="Busqueda"
            aria-describedby="basic-addon1"
            (keyup)="busquedaDistribuidor($event)"
          />
          <div class="input-group-append">
            <span class="input-group-text" id="basic-addon1"
              ><i class="fab fa-searchengin"></i
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!---->
<div class="container-fluid">
  <div
    class="table-responsive"
    *ngIf="hayDistribuidor === true; else sinClientes"
  >
    <table class="table table-striped table-sm table-hover m-0 p-0">
      <thead>
        <tr>
          <th
            scope="col text-center"
            style="width: 50px !important; text-align: center"
          ></th>
          <th scope="col" style="min-width: 25px">Bloqueado</th>
          <th scope="col" style="min-width: 150px">Identificación</th>
          <th scope="col" style="min-width: 250px">Nombre</th>
          <th scope="col" style="min-width: 250px">Dirección</th>
          <th scope="col" style="min-width: 250px">Email</th>
          <th scope="col" style="min-width: 150px">Teléfonos</th>

          <th scope="col" style="min-width: 25px">Verificado</th>
        </tr>
      </thead>

      <tbody>
        <tr
          *ngFor="
            let distribuidor of distribuidores
              | paginate
                : {
                    itemsPerPage: 25,
                    currentPage: p,
                    totalItems: distribuidores.count
                  }
          "
        >
          <td>
            <button
              type="button"
              class="btn btn-outline-dark btn-sm"
              (click)="abrirModalDetallesDistribuidor(distribuidor)"
            >
              <i class="far fa-eye"></i>
            </button>
          </td>
          <td>
            <i
              class="fas fa-ban text-danger"
              *ngIf="distribuidor.bloqueado == true"
            >
              BLOQUEADO</i
            >
          </td>
          <td>{{ distribuidor.cedula }}</td>
          <td>{{ distribuidor.nombre }}</td>
          <td>{{ distribuidor.direccion }}</td>
          <td>{{ distribuidor.correo }}</td>
          <td>{{ distribuidor.telefono }}</td>

          <td>
            <i
              class="fas fa-ban text-danger"
              *ngIf="distribuidor.verificado != true"
            >
            </i>
            <i
              class="fas fa-check-circle text-success"
              *ngIf="distribuidor.verificado == true"
            >
              Verificado</i
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="container-fluid" *ngIf="hayDistribuidor === true">
  <div class="row justify-content-center align-items-center mt-4 mb-3">
    <div class="col-12" style="text-align: center">
      <pagination-controls
        (pageChange)="p = $event"
        autoHide="true"
        previousLabel="Anterior"
        nextLabel="Siguiente"
      >
      </pagination-controls>
    </div>
  </div>
</div>

<ng-template #sinClientes>
  <ng-container>
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-6 mt-5" style="text-align: center">
          <lottie-player
            src="https://assets2.lottiefiles.com/temp/lf20_TOE9MF.json"
            background="transparent"
            speed="1"
            loop
            autoplay
          ></lottie-player>
          <h3>
            Sin Resultados
            <small class="text-muted"> de Distribuidor</small>
          </h3>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<app-spinner></app-spinner>
