import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css'],
})
export class PageNotFoundComponent implements OnInit {
  empresa: any = {
    nombre: '',
    color: '',
    urlLogo: '',
  };

  constructor() {}

  ngOnInit(): void {
    if (localStorage.getItem('empresa'))
      this.empresa = JSON.parse(localStorage.getItem('empresa'));
  }
}
