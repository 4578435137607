import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-crud-activo',
  templateUrl: './modal-crud-activo.component.html',
  styleUrls: ['./modal-crud-activo.component.css'],
})
export class ModalCRUDActivoComponent implements OnInit {
  years: any = [];
  yearSelected: number;
  tiposActivo: any = [];
  tipoActivo: string = '';

  nombre: string = '';
  serie: string = '';
  valor: any = [0, 0];
  fechaCompra: any;
  habilitado: boolean = true;
  comentario: string = '';

  tipo_consulta: string;
  datosActivo: any;

  ocultarBotones: boolean = false;

  campos_bloquear = false;

  public event: EventEmitter<any> = new EventEmitter(); //<-- Para devolver datos a cliente
  empresa: any;
  constructor(
    public modalReferencia: BsModalRef,
    private servicio: RestauranteService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }

    console.log(this.tipo_consulta);
    console.log(this.datosActivo);
    let year = new Date().getFullYear();
    this.yearSelected = year;
    for (let i = year - 5; i < year + 6; i++) {
      this.years.push(i);
    }
    console.log(this.years);
    this.cargarTiposActivo();

    if (this.tipo_consulta == 'create') {
      this.ocultarBotones = true;
    } else if (this.tipo_consulta == 'read') {
      this.campos_bloquear = true;
      this.cargarDatosPrevios();
      return;
    }

    var date = new Date();
    var dateString = new Date(date.getTime()).toISOString().split('T')[0];
    console.log(dateString);

    this.fechaCompra = dateString;
  }

  cargarDatosPrevios() {
    this.empresa = this.datosActivo.empresa;
    this.yearSelected = this.datosActivo.anio_compra;
    this.tipoActivo = this.datosActivo.tipoactivofijo;
    this.nombre = this.datosActivo.nombre.trim();
    this.serie = this.datosActivo.serie.trim();
    this.valor = (this.datosActivo.valor + '').split('.');
    var date = new Date(this.datosActivo.fecha_compra);
    var dateString = new Date(date.getTime()).toISOString().split('T')[0];
    this.fechaCompra = dateString;
    this.habilitado = this.datosActivo.habilitado;
    this.comentario = this.datosActivo.comentario.trim();
  }

  cargarTiposActivo() {
    this.servicio
      .getConfiguraciones('005', this.empresa)
      .subscribe((result) => {
        console.log(result);
        this.tiposActivo = result;
        if (this.tiposActivo.length > 0)
          this.tipoActivo = this.tiposActivo[0].codigo;
        console.log(this.tipoActivo);
      });
  }

  validar() {
    if (this.nombre == '') {
      this.alerta('Faltan campos', 'Por favor ingrese un nombre', 'warning');
      return false;
    }
    if (this.valor[0] == '' && this.valor[1] == '') {
      this.alerta('Faltan campos', 'Por favor ingrese un valor', 'warning');
      return false;
    }
    if (this.serie == '') {
      this.alerta('Faltan campos', 'Por favor ingrese un serie', 'warning');
      return false;
    }

    return true;
  }

  guardarActivoFijo() {
    if (!this.validar()) return;

    let data = {
      empresa: this.empresa,
      tipoActivo: this.tipoActivo,
      nombre: this.nombre,
      valor: parseFloat(this.valor[0] + '.' + this.valor[1]),
      serie: this.serie,
      fechaCompra: this.formatearFechaQuery(this.fechaCompra),
      campania: this.yearSelected,
      habilitado: this.habilitado,
      comentario: this.comentario,
    };
    console.log(data);
    this.spinner.show();
    this.servicio.registrarActivoFijo(data).subscribe(
      (result) => {
        this.spinner.hide();
        this.alerta(
          'Exito',
          'El Activo fijo se registro correctamente',
          'success'
        ).then(() => this.cerrarModal());
      },
      (err) => {
        this.spinner.hide();
        this.alerta(
          'Error',
          'El Activo fijo no se registro correctamente',
          'error'
        );
      }
    );
  }

  actualizarActivoFijo() {
    if (!this.validar()) return;
    let data = {
      idActivo: this.datosActivo.id_activofijo,
      empresa: this.empresa,
      tipoActivo: this.tipoActivo,
      nombre: this.nombre,
      valor: parseFloat(this.valor[0] + '.' + this.valor[1]),
      serie: this.serie,
      fechaCompra: this.formatearFechaQuery(this.fechaCompra),
      campania: this.yearSelected,
      habilitado: this.habilitado,
      comentario: this.comentario,
    };
    console.log('DESDE FRONTEND', data);
    this.spinner.show();
    this.servicio.actualizarActivoFijo(data).subscribe(
      (result) => {
        this.spinner.hide();
        this.alerta(
          'Exito',
          'El Activo fijo se actualizó correctamente',
          'success'
        ).then(() => this.cerrarModal());
      },
      (err) => {
        this.spinner.hide();
        this.alerta(
          'Error',
          'El Activo fijo no se actualizó correctamente',
          'error'
        );
      }
    );
  }

  async eliminar() {
    let confirmar = await this.alertaConfirmacion(
      '¿Desea eliminar este activo fijo?'
    );
    if (!confirmar) return;
    let data = {
      id: this.datosActivo.id_activofijo,
    };
    console.log(data);
    this.spinner.show();
    this.servicio.eliminarActivoFijo(data).subscribe(
      (result) => {
        this.spinner.hide();
        this.alerta(
          'Exito',
          'El Activo fijo se eliminó correctamente',
          'success'
        ).then(() => this.cerrarModal());
      },
      (err) => {
        this.spinner.hide();
        this.alerta(
          'Error',
          'El Activo fijo no se eliminó correctamente',
          'error'
        );
      }
    );
  }

  crear() {
    this.ocultarBotones = true;
    this.tipo_consulta = 'create';
    this.campos_bloquear = false;
    //this.limpiarCampos();
  }

  actualizar() {
    this.tipo_consulta = 'update';
    this.campos_bloquear = false;
  }

  limpiarCampos() {
    let year = new Date().getFullYear();
    this.yearSelected = year;
    this.empresa = '';
    this.tipoActivo = this.tiposActivo[0].codigo;
    this.nombre = '';
    this.serie = '';
    this.valor = [0, 0];
    var date = new Date();
    var dateString = new Date(date.getTime()).toISOString().split('T')[0];
    this.fechaCompra = dateString;
    this.habilitado = false;
    this.comentario = '';
  }

  cerrarModal() {
    this.lanzarEvento(''); //<-- Envia la data a cliente
    this.modalReferencia.hide(); //<-- Cierra el modal
  }

  lanzarEvento(item) {
    this.event.emit({ data: item, res: 200 });
  }

  cancelar() {
    this.modalReferencia.hide();
  }

  formatearFechaQuery(fecha = new Date()) {
    let fechaFin = new Date(fecha);
    let fechaTotal = fechaFin.toJSON().slice(0, 19);
    return fechaTotal;
  }

  alerta(titulo, texto, icono) {
    return Swal.fire({
      icon: icono,
      title: titulo,
      text: texto,
      allowOutsideClick: false,
    });
  }

  async alertaConfirmacion(titulo) {
    return Swal.fire({
      title: titulo,
      showDenyButton: true,
      confirmButtonText: 'Si',
      denyButtonText: 'No',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        return true;
      } else if (result.isDenied) {
        return false;
      }
    });
  }
}
