import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-fecha-caducidad',
  templateUrl: './modal-fecha-caducidad.component.html',
  styleUrls: ['./modal-fecha-caducidad.component.css'],
})
export class ModalFechaCaducidadComponent implements OnInit {
  datos: Object[]; //<-- Debe tener el mismo nombre de la variable en producto
  fecha;
  public event: EventEmitter<any> = new EventEmitter(); //<-- Para devolver datos a producto
  constructor(public modalReferencia: BsModalRef) {}

  ngOnInit(): void {
    let fecha = this.datos[0];
    if (fecha != '') {
      this.fecha = this.formatearFechaQuery(new Date(fecha + ''));
    } else this.fecha = this.formatearFechaQuery();
    console.log(this.fecha);
  }
  lanzarEvento() {
    this.event.emit({ data: this.fecha, res: 200 }); //<-- Internamente envia la data a producto
    this.modalReferencia.hide();
  }

  formatearFechaString(fecha = new Date(), hora = false) {
    //new Date().toJSON().slice(0,10).split('-').reverse().join('/')
    let fechaFin = new Date(fecha);
    let fechaString = fechaFin
      .toJSON()
      .slice(0, 10)
      .split('-')
      .reverse()
      .join('/');
    if (hora) {
      //Formatear hora
      return fechaString + ' ' + fechaFin.toJSON().slice(11, 19);
    }
    return fechaString;
  }

  formatearFechaQuery(fecha = new Date()) {
    let fechaFin = new Date(fecha);
    let fechaTotal = fechaFin.toJSON().slice(0, 10);
    return fechaTotal;
  }
}
