import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FacturaComponent } from '../factura/factura.component'; //<--Modal factura
import { ModalUnirComponent } from '../modal-unir/modal-unir.component'; //<--Modal unir
import Swal from 'sweetalert2';
import { ServicioMeseroService } from 'src/app/services/servicio-mesero/servicio-mesero.service';

@Component({
  selector: 'app-pedidosmesas',
  templateUrl: './pedidosmesas.component.html',
  styleUrls: ['./pedidosmesas.component.css'],
})
export class PedidosmesasComponent implements OnInit {
  //Referencia del modal
  modalReferencia: BsModalRef;
  pedidos: any = []; //<--Contiene todos los pedidos representantes de cada mesa
  estados: any = { creado: '#56a419', unido: 'yellow', dividido: 'pink' };
  usuario: any;

  sucursales: any;
  sucursal: string = 'TODOS';
  admin: boolean = false;
  empresa: any;

  constructor(
    private router: Router,
    private modalServicio: BsModalService,
    private servicio: ServicioMeseroService
  ) {}

  ngOnInit(): void {
    //Verifica que este logeado
    if (!localStorage.getItem('usuario')) {
      this.router.navigate(['login']);
      return;
    }
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    //this.cargarPedidos();

    setInterval(() => {
      this.cargarMesas();
    }, 60000);

    this.admin = JSON.parse(localStorage.getItem('usuario')).admin;
    console.log(this.admin);
    if (this.admin) this.cargarSucursales();
    else this.sucursal = JSON.parse(localStorage.getItem('sucursal')).codigo;
    this.cargarMesas();
  }

  cargarMesas() {
    let campos = {
      empresa: JSON.parse(localStorage.getItem('empresa')).codigo,
      sucursal: this.sucursal,
    };
    console.log(campos);
    this.servicio.getMesas(campos).subscribe((result) => {
      let i = 0;
      console.log(result)

      result.forEach((element) => {
        element.estado = this.estados.creado;
        element.estadoAdentro = 'orange';
        element.estadoAfuera = this.estados.creado;
        element.pedido = [];
        element.ruc = '9999999999999';
        element.nuevo = false;
        element.productos = [];
        let b = true;
        this.pedidos.forEach((element2) => {
          if (element.mesa == element2.mesa) {
            console.log(element2);
            element2.items = element.items;
            element2.total = element.total;
            element2.numero = element.numero;
            element2.nomcli = element.nomcli;
            element2.empresa = element.empresa;
            element2.tipo = element.tipo;
            element2.modulo = element.modulo;
            element2.sucursal = element.sucursal;

            b = false;
          }
        });
        if (b) {
          this.pedidos.push(element);
        }
        this.cargarMovimiento(element, i);
        i++;
      });

      //this.pedidos = result;
      console.log(result);
      console.log(this.pedidos);
    });
  }

  cambiarSucursal() {
    this.pedidos = [];

    this.cargarMesas();
  }

  cambiaColor(producto) {
    producto.estado = producto.estadoAdentro;
  }

  sinColor(producto) {
    producto.estado = producto.estadoAfuera;
  }

  //Abre el modal de factura
  abrirModalPedido(mesa, i) {
    //Este modal recibe el id de la mesa
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<--Datos enviados desde componente hacia modal
        mesa,
        i,
      ],
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferencia = this.modalServicio.show(FacturaComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-xl',
    });
    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((respuesta) => {
      console.log('MODAL DE FACTURA CERRADO');
      //Vuelvo a cargar todos los datos para que se actualicen en automatico
      this.pedidos[i].nuevo = false;

      this.cargarMesas();
    });
  }

  //Unir mesas
  unir() {
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<--Datos enviados desde componente hacia modal
        this.pedidos,
      ],
    };

    this.modalReferencia = this.modalServicio.show(ModalUnirComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-xl',
    });
    //Este modal no devuelve nada
    this.modalReferencia.content.event.subscribe(() => {
      //this.cargarMesas();
    });
  }

  //Mostrar alerta agregar numero mesa
  mostrarAlertaAddMesa() {
    Swal.fire({
      icon: 'question',
      title: 'Ingrese el número de la mesa',
      input: 'number',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: 'AGREGAR MESA',
      cancelButtonText: 'CANCELAR',

      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        //Caja de texto vacía
        if (result.value === '') {
          this.alerta(
            '¡Escoja una mesa por favor!',
            'Por favor, indique la mesa a utilizar.',
            'warning'
          );
        } else {
          let bandera = true;
          this.pedidos.forEach((element) => {
            if (element.mesa == result.value) {
              this.alerta(
                '¡Mesa ocupada!',
                'La mesa ' +
                  result.value +
                  ' ya está ocupada, por favor seleccione otra.',
                'warning'
              );
              bandera = false;
            }
          });
          if (bandera) this.mesaAgregada(parseInt(result.value));
        }
      }
    });
  }

  //Agrega la mesa como tal
  mesaAgregada(numero) {
    let pedido = {
      mesa: numero,
      total: 0,
      items: 0,
      ruc: '9999999999999',
      nomcli: 'Consumidor Final',
      estado: this.estados.creado,
      estadoAdentro: 'orange',
      estadoAfuera: this.estados.creado,
      nuevo: true,
      pedido: [],
    };

    this.pedidos.push(pedido);
  }

  //Valida entero de manera primitiva
  esEntero(numero) {
    if (numero === '0') {
      return false;
    }
    let numeros = /^[0-9]+$/;
    if (numero.match(numeros)) {
      return true;
    } else {
      return false;
    }
  }

  async alerta(titulo, mensaje, icono) {
    return Swal.fire({
      title: titulo,
      text: mensaje,
      icon: icono,
    });
  }

  cargarSucursales() {
    this.servicio
      .getConfiguraciones('015', this.empresa)
      .subscribe((result) => {
        console.log(result);
        if (result.length > 0) {
          this.sucursales = result;
        }
      });
  }

  cargarMovimiento(mesa, i) {
    let datos = {
      opcion: 1,
      idFactura: mesa.numero,
      tipo: mesa.tipo,
      sucursal: mesa.sucursal,
      modulo: mesa.modulo,
      empresa: mesa.empresa,
    };
    console.log(datos);

    this.servicio.verFactura(datos).subscribe((data) => {
      console.log('verFactura',data);
      if (this.pedidos[i]) this.pedidos[i].productos = data[1];
    });
  }
}
