import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-kardex-producto',
  templateUrl: './kardex-producto.component.html',
  styleUrls: ['./kardex-producto.component.css'],
})
export class KardexProductoComponent implements OnInit {
  campaignOne: FormGroup;
  //Referencia del modal
  modalReferencia: BsModalRef;
  //Datos traidos de la DB
  clientes: any = [];
  //Verifica que hay cliente
  hayCliente: boolean;
  //Variable de evento de pagina
  p: Number = 1;
  //Bandera para spinner
  banderaSpinner: boolean = false;
  //Bandera para saber si son datos filtrados o todos
  banderaClientes: boolean; //<-- true todos y false filtro
  busco_pedido = false;
  producto = '';
  kardex: any = [];
  saldo: any = { tipo: '', numero: 0, cantidad: 0, saldo: 0 };
  productos: any = [];
  totales = {
    label: 'TOTALES',
    cantidad: 0,
    saldo: 0,
    cantbod: 0,
    saldobod: 0,
  };
  last_opened = '';

  admin: boolean = false;
  sucursales: any;
  sucursal: string = 'todos';
  empresa: any;

  constructor(
    private router: Router,
    private service: RestauranteService,
    private spinner: NgxSpinnerService
  ) {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    const day = today.getDate();
    console.log(today);

    this.campaignOne = new FormGroup({
      start: new FormControl(new Date(year, month, day)),
      end: new FormControl(new Date(year, month, day)),
    });
  }

  ngOnInit(): void {
    if (!localStorage.getItem('usuario')) {
      this.router.navigate(['login']);
    }
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }

    //Verifica bandera para ocultar spinner
    setInterval(() => {
      if (this.banderaSpinner === true) {
        this.spinner.hide();
        this.banderaSpinner = false;
      }
    }, 100);

    this.admin = JSON.parse(localStorage.getItem('usuario')).admin;
    if (this.admin) this.cargarSucursales();
    else {
      this.sucursal = JSON.parse(localStorage.getItem('sucursal')).codigo;
      //this.cargarData();
      this.banderaSpinner = true;
    }
  }

  cargarData() {
    let campos = {
      desde: this.formatearFechaQuery(this.campaignOne.value.start.toJSON()),
      hasta: this.formatearFechaQuery(
        this.campaignOne.value.end.toJSON(),
        '23:59:59'
      ),
      sucursal: this.sucursal,
      empresa: JSON.parse(localStorage.getItem('empresa')).codigo,
    };
    //console.log(campos);
    this.spinner.show();
    this.service.obtenerKardexCompleto(campos).subscribe((result) => {
      this.kardex = result;
      //console.log(result);
      this.busco_pedido = true;
      this.banderaSpinner = true;
    });
  }

  actualizarData() {
    if (this.campaignOne.value.end) {
      if (this.busco_pedido) {
        this.cargarData();
      }
    }
  }

  consultarProducto(codigo) {
    if (document.getElementById('tabla_' + codigo).style.display == 'none') {
      document.getElementById('i_' + codigo).className = 'fas fa-chevron-down';
      document.getElementById('tabla_' + codigo).style.display = 'block';
      if (this.last_opened != '') {
        document.getElementById('i_' + this.last_opened).className =
          'fas fa-chevron-right';
        document.getElementById('tabla_' + this.last_opened).style.display =
          'none';
        this.last_opened = codigo;
      } else this.last_opened = codigo;
    } else {
      document.getElementById('i_' + codigo).className = 'fas fa-chevron-right';
      document.getElementById('tabla_' + codigo).style.display = 'none';
      this.last_opened = '';
    }
    let campos = {
      desde: this.formatearFechaQuery(this.campaignOne.value.start.toJSON()),

      hasta: this.formatearFechaQuery(
        this.campaignOne.value.end.toJSON(),
        '23:59:59'
      ),

      codigo: codigo,
      sucursal: this.sucursal,
      empresa: this.empresa,
    };

    this.service.obtenerKardexProducto(campos).subscribe((result) => {
      this.saldo = result[0];
      this.productos = result.slice(1);
      this.totales.cantidad = 0;
      this.totales.saldo = 0;
      this.totales.cantbod = 0;
      this.totales.saldobod = 0;

      this.productos.forEach((element) => {
        this.totales.cantidad += element.cantidad;
        this.totales.saldo += element.saldo;
        this.totales.cantbod += element.cantbod;
        this.totales.saldobod += element.saldobod;
      });
    });
  }

  formatearFechaString(fecha = new Date(), hora = false) {
    //new Date().toJSON().slice(0,10).split('-').reverse().join('/')
    let fechaFin = new Date(fecha);
    let fechaString = fechaFin
      .toJSON()
      .slice(0, 10)
      .split('-')
      .reverse()
      .join('/');
    if (hora) {
      //Formatear hora
      return fechaString;
    }
    return fechaString;
  }

  cargarSucursales() {
    this.service.getConfiguraciones('015', this.empresa).subscribe((result) => {
      console.log(result);
      if (result.length > 0) {
        this.sucursales = result;
        this.cargarData();
      }
    });
  }

  formatearFechaQuery(fecha = new Date(), hora = '00:00:00') {
    let fechaFin = new Date(fecha);
    let fechaTotal = fechaFin.toJSON().slice(0, 10);
    return fechaTotal + ' ' + hora;
  }
}
