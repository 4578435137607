import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//RAIZ

import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

import { MainLoginComponent } from './components/login/main-login/main-login.component';
import { LoginRestauranteComponent } from './components/login/login-restaurante/login-restaurante.component';
import { LoginMeseroComponent } from './components/login/login-mesero/login-mesero.component';

import { DashboardComponent } from '../app/components/restaurante/dashboard/dashboard.component';
import { EdicionMenuComponent } from './components/seguridad/edicion-menu/edicion-menu.component';
import { CambioClaveComponent } from './components/seguridad/cambio-clave/cambio-clave.component';

//BIBLIOTECAS

import { ProductoComponent } from './components/restaurante/bibliotecas/productos/producto.component';
import { ClienteComponent } from './components/restaurante/bibliotecas/cliente/cliente.component';
import { ProveedoresComponent } from './components/restaurante/bibliotecas/proveedores/proveedores.component';
import { DistribuidoresComponent } from './components/restaurante/bibliotecas/distribuidores/distribuidores.component';
import { ConfiguracionComponent } from '../app/components/restaurante/configuracion/configuracion.component';
import { CuentasComponent } from './components/restaurante/bibliotecas/cuentas/cuentas.component';

//INVENTARIO

import { KardexProductoComponent } from './components/restaurante/inventario/kardex-producto/kardex-producto.component';
import { InventarioComponent } from '../app/components/restaurante/inventario/registro-inventario/registro-inventario.component';
import { InformeDinamicoComponent } from './components/restaurante/inventario/informe-dinamico/informe-dinamico.component';
import { DiarioInventarioComponent } from './components/restaurante/inventario/diario-inventario/diario-inventario.component';

//COMPRAS

import { RegistroCompraComponent } from './components/restaurante/compras/registro-compra/registro-compra.component';
import { InformeDinamicoComprasComponent } from './components/restaurante/compras/informe-dinamico/informe-dinamico.component';
import { DiarioComprasComponent } from './components/restaurante/compras/diario-compras/diario-compras.component';

//CAJA
import { AperturaComponent } from './components/restaurante/caja/apertura/apertura.component';
import { CierreComponent } from './components/restaurante/caja/cierre/cierre.component';

//VENTAS

import { FacturaComponent } from './components/restaurante/ventas/factura/factura.component';
import { InformeDinamicoVentasComponent } from './components/restaurante/ventas/informe-dinamico/informe-dinamico.component';
import { DiarioVentasComponent } from './components/restaurante/ventas/diario-ventas/diario-ventas.component';

//PEDIDOS MOVIL

import { InformeDinamicoPmComponent } from './components/restaurante/pedidos/movil/informe-dinamico-pm/informe-dinamico-pm.component';
import { DiarioPedidosPmComponent } from './components/restaurante/pedidos/movil/diario-pedidos-pm/diario-pedidos-pm.component';

//COCINA

import { CocinaComponent } from './components/cocina/cocina/cocina.component';
import { AperturaCocinaComponent } from './components/cocina/apertura-cocina/apertura-cocina.component';

//PEDIDOS LOCAL

import { PedidosmesasComponent } from './components/mesero/pedidosmesas/pedidosmesas.component';

//CONTABILIDAD

import { ProvisionComponent } from './components/restaurante/contabilidad/provision/provision.component';

//ACTIVOS FIJOS

import { RegistroActivoComponent } from './components/restaurante/activo_fijo/registro-activo/registro-activo.component';
import { ConsultaActivoComponent } from './components/restaurante/activo_fijo/consulta-activo/consulta-activo.component';

//GASTOS

import { GastosComponent } from './components/restaurante/gastos/registro-gastos/gastos.component';
import { ReporteGastosComponent } from './components/restaurante/gastos/reporte-gastos/reporte-gastos.component';

//NOMINA

import { NominaEmpleadosComponent } from './components/restaurante/nomina/nomina-empleados/nomina-empleados.component';
import { ReporteEmpleadoComponent } from './components/restaurante/nomina/reporte-empleado/reporte-empleado.component';
import { FaltasComponent } from './components/restaurante/nomina/faltas/faltas.component';
import { ReporteFaltasComponent } from './components/restaurante/nomina/reporte-faltas/reporte-faltas.component';
import { ReporteMovimientoEmpleadosComponent } from './components/restaurante/reporte-movimiento-empleados/reporte-movimiento-empleados.component';

//FINANCIERO

import { InformePerdidaGananciaComponent } from './components/restaurante/informe-perdida-ganancia/informe-perdida-ganancia.component';

//PRODUCCION

import { OrdenComponent } from './components/restaurante/produccion/orden/orden.component';


//Importando rutas de las paginas
const routes: Routes = [
  { path: '', component: MainLoginComponent },
  { path: 'cocina', component: CocinaComponent },
  { path: 'cocina/apertura-cocina', component: AperturaCocinaComponent, },
  { path: 'mesero', component: PedidosmesasComponent },
  { path: 'mesero/login', component: LoginMeseroComponent },
  { path: 'restaurante/login', component: LoginRestauranteComponent },
  { path: 'restaurante/dashboard', component: DashboardComponent },
  { path: 'restaurante/bibliotecas/productos', component: ProductoComponent },
  { path: 'restaurante/bibliotecas/clientes', component: ClienteComponent },
  { path: 'restaurante/bibliotecas/proveedores', component: ProveedoresComponent, },
  { path: 'restaurante/bibliotecas/distribuidores', component: DistribuidoresComponent,},
  { path: 'restaurante/factura', component: FacturaComponent },
  { path: 'restaurante/inventario/registro-inventario', component: InventarioComponent,}, 
  { path: 'restaurante/ventas/informe-dinamico', component: InformeDinamicoVentasComponent,  },
  { path: 'restaurante/ventas/diario-ventas', component: DiarioVentasComponent, },
  { path: 'restaurante/pedidos/movil/informe-dinamico-pm', component: InformeDinamicoPmComponent, },
  { path: 'restaurante/pedidos/movil/diario-pedidos', component: DiarioPedidosPmComponent, },
  { path: 'restaurante/reportes/kardex-producto', component: KardexProductoComponent,},
  { path: 'restaurante/configuraciones', component: ConfiguracionComponent },
  { path: 'restaurante/nomina_empleados', component: NominaEmpleadosComponent },
  { path: 'restaurante/faltas', component: FaltasComponent },
  { path: 'restaurante/reporte-empleado', component: ReporteEmpleadoComponent },
  { path: 'restaurante/registro-compra', component: RegistroCompraComponent },
  { path: 'restaurante/compras/diario-compras', component: DiarioComprasComponent, },
  { path: 'restaurante/registro-activo', component: RegistroActivoComponent },
  { path: 'restaurante/consulta-activo', component: ConsultaActivoComponent },
  { path: 'restaurante/gastos', component: GastosComponent },
  { path: 'restaurante/reportes/gastos', component: ReporteGastosComponent },
  { path: 'restaurante/reportes/faltas', component: ReporteFaltasComponent },
  { path: 'restaurante/compras/informe-dinamico', component: InformeDinamicoComprasComponent, },
  { path: 'restaurante/reportes/movimiento-empleados', component: ReporteMovimientoEmpleadosComponent, },
  { path: 'restaurante/informe', component: InformePerdidaGananciaComponent },
  { path: 'proximamente', component: PageNotFoundComponent },
  { path: 'seguridad/editar-menu', component: EdicionMenuComponent },
  { path: 'seguridad/cambio-clave', component: CambioClaveComponent },
  { path: 'restaurante/bibliotecas/cuentas', component: CuentasComponent },
  { path: 'restaurante/contabilidad/provision', component: ProvisionComponent, },
  { path: 'restaurante/inventario/informe-dinamico', component: InformeDinamicoComponent, },
  { path: 'restaurante/inventario/diario-inventario', component: DiarioInventarioComponent, },
  { path: 'restaurante/caja/apertura', component: AperturaComponent, },
  { path: 'restaurante/caja/cierre', component: CierreComponent, },
  { path: 'restaurante/produccion/orden', component: OrdenComponent, },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
