declare var require: any; //<-- Para poder importar archivos JS en TS

import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
var validadorCedula = require('src/app/validadores/validarCedula.js');
var validadorRuc = require('src/app/validadores/validarRuc.js');
var validadorCorreo = require('src/app/validadores/validarCorreo.js');

@Component({
  selector: 'app-modal-clientes',
  templateUrl: './modal-clientes.component.html',
  styleUrls: ['./modal-clientes.component.css'],
})
export class ModalClientesComponent implements OnInit {
  datos: Object; //<-- Debe tener el mismo nombre de la variable en clientes
  cliente: any = {}; //<-- Aqui se guardan los datos traidos desde cliente
  clienteCrear: any = {
    ruc: '',
    nombre: '',
    direccion: '',
    telefono: '',
    correo: '',
    empresa: '',
    sucursal: '',
  }; //<-- Aqui se guarda el nuevo cliente creado
  clienteDatosOriginales: any; //<--Aqui se copian los datos que estan en cliente
  public event: EventEmitter<any> = new EventEmitter(); //<-- Para devolver datos a cliente
  habilitarEstado: boolean = true; //<-- Si es true en la template se deshabilitan los inputs
  operacion: Number; //<--Indica si es 1 crear, 2 actualizar o 3 borrar
  titulo: String = 'Detalle : ';
  subtitulo: String = 'Cliente';
  ocultarBotonesPie: boolean = true;
  ocultarBotones: boolean = false;
  ocultarCodigo: boolean = false;
  banderaSpinner: boolean = false;
  tipo: string = '';
  empresa: string = '';
  sucursal: string = '';

  constructor(
    public modalReferencia: BsModalRef,
    private servicio: RestauranteService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
    if (JSON.parse(localStorage.getItem('sucursal')) !== null) {
      this.sucursal = JSON.parse(localStorage.getItem('sucursal'))['codigo'];
    }
    this.cliente = this.datos[0];
    this.tipo = this.datos[1];
    this.subtitulo = this.cliente.nombre;
    console.log('DATOS TRAIDOS DESDE CLIENTE', this.cliente);
    this.clienteDatosOriginales = JSON.parse(JSON.stringify(this.cliente));
    //Verifica bandera para ocultar spinner
  }

  //Habilita los campos
  actualizarCliente() {
    this.habilitarEstado = false;
    this.operacion = 2;
    this.titulo = 'Actualizar : ';
    this.subtitulo = this.cliente.nombre;
    this.ocultarBotonesPie = false;
    this.ocultarCodigo = false;
    this.ocultarBotones = true;
    //Obtiene clientes
    this.cliente = JSON.parse(JSON.stringify(this.clienteDatosOriginales));
  }

  //Habilita los campos y los limpia
  crearCliente() {
    this.habilitarEstado = false;
    this.operacion = 1;
    this.titulo = 'Nuevo : ';
    this.subtitulo = 'Cliente';
    this.ocultarBotones = true;
    this.ocultarBotonesPie = false;
    this.ocultarCodigo = true;
    //Obtiene clientes
    this.cliente = JSON.parse(JSON.stringify(this.clienteCrear));
  }

  //Guarda el cliente creado o actualizado
  enviarCliente() {
    //Obtiene clientes
    let ruc = this.cliente.ruc;
    let nombre = this.cliente.nombre;
    let direccion = this.cliente.direccion;
    let telefono = this.cliente.telefono;
    let correo = this.cliente.correo;
    //Valida
    if (
      nombre == '' ||
      ruc == '' ||
      direccion == '' ||
      telefono == '' ||
      correo == ''
    ) {
      //No escogio todo
      Swal.fire('¡Error!', 'Por favor, llene todos los campos', 'error');
      return;
    }
    if (ruc.length != 10 && ruc.length != 13) {
      //Tam cedula o ruc
      Swal.fire('¡Error!', 'El campo RUC debe tener 10 o 13 digitos.', 'error');
      return;
    }
    if (ruc.length === 10) {
      if (validadorCedula.validar_CED(ruc) === 0) {
        Swal.fire(
          '¡Cédula inválida!',
          'Por favor, ingrese una cédula válida.',
          'error'
        );
        return;
      }
    }
    if (ruc.length === 13) {
      if (validadorRuc.validar_RUC_3(ruc) === 0) {
        Swal.fire(
          '¡RUC inválido!',
          'Por favor, ingrese un RUC válido.',
          'error'
        );
        return;
      }
    }
    if (validadorCorreo.validarEmail(correo) === false) {
      Swal.fire(
        '¡Correo inválido!',
        'Por favor, ingrese un correo válido.',
        'error'
      );
      return;
    }
    //<-- Al crear o actualizar

    if (this.operacion === 1 || this.operacion === 2) {
      if (this.operacion === 1) {
        //Devuelve el cliente
        this.spinner.show();
        if (this.tipo == 'Cliente') {
          this.servicio.buscarCliente(ruc, this.empresa).subscribe((data) => {
            //Si ya existe
            if (data.length > 0) {
              Swal.fire(
                '¡Error!',
                'Este RUC ya se encuentra registrado.',
                'error'
              );
            } else {
              Swal.fire('¡Éxito!', 'Operación exitosa.', 'success').then(() => {
                this.cerrarModal(this.operacion); //<-- 1 significa crear y 2 actualizar
              });
            }
            this.spinner.hide();
          });
        } else if (this.tipo == 'Proveedor') {
          this.servicio.buscarProveedor(ruc, this.empresa).subscribe((data) => {
            //Si ya existe
            if (data.length > 0) {
              Swal.fire(
                '¡Error!',
                'Este RUC ya se encuentra registrado.',
                'error'
              );
            } else {
              Swal.fire('¡Éxito!', 'Operación exitosa.', 'success').then(() => {
                this.cerrarModal(this.operacion); //<-- 1 significa crear y 2 actualizar
              });
            }
            this.spinner.hide();
          });
        }
      } else if (this.operacion == 2) {
        Swal.fire('¡Éxito!', 'Operación exitosa.', 'success').then(() => {
          this.cerrarModal(this.operacion); //<-- 1 significa crear y 2 actualizar
        });
      }
    }
  }

  confirmaBorrar() {
    Swal.fire({
      icon: 'error',
      title: '¿Deseas Eliminar?',
      text: 'A: ' + this.cliente.nombre.trim(),
      showDenyButton: true,
      confirmButtonText: `Sí`,
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.operacion = 3;
        Swal.fire('¡Cliente eliminado!', '', 'success').then(() => {
          this.cerrarModal(this.operacion); //<-- 3 significa eliminar
        });
      }
    });
  }

  cerrarModal(valor) {
    if (valor === 1) {
      //<-- Al crear
      this.lanzarEvento(this.cliente, valor); //<-- Envia la data a cliente
    } else {
      if (valor === 2 || valor === 3) {
        //<-- Al actualizar o borrar
        this.lanzarEvento(this.cliente, valor); //<-- Envia la data a cliente
      }
    }
    this.modalReferencia.hide(); //<-- Cierra el modal
  }

  lanzarEvento(item, valor) {
    this.event.emit({ data: [valor, item], res: 200 });
  }

  cancelar() {
    this.titulo = 'Detalle :';
    //Obtiene cliente y rellena los campos
    this.habilitarEstado = true;
    this.ocultarBotonesPie = true;
    this.ocultarCodigo = false;
    this.ocultarBotones = false;
    this.cliente = JSON.parse(JSON.stringify(this.clienteDatosOriginales));
    this.subtitulo = this.cliente.nombre;
  }
}
