import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalInventarioComponent } from 'src/app/components/restaurante/modal-inventario/modal-inventario.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-apertura-cocina',
  templateUrl: './apertura-cocina.component.html',
  styleUrls: ['./apertura-cocina.component.css'],
})
export class AperturaCocinaComponent implements OnInit {
  modalReferencia: BsModalRef;
  banderaSpinner: boolean = false;
  empleados: any = [];
  productos: any = [];

  admin: boolean = false;
  sucursales: any;
  sucursal: string = 'todos';
  empresa: any;
  datosempresa: any;
  datosUm: any = [];
  tarifas: any = [];
  tarifa: string = '';

  constructor(
    private router: Router,
    private servicio: RestauranteService,
    private modalServicio: BsModalService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    if (!localStorage.getItem('usuario')) {
      this.router.navigate(['login']);
    }

    //REVISAR SI NO AFECTO
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.datosempresa = JSON.parse(localStorage.getItem('empresa'));
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }

    //this.cargarLocalStorage();
    this.getUND();

    this.admin = JSON.parse(localStorage.getItem('usuario')).admin;
    this.sucursal = JSON.parse(localStorage.getItem('sucursal')).codigo;
    this.cargarSucursales();
    this.cargoayudantes();
    this.cargoproductos();
  }

  cargoayudantes() {
    this.servicio.getAyudantes('', this.empresa).subscribe(
      (result) => {
        console.log(result);
        if (result.length > 0) {
          this.empleados = result;
        }
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
  }

  cargoproductos() {
    let campos = {
      empresa: this.empresa,
    };

    this.servicio.getProdcocina(campos).subscribe(
      (result) => {
        console.log(result);
        if (result.length > 0) {
          this.productos = result;
        }
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
  }

  //Manda los datos de inventario para guardarlos en la DB
  grabarInventario() {
    //Armando cabecera
    let cabecera = {
      empresa: this.empresa,
      sucursal: this.sucursal,
      usuario: JSON.parse(localStorage.getItem('usuario')).codigo,
    };
    let items = this.productos;
    let ayudaDia = this.empleados;
    let infoFinalInventario = { cabecera, items, ayudaDia };
    this.spinner.show();
    this.servicio.guardarInvCocina(infoFinalInventario).subscribe((data) => {
      if (data.pedido) {
        Swal.fire({
          icon: 'success',
          title: '¡Transacción exitosa!',
          text: 'INGRESO DE Apertura de Cocina #' + data.pedido,
        });
        this.productos = [];
        this.empleados = [];
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Transacción no realizada.',
          text: 'Ha ocurrido un error inesperado.',
        });
      }
      this.spinner.hide();
    });
  }

  //Borra un item especifico
  borrarItem(i) {
    Swal.fire({
      title: '¿Desea eliminar este Ayudante de Cocina?',
      showDenyButton: true,
      confirmButtonText: `Sí`,
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.empleados.splice(i, 1);
        Swal.fire('Ayudante de Cocina eliminado!', '', 'success');
      }
    });
  }

  //Obtiene todas las unidades de medida
  getUND() {
    this.servicio.getConfiguraciones('008', this.empresa).subscribe((data) => {
      this.datosUm = data;
      this.datosUm.forEach((datoUm) => {
        datoUm.codigo = datoUm.codigo.trim();
        datoUm.nombre = datoUm.nombre.trim();
      });
    });
  }

  cargarSucursales() {
    this.servicio
      .getConfiguraciones('015', this.empresa)
      .subscribe((result) => {
        //console.log(result);
        if (result.length > 0) {
          this.sucursales = result;
        }
      });
  }

  calcula_producto(producto) {
    producto.merma = producto.inicial - producto.limpio;
    producto.total = producto.anterior + producto.limpio;
  }

  limpiarInput(item, campo) {
    item[campo] = '';
  }

  verificarInput(item, campo, i) {
    if (item[campo] === '') item[campo] = item[campo + '_ant'];
    if (item[campo] < 0) item[campo] = item[campo + '_ant'];
    item[campo + '_ant'] = item[campo];

    this.calcula_producto(item);
  }

  limitarDecimales(event, decimales, valor) {
    console.log(event.key);
    if (event.key == '.') {
      if (decimales === 0) return false;
      else return true;
    }

    if (isNaN(parseInt(event.key))) return true;
    let val = (valor + '').split('.');
    if (val.length > 1) {
      let decima = val[1];
      if (decima.length > decimales - 1) return false;
    }
    return true;
  }

  alerta(titulo, texto, icono) {
    return Swal.fire({
      icon: icono,
      title: titulo,
      text: texto,
      allowOutsideClick: false,
    });
  }
}
