<!--CABECERA-->
<div class="container-fluid">
  <div class="row my-2 align-items-center">
    <div class="col-12 d-flex align-items-center">
      <button
        type="button"
        class="btn btn-outline-success btn-sm m-1"
        (click)="cargarDatos()"
      >
        <i class="fas fa-sync"></i>
      </button>

      <h4 class="m-0 pl-2">
        Informe de Ventas
        <small class="text-muted">Dinámico</small>
      </h4>
    </div>
  </div>
</div>

<!--FILTROS-->
<div class="container-fluid">
  <div class="row mb-3">
    <div class="col-12 col-md-6 col-xl-4 py-1">
      <div class="card">
        <div class="card-body p-2">
          <mat-form-field appearance="fill" class="no-padding w-100">
            <mat-label>Tipo de Informe</mat-label>
            <mat-select
              panelClass="my-select-panel-class"
              [(value)]="opcion"
              (selectionChange)="cargarDatos()"
            >
              <mat-option *ngFor="let opc of opciones" [value]="opc.orden">{{
                opc.nombre
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="no-padding w-100" appearance="fill">
            <mat-label>Rango de fecha</mat-label>
            <mat-date-range-input
              [formGroup]="campaignOne"
              [rangePicker]="campaignOnePicker"
            >
              <input
                matStartDate
                placeholder="Inicio"
                formControlName="start"
              />
              <input
                matEndDate
                placeholder="Fin"
                formControlName="end"
                (dateChange)="actualizarData()"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="campaignOnePicker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6 col-xl-4 py-1" *ngIf="admin">
      <div class="card">
        <div class="card-body p-2">
          <mat-form-field appearance="fill" class="no-padding w-100">
            <mat-label>Sucursal</mat-label>
            <mat-select
              panelClass="my-select-panel-class"
              [(value)]="sucursal"
              (selectionChange)="cargarDatos()"
            >
              <mat-option value="TODOS"> Todos </mat-option>
              <mat-option
                *ngFor="let sucursal of sucursales"
                [value]="sucursal.codigo.trim()"
                >{{ sucursal.nombre }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" class="no-padding w-100">
            <mat-label>Empleado</mat-label>

            <mat-select
              panelClass="my-select-panel-class"
              [(value)]="usuario"
              (selectionChange)="cargarDatos()"
            >
              <div style="display: flex; justify-content: center">
                <mat-form-field
                  class="example-full-width busqueda"
                  style="padding-top: 10px; width: 90%"
                >
                  <mat-label>Buscar</mat-label>
                  <input
                    matInput
                    [(ngModel)]="busqueda"
                    (keyup)="onKey()"
                    (keydown)="$event.stopPropagation()"
                  />
                </mat-form-field>
              </div>
              <mat-option value="TODOS"> Todos </mat-option>
              <mat-option
                *ngFor="let empleado of empleados"
                [value]="empleado.codigo"
                >{{ empleado.nombre }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6 col-xl-4 py-1">
      <div class="card h-100">
        <div class="card-body py-2">
          <div class="input-group input-group-sm py-1">
            <div class="input-group-prepend text-center">
              <span class="input-group-text" id="ruc"
                ><i class="fas fa-id-card-alt"></i
              ></span>
            </div>
            <input
              type="text"
              class="form-control form-control-sm"
              id="inlineFormInputGroup"
              placeholder="Producto [ Codigo | nombre ]"
              [(ngModel)]="producto"
              (keyup.enter)="abrirModalBuscarProducto()"
              (focusout)="cargarDatos()"
            />
            <div
              class="input-group-prepend"
              (click)="abrirModalBuscarProducto()"
            >
              <div class="input-group-text">
                <i class="far fa-address-book"></i>
              </div>
            </div>
          </div>

          <div class="input-group input-group-sm py-1">
            <div class="input-group-prepend text-center">
              <span class="input-group-text" id="ruc"
                ><i class="fas fa-id-card-alt"></i
              ></span>
            </div>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="Cliente [ Cedula | Ruc | Nombre ]"
              [(ngModel)]="cliente.ruc"
              (keyup.enter)="abrirModalBuscarCliente()"
              (click)="borrarConsumidorFinal()"
              (focusout)="cargarDatos()"
            />
            <div class="input-group-append" (click)="abrirModalBuscarCliente()">
              <span class="input-group-text"
                ><i class="far fa-address-book"></i
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--TABLA-->
<div class="container-fluid">
  <div class="table table-responsive" *ngIf="regmov > 0; else sinResultados">
    <table class="table table-striped table-sm table-hover m-0 p-0">
      <thead class="thead-dark">
        <tr>
          <th
            scope="col"
            *ngFor="let dato of cabecera"
            [style.text-align]="dato.align"
            [style.background-color]="datosempresa['color']"
          >
            {{ dato.alias }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let mov of movimiento
              | paginate
                : {
                    id: 'pagination1',
                    itemsPerPage: 25,
                    currentPage: p,
                    totalItems: movimiento.count
                  }
          "
        >
          <td
            [style.text-align]="dato.align"
            *ngFor="let dato of cabecera"
            class="p-0"
          >
            <div>{{ mov[dato.campo.trim()] }}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<!--PAGINADO-->
<div class="container-fluid" *ngIf="regmov > 0">
  <div class="row justify-content-center align-items-center mt-4 mb-3">
    <div class="col-12 text-center">
      <pagination-controls
        id="pagination1"
        (pageChange)="p = $event"
        autoHide="true"
        responsive="true"
        previousLabel="Anterior"
        nextLabel="Siguiente"
      >
      </pagination-controls>
    </div>
  </div>
</div>

<ng-template #sinResultados>
  <ng-container>
    <div class="container-fluid">
      <div class="row justify-content-center align-items-center">
        <div class="col-12 col-md-6 col-lg-4 mt-5 text-center">
          <h3>
            <small class="text-muted">Sin Resultados en Consulta</small>
          </h3>

          <lottie-player
            src="https://assets2.lottiefiles.com/temp/lf20_TOE9MF.json"
            background="transparent"
            speed="1"
            loop
            autoplay
          ></lottie-player>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<app-spinner></app-spinner>
