<ngx-spinner
  bdColor="#d3631b"
  size="large"
  type="ball-clip-rotate"
  [fullScreen]="true"
>
  <div class="d-flex" style="justify-content: center">
    <img
      src="../../../assets/logo_spinner.gif"
      style="max-width: 300px; top: 0"
      alt=""
    />
  </div>

  <h2 style="color: #fff; text-align: center" class="m-0 p-0">
    <strong>Procesando Datos...</strong>
  </h2>
</ngx-spinner>
