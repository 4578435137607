<!--Cabecera-->
<div class="row justify-content-center align-items-center my-2">
  <div class="col-9 col-md-10 text-center">
    <h3>
      MODAL Búsqueda
      <small class="text-muted">Materia Prima </small>
    </h3>
  </div>
  <div
    class="col-2 col-md-1 justify-content-center align-items-center"
    (click)="modalReferencia.hide()"
  >
    <button type="button" class="close pull-right" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
<!--Busqueda-->
<div class="row justify-content-center align-items-center m-3">
  <div class="col-10 col-lg-8 col-xl-6 mt-2 text-center text-md-left">
    <div class="input-group">
      <input
        type="text"
        class="form-control form-control-sm"
        placeholder="Buscar Nombre | Codigo"
        aria-label="Busqueda"
        aria-describedby="basic-addon1"
        (keyup)="traerMateriasPrimas($event)"
      />
      <div class="input-group-append">
        <span class="input-group-text" id="basic-addon1"
          ><i class="fab fa-searchengin"></i
        ></span>
      </div>
    </div>
  </div>
</div>
<!--Accion de busqueda-->
<div
  class="table table-responsive table-hover table-responsive-sm table-responsive-md table-responsive-xl"
  *ngIf="hayProductos === true; else noItems"
>
  <table class="table table-sm">
    <thead>
      <tr>
        <th scope="col">Ingredientes</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let producto of productos">
        <td id="{{ producto.codigo }}" (click)="cerrarModal($event)">
          {{ producto.nombre }}
        </td>
      </tr>
    </tbody>
  </table>
</div>

<!--Boton-->
<div class="container text-right my-1" *ngIf="hayProductos === true">
  <button
    type="button"
    class="btn btn-outline-danger btn-sm btn_est m-2"
    (click)="cerrarModal()"
  >
    <i class="fas fa-times-circle"></i> Cancelar
  </button>
</div>

<!--Busqueda sin resultados-->
<ng-template #noItems>
  <ng-container>
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-12 col-md-6 col-lg-4 mt-5" style="text-align: center">
          <h3>
            <small class="text-muted">Búsqueda sin resultados</small>
          </h3>

          <lottie-player
            src="https://assets2.lottiefiles.com/temp/lf20_TOE9MF.json"
            background="transparent"
            speed="1"
            loop
            autoplay
          ></lottie-player>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
