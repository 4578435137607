<div class="container-fluid my-4">
  <div class="row justify-content-center align-items-center text-center">
    <div class="col-sm-12 col-md-6 col-lg-7">
      <h3>
        Rol de Pago
        <small class="text-muted"></small>
        <small class="text-info"
          ><strong>{{ cliente.nombre }}</strong></small
        >
      </h3>
    </div>
  </div>
</div>

<div class="container-fluid py-2">
  <div class="row d-flex justify-content-center">
    <div class="col-12 col-md-3">
      <div class="card py-2 text-center">
        <div class="card-content">
          <mat-form-field appearance="fill">
            <mat-label class="text-info">Año y mes</mat-label>
            <input matInput [matDatepicker]="dp" [formControl]="date" />
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker
              #dp
              startView="multi-year"
              (yearSelected)="chosenYearHandler($event)"
              (monthSelected)="chosenMonthHandler($event, dp)"
              panelClass="example-month-picker"
            >
            </mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-4">
      <div class="card py-2 text-center">
        <div class="card-content">
          <div class="row align-items-center p-2">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend text-center">
                <span class="input-group-text" id="ruc"
                  ><i class="fas fa-id-card-alt"></i
                ></span>
              </div>
              <input
                type="text"
                class="form-control"
                placeholder="Cedula | Ruc"
                aria-label="Busqueda"
                aria-describedby="basic-addon1"
                [(ngModel)]="cliente.ruc"
                (keyup.enter)="abrirModalBuscarEmpleado()"
              />
              <div
                class="input-group-append"
                (click)="abrirModalBuscarEmpleado()"
              >
                <span class="input-group-text" id="basic-addon1"
                  ><i class="fab fa-searchengin"></i
                ></span>
              </div>
            </div>

            <div class="input-group input-group-sm pt-1">
              <div class="input-group-prepend text-center">
                <span class="input-group-text" id="pago"
                  ><i class="fas fa-money-bill-wave"></i
                ></span>
              </div>
              <select
                class="custom-select"
                name="pago"
                [(ngModel)]="quincena"
                id="pago"
              >
                <option *ngFor="let opcion of quincenas" [ngValue]="opcion.id">
                  {{ opcion.nombre }}
                </option>
              </select>
              <div class="input-group-append">
                <label class="input-group-text" for="pago"
                  ><i class="fab fa-audible"></i
                ></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-2">
      <button class="btn btn-warning btn-sm m-2" (click)="consultar()">
        <i class="fas fa-file-invoice-dollar"></i> Consultar
      </button>
    </div>
  </div>
</div>

<div class="container" *ngIf="info.length > 0">
  <div class="row justify-content-center align-items-center mt-5">
    <div class="col-11 col-md-6 border-right pb-4">
      <div class="row">
        <div class="col-12 text-center text-success">
          <b>Ingresos</b>
          <hr />
        </div>
      </div>

      <div class="row" *ngFor="let ingreso of ingresos">
        <div class="col-8">
          {{ ingreso.nombre }}
        </div>
        <div class="col-4 text-right">
          {{ ingreso.valor.toFixed(2) }}
        </div>
      </div>
      <hr class="text-success" />
      <div class="row text-right">
        <div class="col-8"><strong>Total</strong> Ingresos</div>
        <div class="col-4">
          <p class="text-success">
            <strong> $ {{ totalIngresos.toFixed(2) }} </strong>
          </p>
        </div>
      </div>
    </div>

    <div class="col-11 col-md-6 pb-4">
      <div class="row">
        <div class="col-12 text-center text-danger">
          <b>Egresos</b>
          <hr />
        </div>
      </div>
      <div class="row" *ngFor="let egreso of egresos">
        <div class="col-8">
          {{ egreso.nombre }}
        </div>
        <div class="col-4 text-right">
          {{ egreso.valor.toFixed(2) }}
        </div>
      </div>
      <hr class="text-danger" />
      <div class="row text-right">
        <div class="col-8"><strong>Total</strong> Egresos</div>
        <div class="col-4">
          <p class="text-danger">
            <strong> $ {{ totalEgresos.toFixed(2) }} </strong>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-center align-items-center">
    <div class="col-11 col-md-5">
      <div class="card border-success">
        <div class="card-body text-center">
          <p class="p-0 m-0">
            <strong>Neto a Recibir</strong>
            <strong class="text-success">
              $ {{ (totalIngresos - totalEgresos).toFixed(2) }}
            </strong>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container" *ngIf="info.length < 1">
  <div class="row justify-content-center align-items-center">
    <div class="col-6 mt-5" style="text-align: center">
      <lottie-player
        src="https://assets2.lottiefiles.com/temp/lf20_TOE9MF.json"
        background="transparent"
        speed="1"
        loop
        autoplay
      ></lottie-player>
      <h3>
        Sin movimientos
        <small class="text-muted"> Rol de Pago</small>
      </h3>
    </div>
  </div>
</div>

<app-spinner></app-spinner>
