import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ServicioCocinaService } from 'src/app/services/servicio-cocina/servicio-cocina.service';
import { NgxSpinnerService } from 'ngx-spinner';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalAceptarCocinaComponent } from '../modal-aceptar-cocina/modal-aceptar-cocina.component';
import { ModalAlertaComponent } from '../modal-alerta/modal-alerta.component';

@Component({
  selector: 'app-cocina',
  templateUrl: './cocina.component.html',
  styleUrls: ['./cocina.component.css'],
})
export class CocinaComponent implements OnInit {
  pedidosCocina: any = [];
  idSucursal: String;
  fecha: any;
  //Bandera para spinner
  banderaSpinner: boolean = false;
  actual = -1;
  key = '';
  bloquear = false;
  modalReferencia: BsModalRef;
  in = true;
  nRegistroVerificacion = 0;

  sucursal;

  cargando = false;

  tiempo: number = 12;

  seleccionarMultiple = true;
  textoSeleccionMultiple = 'Selección múltiple';
  empresa: any;

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (!this.bloquear) {
      if (
        event.key == '1' ||
        event.key == '2' ||
        event.key == '3' ||
        event.key == '4' ||
        event.key == '5' ||
        event.key == '6' ||
        event.key == '7' ||
        event.key == '8' ||
        event.key == '9'
      ) {
        console.log(event.key);
        if (this.seleccionarPedido(event.key))
          this.actual = parseInt(event.key);

        this.key = event.key;
      }
      if (event.key == 'Enter') {
        if (this.actual) {
          this.bloquear = true;
          this.confirmarMultiples();
        }
      }
    }
  }

  constructor(
    private router: Router,
    private service: ServicioCocinaService,
    private spinner: NgxSpinnerService,
    private modalServicio: BsModalService
  ) {}

  ngOnInit(): void {
    this.sucursal = JSON.parse(localStorage.getItem('sucursal'));
    this.empresa = JSON.parse(localStorage.getItem('empresa'));
    this.fecha = this.formatearFechaString(new Date(), true);
    setInterval(() => {
      this.fecha = this.formatearFechaString(new Date(), true);
    }, 1000);
    setInterval(() => {
      if (this.banderaSpinner === true) {
        this.spinner.hide();
        this.banderaSpinner = false;
      }
    }, 100);

    setInterval(() => {
      if (!this.cargando) this.cargarDatos();
    }, 1000);

    setInterval(() => {
      if (this.pedidosCocina.length > 0)
        for (let i = 0; i < this.pedidosCocina.length && i < 9; i++) {
          this.pedidosCocina[i].hora = this.calcularDiferencia(i);
        }
    }, 1000);
  }

  cargarDatos(spinner = false) {
    this.cargando = true;
    let campos = {
      empresa: JSON.parse(localStorage.getItem('empresa')).codigo,
      sucursal: JSON.parse(localStorage.getItem('sucursal')).codigo,
    };
    if (spinner) this.spinner.show();

    this.service.cargarPedidosCocinaSucursal(campos).subscribe((data) => {
      if (data.length == this.nRegistroVerificacion) {
        this.cargando = false;
        return;
      }
      console.log(data);
      this.nRegistroVerificacion = data.length;

      //Reestructurando JSON
      let mini = [];
      for (let i = 0; i < data.length; i++) {
        let elemento = data[i];
        let temp = [];
        for (let j = 0; j < elemento.length - 1; j++) {
          temp.push({
            nombre: elemento[j].nombre.trim(),
            cantidad: elemento[j].cantidad,
          });
        }

        let dato = {
          tipo: elemento[elemento.length - 1].tipo,
          numero: elemento[elemento.length - 1].numero,
          empresa: elemento[elemento.length - 1].empresa,
          modulo: elemento[elemento.length - 1].modulo,
          sucursal: elemento[elemento.length - 1].sucursal,
          fecha: elemento[elemento.length - 1].fecha,
          hora: '',
          items: temp,
          selected: false,
          nomtipo: elemento[elemento.length - 1].nomtipo,
          mesa: elemento[elemento.length - 1].mesa,
          itemsped: elemento[elemento.length - 1].itemsped,
          nom_tipoentrega: elemento[elemento.length - 1].nom_tipoentrega.trim(),
          nom_servicioentrega:
            elemento[elemento.length - 1].nom_servicioentrega,
          comentario: elemento[elemento.length - 1].comentario.trim(),
        };

        console.log(dato);
        mini.push(dato);
      }
      this.pedidosCocina = mini;
      this.spinner.hide();
      this.cargando = false;
    });
  }

  calcularDiferencia(i, nueva = new Date()) {
    let actual = this.pedidosCocina[i].fecha;
    let fechaFormateada = new Date(actual).getTime() + 18000000;

    let timeFormated = new Date(fechaFormateada).getTime() + 720000;
    let nuevaFecha = new Date(timeFormated);

    var time = nuevaFecha.getTime() - nueva.getTime();
    if (time < 0) {
      this.pedidosCocina[i].color = '#f39c9c';
      return 'Atrasado';
    }
    //var time2 = nueva.getTime() - new Date(fecha).getTime();

    //time = time ;
    //console.log(new Date(nuevaFecha));
    //var dias = Math.floor(time / 86400000); // days
    var horas = Math.floor((time / 3600000) % 24); // hours
    let minutos = Math.floor((time / 1000 / 60) % 60);
    //let segundos = Math.round(((time % 86400000) % 3600000) % 60000);
    let segundos = Math.floor(time / 1000) % 60;
    //let segundos = Math.floor(time / 1000) % 60;

    //let hora = horas + '';
    let minuto = minutos + '';
    let segundo = segundos + '';
    //if (horas < 10) hora = '0' + horas;

    if (minutos <= 2) this.pedidosCocina[i].color = '#d4cf2c';
    if (minutos < 10) minuto = '0' + minutos;
    if (segundos < 10) segundo = '0' + segundos;
    return minuto + ':' + segundo;
  }

  //Funciones de formateo de fecha
  formatearFechaString(fecha = new Date(), hora = false) {
    let fechaFin = new Date(fecha);
    let fechaString = fechaFin
      .toJSON()
      .slice(0, 10)
      .split('-')
      .reverse()
      .join('/');
    if (hora) {
      //Formatear hora
      return fechaString + ' ' + this.formatearHora(fecha);
    }
    return fechaString;
  }

  formatearHora(fecha = new Date()) {
    let hora = new Date(fecha);
    let hora_final = '';
    if (hora.getHours() < 10) hora_final += '0' + hora.getHours() + ':';
    else hora_final += hora.getHours() + ':';
    if (hora.getMinutes() < 10) hora_final += '0' + hora.getMinutes() + ':';
    else hora_final += hora.getMinutes() + ':';
    if (hora.getSeconds() < 10) hora_final += '0' + hora.getSeconds();
    else hora_final += hora.getSeconds();
    return hora_final;
  }

  salir() {
    //localStorage.clear();
    localStorage.removeItem('pagina');
    location.href = '/';
  }

  confirmarMultiples() {
    let pedidos = [];
    for (let i = 0; i < this.pedidosCocina.length && i < 9; i++) {
      let pedido = this.pedidosCocina[i];
      if (this.pedidosCocina[i].selected) {
        pedido.indice = i + 1;
        pedidos.push(pedido);
      }
    }
    if (pedidos.length == 0) return;
    this.in = false;
    //Este modal recibe el id de la mesa
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos:
        //<--Datos enviados desde componente hacia modal
        pedidos,
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferencia = this.modalServicio.show(
      ModalAceptarCocinaComponent,
      {
        initialState,
        ignoreBackdropClick: true,
        class: 'modal-dialog-centered',
      }
    );
    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((data) => {
      //console.log(data);
      this.bloquear = false;
      this.in = true;

      if (data.data == 'Aceptar') {
        this.pedidosCocina = [];
        //Elimino de pedidos los que ya no sirven mientras itero
        /*let j = 0;
        let h = 0;
        let maxH = pedidos.length;
        //this.pedidosCocina.splice(j, 1);
        console.log;
        while (j < this.pedidosCocina.length) {
          console.log(j);
          if (
            this.pedidosCocina[j].tipo.trim() === pedidos[h].tipo.trim() &&
            this.pedidosCocina[j].numero === pedidos[h].numero
          ) {
            this.pedidosCocina.splice(j, 1);
            maxH--;
          }
          if (h < maxH) h++;
          else {
            h = 0;
            j++;
          }
          if (maxH == 0) break;
        }*/

        //this.modalConfirmacion(data.pedido);
      }
    });
  }

  modalConfirmacion(info) {
    let pedidoCocina = info;
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<--Datos enviados desde componente hacia modal
        pedidoCocina,
      ],
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferencia = this.modalServicio.show(ModalAlertaComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered',
    });
    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((data) => {
      //console.log(data);
      this.actual = -1;
      //this.cargarDatos();
      this.bloquear = false;
      this.in = true;
    });
  }

  seleccionarPedido(key) {
    if (this.pedidosCocina.length > 0) {
      if (key <= this.pedidosCocina.length) {
        //console.log(this.actual);
        if (this.actual != key) {
          this.pedidosCocina[key - 1].selected = true;
          if (this.actual >= 0)
            this.pedidosCocina[this.actual - 1].selected = false;

          return true;
        }
      }
    }
    return false;
  }

  seleccionMultiple(i) {
    if (this.seleccionarMultiple) {
      this.confirmarPedido(this.pedidosCocina[i]);
      return;
    }

    if (this.pedidosCocina[i].selected) {
      this.pedidosCocina[i].selected = false;
    } else {
      this.pedidosCocina[i].selected = true;
    }
  }

  confirmarPedido(element) {
    let informacion = {
      empresa: element.empresa,
      sucursal: element.sucursal,
      modulo: element.modulo,
      tipo: element.tipo,
      numero: element.numero,
      envio: '2',
    };
    console.log(informacion);
    this.spinner.show();
    this.service.confirmarPedido(informacion).subscribe((data) => {
      console.log(data);
      //this.spinner.hide();
    });
  }

  cambiarSeleccion() {
    if (this.seleccionarMultiple) {
      this.textoSeleccionMultiple = 'Desactivar selección múltiple';
      this.seleccionarMultiple = false;
    } else {
      this.desactivar();
      this.textoSeleccionMultiple = 'Selección múltiple';
      this.seleccionarMultiple = true;
    }
  }

  desactivar() {
    for (let i = 0; i < this.pedidosCocina.length && i < 9; i++)
      this.pedidosCocina[i].selected = false;
  }

  sonido() {
    let audio = new Audio();
    audio.src = '../../../../assets/notificacion.mp3';
    audio.load();
    audio.play();
  }
}
