<div class="modal-header">
  <h4 class="modal-title" id="titulo">Confirma Pedido</h4>
</div>

<div class="mx-5 my-3">
  <div *ngFor="let pedido of pedidosCocina">
    <span class="font-weight-bold">{{ pedido.indice }}</span>
    {{ pedido.tipo }} - {{ pedido.numero }}
  </div>
</div>
<div class="d-flex mb-3" style="justify-content: space-around">
  <button
    type="button"
    class="btn btn-outline-success"
    (click)="confirmarPedido()"
  >
    [Intro] Sí
  </button>
  <button
    type="button"
    class="btn btn-outline-danger"
    (click)="cerrarModal('Cancelar')"
  >
    [-] No
  </button>
</div>
<app-spinner></app-spinner>
