import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-productoadd',
  templateUrl: './modal-productoadd.component.html',
  styleUrls: ['./modal-productoadd.component.css'],
})
export class ModalProductoaddComponent implements OnInit {
  producto: any = {
    nombre: '',
    precio: '',
    porc_tarifa: '0',
    bloqueado: false,
    inv_cocina: false,
    mostrar_app: false,
    cant_viandas: '0',
    compra: false,
  }; //<--Este es el producto nuevo creado
  lineas: any = []; //<-- Aqui se cargan las lineas
  grupos: any = []; //<-- Aqui se cargan los productos
  public event: EventEmitter<any> = new EventEmitter(); //<-- Para devolver datos a producto
  lineaEscogida: any; //<--Elemento enviado de aqui a la template y de la template aca
  grupoEscogido: any; //<--Elemento enviado de aqui a la template y de la template aca
  titulo: String = 'CREAR PRODUCTO';
  //Banderas para spinner
  banderaSpinner: boolean = false;
  banderaSpinner2: boolean = false;
  medida: string;
  unidades: any;

  empresa: any;
  constructor(
    public modalReferencia: BsModalRef,
    private service: RestauranteService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    console.log('INICIO', this.producto);
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
    this.cargarSelectores();
    setInterval(() => {
      if (this.banderaSpinner === true && this.banderaSpinner2 === true) {
        this.spinner.hide();
        this.banderaSpinner = false;
        this.banderaSpinner2 = false;
      }
    }, 100);
  }

  cargarSelectores() {
    this.service.getConfiguraciones('003', this.empresa).subscribe((data) => {
      this.lineas = data;
      this.lineaEscogida = this.lineas[0];
      console.log(this.lineas);
    });
    this.service.getConfiguraciones('004', this.empresa).subscribe((data) => {
      this.grupos = data;
      this.grupoEscogido = this.grupos[0];
      console.log(this.grupos);
    });
    this.service.getConfiguraciones('008', this.empresa).subscribe((data) => {
      this.unidades = data;
      this.medida = this.unidades[0].codigo;
    });
  }

  //Guarda el producto creado
  enviarProducto() {
    let bloqueado = this.producto.bloqueado;
    let inv_cocina = this.producto.inv_cocina;
    let compra = this.producto.compra;
    let mostrar_app = this.producto.mostrar_app;
    let cant_viandas = this.producto.cant_viandas;
    
    //Obtiene productos
    if (this.producto.nombre == '') {
      this.alerta('Faltan datos', 'Por favor ingrese un nombre', 'warning');
      return;
    }

    if (this.producto.precio == '') {
      this.alerta('Faltan datos', 'Por favor ingrese un precio', 'warning');
      return;
    }
/*
    if (this.producto.porc_tarifa == '') {
      this.alerta('Faltan datos', 'Por favor ingrese una tarifa', 'warning');
      return;
    }
*/
    let nombre = this.producto.nombre;
    let precio = this.producto.precio.toString();
    precio = precio.replace(',', '.');
    let tarifa = this.producto.porc_tarifa.toString();
    tarifa = tarifa.replace(',', '.');
    let cod_linea = this.lineaEscogida.codigo.trim();
    let cod_grupo = this.grupoEscogido.codigo.trim();
    //Valida
    if (
      nombre == '' ||
      cod_linea == '' ||
      cod_grupo == '' ||
      precio == '' ||
      tarifa == ''
    ) {
      //No escogio todo
      Swal.fire('¡Error!', 'Por favor, llene todos los campos.', 'error').then(
        () => {}
      );
    } else {
      //Devuelve el producto
      let linea = this.lineaEscogida.nombre.trim();
      let grupo = this.grupoEscogido.nombre.trim();

      this.producto.nombre = nombre.trim();
      this.producto.precio = precio;
      this.producto.porc_tarifa = tarifa;
      this.producto.nomlin = linea;
      this.producto.nomgru = grupo;
      this.producto.linea = cod_linea;
      this.producto.grupo = cod_grupo;
      this.producto.medida = this.medida;
      this.producto.bloqueado = bloqueado;
      this.producto.inv_cocina = inv_cocina;
      this.producto.compra = compra;
      this.producto.mostrar_app = mostrar_app;
      this.producto.cant_viandas = cant_viandas;

      console.log(this.producto);
      Swal.fire('¡Éxito!', 'Operación exitosa.', 'success').then(() => {
        this.cerrarModal();
      });
    }
  }

  cerrarModal() {
    console.log('CIERRA', this.producto);
    this.lanzarEvento(this.producto); //<-- Envia la data a producto
    this.modalReferencia.hide(); //<-- Cierra el modal
  }

  lanzarEvento(item) {
    this.event.emit({ data: item, res: 200 }); //<-- Internamente envia la data a producto
  }

  cancelar() {
    this.modalReferencia.hide();
  }

  alerta(titulo, texto, icono) {
    return Swal.fire({
      icon: icono,
      title: titulo,
      text: texto,
      allowOutsideClick: false,
      timer: 1000,
      showConfirmButton: false,
    });
  }
}
