import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalCuentasComponent } from '../cuentas/modales/modal-cuentas/modal-cuentas.component';
//import { ModalCuentaaddComponent } from '../../modal-cuentaadd/modal-cuentaadd.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-cuentas',
  templateUrl: './cuentas.component.html',
  styleUrls: ['./cuentas.component.css'],
})
export class CuentasComponent implements OnInit {
  //Referencia del modal
  modalReferencia: BsModalRef;
  //Datos traidos de la DB
  cuentas: any = [];
  //Verifica que hay cuenta
  hayCuenta: boolean;
  //Variable de evento de pagina
  p: Number = 1;
  //Bandera para spinner
  banderaSpinner: boolean = false;
  //Bandera para saber si son datos filtrados o todos
  banderaCuentas: boolean; //<-- true todos y false filtro
  empresa: string = '';
  sucursal: string;

  constructor(
    private router: Router,
    private service: RestauranteService,
    private modalServicio: BsModalService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    if (!localStorage.getItem('usuario')) {
      this.router.navigate(['login']);
    }
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
    if (JSON.parse(localStorage.getItem('sucursal')) !== null) {
      this.sucursal = JSON.parse(localStorage.getItem('sucursal'))['codigo'];
    }

    console.log('consulta de cuentas');
    this.getCuentas();
    //Verifica bandera para ocultar spinner
    setInterval(() => {
      if (this.banderaSpinner === true) {
        this.spinner.hide();
        this.banderaSpinner = false;
      }
    }, 100);
  }

  //Trae todos los cuentas de la DB
  getCuentas() {
    this.spinner.show();
    let consulta = {
      texto: ' ',
      empresa: this.empresa,
    };
    console.log(consulta);
    this.service.getCuentas(consulta).subscribe((data) => {
      if (data.length === 0) {
        this.hayCuenta = false;
      } else {
        this.cuentas = data;
        this.hayCuenta = true;
      }
      this.banderaSpinner = true;
      this.banderaCuentas = true; //<--Los datos actuales son todos
    });
  }

  //Abre el modal
  abrirModalDetallesCuenta(cuenta) {
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<--Datos enviados desde componente hacia modal
        cuenta,
        'Cuenta',
      ],
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo

    this.modalReferencia = this.modalServicio.show(ModalCuentasComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });

    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((res) => {
      console.log('MODAL CERRADO, DATA RECIBIDA', res.data);
      if (res.data[0] === 1) {
        //Crea el elemento en el temporal y en la DB
        this.creaItemArray(res.data[1]);
      }
      if (res.data[0] === 2) {
        //Actualiza el elemento en el temporal y en la DB
        this.actualizaItemArray(res.data[1]);
      }
      if (res.data[0] === 3) {
        //Elimina el elemento en el temporal y en la DB
        this.eliminaItemArray(res.data[1], this.empresa);
      }
    });
  }

  //Abre el modal
  abrirModalAgregarCuenta() {
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<--Datos enviados desde componente hacia modal
        'Cuenta',
      ],
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    /*this.modalReferencia = this.modalServicio.show(ModalCuentaaddComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });
    */
    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((res) => {
      console.log('MODAL CERRADO, DATA RECIBIDA', res.data);
      this.creaItemArray(res.data);
    });
  }

  //Busca cuenta en array de cuentas y lo devuelve
  buscarCuenta(id) {
    for (let indice in this.cuentas) {
      //<-- Itera todos los indices de cuenta
      let cuenta = this.cuentas[indice]; //<-- Toma el i-esimo cuenta
      if (cuenta.codigo == id) {
        //Compara el id cuenta con el id del cuenta a buscar
        return cuenta;
      }
    }
  }

  //Crea el elemento en el array
  creaItemArray(item) {
    this.spinner.show();
    this.service.creaCuenta(item).subscribe((data) => {
      this.getCuentas();
      this.banderaSpinner = true;
    });
  }

  //Actualiza elemento en el array
  actualizaItemArray(item) {
    const ruc = item.ruc;

    this.spinner.show();
    this.service.actualizaCuenta(ruc, item).subscribe((data) => {
      if (data.data === 'Dato actualizado exitosamente') {
        this.getCuentas();
      }
      this.banderaSpinner = true;
    });
  }

  //Borra elemento del array
  eliminaItemArray(item, empresa) {
    this.spinner.show();
    this.service.eliminaCuenta(item.ruc, empresa).subscribe((data) => {
      if (data.data === 'Dato eliminado exitosamente') {
        this.getCuentas();
      }
      this.banderaSpinner = true;
    });
  }

  //Buscar cuenta en API
  busquedaCuenta(event) {
    let texto = event.target.value; //<--Obtenemos el texto
    if (texto != '') {
      //<-- Si no está vacío coge el texto y busca
      if (texto.length > 0) {
        this.spinner.show();
        let campos = {
          texto: texto,
          usuario: JSON.parse(localStorage.getItem('usuario')).codigo,
          empresa: this.empresa,
        };
        this.service.buscarCuenta(campos).subscribe((data) => {
          console.log('PARA CARGAR', data);
          if (data.length === 0) {
            this.hayCuenta = false;
          } else {
            this.cuentas = data;
            this.p = 1;
          }
          this.banderaSpinner = true;
          this.banderaCuentas = false; //<--Los datos actuales son filtrados
        });
      }
    } else {
      //<--Si esta vacio trae todos los registros
      if (this.banderaCuentas === false) {
        this.getCuentas();
      }
    }
  }
}
