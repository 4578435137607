<!--CABECERA-->
<div class="container-fluid my-2">
  <div class="row align-items-center">
    <div class="col-12 col-md-4 col-lg-3 col-xl-2" *ngIf="admin">
      <mat-form-field appearance="fill" class="no-padding">
        <mat-label>Sucursal</mat-label>
        <mat-select panelClass="my-select-panel-class" [(value)]="sucursal">
          <mat-option
            *ngFor="let sucursal of sucursales"
            [value]="sucursal.codigo.trim()"
            >{{ sucursal.nombre }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col">
      <h4>
        Apertura de Cocina
        <small class="text-muted"></small>
      </h4>
    </div>
  </div>
</div>

<div class="container-fluid my-1">
  <div class="row">
    <div class="col-12 col-md-8 px-0">
      <!-- BOTONES DE ACCION-->
      <div class="container-fluid">
        <div class="row">
          <div class="col-12  text-right">
            <button
              type="button"
              class="btn btn-sm btn-outline-danger"
              (click)="grabarInventario()"
              *ngIf="productos.length > 0"
            >
              <i class="fas fa-cloud-upload-alt"></i> Grabar
            </button>
          </div>
        </div>
      </div>

      <!--MOVIMIENTO DE LA COMPRA-->
      <div class="container-fluid mt-2" *ngIf="productos.length > 0">
        <div class="table-responsive">
          <table class="table table-sm table-striped table-hover m-0 p-0">
            <thead>
              <tr>
                <th
                  style="
                    width: 150px;
                    text-align: center;
                    background-color: #27ae60;
                  "
                  class="text-light"
                >
                  Ayer
                </th>
                <th style="width: 250px; text-align: center">Materia Prima</th>
                <th style="width: 100px; text-align: center">Medida</th>
                <th style="width: 150px; text-align: center">Inicial</th>
                <th style="width: 150px; text-align: center">Merma</th>
                <th style="width: 150px; text-align: center">Limpio</th>
                <th
                  style="width: 150px; text-align: center"
                  [style.background-color]="datosempresa['color']"
                  class="text-light"
                >
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let producto of productos
                    | paginate
                      : {
                          itemsPerPage: 25,
                          currentPage: p,
                          totalItems: productos.count
                        };
                  index as i
                "
                id="{{ producto.codigo }}"
              >
                <td style="text-align: center; max-height: 40px">
                  <p class="m-0 p-0">
                    {{ producto.anterior }}
                  </p>
                </td>
                <td>
                  <p class="m-0 p-0">
                    {{ producto.nombre }}
                  </p>
                </td>
                <td>
                  <div class="input-group" style="width: 120px">
                    <select
                      class="custom-select custom-select-sm"
                      name="um"
                      id="um"
                      [(ngModel)]="producto.umEscogida"
                    >
                      <!--<option *ngFor="let datoTin of datosTin" [ngValue]="datoTin">{{datoTin.nombre}}</option>    -->
                      <option
                        *ngFor="let datoUm of datosUm"
                        value="{{ datoUm.codigo }}"
                      >
                        {{ datoUm.nombre }}
                      </option>
                    </select>
                  </div>
                </td>

                <td>
                  <input
                    type="numeric"
                    class="form-control form-control-sm text-right"
                    [(ngModel)]="producto.inicial"
                    (keydown)="limitarDecimales($event, 4, producto.inicial)"
                    (focusin)="limpiarInput(producto, 'inicial')"
                    (focusout)="verificarInput(producto, 'inicial', i)"
                  />
                </td>

                <td>
                  <p class="text-right pr-2">{{ producto.merma.toFixed(2) }}</p>
                </td>

                <td>
                  <input
                    type="numeric"
                    class="form-control form-control-sm text-right"
                    [(ngModel)]="producto.limpio"
                    (keydown)="limitarDecimales($event, 4, producto.limpio)"
                    (focusin)="limpiarInput(producto, 'limpio')"
                    (focusout)="verificarInput(producto, 'limpio', i)"
                  />
                </td>

                <td
                  class="text-light"
                  [style.background-color]="datosempresa['color']"
                >
                  <p
                    style="text-align: right; font-size: 1.2rem"
                    class="m-0 p-0"
                  >
                    <strong>{{ producto.total.toFixed(2) }}</strong>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!---->
    <div class="col-12 col-md-4 px-0">
      <!--MOVIMIENTO DE AYUDANTES-->
      <div class="container-fluid mt-2" *ngIf="empleados.length > 0">
        <div class="table-responsive">
          <table class="table table-sm table-striped table-hover m-0 p-0">
            <thead>
              <tr>
                <th style="width: 50px; text-align: center">#</th>

                <th style="width: 250px; text-align: center">
                  Ayudante o Cocinero
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let ayudante of empleados
                    | paginate
                      : {
                          itemsPerPage: 25,
                          currentPage: p,
                          totalItems: 100
                        };
                  index as i
                "
                id="{{ ayudante.codigo }}"
              >
                <td class="d-flex" style="justify-content: space-around">
                  <button
                    type="button"
                    class="btn btn-outline-danger btn-sm"
                    (click)="borrarItem(i)"
                  >
                    <i class="far fa-trash-alt"></i>
                  </button>
                </td>

                <td>
                  <p class="m-0 p-0">
                    {{ ayudante.nombre }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
