<div class="pt-3">
  <div class="container-fluid">
    <div class="text-center py-2">
      <h3>{{ empresa.nombre }} - {{ sucursal.nombre }}</h3>
    </div>
    <div class="row">
      <div class="col-4">
        <h3>
          <b>{{ fecha }}</b>
        </h3>
      </div>
      <div class="col-4 text-center">
        <div class="font-weight-bold" style="font-size: 20px">
          Pendientes: {{ pedidosCocina.length }}
        </div>
      </div>
      <div class="col-4 text-right">
        <button
          type="button"
          class="btn btn-outline-danger"
          (click)="salir()"
          style="width: 65px"
        >
          <i class="fas fa-door-open"></i> Salir
        </button>
      </div>
    </div>

    <div class="text-center my-3">
      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="cambiarSeleccion()"
      >
        {{ textoSeleccionMultiple }}
      </button>
    </div>

    <div class="text-center">
      <button
        type="button"
        class="btn btn-outline-success"
        (click)="confirmarMultiples()"
        style="width: 120px"
        *ngIf="!seleccionarMultiple"
      >
        Confirmar
      </button>
    </div>

    <div class="row">
      <div
        class="col-lg-4 col-md-4 my-2"
        *ngFor="let pedidoCocina of pedidosCocina | slice : 0 : 9; index as i"
      >
        <div
          class="card tarjeta"
          style="min-height: 150px; border-color: rgb(177 177 177)"
          (click)="seleccionMultiple(i)"
          [style]="
            pedidoCocina.selected
              ? { 'background-color': '#afe0ab' }
              : { 'background-color': '' }
          "
        >
          <div class="card-body" style="padding-bottom: 0.4rem">
            <div class="contenido_cabecera">
              <div style="font-size: 28px; font-weight: bold">{{ i + 1 }}</div>
              <button
                type="button"
                class="btn btn-dark btn-sm"
                *ngIf="pedidoCocina.mesa > 0"
              >
                Mesa
                <span class="badge badge-light">{{ pedidoCocina.mesa }}</span>
              </button>
              <div>
                <p style="margin: 0; font-size: 15px">
                  {{ pedidoCocina.nomtipo }} : {{ pedidoCocina.numero }}
                </p>
              </div>
            </div>

            <div class="row">
              <div
                class="col-12"
                *ngIf="pedidoCocina.nom_tipoentrega == 'Local'"
              >
                <button type="button" class="btn btn-sm btn-success">
                  {{ pedidoCocina.nom_tipoentrega }}
                </button>
              </div>

              <div
                class="col-12 text-center"
                *ngIf="pedidoCocina.nom_tipoentrega != 'Local'"
              >
                <button type="button" class="btn btn-sm btn-warning">
                  {{ pedidoCocina.nom_tipoentrega }}
                  <span class="badge badge-light">{{
                    pedidoCocina.nom_servicioentrega
                  }}</span>
                </button>
              </div>
            </div>
            <hr style="margin-top: 0.3rem" />
            <div *ngFor="let item of pedidoCocina.items">
              <span class="font-weight-bold">{{ item.cantidad }}x</span>
              {{ item.nombre }}
            </div>
            <hr style="margin-bottom: 0.4rem" />
            <div
              class="d-flex justify-content-center mt-1"
              style="width: 100%; border-radius: 10px"
              [style.background-color]="pedidoCocina.color"
            >
              <p class="font-weight-bold" style="font-size: 22px; margin: 0">
                {{ pedidoCocina.hora }}
              </p>
            </div>
            <div
              class="d-flex justify-content-center mt-1"
              style="width: 100%; border-radius: 10px"
              [style.background-color]="pedidoCocina.color"
            >
              <p style="font-size: 14px; margin: 0; text-transform: uppercase">
                {{ pedidoCocina.comentario }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-spinner *ngIf="in"></app-spinner>
