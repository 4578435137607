<div
  [style.display]="menu ? '' : 'none'"
  style="
    z-index: 1;
    height: 50px;
    width: 100% !important;
    position: fixed;
    top: 0;
  "
>
  <app-barra-menu></app-barra-menu>
  <app-panel-notificaciones></app-panel-notificaciones>
</div>

<div class="contenido-cuerpo">
  <router-outlet></router-outlet>
</div>
