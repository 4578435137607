<div class="row justify-content-center align-items-center my-2">
  <div class="col-9 col-md-10 text-center">
    <h3>
      {{ nomLinea }}
      <small class="text-muted"></small>
    </h3>
  </div>
  <div
    class="col-2 col-md-1 justify-content-center align-items-center"
    (click)="modalReferenciaItems.hide()"
  >
    <button type="button" class="close pull-right" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>

<div class="text-center">
  <button class="btn btn-outline-success mr-1" (click)="agregar()">
    Agregar
  </button>
</div>
<div class="container">
  <div
    class="row justify-content-center align-items-center"
    *ngIf="hayProductos === true; else sinProductos"
  >
    <div class="col-11">
      <div
        class="row justify-content-center justify-content-md-start align-items-center"
      >
        <div
          class="col-11 col-md-4 col-lg-3 col-xl-3 py-3"
          *ngFor="let producto of productos; index as i"
        >
          <div class="card">
            <div
              class="card-header d-flex justify-content-center align-items-center p-2 boton"
              style="min-height: 70px; display: flex"
              (click)="aumentarCantidad(i)"
            >
              <p class="m-0 p-0">{{ producto.nombre }}</p>
            </div>
            <div class="card-body p-2">
              <div class="row">
                <div class="col-3">
                  <h6>
                    <span class="badge badge-primary"
                      >{{ producto.porc_tarifa.toFixed(0) }} %</span
                    >
                  </h6>
                </div>
                <div class="col-7 text-right">
                  <h6 class="font-weight-bold text-dark">
                    $ {{ producto.precio.toFixed(2) }}
                  </h6>
                </div>
              </div>

              <div class="row justify-content-center align-items-center">
                <div class="col-9 col-xl-11 p-2">
                  <div class="input-group">
                    <div
                      class="input-group-prepend"
                      (click)="restarCantidad(i)"
                    >
                      <button
                        type="button"
                        class="btn btn-outline-danger btn-sm"
                      >
                        -
                      </button>
                    </div>
                    <input
                      style="text-align: right"
                      type="numeric"
                      class="form-control text-right"
                      placeholder="{{ producto.cantidad }}"
                      [(ngModel)]="producto.cantidad"
                      appOnlynumber
                    />
                    <div
                      class="input-group-append"
                      (click)="aumentarCantidad(i)"
                    >
                      <button
                        type="button"
                        class="btn btn-outline-success btn-sm"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #sinProductos>
  <ng-container *ngIf="hayProductos === false"
    >No existen Items en esta Sección.</ng-container
  >
</ng-template>

<app-spinner></app-spinner>
