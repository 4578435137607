import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-apertura',
  templateUrl: './apertura.component.html',
  styleUrls: ['./apertura.component.css'],
})
export class AperturaComponent implements OnInit {
  modalReferencia: BsModalRef;
  banderaSpinner: boolean = false;
  empleados: any = [];
  productos: any = [];
  monedas: any = [];
  billetes: any = [];

  admin: boolean = false;
  sucursales: any;
  sucursal: string = 'todos';
  empresa: any;
  datosempresa: any;
  datosUm: any = [];
  tarifas: any = [];
  tarifa: string = '';
  totalMonedas: any;
  totalBilletes: any;
  totalGeneral: any;
  constructor(
    private router: Router,
    private servicio: RestauranteService,
    private modalServicio: BsModalService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    if (!localStorage.getItem('usuario')) {
      this.router.navigate(['login']);
    }

    //REVISAR SI NO AFECTO
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.datosempresa = JSON.parse(localStorage.getItem('empresa'));
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }

    //this.cargarLocalStorage();
    this.getUND();

    this.admin = JSON.parse(localStorage.getItem('usuario')).admin;
    this.sucursal = JSON.parse(localStorage.getItem('sucursal')).codigo;
    this.cargarSucursales();
    this.cargoproductos();
    this.cargomonedas();
    this.cargobilletes();
    this.calculaTotales();
  }

  grabarApertura(){
    this.alerta(
      'Error de conexión',
      'Se perdió la conexión con el servidor',
      'error'
    );
  }
  cargoproductos() {
    let campos = {
      empresa: this.empresa,
    };

    this.servicio.getProdcocina(campos).subscribe(
      (result) => {
        console.log(result);
        if (result.length > 0) {
          this.productos = result;
        }
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
  }

  cargomonedas() {
    this.servicio.getDivisas('MONEDA', '039').subscribe(
      (result) => {
        console.log(result);
        if (result.length > 0) {
          this.monedas = result;
        }
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
  }

  calculaMonedas(moneda) {
    moneda.subtotal = moneda.inicial * moneda.valor;
  }

  calculaBilletes(billete) {
    billete.subtotal = billete.inicial * billete.valor;
  }

  cargobilletes() {
    this.servicio.getDivisas('BILLETE', '039').subscribe(
      (result) => {
        console.log(result);
        if (result.length > 0) {
          this.billetes = result;
        }
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
  }

  // Función para sumar los subtotales de un arreglo
  sumarSubtotales = (items) => {
    return items.reduce((total, item) => {
      return total + item.subtotal;
    }, 0);
  };

  calculaTotales() {
    // Sumar los subtotales de ambos arreglos
    this.totalMonedas = this.sumarSubtotales(this.monedas);
    this.totalBilletes = this.sumarSubtotales(this.billetes);

    // Calcular el total general
    this.totalGeneral = this.totalMonedas + this.totalBilletes;
  }

  //Obtiene todas las unidades de medida
  getUND() {
    this.servicio.getConfiguraciones('008', this.empresa).subscribe((data) => {
      this.datosUm = data;
      this.datosUm.forEach((datoUm) => {
        datoUm.codigo = datoUm.codigo.trim();
        datoUm.nombre = datoUm.nombre.trim();
      });
    });
  }

  cargarSucursales() {
    this.servicio
      .getConfiguraciones('015', this.empresa)
      .subscribe((result) => {
        //console.log(result);
        if (result.length > 0) {
          this.sucursales = result;
        }
      });
  }

  limpiarInput(item, campo) {
    item[campo] = 0;
    if (isNaN(item[campo])) {
      item[campo] = 0;
    }
    if (item[campo] === undefined) {
      item[campo] = 0;
    }
  }

  verificarInput(item, campo, i) {
    if (isNaN(item[campo])) {
      item[campo] = 0;
    }

    if (item[campo] === '') item[campo] = item[campo + '_ant'];
    if (item[campo] < 0) item[campo] = item[campo + '_ant'];
    item[campo + '_ant'] = item[campo];

    this.calculaMonedas(item);
    this.calculaBilletes(item);
    this.calculaTotales();
  }

  limitarDecimales(event, decimales, valor) {
    console.log(event.key);
    if (event.key == '.') {
      if (decimales === 0) return false;
      else return true;
    }

    if (isNaN(parseInt(event.key))) return true;
    let val = (valor + '').split('.');
    if (val.length > 1) {
      let decima = val[1];
      if (decima.length > decimales - 1) return false;
    }
    return true;
  }

  alerta(titulo, texto, icono) {
    return Swal.fire({
      icon: icono,
      title: titulo,
      text: texto,
      allowOutsideClick: false,
    });
  }
}
