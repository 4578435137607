import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalReporteGastosComponent } from 'src/app/components/restaurante/gastos/modales/modal-reporte-gastos/modal-reporte-gastos.component';
@Component({
  selector: 'app-reporte-gastos',
  templateUrl: './reporte-gastos.component.html',
  styleUrls: ['./reporte-gastos.component.css'],
})
export class ReporteGastosComponent implements OnInit {
  modalReferencia: BsModalRef;
  campaignOne: FormGroup;

  tipos: any = [];
  tipo: string = 'TODOS';

  servicios: any = [];
  servicio: string = 'TODOS';

  cabecera: any;
  movimiento: any;
  regmov: number = 0;

  selector: number = 1;

  admin: boolean = false;
  sucursales: any;
  sucursal: string = 'TODOS';
  empresa: any;
  //Variable de evento de pagina
  p: Number = 1;
  datosempresa: any;
  constructor(
    private service: RestauranteService,
    private spinner: NgxSpinnerService,
    private modalServicio: BsModalService
  ) {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    const day = today.getDate();
    //console.log(today);

    this.campaignOne = new FormGroup({
      start: new FormControl(new Date(year, month, 1)),
      end: new FormControl(new Date(year, month, day)),
    });
  }

  ngOnInit(): void {
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.datosempresa = JSON.parse(localStorage.getItem('empresa'));
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
    this.cargarDatos();
    this.cargarTipos();
    this.cargarServicios();

    this.admin = JSON.parse(localStorage.getItem('usuario')).admin;

    if (this.admin) this.cargarSucursales();
    else this.sucursal = JSON.parse(localStorage.getItem('sucursal')).codigo;
  }

  buscarFecha() {
    if (this.campaignOne.value.end) {
      this.cargarDatos();
    }
  }

  cargarDatos() {
    this.campaignOne.value.end.toJSON();
    let datos = {
      tipo: this.selector,
      tipo_gasto: this.tipo,
      servicio_gasto: this.servicio,
      desde:
        this.formatearFechaQuery(this.campaignOne.value.start.toJSON()) +
        '00:00:00',
      hasta:
        this.formatearFechaQuery(this.campaignOne.value.end.toJSON()) +
        '23:59:59',
      tabla: 1,
      sucursal: this.sucursal,
      empresa: JSON.parse(localStorage.getItem('empresa')).codigo,
    };
    console.log(datos);
    this.service.reporteGastos(datos).subscribe((cabeceras) => {
      console.log(cabeceras);

      this.cabecera = cabeceras;
      datos['tabla'] = 2;
      this.service.reporteGastos(datos).subscribe((movimientos) => {
        console.log(movimientos);

        for (let i = 0; i < cabeceras.length; i++) {
          for (let e = 0; e < movimientos.length; e++) {
            const element = cabeceras[i];
            switch (element.tipodato.trim()) {
              case 'char':
                movimientos[e][element['campo']] =
                  movimientos[e][element['campo']].trim();
                break;
              case 'numerico':
                movimientos[e][element['campo']] = parseFloat(
                  movimientos[e][element['campo']]
                ).toFixed(parseInt(element['longitud']));
                break;
              case 'date':
                movimientos[e][element['campo']] = this.formatearFechaString(
                  movimientos[e][element['campo']]
                );
                break;
              case 'datetime':
                movimientos[e][element['campo']] = this.formatearFechaString(
                  movimientos[e][element['campo']],
                  true
                );
                break;
              default:
                console.log(element);
                break;
            }
          }
        }

        this.movimiento = movimientos;
        this.regmov = this.movimiento.length;
      });
    });
  }

  formatearFechaString(fecha = new Date(), hora = false) {
    //new Date().toJSON().slice(0,10).split('-').reverse().join('/')
    let fechaFin = new Date(fecha);
    let fechaString = fechaFin
      .toJSON()
      .slice(0, 10)
      .split('-')
      .reverse()
      .join('/');
    if (hora) {
      //Formatear hora
      return fechaString + ' ' + fechaFin.toJSON().slice(11, 19);
    }
    return fechaString;
  }

  formatearMes(fecha = new Date()) {
    let fechaFormateada = this.formatearFechaString(fecha).split('/');
    let fechaMes = fechaFormateada[2] + fechaFormateada[1];
    console.log(fechaMes);
    return fechaMes;
  }

  cargarTipos() {
    this.service.getConfiguraciones('013', this.empresa).subscribe((result) => {
      console.log(result);

      result.forEach((element) => {
        let mini = {
          codigo: element.codigo,
          nombre: element.nombre,
        };
        this.tipos.push(mini);
      });
    });
  }

  cargarServicios() {
    this.service.getConfiguraciones('016', this.empresa).subscribe((result) => {
      console.log(result);

      result.forEach((element) => {
        let mini = {
          codigo: element.codigo,
          nombre: element.nombre,
        };
        this.servicios.push(mini);
      });
    });
  }

  formatearFechaQuery(fecha = new Date()) {
    let fechaFin = new Date(fecha);
    let fechaTotal = fechaFin.toJSON().slice(0, 11);
    return fechaTotal;
  }

  //Abre el modal
  abrirModalNuevo(fecha,sucursal,nomsuc) {
    const initialState: any = {
      datos: [fecha, this.tipo, this.servicio, sucursal,nomsuc],
    };
    this.modalReferencia = this.modalServicio.show(
      ModalReporteGastosComponent,
      {
        initialState,
        ignoreBackdropClick: true,
        class: 'modal-xxl',
      }
    );
  }

  cargarSucursales() {
    this.service.getConfiguraciones('015', this.empresa).subscribe((result) => {
      console.log(result);
      if (result.length > 0) {
        this.sucursales = result;
      }
    });
  }
}
