<div class="barra-titulo">
  <div class="modal-header">
    <h4 class="modal-title" id="titulo">
      Mesa <b># {{ mesa.mesa }} - {{ mesa.numero }}</b>
    </h4>

    <!--boton facturar-->
    <div class="d-flex justify-content-center" *ngIf="total > 0">
      <button class="btn btn-warning mx-3" >
        Enviar a cocina
      </button>
      <button
        type="button"
        class="btn btn-primary mx-3"
        
        *ngIf="mesa.nuevo"
      >
        <i class="far fa-calendar-check"></i> Realizar Pedido
      </button>
      <button
        type="button"
        class="btn btn-success mx-3"
        
        *ngIf="!mesa.nuevo"
      >
        <i class="far fa-calendar-check"></i> Actualizar Pedido
      </button>

      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="cerrarModal('Cerrar')"
      >
        <span aria-hidden="true">&times; </span>
      </button>
    </div>
  </div>
</div>

<div class="container-fluid my-2">
  <div class="row justify-content-center my-3">
    <!--Datos de la factura-->
    <div class="col-12 col-md-6">
      <div class="container-fluid">
        <!--Datos del cliente-->
        <div class="row justify-content-center">
          <!--colapsable del cliente-->
          <div class="col-12 col-lg-7 my-2 p-0 pr-1">
            <div class="card p-0" (click)="abrirCollapseCliente()">
              <div class="card-header m-0 p-0 py-1">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-10 p-0 text-center py-1">
                      <h6 class="m-0 p-0 bloquea-select">
                        {{ clienteCollapse }}
                      </h6>
                    </div>
                    <div class="col-2 text-right">
                      <span
                        ><i
                          id="datos_cliente_icon"
                          class="fas fa-chevron-down"
                        ></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-lg-2">
                <div class="container p-0">
                  <div class="row p-0 justify-content-center">
                    <div class="col-11 p-0">
                      <div class="input-group my-1">
                        <div class="input-group-prepend">
                          <button
                            type="button"
                            class="btn btn-sm btn-outline-primary"
                            (click)="abrirModalDetallesCliente(cliente)"
                          >
                            <i class="fas fa-user-cog"></i>
                          </button>
                        </div>
                        <!--
                        <div class="input-group-prepend">
                          <button
                            type="button"
                            class="btn btn-sm btn-outline-success"
                            (click)="abrirModalAgregarCliente()"
                          >
                            <i class="far fa-plus-square"></i>
                          </button>
                        </div>
-->
                        <div class="input-group-prepend">
                          <button
                            type="button"
                            class="btn btn-sm btn-outline-dark"
                            (click)="traerConsumidorFinal()"
                          >
                            <i class="fas fa-house-user"></i>
                          </button>
                        </div>
                        <div
                          class="input-group-prepend text-center"
                          (click)="abrirCollapseCliente()"
                        >
                          <span class="input-group-text" id="ruc"
                            ><i class="fas fa-id-card-alt"></i
                          ></span>
                        </div>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          placeholder="Cedula | Ruc | Nombre"
                          [(ngModel)]="cliente.ruc"
                          (keyup.enter)="verificarRUC()"
                        />
                        <div
                          class="input-group-append"
                          (click)="abrirModalBuscarCliente()"
                        >
                          <span class="input-group-text" id="ruc"
                            ><i class="far fa-address-book"></i
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="datos_cliente_contenido" style="display: none">
                  <div *ngIf="cliente.nombre">
                    <div class="input-group my-1">
                      <div class="input-group-prepend text-center">
                        <span class="input-group-text" id="nombre"
                          ><i class="far fa-id-card"></i
                        ></span>
                      </div>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Nombre Completo"
                        [(ngModel)]="cliente.nombre"
                      />
                    </div>

                    <div class="input-group my-1">
                      <div class="input-group-prepend text-center">
                        <span class="input-group-text" id="direccion"
                          ><i class="fas fa-map-marked-alt"></i
                        ></span>
                      </div>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Direccion"
                        [(ngModel)]="cliente.direccion"
                      />
                    </div>

                    <div class="input-group my-1">
                      <div class="input-group-prepend text-center">
                        <span class="input-group-text" id="telefono"
                          ><i class="fab fa-whatsapp"></i
                        ></span>
                      </div>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Telefonos"
                        [(ngModel)]="cliente.telefono"
                      />
                    </div>

                    <div class="input-group my-1">
                      <div class="input-group-prepend text-center">
                        <span class="input-group-text" id="correo"
                          ><i class="fas fa-envelope-open-text"></i
                        ></span>
                      </div>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Correo Electronico"
                        [(ngModel)]="cliente.correo"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--totales del documento-->
          <div class="col-12 col-lg-5 my-2 p-0">
            <div class="card p-0" (click)="abrirCollapseTotales()">
              <div class="card-header m-0 p-0 py-1">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-6 p-0 text-right py-1">
                      <h6 class="m-0 p-0 bloquea-select">TOTAL : $</h6>
                    </div>
                    <div class="col-4 p-0 text-right py-1">
                      <h6 class="m-0 p-0 bloquea-select">
                        {{ total.toFixed(2) }}
                      </h6>
                    </div>
                    <div class="col-2 text-right">
                      <span
                        ><i
                          id="datos_totales_icon"
                          class="fas fa-chevron-down"
                        ></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body py-3">
                <div class="row">
                  <div
                    class="col-6 p-0 text-right font-weight-bold"
                    style="color: #e2652a"
                    *ngIf="formapago == '001'"
                  >
                    Cambio :
                  </div>

                  <div
                    class="col-6 p-0 text-right font-weight-bold"
                    style="color: #e2652a"
                    *ngIf="formapago != '001'"
                  >
                    {{ strformapago }} :
                  </div>

                  <div
                    class="col-4 p-0 offset-col-2 text-right font-weight-bold"
                    style="color: #e2652a"
                  >
                    ${{ cambio.toFixed(2) }}
                  </div>
                </div>

                <div id="datos_totales_contenido" style="display: none">
                  <div class="row">
                    <div class="col-12">
                      <hr class="m-1" style="color: #e2652a" />
                    </div>
                    <div class="col-6 p-0 text-right">Subtotal :</div>
                    <div class="col-4 p-0 offset-col-2 text-right">
                      ${{ subtotal.toFixed(2) }}
                    </div>

                    <div class="col-6 p-0 text-right">Impuesto :</div>
                    <div class="col-4 p-0 offset-col-2 text-right">
                      $ {{ impuesto.toFixed(2) }}
                    </div>

                    <div
                      class="col-6 p-0 text-right font-weight-bold"
                      style="color: #e2652a"
                    >
                      Total :
                    </div>
                    <div
                      class="col-4 p-0 offset-col-2 text-right font-weight-bold"
                      style="color: #e2652a"
                    >
                      $ {{ total.toFixed(2) }}
                    </div>
                  </div>

                  <div class="row" *ngIf="formapago == '001'">
                    <div class="col-6 p-0 text-right">Efectivo :</div>
                    <div class="col-4 p-0 offset-col-2 text-right">
                      ${{ efectivoEscogido.toFixed(2) }}
                    </div>

                    <div
                      class="col-6 p-0 text-right font-weight-bold"
                      style="color: #e2652a"
                    >
                      Cambio :
                    </div>
                    <div
                      class="col-4 p-0 offset-col-2 text-right font-weight-bold"
                      style="color: #e2652a"
                    >
                      ${{ cambio.toFixed(2) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Detalle de la Factura-->
        <div class="row justify-content-center" *ngIf="total > 0">
          <div class="col-12 p-0">
            <div class="card">
              <div class="card-header py-1">
                <div class="container-fluid">
                  <!--Cabecera del movimiento-->
                  <div
                    class="row justify-content-center align-items-center p-0 m-0"
                  >
                    <!--Solo para moviles-->
                    <div
                      class="d-block d-lg-none col-lg-2 p-0 m-0 text-center font-weight-bold"
                    >
                      Detalle
                    </div>

                    <!--para el resto de dispositivos-->

                    <div
                      class="d-none d-lg-block col-6 col-lg-6 p-0 m-0 text-md-center font-weight-bold"
                    >
                      Nombre
                    </div>
                    <div
                      class="d-none d-lg-block col-3 col-lg-3 p-0 m-0 text-center font-weight-bold"
                    >
                      Cantidad
                    </div>
                    <div
                      class="d-none d-lg-block col-2 text-right p-0 m-0 text-success font-weight-bold"
                    >
                      Total
                    </div>
                    <div class="col-lg-1 p-0 m-0"></div>
                  </div>
                </div>
              </div>
              <div class="card-body p-2">
                <div
                  class="m-0 p-0"
                  *ngFor="
                    let producto of productos
                      | paginate
                        : {
                            itemsPerPage: 25,
                            currentPage: p,
                            totalItems: productos.count
                          };
                    index as i
                  "
                >
                  <div
                    class="row justify-content-center align-items-center p-0 m-0"
                    *ngIf="producto.cantidad > 0"
                    id="{{ producto.codigo }}"
                  >
                    <!--btn elimina y nombre-->
                    <div class="col-12 col-lg-6 p-0 m-0">
                      <div class="container-fluid p-1">
                        <div
                          class="row justify-content-center align-items-center p-0 m-0"
                        >
                          <div class="col-2 p-0 m-0 text-center">
                            <button
                              type="button"
                              class="btn btn-outline-warning btn-sm"
                              (click)="borrarItem(producto.codigo)"
                            >
                              <i class="far fa-trash-alt"></i>
                            </button>
                          </div>

                          <div class="col-10 p-0 m-0 text-left">
                            <h6 class="p-0 m-0">
                              {{ producto.nombre
                              }}<span class="badge badge-secondary ml-2 p-1"
                                >$ {{ producto.precio.toFixed(2) }}</span
                              >
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!--btn cantidad, precio y receta-->
                    <div class="col-12 col-lg-6 p-0 m-0">
                      <div class="container-fluid p-1">
                        <div
                          class="row justify-content-center align-items-center p-0 m-0"
                        >
                          <div class="col-5 p-0 m-0">
                            <div class="input-group">
                              <div
                                class="input-group-prepend"
                                (click)="restarCantidad(producto.codigo)"
                              >
                                <button
                                  type="button"
                                  class="btn btn-outline-danger btn-sm"
                                >
                                  <i class="fas fa-minus"></i>
                                </button>
                              </div>
                              <input
                                style="text-align: right"
                                type="numeric"
                                class="form-control form-control-sm text-right"
                                placeholder="{{ producto.cantidad }}"
                                [(ngModel)]="producto.cantidad"
                                appOnlynumber
                                (keyup)="
                                  calcularValores(producto.codigo, $event)
                                "
                              />
                              <div
                                class="input-group-append"
                                (click)="aumentarCantidad(producto.codigo)"
                              >
                                <button
                                  type="button"
                                  class="btn btn-outline-success btn-sm"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>
                              </div>
                            </div>
                          </div>

                          <div class="col-4 p-0 m-0 text-right align-middle">
                            <p
                              style="font-size: 1.2rem"
                              class="text-success p-0 m-0"
                            >
                              <strong
                                >$
                                {{
                                  (
                                    producto.precio * producto.cantidad +
                                    (producto.precio *
                                      producto.cantidad *
                                      producto.porc_tarifa) /
                                      100
                                  ).toFixed(2)
                                }}</strong
                              >
                            </p>
                            <span
                              class="badge badge-dark"
                              *ngIf="producto.porc_tarifa != '0'"
                              >incl.IVA</span
                            >
                          </div>

                          <div class="col my-1 text-center align-middle">
                            <button
                              type="button"
                              class="btn btn-outline-dark btn-sm"
                              (click)="consultarReceta(i)"
                            >
                              <i class="fas fa-tasks"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Botones de seleccion de cambio-->
        <div
          class="row justify-content-center align-items-center"
          *ngIf="formapago == '001'"
        >
          <div class="col-12">
            <div
              class="row justify-content-center align-items-center mt-2"
              *ngIf="total > 0"
            >
              <div
                class="col-6 col-md-3 col-lg-3 col-xl-2 my-1 text-center"
                *ngIf="efectivo[0] > 0"
              >
                <button
                  type="button"
                  class="btn btn-est btn-sm"
                  [class]="
                    efectivoColor[0]
                      ? 'btn-outline-secondary'
                      : 'btn-outline-info'
                  "
                  (click)="pintarEfectivo(1)"
                >
                  {{ efectivo[0].toFixed(2) }}
                </button>
              </div>
              <div
                class="col-6 col-md-3 col-lg-3 col-xl-2 my-1 text-center"
                *ngIf="efectivo[2] > 0"
              >
                <button
                  type="button"
                  class="btn btn-est btn-sm"
                  [class]="
                    efectivoColor[2]
                      ? 'btn-outline-secondary'
                      : 'btn-outline-info'
                  "
                  (click)="pintarEfectivo(3)"
                >
                  {{ efectivo[2].toFixed(2) }}
                </button>
              </div>
              <div
                class="col-6 col-md-3 col-lg-3 col-xl-2 my-1 text-center"
                *ngIf="efectivo[1] > 0"
              >
                <button
                  type="button"
                  class="btn btn-est btn-sm"
                  [class]="
                    efectivoColor[1]
                      ? 'btn-outline-secondary'
                      : 'btn-outline-info'
                  "
                  (click)="pintarEfectivo(2)"
                >
                  {{ efectivo[1].toFixed(2) }}
                </button>
              </div>
              <div
                class="col-6 col-md-3 col-lg-3 col-xl-2 my-1 text-center"
                *ngIf="efectivo[3] > 0"
              >
                <button
                  type="button"
                  class="btn btn-est btn-sm"
                  [class]="
                    efectivoColor[3]
                      ? 'btn-outline-secondary'
                      : 'btn-outline-info'
                  "
                  (click)="pintarEfectivo(4)"
                >
                  {{ efectivo[3].toFixed(2) }}
                </button>
              </div>
              <div
                class="col-6 col-md-3 col-lg-3 col-xl-2 my-1 text-center"
                *ngIf="efectivo[4] > 0"
              >
                <button
                  type="button"
                  class="btn btn-est btn-sm"
                  [class]="
                    efectivoColor[4]
                      ? 'btn-outline-secondary'
                      : 'btn-outline-info'
                  "
                  (click)="pintarEfectivo(5)"
                >
                  {{ efectivo[4].toFixed(2) }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!--botones de tipo de entrega-->
        <div
          class="row justify-content-center align-items-center my-3"
          *ngIf="total > 0"
        >
          <div class="col-12 col-sm-6">
            <div class="d-flex justify-content-center mt-2">
              <mat-form-field appearance="fill" class="no-padding">
                <mat-label>Tipos de Entregas</mat-label>
                <mat-select
                  panelClass="my-select-panel-class"
                  [(value)]="tipoentrega"
                  (selectionChange)="save_tipoentrega()"
                >
                  <mat-option
                    *ngFor="let entrega of tiposentregas"
                    [value]="entrega.codigo.trim()"
                    >{{ entrega.nombre }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="col-12 col-sm-6">
            <div class="d-flex justify-content-center mt-2">
              <mat-form-field appearance="fill" class="no-padding">
                <mat-label>Formas de Pago</mat-label>
                <mat-select
                  panelClass="my-select-panel-class"
                  [(value)]="formapago"
                  (selectionChange)="save_formapago()"
                >
                  <mat-option
                    *ngFor="let formapg of formaspagos"
                    [value]="formapg.codigo.trim()"
                    >{{ formapg.nombre }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 col-sm-6" *ngIf="tipoentrega == '002'">
            <div class="d-flex justify-content-center mt-2">
              <mat-form-field appearance="fill" class="no-padding">
                <mat-label>Servicios de Entregas</mat-label>
                <mat-select
                  panelClass="my-select-panel-class"
                  [(value)]="servicioentrega"
                >
                  <mat-option
                    *ngFor="let serventrega of serviciosentregas"
                    [value]="serventrega.codigo.trim()"
                    >{{ serventrega.nombre }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="col-12 col-sm-6" *ngIf="servicioentrega == '001'">
            <div class="d-flex justify-content-center mt-2">
              <mat-form-field appearance="fill" class="no-padding w-100">
                <mat-label>Distribuidor</mat-label>

                <mat-select
                  panelClass="my-select-panel-class"
                  [(value)]="distribuidor"
                >
                  <div style="display: flex; justify-content: center">
                    <mat-form-field
                      class="example-full-width busqueda"
                      style="padding-top: 10px; width: 90%"
                    >
                      <mat-label>Buscar</mat-label>
                      <input
                        matInput
                        [(ngModel)]="busqueda"
                        (keyup)="onKey()"
                        (keydown)="$event.stopPropagation()"
                      />
                    </mat-form-field>
                  </div>
                  <mat-option
                    *ngFor="let itemdistri of distribuidores"
                    [value]="itemdistri.cedula"
                    >{{ itemdistri.nombre }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div
          class="row justify-content-center align-items-center"
          *ngIf="total > 0"
        >
          <div class="col-12 col-md-10 p-0">
            <div class="form-group">
              <label for="exampleFormControlTextarea1">Comentario</label>
              <textarea
                [(ngModel)]="comentario"
                class="form-control"
                rows="1"
              ></textarea>
            </div>
          </div>

          <div class="col-6 col-md-4">
            <!--boton facturar-->
            <div
              class="card btn-outline-success p-0"
              [style.border-color]="datosempresa.color"
              style="border-bottom-style: solid"
              (click)="grabar()"
            >
              <div class="card-body p-0 text-center">
                <i class="far fa-calendar-check"></i>
                <h6>Grabar</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Botones de seleccion de grupos de productos-->
    <div class="col-12 col-md-6" *ngIf="lineas.length">
      <div class="row justify-content-center justify-content-md-start">
        <div class="col-10 col-sm-6 col-xl-3 p-1" *ngFor="let linea of lineas">
          <button
            type="button"
            class="btn my-1 w-100 text-center font-weight-bold"
            style="
              height: 90px;
              white-space: normal;
              color: white;
              background-color: #e2652a;
            "
            (click)="mostrarModalLinea(linea)"
          >
            {{ linea.nombre }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-spinner></app-spinner>
