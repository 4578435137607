import { Component, OnInit } from '@angular/core';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-login',
  templateUrl: './main-login.component.html',
  styleUrls: ['./main-login.component.css'],
})
export class MainLoginComponent implements OnInit {
  datos = { usuario: '', password: '' };

  sucursales: any;
  sucursal: string = ' ';

  empresa: string = ' ';
  empresas: any;

  usuarios: any;
  usuariosTotal: any;
  haysucursales = false;
  //Bandera para spinner

  constructor(
    private servicio: RestauranteService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    //Verifica bandera para ocultar spinner
    this.cargarsSelectores();
  }

  async cargarsSelectores() {
    let promesas = [];
    this.spinner.show();

    promesas.push(this.cargarEmpresas());

    await Promise.all(promesas).then((resultados) => {
      if (JSON.parse(localStorage.getItem('empresa')) !== null) {
        this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
      }

      if (JSON.parse(localStorage.getItem('sucursal')) !== null) {
        this.sucursal = JSON.parse(localStorage.getItem('sucursal'))['codigo'];
      }
    });
    //console.log('inicio', this.sucursal);
    promesas.push(this.cargarSucursales());
    this.spinner.hide();
  }

  cargarSucursales() {
    this.haysucursales = false;

    return new Promise((resolve, reject) => {
      this.servicio
        .getConfiguraciones('015', this.empresa)
        .subscribe((result) => {
          if (result.length > 0) {
            this.sucursal = result[0].codigo.trim();
            this.sucursales = result;
            this.haysucursales = true;
          }

          return resolve(result);
        });
    });
  }

  cargarEmpresas() {
    return new Promise((resolve, reject) => {
      this.servicio
        .getConfiguraciones('019', this.empresa)
        .subscribe((result) => {
          // console.log(result);
          if (result.length > 0) {
            //console.log('cambio de empresa');
            this.empresa = result[0].codigo.trim();
            this.empresas = result;
          }
          return resolve(result);
        });
    });
  }

  alerta(titulo, texto, icono) {
    return Swal.fire({
      icon: icono,
      title: titulo,
      text: texto,
      allowOutsideClick: false,
    });
  }

  //Redirecciona a tablero
  redireccionamiento(ruta, info = '') {
    //console.log(ruta);

    let sucursal = {
      codigo: this.sucursal.trim(),
    };
    this.sucursales.forEach((element) => {
      if (element.codigo.trim() == this.sucursal.trim())
        sucursal['nombre'] = element.nombre;
    });

    let empresa = {
      codigo: this.empresa.trim(),
    };
    this.empresas.forEach((element) => {
      if (element.codigo.trim() == this.empresa.trim()) {
        empresa['nombre'] = element.nombre.trim();
        empresa['urlLogo'] = element.urllogo.trim();
        empresa['color'] = element.color.trim();
        empresa['cal_artesanal'] = element.cal_artesanal.trim();
        empresa['acceso'] = element.acceso.trim();
      }
    });

    if (info != '') {
      localStorage.setItem('pagina', info);
    } else {
      localStorage.setItem('pagina', ruta);
    }

    localStorage.setItem('sucursal', JSON.stringify(sucursal));
    localStorage.setItem('empresa', JSON.stringify(empresa));
    this.router.navigate([ruta]);
  }

  save_sucursal() {
    let sucursal = {
      codigo: this.sucursal.trim(),
    };
    this.sucursales.forEach((element) => {
      if (element.codigo.trim() == this.sucursal.trim())
        sucursal['nombre'] = element.nombre;
    });
    localStorage.setItem('sucursal', JSON.stringify(sucursal));
    //console.log('Sucursal', sucursal);
  }

  save_empresa() {
    let empresa = {
      codigo: this.empresa.trim(),
    };
    this.empresas.forEach((element) => {
      if (element.codigo.trim() == this.empresa.trim()) {
        empresa['nombre'] = element.nombre.trim();
        empresa['urlLogo'] = element.urllogo.trim();
        empresa['color'] = element.color.trim();
        empresa['cal_artesanal'] = element.cal_artesanal.trim();
        empresa['acceso'] = element.acceso.trim();
      }
    });
    localStorage.setItem('empresa', JSON.stringify(empresa));

    this.cargarSucursales();
  }
}
