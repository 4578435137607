import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-productos',
  templateUrl: './modal-productos.component.html',
  styleUrls: ['./modal-productos.component.css'],
})
export class ModalProductosComponent implements OnInit {
  datos: Object[]; //<-- Debe tener el mismo nombre de la variable en producto
  producto: any = {}; //<-- Aqui se guardan los datos traidos desde producto
  productoCrear: any = { nombre: '', precio: '', porc_tarifa: '0' }; //<-- Nuevo producto a crear

  productoDatosOriginales: any; //<--Aqui se copian los datos que estan en producto
  lineas: any = []; //<-- Aqui se cargan las lineas
  grupos: any = []; //<-- Aqui se cargan los productos
  habilitarEstado = true; //<-- Si es true en la template se deshabilitan los inputs
  ocultarBotonesPie = true; //<-- Si es true se ocultan los botones pie de pagina
  ocultarCodigo = false; //<-- Si es true se oculta el label true
  ocultarBotones = false; //<-- Si es true se ocultan los botones cabeceras
  public event: EventEmitter<any> = new EventEmitter(); //<-- Para devolver datos a producto
  operacion: Number; //<--Indica si es 1 crear, 2 actualizar o 3 borrar
  titulo: String = 'Detalles : ';
  subtitulo: String = 'Producto';
  lineaEscogida: any; //<--Elemento enviado de aqui a la template y de la template aca
  grupoEscogido: any; //<--Elemento enviado de aqui a la template y de la template aca
  //Banderas para spinner
  banderaSpinner: boolean = false;
  banderaSpinner2: boolean = false;

  unidad: string;
  unidades: any;

  empresa: string = '';
  empresas: any;

  usuario: any;
  constructor(
    public modalReferencia: BsModalRef,
    private service: RestauranteService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.producto = this.datos[0];

    this.productoCrear.linea = this.producto.linea;
    this.productoCrear.nomlin = this.producto.nomlin;
    this.productoCrear.grupo = this.producto.grupo;
    this.productoCrear.nomgru = this.producto.nomgru;
    this.productoCrear.bloqueado = this.producto.bloqueado;
    this.productoCrear.compra = this.producto.compra;
    this.productoCrear.inv_cocina = this.producto.inv_cocina;
    this.productoCrear.mostrar_app = this.producto.mostrar_app;
    this.productoCrear.cant_viandas = this.producto.cant_viandas;

    this.subtitulo = this.producto.nombre;
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
    this.cargarSelectores();
    console.log(
      'MODAL ABIERTO, DATOS OBTENIDOS DESDE PRODUCTO: ',
      this.producto
    );
    this.productoDatosOriginales = JSON.parse(JSON.stringify(this.producto));
    setInterval(() => {
      if (this.banderaSpinner === true && this.banderaSpinner2 === true) {
        this.spinner.hide();
        this.banderaSpinner = false;
        this.banderaSpinner2 = false;
      }
    }, 100);
  }
  /*
  cargarEmpresas() {
    return new Promise((resolve, reject) => {
      this.service
        .getConfiguraciones('019', this.empresa)
        .subscribe((result) => {
          console.log(result);
          if (result.length > 0) {
            this.empresa = JSON.parse(localStorage.getItem('empresa')).codigo;
            this.cargarSelectores();
            console.log(this.empresa);
            this.empresas = result;
          }
          return resolve(result);
        });
    });
  }
*/
  cargarSelectores() {
    console.log('EMPRESA : ', this.empresa);
    this.service.getConfiguraciones('003', this.empresa).subscribe((data) => {
      console.log(data);
      this.lineas = data;
      this.lineaEscogida = this.producto.linea;
    });
    this.service.getConfiguraciones('004', this.empresa).subscribe((data) => {
      this.grupos = data;
      this.grupoEscogido = this.producto.grupo;
    });

    this.service.getConfiguraciones('008', this.empresa).subscribe((data) => {
      this.unidades = data;
      //this.unidad = this.producto.medida;
    });

    //this.cargarEmpresas();
  }

  // Habilita los campos
  actualizarProducto() {
    this.habilitarEstado = false;
    this.operacion = 2;
    this.titulo = 'Actualizar : ';
    this.subtitulo = this.producto.nombre;
    this.ocultarBotonesPie = false;
    this.ocultarCodigo = false;
    this.ocultarBotones = true;
    //Obtiene productos
    this.producto = JSON.parse(JSON.stringify(this.productoDatosOriginales));
    this.lineaEscogida = this.producto.linea;
    this.grupoEscogido = this.producto.grupo;
    console.log(this.producto);
    console.log(this.productoDatosOriginales);
  }

  //Habilita los campos y los limpia
  crearProducto() {
    this.habilitarEstado = false;
    this.operacion = 1;
    this.titulo = 'Nuevo : ';
    this.subtitulo = 'Producto';
    this.ocultarBotonesPie = false;
    this.ocultarCodigo = true;
    this.ocultarBotones = true;
    //Obtiene productos
    this.producto = JSON.parse(JSON.stringify(this.productoCrear));
    this.lineaEscogida = '';
    this.grupoEscogido = '';
    console.log(this.producto);
    console.log(this.productoDatosOriginales);
  }

  //Guarda el producto creado o actualizado
  enviarProducto() {
    let bloqueado = this.producto.bloqueado;
    let compra = this.producto.compra;
    let inv_cocina = this.producto.inv_cocina;
    let mostrar_app = this.producto.mostrar_app;
    let cant_viandas = this.producto.cant_viandas;

    if (this.producto.nombre == '') {
      this.alerta('Faltan datos', 'Por favor ingrese un nombre', 'warning');
      return;
    }

    if (this.producto.precio == '') {
      this.alerta('Faltan datos', 'Por favor ingrese un precio', 'warning');
      return;
    }
/*
    if (this.producto.porc_tarifa == '') {
      this.alerta('Faltan datos', 'Por favor ingrese una tarifa', 'warning');
      return;
    }
*/
    let nombre = this.producto.nombre;
    let precio = this.producto.precio.toString();
    precio = precio.replace(',', '.');
    let tarifa = this.producto.porc_tarifa.toString();
    tarifa = tarifa.replace(',', '.');
    let cod_linea = this.lineaEscogida;
    let cod_grupo = this.grupoEscogido;

    //Devuelve el producto
    if (this.operacion === 1 || this.operacion === 2) {
      //<-- Al crear o actualizar
      //Devuelve el producto
      let linea;
      for (let i = 0; i < this.lineas.length; i++) {
        if (
          parseInt(this.lineas[i].codigo.trim()) ===
          parseInt(this.lineaEscogida.trim())
        ) {
          linea = this.lineas[i].nombre.trim();
          break;
        }
      }
      if (linea === undefined) {
        linea = this.productoDatosOriginales.nomlin;
      }
      let grupo;
      for (let i = 0; i < this.grupos.length; i++) {
        if (
          parseInt(this.grupos[i].codigo) ===
          parseInt(this.grupoEscogido.trim())
        ) {
          grupo = this.grupos[i].nombre.trim();
          break;
        }
      }
      if (grupo === undefined) {
        grupo = this.productoDatosOriginales.nomgru;
      }
      this.producto.nombre = nombre.trim();
      this.producto.precio = precio;
      this.producto.porc_tarifa = tarifa;
      this.producto.nomlin = linea;
      this.producto.nomgru = grupo;
      this.producto.linea = cod_linea;
      this.producto.grupo = cod_grupo;
      this.producto.bloqueado = bloqueado;
      this.producto.compra = compra;
      this.producto.inv_cocina = inv_cocina;
      this.producto.mostrar_app = mostrar_app;
      this.producto.cant_viandas = cant_viandas;
    }
    Swal.fire('¡Éxito!', 'Operación exitosa.', 'success').then(() => {
      this.cerrarModal(this.operacion); //<-- 1 significa crear y 2 actualizar
    });
  }

  //Confirma o declina el borrado
  confirmaBorrar() {
    this.operacion = 3;
    this.cerrarModal(this.operacion); //<-- 3 significa eliminar

    /*Swal.fire({
      icon: 'error',
      title: '¿Deseas Eliminar?',
      text: '' + this.subtitulo,
      showDenyButton: true,
      confirmButtonText: `Sí`,
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.operacion = 3;
        Swal.fire('¡Producto eliminado!', '', 'success').then(() => {
          this.cerrarModal(this.operacion); //<-- 3 significa eliminar
        });
      }
    });*/
  }

  cerrarModal(valor) {
    if (valor === 1) {
      //<-- Al crear
      this.lanzarEvento(this.producto, valor); //<-- Envia la data a producto
    } else {
      if (valor === 2 || valor === 3) {
        //<-- Al actualizar o borrar
        this.lanzarEvento(this.producto, valor); //<-- Envia la data a producto
      }
    }
    this.modalReferencia.hide(); //<-- Cierra el modal
  }

  lanzarEvento(item, valor) {
    this.event.emit({ data: [valor, item], res: 200 }); //<-- Internamente envia la data a producto
  }

  cancelar() {
    this.titulo = 'Detalles : ';
    //Obtiene producto y rellena los campos
    this.habilitarEstado = true;
    this.ocultarBotonesPie = true;
    this.ocultarCodigo = false;
    this.ocultarBotones = false;
    this.producto = JSON.parse(JSON.stringify(this.productoDatosOriginales));
    console.log(this.lineaEscogida);
    this.lineaEscogida = this.producto.linea;
    this.grupoEscogido = this.producto.grupo;
    console.log(this.producto);
    console.log(this.productoDatosOriginales);
    this.subtitulo = this.producto.nombre;
  }

  alerta(titulo, texto, icono) {
    return Swal.fire({
      icon: icono,
      title: titulo,
      text: texto,
      allowOutsideClick: false,
      timer: 1000,
      showConfirmButton: false,
    });
  }
}
