import { Component, OnInit, DoCheck } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit, DoCheck {
  menu: any = [];
  existemenu = false;
  estaLogeado = false;
  hidden = false;
  nombre: string = '';
  usuario: string = '';
  tipo: string = 'Cliente';

  empresa: any = {
    nombre: '',
    color: '',
  };
  admin: boolean = true;

  b = [true, true, true, true, true, true, true];

  sucursal: any = {
    nombre: '',
  };

  pagina: string = '';

  filtros = [];
  menuFiltrado = [];
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private servicio: GlobalService
  ) {}

  ngOnInit(): void {
    if (localStorage.getItem('usuario')) {
      this.usuario = JSON.parse(localStorage.getItem('usuario'));
      this.sucursal = JSON.parse(localStorage.getItem('sucursal'));
      this.empresa = JSON.parse(localStorage.getItem('empresa'));
      this.pagina = localStorage.getItem('pagina');
      this.filtros = JSON.parse(localStorage.getItem('usuario')).acceso.split(
        '|'
      );
      this.cargarMenu();
    }
  }

  /*Detecta cambios que Angular no detectaria por si mismo y lo hace de manera global,
  por ejemplo se dispara cuando se hace una redireccion desde algun componente*/
  ngDoCheck() {
    if (localStorage.getItem('usuario')) {
      this.estaLogeado = true;
    } else {
      this.estaLogeado = false;
    }
  }

  //Cierra sesión
  logout() {
    localStorage.clear();
    //asigno lo guardado temporalemente
    localStorage.setItem('empresa', JSON.stringify(this.empresa));
    localStorage.setItem('sucursal', JSON.stringify(this.sucursal));

    location.href = '/login';
    // this.router.navigate(['login'])
  }

  //Redirecciona a tablero
  redireccionamiento(ruta) {
    //console.log(ruta);
    if (ruta == '') {
      //      this.router.navigate(['proximamente']);
      return;
    }
    this.hidden = false;
    this.router.navigate([ruta]).catch((err) => {
      //console.log(err);
      this.router.navigate(['proximamente']);
    });
  }

  cerrarMenu() {
    if (this.hidden) this.hidden = false;
    else this.hidden = true;
  }
  cerrarSesion() {
    this.spinner.show();
    localStorage.removeItem('usuario');
    localStorage.removeItem('notificacion');

    location.href = '/login';
  }

  desplegar(i, e = -1, o = -1, u = -1) {
    console.log(this.menuFiltrado[i]);
    /*if (this.b[i]) this.b[i] = false;
    else this.b[i] = true;*/
    if (u >= 0)
      this.menuFiltrado[i].submenu[e].submenu[o].submenu[u].open =
        !this.menuFiltrado[i].submenu[e].submenu[o].submenu[u].open;
    else if (o >= 0)
      this.menuFiltrado[i].submenu[e].submenu[o].open =
        !this.menuFiltrado[i].submenu[e].submenu[o].open;
    else if (e >= 0)
      this.menuFiltrado[i].submenu[e].open = !this.menu[i].submenu[e].open;
    else this.menuFiltrado[i].open = !this.menuFiltrado[i].open;
  }

  cargarMenu() {
    let campos = {
      empresa: JSON.parse(localStorage.getItem('empresa')).codigo,
      sucursal: JSON.parse(localStorage.getItem('sucursal')).codigo,
      empleado: JSON.parse(localStorage.getItem('usuario')).codigo,
    };
    this.servicio.getMenu(campos).subscribe((result) => {
      //console.log(result);
      if (result.length > 0) {
        this.existemenu = true;
      }
      this.menu = result;
      this.filtrarMenu();
    });
  }

  filtrarMenu() {
    for (let i = 0; i < this.menu.length; i++) {
      let submenu = this.menu[i];
      let resultado = this.filtroRecursivo(submenu);

      if (resultado != null) {
        this.menuFiltrado.push(resultado);
      }
    }
    //console.log(this.menuFiltrado);
  }

  filtroRecursivo(item) {
    if (item.submenu.length == 0) {
      for (let i = 0; i < this.filtros.length; i++) {
        const filtro = this.filtros[i];
        if (filtro == item.codigo) {
          //console.log(item);
          return item;
        }
      }
    } else {
      let lista = [];
      for (let e = 0; e < item.submenu.length; e++) {
        let element = item.submenu[e];
        let resultado = this.filtroRecursivo(element);
        if (resultado != null) {
          lista.push(resultado);
        }
      }
      if (lista.length > 0) {
        item.submenu = lista;
        return item;
      }
    }
    return null;
  }
}
