<div class="container-fluid my-2">
  <div class="row justify-content-center align-items-center">
    <div
      class="col-11 col-md-8 col-xl-4 text-center text-md-left text-xl-center"
    >
      <h3>
        Actualización
        <small class="text-muted">de Accesos a los Empleados</small>
      </h3>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-12 col-md-3">
      <div class="card">
        <div class="card-body">
          <mat-form-field
            appearance="fill"
            class="no-padding"
            style="width: 100%"
          >
            <mat-label>Seleccione un empleado</mat-label>

            <mat-select
              panelClass="my-select-panel-class"
              [(value)]="empleado"
              (selectionChange)="buscarEmpleado()"
            >
              <div style="display: flex; justify-content: center">
                <mat-form-field
                  class="example-full-width busqueda"
                  style="padding-top: 10px"
                >
                  <mat-label>Buscar</mat-label>
                  <input
                    matInput
                    [(ngModel)]="busqueda"
                    (keyup)="onKey()"
                    (keydown)="$event.stopPropagation()"
                  />
                </mat-form-field>
              </div>

              <mat-option
                *ngFor="let empleado of empleados"
                [value]="empleado.codigo"
                >{{ empleado.nombre }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <div *ngIf="empleado != ''">
            <div class="d-flex mb-3" style="align-items: center">
              <i class="far fa-user mr-3"></i>
              <h6 style="margin: 0">{{ empleadoCompleto.codigo }}</h6>
            </div>
            <div class="d-flex" style="align-items: center">
              <i class="fas fa-user mr-3"></i>
              <h6 style="margin: 0">{{ empleadoCompleto.nombre }}</h6>
            </div>
          </div>
        </div>
        <div class="card-footer text-right">
          <button
            class="btn btn-outline-success btn-sm"
            (click)="guardarMenu()"
          >
            <i class="far fa-save mr-2"></i> Guardar Acceso
          </button>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-4 mb-5" *ngIf="empleado != ''">
      <div class="card">
        <div class="card-body">
          <section
            class="example-section"
            *ngFor="let opcion of menu; index as i"
          >
            <span class="example-list-section">
              <mat-checkbox
                class="example-margin"
                [(ngModel)]="opcion.check"
                [indeterminate]="opcion.intermediario"
                (change)="checkPrincipal(i, menu)"
              >
                <label class="m-0 font-weight-bold">{{ opcion.nombre }}</label>
              </mat-checkbox>

              <i
                class="fas fa-chevron-down"
                *ngIf="opcion.submenu.length > 0 && !opcion.open"
                (click)="abrir(opcion)"
              ></i>
              <i
                class="fas fa-chevron-up"
                *ngIf="opcion.submenu.length > 0 && opcion.open"
                (click)="abrir(opcion)"
              ></i>
            </span>
            <span
              class="example-list-section"
              [style.display]="opcion.open ? 'block' : 'none'"
            >
              <ul>
                <li *ngFor="let submenu of opcion.submenu; index as e">
                  <mat-checkbox
                    class="mr-2"
                    [(ngModel)]="submenu.check"
                    [indeterminate]="submenu.intermediario"
                    (change)="checkPrincipal(e, opcion.submenu)"
                  >
                    {{ submenu.nombre }}
                  </mat-checkbox>
                  <i
                    class="fas fa-chevron-down"
                    *ngIf="submenu.submenu.length > 0 && !submenu.open"
                    (click)="abrir(submenu)"
                  ></i>
                  <i
                    class="fas fa-chevron-up"
                    *ngIf="submenu.submenu.length > 0 && submenu.open"
                    (click)="abrir(submenu)"
                  ></i>
                  <span
                    class="example-list-section"
                    [style.display]="submenu.open ? 'block' : 'none'"
                  >
                    <ul>
                      <li *ngFor="let submenu2 of submenu.submenu; index as o">
                        <mat-checkbox
                          [(ngModel)]="submenu2.check"
                          [indeterminate]="submenu2.intermediario"
                          (change)="checkPrincipal(o, submenu.submenu)"
                        >
                          {{ submenu2.nombre }}
                        </mat-checkbox>
                        <span class="example-list-section">
                          <i
                            class="fas fa-chevron-down"
                            *ngIf="
                              submenu2.submenu.length > 0 && !submenu2.open
                            "
                            (click)="abrir(submenu2)"
                          ></i>
                          <i
                            class="fas fa-chevron-up"
                            *ngIf="submenu2.submenu.length > 0 && submenu2.open"
                            (click)="abrir(submenu2)"
                          ></i>
                          <ul>
                            <li *ngFor="let submenu3 of submenu2.submenu">
                              <mat-checkbox [(ngModel)]="submenu3.check">
                                {{ submenu3.nombre }}
                              </mat-checkbox>
                            </li>
                          </ul>
                        </span>
                      </li>
                    </ul>
                  </span>
                </li>
              </ul>
            </span>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>

<app-spinner></app-spinner>
