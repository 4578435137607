<!--Cabecera-->
<div class="row justify-content-center align-items-center my-2">
  <div class="col-9 col-md-10 text-center">
    <h3>
      {{ titulo }}
      <small class="text-muted">{{ subtitulo }}</small>
    </h3>
  </div>
  <div
    class="col-2 col-md-1 justify-content-center align-items-center"
    (click)="modalReferencia.hide()"
  >
    <button type="button" class="close pull-right" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>

<!--Botones Superiores para Inciar las Transaaciones-->
<div class="container my-1" [hidden]="ocultarBotones">
  <button
    type="button"
    class="btn btn-outline-success btn-sm btn_est m-2"
    (click)="crearCuenta()"
  >
    <i class="far fa-plus-square"></i> Crear
  </button>

  <button
    type="button"
    class="btn btn-outline-warning btn-sm btn_est btn_est m-2"
    (click)="actualizarCuenta()"
  >
    <i class="fas fa-spinner"></i> Actualizar
  </button>

  <button
    type="button"
    class="btn btn-outline-danger btn-sm btn_est m-2"
    (click)="confirmaBorrar()"
  >
    <i class="fas fa-times-circle"></i> Eliminar
  </button>
</div>

<!--Datos del Cuenta-->
<div class="container">
  <div class="row justify-content-center align-items-center">
    <div class="col-11 col-md-3 text-md-right">
      <span>Codigo : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-8">
          <input
            [disabled]="habilitarEstado"
            type="numeric"
            class="form-control form-control-sm"
            placeholder="Ingrese Codigo"
            [(ngModel)]="cuenta.codigo"
            appOnlynumber
          />
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Cuenta Superior :</span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <span> </span>
    </div>

    <div class="col-11 col-md-3 justify-content-right">
      <div class="input-group">
        <input
          [disabled]="habilitarEstado"
          type="text"
          class="form-control form-control-sm text-right"
          aria-label="Busqueda"
          placeholder="Ingrese Superior"
          [(ngModel)]="cuenta.superior"
          (keyup)="busquedaCuenta()"
        />
        <div class="input-group-append" (click)="busquedaCuenta()">
          <span class="input-group-text" id="basic-addon1"
            ><i class="fab fa-searchengin"></i
          ></span>
        </div>
      </div>
    </div>

    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-8">
          <input
            disabled
            type="numeric"
            class="form-control form-control-sm"
            placeholder="Nombre Cuenta Superior"
            [(ngModel)]="cuenta.nom_superior"
            appOnlynumber
          />
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Nombre : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-8">
          <input
            [disabled]="habilitarEstado"
            type="text"
            class="form-control form-control-sm"
            placeholder="Ingrese el Nombre Completo Cuenta"
            [(ngModel)]="cuenta.nombre"
          />
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Categoria : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-8">
          <select
            class="form-select form-select-sm"
            name="cuenta.tipo"
            [(ngModel)]="cuenta.tipo"
            [disabled]="habilitarEstado"
          >
            <option *ngFor="let tipo of tipos" [ngValue]="tipo.codigo">
              {{ tipo.nombre }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Tipo Cuenta : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-8">
          <select
            class="form-select form-select-sm"
            name="cuenta.tipcta"
            [(ngModel)]="cuenta.tipcta"
            [disabled]="habilitarEstado"
          >
            <option
              *ngFor="let tcuenta of tiposcuentas"
              [ngValue]="tcuenta.codigo"
            >
              {{ tcuenta.nombre }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Clase: </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-8">
          <select
            class="form-select form-select-sm"
            name="cuenta.clase"
            [(ngModel)]="cuenta.clase"
            [disabled]="habilitarEstado"
          >
            <option *ngFor="let clase of clases" [ngValue]="clase.codigo">
              {{ clase.nombre }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Botones Inferiores de Transaccion-->
<div class="container text-right my-1" [hidden]="ocultarBotonesPie">
  <button
    type="button"
    class="btn btn-outline-success btn-sm btn_est m-2"
    (click)="enviarCuenta()"
  >
    <i class="fas fa-check-circle"></i> Guardar
  </button>

  <button
    type="button"
    class="btn btn-outline-danger btn-sm btn_est m-2"
    (click)="cancelar()"
  >
    <i class="fas fa-times-circle"></i> Cancelar
  </button>
</div>

<app-spinner></app-spinner>
