<!--CABECERA-->
<div class="modal-header" [style.background-color]="datosempresa['color']">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-4">
        <!--
        <button
          *ngIf="!edicion"
          type="button"
          class="btn btn-outline-warning mr-2"
          (click)="habilitarEdicion()"
        >
          <i class="far fa-edit"></i>
        </button>
-->
        <button
          *ngIf="edicion"
          type="button"
          class="btn btn-outline-danger mr-2"
          (click)="habilitarEdicion()"
        >
          <i class="far fa-times-circle"></i>
        </button>
      </div>
      <div class="col-12 col-sm-8">
        <h4 class="modal-title" style="color: white !important">
          {{ titulo }}
        </h4>
      </div>
    </div>
  </div>

  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="cerrar()"
    style="color: white !important"
  >
    <span aria-hidden="true">&times; </span>
  </button>
</div>

<!--DATOS DE la TRANSACCION-->
<div class="container-fluid">
  <div class="row my-2 justify-content-center">
    <div class="col-12 col-md-6 pt-1">
      <div class="card">
        <div class="card-body p-1">
          <h6
            class="card-subtitle mb-2 py-2 text-center"
            [style.color]="datosempresa['color']"
          >
            Datos del Proveedor
          </h6>
          <div class="input-group input-group-sm my-1">
            <div class="input-group-prepend text-center">
              <span class="input-group-text" id="ruc"
                ><i class="fas fa-id-card-alt"></i
              ></span>
            </div>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="tipo de movimiento"
              [(ngModel)]="cabecera.ruc"
              [disabled]="inhabilitado"
            />
          </div>
          <div class="input-group input-group-sm my-1">
            <div class="input-group-prepend text-center">
              <span class="input-group-text" id="nombre"
                ><i class="far fa-id-card"></i
              ></span>
            </div>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="tipo de movimiento"
              [(ngModel)]="cabecera.nombre"
              [disabled]="inhabilitado"
            />
          </div>

          <div class="input-group input-group-sm my-1">
            <div class="input-group-prepend text-center">
              <span class="input-group-text" id="direccion"
                ><i class="fas fa-map-marked-alt"></i
              ></span>
            </div>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="Direccion"
              [(ngModel)]="cabecera.direccion"
              [disabled]="inhabilitado"
            />
          </div>

          <div class="input-group input-group-sm my-1">
            <div class="input-group-prepend text-center">
              <span class="input-group-text" id="telefono"
                ><i class="fab fa-whatsapp"></i
              ></span>
            </div>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="Telefonos"
              [(ngModel)]="cabecera.telefono"
              [disabled]="inhabilitado"
            />
          </div>

          <div class="input-group input-group-sm my-1">
            <div class="input-group-prepend text-center">
              <span class="input-group-text" id="telefono"
                ><i class="fas fa-envelope"></i
              ></span>
            </div>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="Correo Electrónico"
              [(ngModel)]="cabecera.correo"
              [disabled]="inhabilitado"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6">
      <div class="card">
        <div class="card-body p-2">
          <h6
            class="card-subtitle mb-2 py-2 text-center"
            [style.color]="datosempresa['color']"
          >
            Datos de la Factura
          </h6>

          <div class="row align-items-center my-1">
            <div class="col-12">
              <p class="m-0 p-0">Doc. Compra : {{ cabecera.factura }}</p>
            </div>
            <!--
            <div class="col-6 d-flex">
              <input
                type="numeric"
                class="form-control form-control-sm mr-2 input-sm"
                [(ngModel)]="serie_doc"
                style="width: 75px"
                placeholder="001-001"
                mask="000-000"
                (focusout)="formatearFacturaNumber()"
              />

              <input
                type="numeric"
                class="form-control form-control-sm mr-2 input-sm"
                [(ngModel)]="numero_doc"
                style="width: 100px"
                maxlength="9"
                mask="0*"
                placeholder="0123456789"
                (focusout)="formatearFacturaNumber()"
              />
            </div>
            -->
          </div>

          <div class="row align-items-center my-1">
            <div class="col-12">
              <p class="m-0 p-0">Autorización : {{ cabecera.autorizacion }}</p>
            </div>
            <!--
            <div class="col-12">
              <input
                type="numeric"
                class="form-control form-control-sm"
                id="inputAutorizacion"
                [(ngModel)]="autoriza_doc"
                maxlength="49"
                placeholder="012345679 o 49 Digitos"
                mask="0*"
                (focusout)="formatearAutorizaNumber()"
              />
            </div>
            -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--BOTONES DE ACCIONES-->
  <div class="container-fluid my-2" *ngIf="edicion">
    <div class="row">
      <!--
          <div class="col-6">
            <button
              class="btn btn-sm btn-outline-primary"
              (click)="abrirModalLineas()"
            >
              <i class="fas fa-plus-circle mr-1"></i> Producto
            </button>
          </div>
    -->
      <div class="col-6 text-md-right">
        <button
          class="btn btn-sm btn-outline-success"
          (click)="actualizarFactura()"
        >
          <i class="far fa-check-circle mr-1"></i>Grabar
        </button>
      </div>
    </div>
  </div>

  <app-spinner></app-spinner>
</div>

<!--TABLA-->
<div class="container-fluid">
  <div class="table table-responsive m-0">
    <table class="table table-sm table-hover m-0 p-0">
      <thead class="thead-dark">
        <tr>
          <th
            scope="col"
            style="width: 80px; text-align: center"
            [style.background-color]="datosempresa['color']"
          >
            <p class="text-center m-0 p-0">Codigo</p>
          </th>
          <th scope="col" [style.background-color]="datosempresa['color']">
            <p class="text-center m-0 p-0">Descripción</p>
          </th>
          <th
            scope="col text-right"
            style="width: 125px"
            [style.background-color]="datosempresa['color']"
          >
            <p class="text-right m-0 p-0">Cantidad</p>
          </th>
          <th
            scope="col text-right"
            style="width: 115px"
            [style.background-color]="datosempresa['color']"
          >
            <p class="text-right m-0 p-0">Medida</p>
          </th>
          <th
            scope="col text-right"
            style="width: 115px"
            [style.background-color]="datosempresa['color']"
          >
            <p class="text-right m-0 p-0">Costo</p>
          </th>
          <th
            scope="col text-right"
            style="width: 115px"
            [style.background-color]="datosempresa['color']"
          >
            <p class="text-right m-0 p-0">Subtotal</p>
          </th>
          <th
            scope="col text-center"
            style="width: 25px; text-align: center"
            *ngIf="edicion"
            [style.background-color]="datosempresa['color']"
          ></th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let producto of cuerpo
              | paginate
                : {
                    itemsPerPage: 25,
                    currentPage: p,
                    totalItems: cuerpo.count
                  };
            index as i
          "
          id="{{ producto.codigo }}"
        >
          <td style="text-align: center">
            <p class="text-left m-0 p-0">
              {{ producto.codigo }}
            </p>
          </td>
          <td>
            <p class="text-left m-0 p-0">
              {{ producto.nombre }}
            </p>
          </td>
          <td>
            <p class="text-right m-0 p-0">
              {{ producto.cantidad }}
            </p>
          </td>
          <td>
            <p class="text-right m-0 p-0">
              {{ producto.nommedida }}
            </p>
          </td>
          <td>
            <p class="text-right m-0 p-0">
              {{ producto.costo.toFixed(2) }}
            </p>
          </td>
          <td>
            <p class="text-right m-0 p-0">
              <strong>
                {{ (producto.costo * producto.cantidad).toFixed(2) }}</strong
              >
            </p>
          </td>
          <td *ngIf="edicion">
            <button
              class="btn btn-sm btn-outline-danger"
              (click)="eliminarItem(i)"
            >
              <i class="fas fa-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <!--comentario-->
    <div class="col-12 col-md-6 py-2">
      <div class="card">
        <div class="card-body p-2">
          <h6 class="card-subtitle mb-2 text-muted text-center">Comentario</h6>
          <div class="input-group">
            <textarea
              class="form-control form-control-sm"
              placeholder="Ingresar Comentario"
              [(ngModel)]="cabecera.comentario"
              [disabled]="inhabilitado"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <!-- totales-->
    <div class="col-12 col-md-5 offset-md-1">
      <table class="table table-clear table-hover m-0 p-0">
        <tbody>
          <tr>
            <td class="m-0 py-1">
              <strong class="text-dark">Sumatoria</strong>
            </td>
            <td class="text-right m-0 p-1">
              {{ cabecera.sumatoria.toFixed(2) }}
            </td>
          </tr>
          <tr>
            <td class="m-0 py-1">
              <strong class="text-dark">Descuento</strong>
            </td>
            <td class="text-right m-0 p-1">
              {{ cabecera.descuento.toFixed(2) }}
            </td>
          </tr>
          <tr>
            <td class="m-0 py-1">
              <strong class="text-dark">Tarifa 0%</strong>
            </td>
            <td class="text-right m-0 p-1">
              {{ cabecera.tarifa0.toFixed(2) }}
            </td>
          </tr>
          <tr>
            <td class="m-0 py-1">
              <strong class="text-dark">Tarifa 12%</strong>
            </td>
            <td class="text-right m-0 p-1">
              {{ cabecera.tarifa12.toFixed(2) }}
            </td>
          </tr>
          <tr>
            <td class="m-0 py-1">
              <strong class="text-dark">Subtotal</strong>
            </td>
            <td class="text-right m-0 p-1">
              {{ cabecera.subtotal.toFixed(2) }}
            </td>
          </tr>

          <tr>
            <td class="m-0 py-1">
              <strong class="text-dark">Impuestos</strong>
            </td>
            <td class="text-right m-0 p-1">
              {{ cabecera.impuesto.toFixed(2) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="modal-footer" [style.background-color]="datosempresa['color']">
  <!--TOTALES-->
  <div class="container-fluid">
    <div class="row justify-content-center justify-content-md-end">
      <div class="col-12 col-md-4 text-center text-md-right" style="color: white !important">
        <h5>TOTAL : $ {{ cabecera.total.toFixed(2) }}</h5>
      </div>
    </div>
  </div>
</div>
