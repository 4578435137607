<!--Cabecera-->
<div class="row justify-content-center align-items-center my-2">
  <div class="col-9 col-md-10 text-center">
    <h3>
      {{ titulo }}
      <small class="text-muted">{{ item.nombre }}</small>
    </h3>
  </div>
  <div
    class="col-2 col-md-1 justify-content-center align-items-center"
    (click)="modalReferencia.hide()"
  >
    <button type="button" class="close pull-right" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>

<!--Botones Superiores para Inciar las Transaciones-->
<div class="container my-1" [hidden]="ocultarBotones">
  <button
    type="button"
    class="btn btn-outline-warning btn-sm btn_est btn_est m-2"
    (click)="actualizarConfiguracion()"
  >
    <i class="fas fa-spinner"></i> Actualizar
  </button>

  <button
    type="button"
    class="btn btn-outline-danger btn-sm btn_est m-2"
    (click)="confirmaBorrar()"
  >
    <i class="fas fa-times-circle"></i> Eliminar
  </button>
</div>

<!--Datos del Registro-->
<div class="container">
  <div class="row justify-content-center align-items-center">
    <div class="col-11 col-md-3 text-md-right">
      <span>Código : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-8">
          <input
            disabled
            type="text"
            class="form-control form-control-sm"
            [(ngModel)]="item.codigo"
          />
        </div>
      </div>
    </div>
    <div class="col-11 col-md-3 text-md-right">
      <span>Nombre : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <input
        [disabled]="habilitarEstado"
        type="text"
        class="form-control form-control-sm"
        placeholder="Ingrese el nombre"
        [(ngModel)]="item.nombre"
      />
    </div>
    <div *ngIf="item.codigoTco.trim() == '023'">
      <div class="row justify-content-center align-items-center">
        <div class="col-11 col-md-3 text-md-right">
          <span> Valor: </span>
        </div>
        <div class="col-11 col-md-8 my-1 d-flex">
          <div class="d-flex mr-3" style="align-items: baseline">
            <input
              [disabled]="habilitarEstado"
              type="numeric"
              class="form-control form-control-sm"
              [(ngModel)]="item.quincena[0]"
              style="max-width: 100px; text-align: right"
              appOnlynumber
            />
            <div class="mx-1" style="font-size: 24px">.</div>
            <input
              [disabled]="habilitarEstado"
              type="numeric"
              class="form-control form-control-sm"
              [(ngModel)]="item.quincena[1]"
              style="max-width: 80px"
              appOnlynumber
            />
          </div>
          <select
            class="form-select form-select-sm"
            [disabled]="habilitarEstado"
            aria-label="Default select example"
            [(ngModel)]="item.sucursal"
          >
            <option *ngFor="let und of unidades" [value]="und.codigo.trim()">
              {{ und.nombre }}
            </option>
          </select>
        </div>
      </div>

      <div class="row justify-content-center align-items-center">
        <div class="col-11 col-md-3 text-md-right">
          <span> Valor: </span>
        </div>
        <div class="col-11 col-md-8 my-1 d-flex">
          <div class="d-flex mr-3" style="align-items: baseline">
            <input
              type="numeric"
              [disabled]="habilitarEstado"
              class="form-control form-control-sm"
              [(ngModel)]="item.findemes[0]"
              style="max-width: 100px; text-align: right"
              appOnlynumber
            />
            <div class="mx-1" style="font-size: 24px">.</div>
            <input
              [disabled]="habilitarEstado"
              type="numeric"
              class="form-control form-control-sm"
              [(ngModel)]="item.findemes[1]"
              style="max-width: 80px"
              appOnlynumber
            />
          </div>

          <select
            [disabled]="habilitarEstado"
            class="form-select form-select-sm"
            aria-label="Default select example"
            [(ngModel)]="item.perfil"
          >
            <option *ngFor="let und of unidades" [value]="und.codigo.trim()">
              {{ und.nombre }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div *ngIf="item.codigoTco.trim() == '011'">
      <div class="row justify-content-center align-items-center">
        <div class="col-11 col-md-3 text-md-right">
          <span>Clave : </span>
        </div>
        <div class="col-11 col-md-8 my-1">
          <input
            [disabled]="habilitarEstado"
            type="text"
            class="form-control form-control-sm"
            placeholder="Ingrese la clave"
            [(ngModel)]="item.clave"
          />
        </div>
      </div>

      <div class="row justify-content-center align-items-center">
        <div class="col-11 col-md-3 text-md-right">
          <span>Cédula : </span>
        </div>
        <div class="col-11 col-md-8 my-1">
          <input
            [disabled]="habilitarEstado"
            type="numeric"
            class="form-control form-control-sm"
            placeholder="Ingrese la cédula"
            appOnlynumber
            [(ngModel)]="item.ruc"
          />
        </div>
      </div>

      <div class="row justify-content-center align-items-center">
        <div class="col-11 col-md-3 text-md-right">
          <span>Dirección : </span>
        </div>
        <div class="col-11 col-md-8 my-1">
          <input
            [disabled]="habilitarEstado"
            type="text"
            class="form-control form-control-sm"
            placeholder="Ingrese la dirección"
            [(ngModel)]="item.direccion"
          />
        </div>
      </div>

      <div class="row justify-content-center align-items-center">
        <div class="col-11 col-md-3 text-md-right">
          <span>Teléfono: </span>
        </div>
        <div class="col-11 col-md-8 my-1">
          <input
            [disabled]="habilitarEstado"
            type="numeric"
            class="form-control form-control-sm"
            placeholder="Ingrese el teléfono"
            appOnlynumber
            [(ngModel)]="item.telefono"
          />
        </div>
      </div>

      <div class="row justify-content-center align-items-center">
        <div class="col-11 col-md-3 text-md-right">
          <span>Correo: </span>
        </div>
        <div class="col-11 col-md-8 my-1">
          <input
            [disabled]="habilitarEstado"
            type="text"
            class="form-control form-control-sm"
            placeholder="Ingrese el correo"
            [(ngModel)]="item.correo"
          />
        </div>
      </div>

      <div class="row justify-content-center align-items-center">
        <div class="col-11 col-md-3 text-md-right">
          <span>Quincena: </span>
        </div>
        <div class="col-11 col-md-8 my-1">
          <div class="d-flex" style="align-items: baseline">
            <input
              [disabled]="habilitarEstado"
              type="numeric"
              class="form-control form-control-sm"
              [(ngModel)]="item.quincena[0]"
              style="max-width: 100px; text-align: right"
              appOnlynumber
            />
            <div class="mx-1" style="font-size: 24px">.</div>
            <input
              [disabled]="habilitarEstado"
              type="numeric"
              class="form-control form-control-sm"
              [(ngModel)]="item.quincena[1]"
              style="max-width: 80px"
              appOnlynumber
            />
          </div>
        </div>
      </div>

      <div class="row justify-content-center align-items-center">
        <div class="col-11 col-md-3 text-md-right">
          <span>Fin de mes: </span>
        </div>
        <div class="col-11 col-md-8 my-1">
          <div class="d-flex" style="align-items: baseline">
            <input
              [disabled]="habilitarEstado"
              type="numeric"
              class="form-control form-control-sm"
              [(ngModel)]="item.findemes[0]"
              style="max-width: 100px; text-align: right"
              appOnlynumber
            />
            <div class="mx-1" style="font-size: 24px">.</div>
            <input
              [disabled]="habilitarEstado"
              type="numeric"
              class="form-control form-control-sm"
              [(ngModel)]="item.findemes[1]"
              style="max-width: 80px"
              appOnlynumber
            />
          </div>
        </div>
      </div>

      <div class="row justify-content-center align-items-center">
        <div class="col-11 col-md-3 text-md-right">
          <span>Fecha de ingreso: </span>
        </div>
        <div class="col-11 col-md-8 my-1">
          <input
            [disabled]="habilitarEstado"
            type="date"
            class="form-control form-control-sm"
            [(ngModel)]="item.fecha_ingreso"
          />
        </div>
      </div>

      <div class="row justify-content-center align-items-center">
        <div class="col-11 col-md-3 text-md-right">
          <span>Sucursal : </span>
        </div>
        <div class="col-11 col-md-8 my-1">
          <select
            [disabled]="habilitarEstado"
            class="form-select form-select-sm"
            aria-label="Default select example"
            [(ngModel)]="item.sucursal"
          >
            <option
              *ngFor="let sucursal of sucursales"
              [value]="sucursal.codigo.trim()"
            >
              {{ sucursal.nombre }}
            </option>
          </select>
        </div>
      </div>

      <div class="row justify-content-center align-items-center">
        <div class="col-11 col-md-3 text-md-right">
          <span>Tipo Usuario: </span>
        </div>
        <div class="col-11 col-md-8 my-1">
          <select
            [disabled]="habilitarEstado"
            class="form-select form-select-sm"
            aria-label="Default select example"
            [(ngModel)]="item.perfil"
          >
            <option *ngFor="let usr of perfiles" [value]="usr.codigo.trim()">
              {{ usr.nombre }}
            </option>
          </select>
        </div>
      </div>

      <div class="row justify-content-center align-items-center">
        <div class="col-11 col-md-3 text-md-right">
          <span>Admin : </span>
        </div>
        <div class="col-11 col-md-8 my-1">
          <div class="mr-2"></div>
          <div class="form-check form-switch" style="padding-left: 2.5rem">
            <input
              [disabled]="habilitarEstado"
              class="form-check-input"
              type="checkbox"
              id="flexSwitchCheckDefault"
              [(ngModel)]="item.admin"
            />
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="item.codigoTco.trim() == '005'">
      <div class="row justify-content-center align-items-center my-1">
        <div class="col-11 col-md-3 text-md-right">
          <span>Vigencia : </span>
        </div>
        <div class="col-5 col-md-5">
          <input
            [disabled]="habilitarEstado"
            type="numeric"
            class="form-control form-control-sm"
            id="nombre"
            placeholder="0"
            [(ngModel)]="item.vigencia"
            appOnlynumber
          />
        </div>
        <div class="col-3 col-md-3 text-md-left">
          <span>Años </span>
        </div>
      </div>

      <div class="row justify-content-center align-items-center">
        <div class="col-11 col-md-3 text-md-right">
          <span>Depreciación: </span>
        </div>
        <div class="col-11 col-md-8 my-1">
          <div class="d-flex" style="align-items: baseline">
            <input
              [disabled]="habilitarEstado"
              type="numeric"
              class="form-control form-control-sm"
              id="nombre"
              placeholder="0"
              [(ngModel)]="depreciacion[0]"
              style="max-width: 100px; text-align: right"
              appOnlynumber
            />
            <div class="mx-1" style="font-size: 24px">.</div>
            <input
              [disabled]="habilitarEstado"
              type="numeric"
              class="form-control form-control-sm"
              id="nombre"
              placeholder="0"
              [(ngModel)]="depreciacion[1]"
              style="max-width: 80px"
              appOnlynumber
            />

            <div>%</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Botones de Transacciones-->
  <div class="container text-right my-1" [hidden]="ocultarBotonesPie">
    <button
      type="button"
      class="btn btn-outline-success btn-sm btn_est m-2"
      (click)="enviarItem()"
    >
      <i class="fas fa-check-circle"></i> Guardar
    </button>

    <button
      type="button"
      class="btn btn-outline-danger btn-sm btn_est m-2"
      (click)="cancelar()"
    >
      <i class="fas fa-times-circle"></i> Cancelar
    </button>
  </div>

  <app-spinner></app-spinner>
</div>
