import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cierre',
  templateUrl: './cierre.component.html',
  styleUrls: ['./cierre.component.css'],
})
export class CierreComponent implements OnInit {
  modalReferencia: BsModalRef;
  banderaSpinner: boolean = false;
  empleados: any = [];

  formaspagos: any = [];
  monedas: any = [];
  billetes: any = [];

  admin: boolean = false;
  sucursales: any;
  sucursal: string = 'todos';
  empresa: any;
  datosempresa: any;
  datosUm: any = [];
  tarifas: any = [];
  tarifa: string = '';

  totalMonedas: any;
  totalBilletes: any;
  totalEfectivo: any;

  totalCaja: any;

  totalGeneral: any;

  constructor(
    private router: Router,
    private servicio: RestauranteService,
    private modalServicio: BsModalService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    if (!localStorage.getItem('usuario')) {
      this.router.navigate(['login']);
    }

    //REVISAR SI NO AFECTO
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.datosempresa = JSON.parse(localStorage.getItem('empresa'));
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }

    //this.cargarLocalStorage();

    this.admin = JSON.parse(localStorage.getItem('usuario')).admin;
    this.sucursal = JSON.parse(localStorage.getItem('sucursal')).codigo;
    this.cargarSucursales();
    this.cargomonedas();
    this.cargobilletes();
    this.calculaTotales();
    this.cargoFormaPagos();
  }
grabarCierre(){
  this.alerta(
    'NO REGISTRA APERTURA',
    'Usuario no ha aperturado CAJA el dia de hoy, Intente Nuevamente',
    'error'
  );
}


  calculaMonedas(mo) {
    mo.subtotal = mo.inicial * mo.valor;
    console.log(mo);
  }

  calculaBilletes(bi) {
    bi.subtotal = bi.inicial * bi.valor;
  }

  calculaFormasPagos(fp) {
    fp.subtotal = fp.inicial * 1;
  }

  cargomonedas() {
    this.servicio.getDivisas('MONEDA', '039').subscribe(
      (result) => {
        console.log(result);
        if (result.length > 0) {
          this.monedas = result;
        }
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
  }

  cargobilletes() {
    this.servicio.getDivisas('BILLETE', '039').subscribe(
      (result) => {
        console.log(result);
        if (result.length > 0) {
          this.billetes = result;
        }
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
  }

  cargoFormaPagos() {
    this.servicio.getDivisas('', '028').subscribe(
      (result) => {
        console.log(result);
        if (result.length > 0) {
          this.formaspagos = result;
        }
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
  }

  // Función para sumar los subtotales de un arreglo
  sumarSubtotales = (items) => {
    return items.reduce((total, item) => {
      return total + item.subtotal;
    }, 0);
  };

  calculaTotales() {
    // Sumar los subtotales de ambos arreglos
    this.totalMonedas = this.sumarSubtotales(this.monedas);
    this.totalBilletes = this.sumarSubtotales(this.billetes);

    // Calcular el total general
    this.totalEfectivo = this.totalMonedas + this.totalBilletes;

    let pago = this.formaspagos.find((fpt) => fpt.codid === '028001'); //SOLO EFECTIVO
    if (pago) {
      pago.inicial = this.totalEfectivo;
      pago.subtotal = this.totalEfectivo;
    }

    this.totalCaja = this.sumarSubtotales(this.formaspagos);
    this.totalGeneral = this.totalCaja;
  }

  cargarSucursales() {
    this.servicio
      .getConfiguraciones('015', this.empresa)
      .subscribe((result) => {
        //console.log(result);
        if (result.length > 0) {
          this.sucursales = result;
        }
      });
  }

  limpiarInput(item, campo) {
    item[campo] = 0;
    if (isNaN(item[campo])) {
      item[campo] = 0;
    }
    if (item[campo] === undefined) {
      item[campo] = 0;
    }
  }

  verificarInput(item, campo, idtabla) {

    if (isNaN(item[campo])) {
      item[campo] = 0;
    }
    if (item[campo] === ' ') item[campo] = item[campo + '_ant'];
    if (item[campo] < 0) item[campo] = item[campo + '_ant'];
    item[campo + '_ant'] = item[campo];

    if (idtabla === 'mo') {
      this.calculaMonedas(item);
    }

    if (idtabla === 'bi') {
      this.calculaBilletes(item);
    }

    if (idtabla === 'fp') {
      this.calculaFormasPagos(item);
    }

    this.calculaTotales();
  }

  limitarDecimales(event, decimales, valor) {
    console.log(event.key);
    if (event.key == '.') {
      if (decimales === 0) return false;
      else return true;
    }

    if (isNaN(parseInt(event.key))) return true;
    let val = (valor + '').split('.');
    if (val.length > 1) {
      let decima = val[1];
      if (decima.length > decimales - 1) return false;
    }
    return true;
  }

  alerta(titulo, texto, icono) {
    return Swal.fire({
      icon: icono,
      title: titulo,
      text: texto,
      allowOutsideClick: false,
    });
  }
}
