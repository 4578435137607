import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from 'src/app/services/global.service';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';

@Component({
  selector: 'app-edicion-menu',
  templateUrl: './edicion-menu.component.html',
  styleUrls: ['./edicion-menu.component.css'],
})
export class EdicionMenuComponent implements OnInit {
  busqueda: string = '';
  empleados: any;
  empleadosTotal: any;
  empleado: string = '';
  filtros = [];
  empresa: any;
  constructor(
    private servicio: GlobalService,
    private servicioRestaurante: RestauranteService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    if (localStorage.getItem('usuario')) {
      this.filtros = JSON.parse(localStorage.getItem('usuario')).acceso.split(
        '|'
      );
      if (JSON.parse(localStorage.getItem('empresa')) !== null) {
        this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
      }
      this.cargarMenu();
      this.cargarEmpleados();
    }
  }

  menu: any = [];
  estructura = '';

  cargarMenu() {
    let campos = {
      sucursal: JSON.parse(localStorage.getItem('sucursal')).codigo,
      empleado: JSON.parse(localStorage.getItem('usuario')).codigo,
      empresa: JSON.parse(localStorage.getItem('empresa')).codigo,
    };
    this.spinner.show();
    this.servicio.getMenu(campos).subscribe((result) => {
      console.log(result);
      this.menu = result;
      this.filtrarMenu();
      this.spinner.hide();
    });
  }

  filtrarMenu() {
    for (let i = 0; i < this.menu.length; i++) {
      let submenu = this.menu[i];
      this.filtroRecursivo(submenu);
    }
  }

  filtroRecursivo(item) {
    if (item.submenu.length == 0) {
      for (let i = 0; i < this.filtros.length; i++) {
        const filtro = this.filtros[i];
        if (filtro == item.codigo) {
          item.check = true;
          return item;
        }
      }
    } else {
      let lista = [];
      for (let e = 0; e < item.submenu.length; e++) {
        let element = item.submenu[e];
        let resultado = this.filtroRecursivo(element);
        if (resultado != null) {
          lista.push(resultado);
        }
      }
      if (lista.length > 0) {
        item.check = true;
        return item;
      }
    }
    return null;
  }

  bloqueoID = true;
  //bloqueoBool: boolean = false;

  checkPrincipal(i, arreglo = this.menu) {
    if (!arreglo[i].submenu) return;
    let bloqueoBool = false;
    if (this.bloqueoID) {
      bloqueoBool = true;
      this.bloqueoID = false;
    }
    /*if (arreglo[i].intermediario) {
      arreglo[i].intermediario = false;
      arreglo[i].check = true;
    }*/
    for (let e = 0; e < arreglo[i].submenu.length; e++) {
      arreglo[i].submenu[e].check = arreglo[i].check;
      this.checkPrincipal(e, arreglo[i].submenu);
    }
    if (bloqueoBool) {
      this.bloqueoID = true;
      this.chequearArriba();
    }
    //
  }

  checkSecundario(i) {
    let sonFalsos = 0;
    let longitud = this.menu[i].submenu.length;
    for (let e = 0; e < longitud; e++) {
      if (!this.menu[i].submenu[e].check) sonFalsos++;
    }

    if (sonFalsos == longitud) {
      this.menu[i].intermediario = false;
      this.menu[i].check = false;
    } else if (sonFalsos == 0) {
      this.menu[i].intermediario = false;
      this.menu[i].check = true;
    } else {
      this.menu[i].intermediario = true;
      this.menu[i].check = false;
    }
  }

  chequearArriba() {}

  funcionAuxiliar(i, arreglo) {
    let sonFalsos = 0;
    let longitud = arreglo[i].submenu.length;
    for (let e = 0; e < longitud; e++) {
      if (!this.menu[i].submenu[e].check) sonFalsos++;
      //funcionAuxiliar;
    }

    if (sonFalsos == longitud) {
      return 'empty';
    } else if (sonFalsos == 0) {
      return 'all';
    } else {
      return 'partial';
    }
  }

  guardarMenu() {
    this.encontrarChecks();
    this.estructura = this.estructura.slice(0, -1);

    let campos = {
      aplicacion: 0,
      usuario: this.empleado,
      actual: '',
      nueva: '',
      acceso: this.estructura,
    };
    console.log(this.estructura);
    this.spinner.show();
    this.servicio.cambiarPassword(campos).subscribe((result) => {
      console.log(result);
      this.spinner.hide();
      if (
        campos.usuario == JSON.parse(localStorage.getItem('usuario')).codigo
      ) {
        //localStorage.clear();
        localStorage.removeItem('pagina');
        location.href = '/';
      } else this.cargarEmpleados();
    });
  }

  encontrarChecks() {
    for (let i = 0; i < this.menu.length; i++) {
      let submenu = this.menu[i];
      this.enconctrarCheck(submenu);
    }
    console.log(this.estructura);
  }

  enconctrarCheck(item) {
    if (item.submenu.length == 0) {
      if (item.check) {
        this.estructura = this.estructura + item.codigo + '|';
        return item;
      }
    } else {
      let lista = [];
      for (let e = 0; e < item.submenu.length; e++) {
        let element = item.submenu[e];
        let resultado = this.enconctrarCheck(element);
        if (resultado != null) {
          lista.push(resultado);
        }
      }
      if (lista.length > 0) {
        return item;
      }
    }
    return null;
  }

  cargarEmpleados() {
    let data = {
      sucursal: JSON.parse(localStorage.getItem('sucursal')).codigo,
      perfil: localStorage.getItem('pagina'),
      empresa: JSON.parse(localStorage.getItem('empresa')).codigo,
    };

    this.servicioRestaurante
      .getConfiguraciones('011', this.empresa)
      .subscribe((data) => {
        this.empleados = data;
        this.empleadosTotal = data;
        this.spinner.hide();
      });
  }

  onKey() {
    console.log(this.busqueda);
    let mini: any = [];
    this.empleadosTotal.forEach((usuario: any) => {
      console.log(usuario);

      if (usuario.nombre.toLowerCase().includes(this.busqueda.toLowerCase()))
        mini.push(usuario);
    });
    this.empleados = mini;
  }

  empleadoCompleto: any;
  buscarEmpleado() {
    this.empleados.forEach((element) => {
      if (element.codigo == this.empleado) this.empleadoCompleto = element;
    });
    this.filtros = this.empleadoCompleto.acceso.split('|');
    this.uncheckMenu();
    this.filtrarMenu();
  }

  uncheckMenu() {
    for (let i = 0; i < this.menu.length; i++) {
      let submenu = this.menu[i];
      this.uncheck(submenu);
    }
  }

  uncheck(item) {
    if (item.submenu.length == 0) {
      item.check = false;
    } else {
      for (let e = 0; e < item.submenu.length; e++) {
        let element = item.submenu[e];
        item.check = false;
        this.uncheck(element);
      }
    }
    return;
  }

  abrir(item) {
    item.open = !item.open;
  }
}
