import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './components/menu/menu.component';
import { MainLoginComponent } from './components/login/main-login/main-login.component';

import { RestauranteService } from '../app/services/servicio-restaurante/servicio-restaurante.service';
import { ServicioMeseroService } from '../app/services/servicio-mesero/servicio-mesero.service';
import { ServicioCocinaService } from '../app/services/servicio-cocina/servicio-cocina.service';

import { HttpClientModule } from '@angular/common/http';

import { DashboardComponent } from './components/restaurante/dashboard/dashboard.component';
import { ProductoComponent } from './components/restaurante/bibliotecas/productos/producto.component';
import { ClienteComponent } from './components/restaurante/bibliotecas/cliente/cliente.component';

import { ModalModule } from 'ngx-bootstrap/modal';
import { ModalClientesComponent } from './components/restaurante/modal-clientes/modal-clientes.component';
import { ModalProductosComponent } from './components/restaurante/bibliotecas/productos/modales/modal-productos/modal-productos.component';
import { ModalPedidoRecetaComponent } from '../app/components/restaurante/modal-pedido-receta/modal-pedido-receta.component';

import { NgxPaginationModule } from 'ngx-pagination';
import { FacturaComponent } from './components/restaurante/ventas/factura/factura.component';

//VENTAS
import { InformeDinamicoVentasComponent } from './components/restaurante/ventas/informe-dinamico/informe-dinamico.component';
import { DiarioVentasComponent } from './components/restaurante/ventas/diario-ventas/diario-ventas.component';

// PEDIDOS MOVIL
import { InformeDinamicoPmComponent } from './components/restaurante/pedidos/movil/informe-dinamico-pm/informe-dinamico-pm.component';
import { DiarioPedidosPmComponent } from './components/restaurante/pedidos/movil/diario-pedidos-pm/diario-pedidos-pm.component';

import { KardexProductoComponent } from './components/restaurante/inventario/kardex-producto/kardex-producto.component';

import { ModalFacturaComponent } from './components/restaurante/modal-factura/modal-factura.component'; //<--Para paginacion

import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalLineasComponent } from './components/restaurante/modal-lineas/modal-lineas.component';
import { InventarioComponent } from './components/restaurante/inventario/registro-inventario/registro-inventario.component';
import { ModalInventarioComponent } from './components/restaurante/modal-inventario/modal-inventario.component';
import { SpinnerComponent } from './components/spinner/spinner.component';

import { OnlynumberDirective } from '../../src/app/onlynumber.directive';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';

import { MatSliderModule } from '@angular/material/slider';
import { MatInputModule } from '@angular/material/input';

import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';

//import { MatNativeDateModule } from '@angular/material/datepicker';
import { ModalRecetaComponent } from './components/restaurante/modal-receta/modal-receta.component';
import { ModalMateriaPrimaComponent } from './components/restaurante/modal-materia-prima/modal-materia-prima.component';
import { ConfiguracionComponent } from './components/restaurante/configuracion/configuracion.component';

import { ModalConfiguracionComponent } from './components/restaurante/modal-configuracion/modal-configuracion.component';
import { ModalListadoProductosComponent } from './components/restaurante/modal-listado-productos/modal-listado-productos.component';
import { ModalConfiguracionaddComponent } from './components/restaurante/modal-configuracionadd/modal-configuracionadd.component';
import { ModalClienteaddComponent } from './components/restaurante/modal-clienteadd/modal-clienteadd.component';
import { ModalProductoaddComponent } from './components/restaurante/bibliotecas/productos/modales/modal-productoadd/modal-productoadd.component';
import { ModalFiltroComponent } from './components/restaurante/modal-filtro/modal-filtro.component';
import { ModalDetalleFacturaComponent } from './components/restaurante/ventas/modales/modal-detalle-factura/modal-detalle-factura.component';

import { ModalNominaEmpleadosComponent } from './components/restaurante/nomina/modal-nomina-empleados/modal-nomina-empleados.component';
import { NominaEmpleadosComponent } from './components/restaurante/nomina/nomina-empleados/nomina-empleados.component';
import { ModalNominaComponent } from './components/restaurante/nomina/modal-nomina/modal-nomina.component';
import { ReporteEmpleadoComponent } from './components/restaurante/nomina/reporte-empleado/reporte-empleado.component';
import { ProveedoresComponent } from './components/restaurante/bibliotecas/proveedores/proveedores.component';
import { FaltasComponent } from './components/restaurante/nomina/faltas/faltas.component';
import { RegistroCompraComponent } from './components/restaurante/compras/registro-compra/registro-compra.component';
import { ModalProveedoresComponent } from './components/restaurante/modal-proveedores/modal-proveedores.component';
import { RegistroActivoComponent } from './components/restaurante/activo_fijo/registro-activo/registro-activo.component';
import { ConsultaActivoComponent } from './components/restaurante/activo_fijo/consulta-activo/consulta-activo.component';
import { ModalCRUDActivoComponent } from './components/restaurante/activo_fijo/modal-crud-activo/modal-crud-activo.component';
import { ModalDepreciacionComponent } from './components/restaurante/activo_fijo/modal-depreciacion/modal-depreciacion.component';
import { GastosComponent } from './components/restaurante/gastos/registro-gastos/gastos.component';

import { ReporteGastosComponent } from './components/restaurante/gastos/reporte-gastos/reporte-gastos.component';
import { ReporteFaltasComponent } from './components/restaurante/nomina/reporte-faltas/reporte-faltas.component';
import { InformeDinamicoComprasComponent } from './components/restaurante/compras/informe-dinamico/informe-dinamico.component';
import { ModalReporteGastosComponent } from './components/restaurante/gastos/modales/modal-reporte-gastos/modal-reporte-gastos.component';
import { ModalFechaCaducidadComponent } from './components/restaurante/modal-fecha-caducidad/modal-fecha-caducidad.component';
import { ReporteMovimientoEmpleadosComponent } from './components/restaurante/reporte-movimiento-empleados/reporte-movimiento-empleados.component';
import { InformePerdidaGananciaComponent } from './components/restaurante/informe-perdida-ganancia/informe-perdida-ganancia.component';
import { MatTabsModule } from '@angular/material/tabs';
import { InformeCardComponent } from './components/restaurante/informe-card/informe-card.component';
import { GraficoZoomComponent } from './components/restaurante/grafico-zoom/grafico-zoom.component';
import { ModalLineasComponent as modalNewModal } from './components/restaurante/reportes/modal-lineas/modal-lineas.component';
import { ModalItemsComponent } from './components/restaurante/reportes/modal-items/modal-items.component';

import { LoginMeseroComponent } from './components/login/login-mesero/login-mesero.component';
import { LoginRestauranteComponent } from './components/login/login-restaurante/login-restaurante.component';

import { CocinaComponent } from './components/cocina/cocina/cocina.component';
import { ModalAceptarCocinaComponent } from './components/cocina/modal-aceptar-cocina/modal-aceptar-cocina.component';
import { ModalAlertaComponent } from './components/cocina/modal-alerta/modal-alerta.component';

import { PedidosmesasComponent } from './components/mesero/pedidosmesas/pedidosmesas.component';
import { FacturaComponent as FacturaMesero } from './components/mesero/factura/factura.component';
import { ModalUnirComponent } from './components/mesero/modal-unir/modal-unir.component';
import { ModalDividirComponent } from './components/mesero/modal-dividir/modal-dividir.component';
import { ModalPedidoRecetaComponent as pedidoReceta } from '../app/components/mesero/modal-pedido-receta/modal-pedido-receta.component';
import { ModalFacturaComponent as ModalFactura } from './components/mesero/modal-factura/modal-factura.component';
import { ModalLineasComponent as ModalLineas } from './components/mesero/modal-lineas/modal-lineas.component';
import { ModalClienteaddComponent as ModalClienteadd } from './components/mesero/modal-clienteadd/modal-clienteadd.component';
import { CajaComponent } from './components/mesero/caja/caja.component';
import { BarraMenuComponent } from './components/barra-menu/barra-menu.component';
import { PanelNotificacionesComponent } from './components/panel-notificaciones/panel-notificaciones.component';

import { EdicionMenuComponent } from './components/seguridad/edicion-menu/edicion-menu.component';
import { CambioClaveComponent } from './components/seguridad/cambio-clave/cambio-clave.component';

import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { CuentasComponent } from './components/restaurante/bibliotecas/cuentas/cuentas.component';
import { DistribuidoresComponent } from './components/restaurante/bibliotecas/distribuidores/distribuidores.component';
import { ModalDistribuidorComponent } from './components/restaurante/bibliotecas/distribuidores/modales/modal-distribuidor/modal-distribuidor.component';
import { ProvisionComponent } from './components/restaurante/contabilidad/provision/provision.component';
import { InformeDinamicoComponent } from './components/restaurante/inventario/informe-dinamico/informe-dinamico.component';

import { NgxMaskModule, IConfig } from 'ngx-mask';

import { RegistroInventarioComponent } from './components/cocina/registro-inventario/registro-inventario.component';
import { AperturaCocinaComponent } from './components/cocina/apertura-cocina/apertura-cocina.component';
import { DiarioInventarioComponent } from './components/restaurante/inventario/diario-inventario/diario-inventario.component';
import { DiarioComprasComponent } from './components/restaurante/compras/diario-compras/diario-compras.component';
import { ModalDetalleInventarioComponent } from './components/restaurante/inventario/modales/modal-detalle-inventario/modal-detalle-inventario.component';
import { ModalDetalleCompraComponent } from './components/restaurante/compras/modales/modal-detalle-compra/modal-detalle-compra.component';
//CAJA
import { AperturaComponent } from './components/restaurante/caja/apertura/apertura.component';
import { CierreComponent } from './components/restaurante/caja/cierre/cierre.component';
import { ModalCuentasComponent } from './components/restaurante/bibliotecas/cuentas/modales/modal-cuentas/modal-cuentas.component';
import { ModalListadoCuentasComponent } from './components/restaurante/bibliotecas/cuentas/modales/modal-listado-cuentas/modal-listado-cuentas.component';
import { OrdenComponent } from './components/restaurante/produccion/orden/orden.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    MainLoginComponent,
    DashboardComponent,
    ProductoComponent,
    ModalProductosComponent,
    ModalPedidoRecetaComponent,
    ModalClientesComponent,
    ClienteComponent,
    FacturaComponent,
    InformeDinamicoVentasComponent,
    DiarioVentasComponent,
    KardexProductoComponent,
    ModalFacturaComponent,
    ModalLineasComponent,
    modalNewModal,
    InventarioComponent,
    ModalInventarioComponent,
    SpinnerComponent,
    OnlynumberDirective,
    ModalRecetaComponent,
    ModalMateriaPrimaComponent,
    ConfiguracionComponent,
    ModalConfiguracionComponent,
    ModalConfiguracionaddComponent,
    ModalListadoProductosComponent,
    ModalClienteaddComponent,
    ModalProductoaddComponent,
    ModalFiltroComponent,
    ModalNominaEmpleadosComponent,
    NominaEmpleadosComponent,
    ModalDetalleFacturaComponent,
    ModalNominaComponent,
    ReporteEmpleadoComponent,
    ProveedoresComponent,
    FaltasComponent,
    RegistroCompraComponent,
    ModalProveedoresComponent,
    RegistroActivoComponent,
    ConsultaActivoComponent,
    ModalCRUDActivoComponent,
    ModalDepreciacionComponent,
    GastosComponent,
    ReporteGastosComponent,
    ReporteFaltasComponent,
    InformeDinamicoComprasComponent,
    ModalReporteGastosComponent,
    ModalFechaCaducidadComponent,
    ReporteMovimientoEmpleadosComponent,
    InformePerdidaGananciaComponent,
    InformeCardComponent,
    GraficoZoomComponent,
    ModalItemsComponent,
    LoginMeseroComponent,
    LoginRestauranteComponent,
    CocinaComponent,
    ModalAceptarCocinaComponent,
    ModalAlertaComponent,
    PedidosmesasComponent,
    FacturaMesero,
    ModalUnirComponent,
    pedidoReceta,
    ModalFactura,
    ModalLineas,
    ModalClienteadd,
    CajaComponent,
    ModalDividirComponent,
    BarraMenuComponent,
    PanelNotificacionesComponent,
    EdicionMenuComponent,
    CambioClaveComponent,
    PageNotFoundComponent,
    CuentasComponent,
    DistribuidoresComponent,
    ModalDistribuidorComponent,
    ProvisionComponent,
    InformeDinamicoComponent,
    InformeDinamicoPmComponent,
    DiarioPedidosPmComponent,
    RegistroInventarioComponent,
    AperturaCocinaComponent,
    DiarioInventarioComponent,
    DiarioComprasComponent,
    ModalDetalleInventarioComponent,
    ModalDetalleCompraComponent,
    AperturaComponent,
    CierreComponent,
    ModalCuentasComponent,
    ModalListadoCuentasComponent,
    OrdenComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatSliderModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatTabsModule,
    MatCheckboxModule,
    ModalModule.forRoot(),
    NgxMaskModule.forRoot(maskConfig),
  ],
  providers: [
    RestauranteService,
    ServicioMeseroService,
    ServicioCocinaService,
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
