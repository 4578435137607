<div class="row justify-content-center align-items-center pantalla">
  <div class="col-10 col-md-6 col-lg-6 col-xl-3">
    <div
      class="card align-items-center"
      [style.background-color]="datosempresa.color"
    >
      <div class="d-flex justify-content-center my-4">
        <img
          class="card-img-top"
          [src]="datosempresa.urlLogo"
          alt="logo"
          style="width: 80%"
        />
      </div>
      <div class="card-body" style="background-color: #fff; width: 100%">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-11">
              <mat-form-field appearance="fill" class="no-padding w-100">
                <mat-label>Sucursal</mat-label>
                <mat-select
                  panelClass="my-select-panel-class"
                  [(value)]="sucursal"
                  (selectionChange)="save_sucursal()"
                >
                  <mat-option
                    *ngFor="let sucursal of sucursales"
                    [value]="sucursal.codigo.trim()"
                    >{{ sucursal.nombre }}</mat-option
                  >
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="fill" class="no-padding w-100">
                <mat-label>Empleado</mat-label>

                <mat-select
                  panelClass="my-select-panel-class"
                  [(value)]="empleado"
                >
                  <div style="display: flex; justify-content: center">
                    <mat-form-field
                      class="example-full-width busqueda w-75"
                      style="padding-top: 10px"
                    >
                      <mat-label>Buscar</mat-label>
                      <input
                        matInput
                        [(ngModel)]="busqueda"
                        (keyup)="onKey()"
                        (keydown)="$event.stopPropagation()"
                      />
                    </mat-form-field>
                  </div>

                  <mat-option
                    *ngFor="let empleado of empleados"
                    [value]="empleado.codigo"
                    >{{ empleado.nombre }}</mat-option
                  >
                </mat-select>
              </mat-form-field>

              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    ><i class="fas fa-unlock-alt"></i
                  ></span>
                </div>
                <input
                  type="password"
                  class="form-control form-control-sm"
                  placeholder="Clave"
                  aria-label="Clave"
                  aria-describedby="basic-addon1"
                  name="password"
                  [(ngModel)]="datos.password"
                  required
                  pattern="[A-Za-z0-9]+"
                  (keyup.enter)="ingresa()"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer" style="background-color: #fff; width: 100%">
        <div class="row">
          <div class="col-6">
            <button class="btn btn-outline-danger btn-sm" (click)="mainLogin()">
              <i class="far fa-arrow-alt-circle-left"></i> Regresar
            </button>
          </div>
          <div class="col-6 text-right">
            <button class="btn btn-outline-success btn-sm" (click)="ingresa()">
              Ingresar <i class="far fa-check-circle"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-spinner></app-spinner>
