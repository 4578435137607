<!--dato-->
<div class="container-fluid p-3">
  <div class="row my-2 align-items-center">
    <div class="col-12 text-center" *ngFor="let dato of datosUser">
      <h4 class="m-0 pl-2 text-center">
        !Hola, {{ dato.nombre }}
        <small class="text-muted"> Cambia tu Clave...</small>
      </h4>
    </div>
  </div>
</div>

<div class="container" *ngFor="let dato of datosUser">
  <div class="row justify-content-center d-flex">
    <div class="col-12 col-md-6 pt-1">
      <div class="card">
        <div class="card-body p-1">
          <h6
            class="card-subtitle mb-2 py-2 text-center"
            [style.color]="datosempresa['color']"
          >
            Datos del Usuario
          </h6>
          <div class="input-group input-group-sm my-1">
            <div class="input-group-prepend text-center">
              <span class="input-group-text" id="ruc"
                ><i class="fas fa-id-card-alt"></i
              ></span>
            </div>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="identificacion"
              [(ngModel)]="dato.ruc"
              [disabled]="inhabilitado"
            />
          </div>
          <div class="input-group input-group-sm my-1">
            <div class="input-group-prepend text-center">
              <span class="input-group-text" id="nombre"
                ><i class="far fa-id-card"></i
              ></span>
            </div>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="Nombre del Usuario"
              [(ngModel)]="dato.nombre"
              [disabled]="inhabilitado"
            />
          </div>

          <div class="input-group input-group-sm my-1">
            <div class="input-group-prepend text-center">
              <span class="input-group-text" id="direccion"
                ><i class="fas fa-map-marked-alt"></i
              ></span>
            </div>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="Direccion"
              [(ngModel)]="dato.direccion"
              [disabled]="inhabilitado"
            />
          </div>

          <div class="input-group input-group-sm my-1">
            <div class="input-group-prepend text-center">
              <span class="input-group-text" id="telefono"
                ><i class="fab fa-whatsapp"></i
              ></span>
            </div>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="Telefonos"
              [(ngModel)]="dato.telefono"
              [disabled]="inhabilitado"
            />
          </div>

          <div class="input-group input-group-sm my-1">
            <div class="input-group-prepend text-center">
              <span class="input-group-text" id="telefono"
                ><i class="fas fa-envelope"></i
              ></span>
            </div>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="Correo Electrónico"
              [(ngModel)]="dato.correo"
              [disabled]="inhabilitado"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-3 pt-1">
      <div class="card">
        <div
          class="card-body py-4"
          [style.background-color]="datosempresa['color']"
        >
          <h6 class="card-subtitle mb-2 py-2 text-center text-light">
            Actualización de Clave
          </h6>
          <div class="input-group input-group-sm my-1">
            <div class="input-group-prepend text-center">
              <span class="input-group-text" id="nombre"
                ><i class="far fa-id-card"></i
              ></span>
            </div>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="Clave Nueva"
              [(ngModel)]="claveNueva"
            />
          </div>

          <div class="input-group input-group-sm my-1">
            <div class="input-group-prepend text-center">
              <span class="input-group-text" id="nombre"
                ><i class="far fa-id-card"></i
              ></span>
            </div>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="Confirmacion de Clave"
              [(ngModel)]="claveConfirma"
            />
          </div>
        </div>
        <div class="card-footer" style="background-color: #fff; width: 100%">
          <div class="row">
            <div class="col- text-center">
              <button class="btn btn-outline-danger btn-sm" (click)="cambio()">
                <i class="fas fa-keyboard"></i> Cambiar Contraseña
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
