import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FacturaComponent } from '../factura/factura.component'; //<--Modal factura
@Component({
  selector: 'app-caja',
  templateUrl: './caja.component.html',
  styleUrls: ['./caja.component.css'],
})
export class CajaComponent implements OnInit {
  pedidos: any = [
    {
      tipo: 'A',
      numero: 1441,
      texto: 'B',
      cliente: 'C',
      pago: 'D',
      valor: 0,
    },
    {
      tipo: 'A',
      numero: 1441,
      texto: 'B',
      cliente: 'C',
      pago: 'D',
      valor: 0,
    },
    {
      tipo: 'A',
      numero: 1441,
      texto: 'B',
      cliente: 'C',
      pago: 'D',
      valor: 0,
    },
  ];

  modalReferencia: BsModalRef;
  constructor(private modalServicio: BsModalService) {}

  ngOnInit(): void {}

  abrirModalPedido() {
    let mesaTemporal = {
      cliente: 'Consumidor Final',
      estado: '#56a419',
      estadoAdentro: 'orange',
      estadoAfuera: '#56a419',
      id: -1,
      items: 0,
      pedido: [],
      ruc: '9999999999999',
      total: 0,
    };
    if (!localStorage.getItem('mesas')) localStorage.setItem('mesas', '[]');
    let mesas = JSON.parse(localStorage.getItem('mesas'));
    mesas.push(mesaTemporal);
    localStorage.setItem('mesas', JSON.stringify(mesas));
    //Este modal recibe el id de la mesa
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<--Datos enviados desde componente hacia modal
        mesaTemporal,
        mesas.length - 1,
        'caja',
      ],
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferencia = this.modalServicio.show(FacturaComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-xl',
    });
    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((respuesta) => {
      console.log('MODAL DE FACTURA CERRADO');
      //Vuelvo a cargar todos los datos para que se actualicen en automatico

      if (respuesta.data == 'Facturar') {
        //this.cargarPedidos();
      }
    });
  }

  salir() {}
}
