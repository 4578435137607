<div class="page-wrapper chiller-theme toggled" *ngIf="estaLogeado">
  <nav
    id="sidebar"
    class="sidebar-wrapper"
    [ngClass]="hidden ? 'menuOpen' : 'menuClosed'"
  >
    <div class="sidebar-content">
      <!--SUPERIOR DE MENU-->
      <div class="sidebar-brand" [style.background-color]="empresa.color">
        <div id="close-session" (click)="logout()">
          <i class="fas fa-door-open"></i>
        </div>

        <a class="text-center bloquea-select">NOVA EXPRESS GROUP</a>

        <div (click)="cerrarMenu()" id="close-sidebar">
          <i class="fas fa-times"></i>
        </div>
      </div>
      <!--FIN SUPERIOR DE MENU-->

      <!--DATOS DE LA EMPRESA Y DATOS-->
      <div class="container-fluid">
        <div
          class="row py-2 justify-content-center align-items-center"
          [style.background-color]="empresa.color"
        >
          <div class="col-12">
            <img
              class="img-fluid p-3 bloquea-select"
              [src]="empresa.urlLogo"
              alt="empresa"
            />
          </div>

          <div class="col-10 my-1">
            <div
              class="mr-3 esconder"
              [style.color]="empresa.color"
              style="
                background-color: white;
                border-radius: 24px;
                padding: 0.4rem 1rem;
              "
            >
              <h6 style="margin: 0" class="bloquea-select">
                <i class="fas fa-map mr-2"></i> {{ sucursal.nombre }}
              </h6>
            </div>
          </div>

          <div class="col-10 py-1">
            <div
              class="mr-3 esconder"
              [style.color]="empresa.color"
              style="
                background-color: white;
                border-radius: 24px;
                padding: 0.4rem 1rem;
              "
            >
              <h6 style="margin: 0" class="bloquea-select">
                <i class="fas fa-user mr-2"></i> {{ usuario.nombre }}
              </h6>
            </div>
          </div>
        </div>
      </div>
      <!--FIN DATOS DE LA EMPRESA Y DATOS-->

      <!---->

      <div class="sidebar-menu py-2">
        <div class="h-100" *ngIf="!existemenu">
          <lottie-player
            src="https://assets7.lottiefiles.com/packages/lf20_h1cidhml.json"
            background="transparent"
            speed="1"
            loop
            autoplay
            mode="bounce"
          >
          </lottie-player>
          <h6 class="text-center">Cargando Menú...</h6>
        </div>
        <div *ngFor="let opcion of menuFiltrado; index as i">
          <!--NIVEL 1-->
          <ul>
            <li (click)="desplegar(i)" class="bloquea-select">
              <div class="container-fluid py-1">
                <div class="row justify-content-center">
                  <div
                    class="btn-menu-n1 col-11 p-2"
                    style="border-radius: 6px"
                  >
                    <div
                      class="d-flex colapsable"
                      style="justify-content: space-between"
                    >
                      <div (click)="redireccionamiento(opcion.ruta)">
                        <span class="m-0 p-2"
                          ><i
                            [class]="opcion.icono"
                            [style.color]="opcion.color_ico"
                          ></i>
                        </span>
                        <span
                          class="m-0 p-0"
                          [style.color]="opcion.color_texto"
                        >
                          {{ opcion.nombre }}</span
                        >
                      </div>
                      <div class="pr-2" *ngIf="opcion.submenu.length > 0">
                        <i
                          *ngIf="!opcion.open"
                          class="fas fa-chevron-down"
                          [style.color]="empresa.color"
                        ></i>
                        <i
                          *ngIf="opcion.open"
                          class="fas fa-chevron-up"
                          style="color: #c0392b"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <!--NIVEL 2-->

            <div
              [style.display]="opcion.open ? '' : 'none'"
              style="background-color: #ecf0f1"
              *ngFor="let submenu of opcion.submenu; index as e"
            >
              <li
                class="d-flex py-1 menuOpciones bloquea-select"
                style="justify-content: space-between; align-items: baseline"
                (click)="desplegar(i, e)"
              >
                <div class="container-fluid ml-2">
                  <div class="row justify-content-center">
                    <div
                      class="btn-menu-n2 col-11 p-1"
                      style="border-radius: 6px"
                    >
                      <div
                        class="d-flex colapsable"
                        style="justify-content: space-between"
                      >
                        <div (click)="redireccionamiento(submenu.ruta)">
                          <span class="m-0 p-2"
                            ><i
                              [class]="submenu.icono"
                              [style.color]="submenu.color_ico"
                            ></i>
                          </span>
                          <span
                            class="m-0 p-0"
                            [style.color]="submenu.color_texto"
                            style="font-size: 0.9rem"
                          >
                            {{ submenu.nombre }}</span
                          >
                        </div>
                        <div class="pr-2" *ngIf="submenu.submenu.length > 0">
                          <i
                            *ngIf="!submenu.open"
                            class="fas fa-chevron-down"
                            [style.color]="empresa.color"
                          ></i>
                          <i
                            *ngIf="submenu.open"
                            class="fas fa-chevron-up"
                            style="color: #2c3e50"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <!--NIVEL 3-->
              <div
                [style.display]="submenu.open ? '' : 'none'"
                *ngFor="let submenu2 of submenu.submenu; index as o"
              >
                <li
                  class="d-flex py-1 menuOpciones bloquea-select"
                  style="justify-content: space-between; align-items: baseline"
                  (click)="desplegar(i, e, o)"
                >
                  <div
                    style="padding-left: 60px; margin-bottom: 5px"
                    (click)="redireccionamiento(submenu2.ruta)"
                  >
                    <span style="width: 40px"
                      ><i
                        [class]="submenu2.icono"
                        [style.color]="submenu2.color_ico"
                      ></i>
                    </span>
                    <span class="m-2" [style.color]="submenu2.color_texto">
                      {{ submenu2.nombre }}</span
                    >
                  </div>
                  <div *ngIf="submenu2.submenu.length > 0">
                    <i
                      *ngIf="!submenu2.open"
                      class="fas fa-chevron-down"
                      style="color: white"
                    ></i>
                    <i
                      *ngIf="submenu2.open"
                      class="fas fa-chevron-up"
                      style="color: white"
                    ></i>
                  </div>
                </li>
                <!--NIVEL 4-->
                <div
                  [style.display]="submenu2.open ? '' : 'none'"
                  *ngFor="let submenu3 of submenu2.submenu"
                >
                  <li class="py-1 menuOpciones bloquea-select">
                    <div
                      style="padding-left: 90px; margin-bottom: 5px"
                      (click)="redireccionamiento(submenu3.ruta)"
                    >
                      <span style="width: 40px"
                        ><i
                          [class]="submenu3.icono"
                          [style.color]="submenu3.color_ico"
                        ></i>
                      </span>
                      <span class="m-2" [style.color]="submenu3.color_texto">
                        {{ submenu3.nombre }}</span
                      >
                    </div>
                  </li>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</div>
