import { Component, OnInit, ViewChild } from '@angular/core';

import { Chart } from 'chart.js';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { GraficoZoomComponent } from 'src/app/components/restaurante/grafico-zoom/grafico-zoom.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-informe-card',
  templateUrl: './informe-card.component.html',
  styleUrls: ['./informe-card.component.css'],
})
export class InformeCardComponent implements OnInit {
  @ViewChild('lineChart') lineChart;
  chart1;
  grafico: boolean = false;

  selectorGrafico: string = '';
  selectorTabla: string = '';
  selectoresTabla: any;
  selectoresGrafico: any;

  cabecera: any;
  movimiento: any;

  modulo = 0;
  fechaDesde = '';
  fechaHasta = '';
  sucursal: string = 'TODOS';

  total = 0;
  tipoGrafico = '';

  modalReferencia: BsModalRef;
  datoGrafico: any;

  tituloGrafico = '';
  banderaSpinner: boolean = false;

  constructor(
    private servicio: RestauranteService,
    private modalServicio: BsModalService,
    private spinner: NgxSpinnerService
  ) {
    setInterval(() => {
      if (this.banderaSpinner === true) {
        this.spinner.hide();
        this.banderaSpinner = false;
      }
    }, 100);
  }

  ngOnInit(): void {}

  inicializarDatos() {
    this.cargarSelector();
  }

  cargarSelector() {
    let datos = {
      modulo: this.modulo,
      selector: '2',
      tabla: '1',
      contenido: '0',
      desde: this.fechaDesde,
      hasta: this.fechaHasta,
      sucursal: this.sucursal,
    };
    this.spinner.show();
    this.servicio.informe_dashboard(datos).subscribe((result) => {
      console.log(result);
      this.selectoresTabla = result;
      this.selectorTabla = this.selectoresTabla[0].orden;

      this.cargarTabla();
    });
    datos['tabla'] = '3';
    this.servicio.informe_dashboard(datos).subscribe((result) => {
      console.log(result);
      this.selectoresGrafico = result;

      this.selectorGrafico = this.selectoresGrafico[0].orden;
      this.cargarGraficos();
    });
  }

  cargarTabla() {
    let datos = {
      modulo: this.modulo,
      selector: '3',
      tabla: this.selectorTabla,
      contenido: '1',
      desde: this.fechaDesde,
      hasta: this.fechaHasta,
      sucursal: this.sucursal,
    };

    this.servicio.informe_dashboard(datos).subscribe((cabeceras) => {
      console.log(cabeceras);

      this.cabecera = cabeceras;
      datos['contenido'] = '0';
      console.log(datos);
      this.servicio.informe_dashboard(datos).subscribe((movimientos) => {
        console.log(movimientos);

        for (let i = 0; i < cabeceras.length; i++) {
          for (let e = 0; e < movimientos.length; e++) {
            const element = cabeceras[i];
            switch (element.tipodato.trim()) {
              case 'char':
                movimientos[e][element['campo']] =
                  movimientos[e][element['campo']].trim();
                break;
              case 'numerico':
                movimientos[e][element['campo']] = parseFloat(
                  movimientos[e][element['campo']]
                ).toFixed(parseInt(element['longitud']));
                break;
              default:
                console.log(element);
                break;
            }
          }
        }

        this.movimiento = movimientos;
      });
    });
  }

  cargarGraficos() {
    let datos = {
      modulo: this.modulo,
      selector: '1',
      tabla: this.selectorGrafico,
      contenido: '1',
      desde: this.fechaDesde,
      hasta: this.fechaHasta,
      sucursal: this.sucursal,
    };
    this.servicio.informe_dashboard(datos).subscribe((result) => {
      console.log(result);
      this.grafico1(result);
    });
  }

  grafico1(data) {
    if (this.chart1) this.chart1.destroy();
    let datasets = [];
    var colors = [
      '#2471A3',
      '#A93226',
      '#884EA0',
      '#17A589',
      '#229954',
      '#D4AC0D',
      '#CA6F1E',
      '#839192',
      '#2E4053',
      '#16A085',
    ];
    console.log(data);
    console.log(data['data']);
    console.log(data['data']['length']);
    for (let i = 0; i < data['data']['length']; i++) {
      let miniData = {
        label: data['data'][i]['etiqueta'],
        data: data['data'][i]['valor'],
        backgroundColor: 'transparent',
        borderColor: colors[i],
        borderWidth: 1,
        pointBackgroundColor: colors[i],
      };
      datasets.push(miniData);
    }
    console.log(datasets);

    var chartData = {
      labels: data['label'],
      datasets: datasets,
    };
    console.log(chartData);
    //var ctx = document.getElementById('lineChart')
    //(this.lineChart.nativeElement
    let dato;
    if (this.tipoGrafico == 'bar') {
      dato = 'bar' as const;
    } else if (this.tipoGrafico == 'line') {
      dato = 'line' as const;
    }
    let datosGrafico = {
      type: dato,
      data: chartData,
      options: {
        scales: {
          y: {
            type: 'linear',
          },
        },
      },
    };
    this.datoGrafico = datosGrafico;
    this.chart1 = new Chart(this.lineChart.nativeElement, datosGrafico);
    this.banderaSpinner = true;
  }

  async cargarTotal() {
    let datos = {
      modulo: this.modulo,
      selector: '4',
      tabla: '1',
      contenido: '1',
      desde: this.fechaDesde,
      hasta: this.fechaHasta,
      sucursal: this.sucursal,
    };
    return this.servicio.informe_dashboard(datos).subscribe((result) => {
      console.log(result);
      this.total = result[0].valor;
    });
  }

  abrirModal() {
    let nombre = '';
    for (let i = 0; i < this.selectoresGrafico.length; i++) {
      const element = this.selectoresGrafico[i];
      if (element.orden == this.selectorGrafico) nombre = element.nombre;
    }
    const initialState: any = {
      datos: [this.datoGrafico, this.tituloGrafico, nombre],
    };

    this.modalReferencia = this.modalServicio.show(GraficoZoomComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });
  }
}
