import { Component, OnInit } from '@angular/core';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-login-restaurante',
  templateUrl: './login-restaurante.component.html',
  styleUrls: ['./login-restaurante.component.css'],
})
export class LoginRestauranteComponent implements OnInit {
  sucursales: any;
  sucursal: string = ' ';
  haysucursales = false;

  datos = { password: '' };
  busqueda: string = '';
  empleados: any;
  empleadosTotal: any;
  empleado: string = '';

  empresa: any = {
    urlLogo: '',
    color: '',
  };

  datosempresa: any;

  //Bandera para spinner
  banderaSpinner: boolean = false;

  constructor(
    private servicio: RestauranteService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    //this.sucursal = JSON.parse(localStorage.getItem('sucursal')).codigo;
    //Verifica bandera para ocultar spinner
    setInterval(() => {
      if (this.banderaSpinner === true) {
        this.spinner.hide();
        this.banderaSpinner = false;
      }
    }, 100);

    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.datosempresa = JSON.parse(localStorage.getItem('empresa'));
      console.warn(this.datosempresa);
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
      console.warn(this.empresa);
    }

    //<--Constructor ejecuta antes que onInit
    this.spinner.show();
    this.cargarSucursales();
  }

  cargarSucursales() {
    this.haysucursales = false;

    return new Promise((resolve, reject) => {
      this.servicio
        .getConfiguraciones('015', this.empresa)
        .subscribe((result) => {
          if (result.length > 0) {
            this.sucursal = result[0].codigo.trim();
            this.sucursales = result;
            this.haysucursales = true;
          }
          this.save_sucursal();
          return resolve(result);
        });
      this.spinner.hide();
    });
  }

  save_sucursal() {
    let sucursal = {
      codigo: this.sucursal.trim(),
    };
    this.sucursales.forEach((element) => {
      if (element.codigo.trim() == this.sucursal.trim())
        sucursal['nombre'] = element.nombre;
    });
    localStorage.setItem('sucursal', JSON.stringify(sucursal));

    let data = {
      sucursal: this.sucursal,
      perfil: localStorage.getItem('pagina'),
      empresa: this.empresa,
    };

    this.servicio.loginEmpleados(data).subscribe((data) => {
      this.empleados = data;
      this.empleadosTotal = data;
      this.spinner.hide();
    });
  }

  ingresa() {
    if (this.empleado === '') {
      this.alerta(
        '¡Usuario requerido!',
        'Por favor, ingrese su usuario',
        'warning'
      );

      return;
    }
    if (this.datos.password === '') {
      this.alerta(
        '¡Contraseña requerida!',
        'Por favor, ingrese su contraseña',
        'warning'
      );
      return;
    }
    let data = {
      usuario: this.empleado,
      password: this.datos.password,
      sucursal: this.sucursal,
      empresa: JSON.parse(localStorage.getItem('sucursal')).codigo,
      perfil: localStorage.getItem('pagina'),
    };
    console.log(data);
    this.spinner.show();
    this.servicio.login(data).subscribe((result) => {
      console.log(result);
      if (result.length === 0) {
        this.alerta(
          '¡Contraseña incorrecta!',
          'Por favor vuelva a intentarlo',
          'warning'
        );
        this.banderaSpinner = true;
      } else {
        localStorage.setItem('usuario', JSON.stringify(result[0]));

        this.servicio.obtenerDireccionIpPublica().subscribe((data) => {
          localStorage.setItem(
            'ipPublica',
            JSON.stringify({ ipPublica: data.ip })
          );
          this.banderaSpinner = true;
          console.log(this.empleado);
          this.alerta(
            JSON.parse(localStorage.getItem('usuario')).nombre,
            '¡Bienvenido a la Plataforma!',
            'success'
          ).then(() => {
            localStorage.setItem('notificacion', 'false');
            location.href = '/';
          });
        });
      }
    });
  }

  alerta(titulo, texto, icono) {
    return Swal.fire({
      icon: icono,
      title: titulo,
      text: texto,
      allowOutsideClick: false,
      timer: 1000,
      showConfirmButton: false,
    });
  }

  mainLogin() {
    //localStorage.clear();
    localStorage.removeItem('pagina');
    location.href = '/';
  }

  onKey() {
    let mini: any = [];
    this.empleadosTotal.forEach((usuario: any) => {
      //console.log(usuario);

      if (usuario.nombre.toLowerCase().includes(this.busqueda.toLowerCase()))
        mini.push(usuario);
    });
    this.empleados = mini;
  }
}
