import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { ModalInventarioComponent } from 'src/app/components/restaurante/modal-inventario/modal-inventario.component';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ModalProveedoresComponent } from '../../modal-proveedores/modal-proveedores.component';
import { ModalFechaCaducidadComponent } from '../../modal-fecha-caducidad/modal-fecha-caducidad.component';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalClienteaddComponent } from '../../modal-clienteadd/modal-clienteadd.component';

@Component({
  selector: 'app-registro-compra',
  templateUrl: './registro-compra.component.html',
  styleUrls: ['./registro-compra.component.css'],
})
export class RegistroCompraComponent implements OnInit {
  comentario: string = ''; //<--Usa double data binding
  campaignOne: FormGroup;
  //Referencia del modal
  modalReferencia: BsModalRef;
  productos: any = []; //<--Para guardar los productos que se van pintando en la template
  //Banderas para spinner

  datosUm: any = [];
  busquedaProveedor: string = '';

  //factura: any = ['001', '001', '0000000001'];
  numero_doc: string = '';
  serie_doc: string = '';
  autoriza_doc: string = '';

  proveedor: any = {
    ruc: '0999999999',
    nombre: '',
  };
  buscoProveedor = false;

  tarifas: any = [];
  tarifa: string = '';

  sumatoria: number = 0;
  tarifa0: number = 0;
  tarifa12: number = 0;

  subtotal: number = 0;
  descuento: number = 0;
  impuesto: number = 0;
  total: number = 0;

  admin: boolean = false;
  sucursales: any;
  sucursal: string = 'todos';
  empresa: any;
  datosempresa: any;

  constructor(
    private router: Router,
    private servicio: RestauranteService,
    private modalServicio: BsModalService,
    private spinner: NgxSpinnerService
  ) {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    const day = today.getDate();
    this.campaignOne = new FormGroup({
      start: new FormControl(new Date(year, month, 1)),
      end: new FormControl(new Date(year, month, day)),
    });
  }

  ngOnInit(): void {
    if (!localStorage.getItem('usuario')) {
      this.router.navigate(['login']);
    }

    //REVISAR SI NO AFECTO
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.datosempresa = JSON.parse(localStorage.getItem('empresa'));
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }

    this.cargarLocalStorage();
    this.getUND();
    this.getIVA();

    this.admin = JSON.parse(localStorage.getItem('usuario')).admin;
    this.sucursal = JSON.parse(localStorage.getItem('sucursal')).codigo;
    this.cargarSucursales();
  }

  cargarSucursales() {
    this.servicio
      .getConfiguraciones('015', this.empresa)
      .subscribe((result) => {
        console.log(result);
        if (result.length > 0) {
          this.sucursales = result;
        }
      });
  }

  //Obtiene todas las unidades de medida
  getUND() {
    this.servicio.getConfiguraciones('008', this.empresa).subscribe((data) => {
      this.datosUm = data;
      this.datosUm.forEach((datoUm) => {
        datoUm.codigo = datoUm.codigo.trim();
        datoUm.nombre = datoUm.nombre.trim();
      });
    });
  }

  getIVA() {
    this.servicio.getConfiguraciones('014', this.empresa).subscribe((data) => {
      if (data.length > 0) {
        this.tarifas = data;
        this.tarifa = data[0].codigo;
      }
    });
  }

  calcularTotal(producto) {
    console.log('calcula total', producto);
    producto.subtotal =
      producto.cantidad * producto.costo * ((100 - producto.descuento) / 100);

    let tarifa;
    this.tarifas.forEach((tarif) => {
      if (tarif.codigo.trim() == producto.tarifa.trim()) {
        console.log(tarif);
        tarifa = tarif.nombre.trim().slice(0, tarif.nombre.trim().length - 1);
        producto.porc_tarifa = tarifa;
        producto.valorTotal = parseFloat(tarifa);
      }
    });
    producto.total = producto.subtotal * (producto.valorTotal / 100 + 1);
    localStorage.setItem('compra', JSON.stringify(this.productos));
    this.calcularTotales();
  }

  calcularTotales() {
    this.sumatoria = 0;
    this.descuento = 0;
    this.tarifa0 = 0;
    this.tarifa12 = 0;
    this.subtotal = 0;
    this.impuesto = 0;
    this.total = 0;

    this.productos.forEach((producto) => {
      let val_desc = (producto.costo * producto.descuento) / 100;

      this.sumatoria += producto.cantidad * producto.costo;
      this.descuento += producto.cantidad * val_desc;

      let tar = producto.cantidad * (producto.costo - val_desc);
      this.subtotal += tar;

      if (producto.porc_tarifa == '0') {
        this.tarifa0 += tar;
      } else {
        this.tarifa12 += tar;
      }
    });
    this.impuesto = this.tarifa12 * 0.12;
    this.total = this.subtotal + this.impuesto;
  }

  abrirModalNuevoProducto() {
    //Este modal recibe si es compra o no

    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: 'SI',
    };

    this.modalReferencia = this.modalServicio.show(ModalInventarioComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });
    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((res) => {
      console.log('MODAL DE INVENTARIO CERRADO');

      //OPERACIONES EN LA TEMPORAL
      let datos = {
        codigo: res.data.codigo,
        nombre: res.data.nombre,
        cantidad: 0,
        cantidad_ant: 0,
        costo: 0,
        costo_ant: 0,
        subtotal: 0,
        total: 0,
        porc_tarifa: 0,
        umEscogida: this.datosUm[0].codigo, //<--Um escogida para este producto
        umLista: this.datosUm.slice(), //<--Lista de la combo para cada producto
        tarifa: this.tarifas[0].codigo,
        valorTotal: this.tarifas[0].nombre
          .trim()
          .slice(0, this.tarifas[0].nombre.trim().length - 1),
        descuento: 0,
        descuento_ant: 0,
        fecha_caducidad: '',
      };
      let bandera = false; //<--Indica si esta el producto en el temporal
      this.productos.forEach((producto) => {
        //Si el producto ya esta en el arreglo de productos lo modifica
        if (producto.codigo === datos.codigo) {
          producto.nombre = datos.nombre;

          bandera = true; //<--Actualiza control
        }
      });
      //Si el producto no esta en el arreglo de productos lo crea
      if (bandera === false) {
        this.productos.push(datos);
      }
      //OPERACIONES EN LOCAL STORAGE
      //Guarda el producto en localStorage
      if (!localStorage.getItem('compra')) localStorage.setItem('compra', '[]');
      let inventario = JSON.parse(localStorage.getItem('compra'));

      let control = false; //<--Indica si esta el producto en el inventario
      inventario.forEach((producto) => {
        //Si el producto ya esta en el arreglo de productos lo modifica
        if (datos.codigo === producto.codigo) {
          producto.nombre = datos.nombre;
          control = true; //<--Actualiza control
        }
      });
      //Si el producto no esta en el arreglo de productos lo crea
      if (!control) {
        inventario.push(datos);
      }

      localStorage.setItem('compra', JSON.stringify(this.productos));
    });
  }

  //Manda los datos de inventario para guardarlos en la DB
  grabarInventario() {
    //Armando cabecera
    let cabecera = {
      comentario: this.comentario,
      sumatoria: this.sumatoria,
      tarifa0: this.tarifa0,
      tarifa12: this.tarifa12,
      subtotal: this.subtotal,
      descuento: this.descuento,
      impuesto: this.impuesto,
      total: this.total,
      autorizacion: this.autoriza_doc,
      ruc: this.proveedor.ruc,
      factura: this.serie_doc + '-' + this.numero_doc,
      sucursal: this.sucursal,
      usuario: JSON.parse(localStorage.getItem('usuario')).codigo,
      empresa: JSON.parse(localStorage.getItem('empresa')).codigo,
    };
    //Armando cuerpo
    let cuerpo = [];
    this.productos.forEach((producto) => {
      let dato = {
        codigo: producto.codigo,
        nombre: producto.nombre,
        cantidad: producto.cantidad,
        costo: producto.costo,
        um: producto.umEscogida,
        descuento: producto.descuento,
        descuento_valor: producto.descuento,
        subtotal: producto.subtotal,
        total: producto.total,
        tarifa: producto.valorTotal,
        tarifa_valor: producto.valorTotal,
        fecha_caducidad: producto.fecha_caducidad,
      };
      dato['descuento_valor'] =
        dato['cantidad'] * dato['costo'] * (dato['descuento'] / 100);
      dato['tarifa_valor'] = dato['total'] - dato['subtotal'];
      cuerpo.push(dato);
    });

    let infoFinalInventario = { cabecera, cuerpo };
    console.log(infoFinalInventario);
    this.spinner.show();
    this.servicio.guardarCompra(infoFinalInventario).subscribe((data) => {
      this.spinner.hide();
      if (data.pedido) {
        Swal.fire({
          icon: 'success',
          title: '¡Transacción exitosa!',
          text: 'El registro de inventario es el #' + data.pedido,
        });
        this.limpiarValores();
        localStorage.setItem('compra', JSON.stringify(this.productos));
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Transacción no realizada.',
          text: 'Ha ocurrido un error inesperado.',
        });
      }
    });
  }

  limpiarValores() {
    this.proveedor = {
      ruc: '9'.repeat(13),
      nombre: '',
    };
    this.buscoProveedor = false;
    this.productos = [];
    this.comentario = '';
    this.busquedaProveedor = '';
    this.autoriza_doc = '';
    //this.factura = ['001', '001', '0000000001'];
    this.serie_doc = '001-001';
    this.numero_doc = '0'.repeat(10);
    this.calcularTotales();
    localStorage.removeItem('compra-proveedor');
  }

  //Borra un item especifico
  borrarItem(i) {
    Swal.fire({
      title: '¿Desea eliminar este producto?',
      showDenyButton: true,
      confirmButtonText: `Sí`,
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        let tempVacio = false;
        let localVacio = false;
        //Borro el item del temporal

        this.productos.splice(i, 1);

        if (this.productos.length === 0) {
          tempVacio = true;
        }

        localStorage.setItem('compra', JSON.stringify(this.productos));

        Swal.fire('¡Producto eliminado!', '', 'success');
      }
    });
  }

  cargarLocalStorage() {
    if (!localStorage.getItem('compra-proveedor')) {
      return;
    }
    this.proveedor = JSON.parse(localStorage.getItem('compra-proveedor'));
    this.buscoProveedor = true;
    if (!localStorage.getItem('compra')) {
      return;
    }
    let compra = JSON.parse(localStorage.getItem('compra'));
    this.productos = compra;
    this.calcularTotales();
  }

  //Abre el modal
  buscarProveedores() {
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<--Datos enviados desde componente hacia modal
        this.busquedaProveedor,
      ],
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferencia = this.modalServicio.show(ModalProveedoresComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });
    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((res) => {
      this.proveedor = res.data;
      this.buscoProveedor = true;
      localStorage.setItem('compra-proveedor', JSON.stringify(this.proveedor));
      console.log(res);
    });
  }

  //Abre el modal
  modalFecha(i) {
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [this.productos[i].fecha_caducidad],
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferencia = this.modalServicio.show(
      ModalFechaCaducidadComponent,
      {
        initialState,
        ignoreBackdropClick: true,
      }
    );
    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((res) => {
      this.productos[i].fecha_caducidad = res.data;
      console.log(res);
    });
  }

  formatearFacturaNumber() {
    if (this.numero_doc.length < 10) {
      this.numero_doc =
        '0'.repeat(10 - this.numero_doc.length) + this.numero_doc;
    }
  }

  formatearAutorizaNumber() {
    console.log(
      'formato de autorizacion',
      this.autoriza_doc.length,
      this.autoriza_doc
    );
    if (this.autoriza_doc.length < 10) {
      this.autoriza_doc =
        '0'.repeat(10 - this.autoriza_doc.length) + this.autoriza_doc;
    }
  }

  //Abre el modal
  abrirModalAgregarCliente() {
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<--Datos enviados desde componente hacia modal
        'Proveedor',
      ],
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferencia = this.modalServicio.show(ModalClienteaddComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });
    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((res) => {
      console.log('MODAL CERRADO, DATA RECIBIDA', res.data);
      this.creaItemArray(res.data);
      this.buscoProveedor = true;
    });
  }

  //Crea el elemento en el array
  creaItemArray(item) {
    let datos = {
      ruc: item.ruc,
      nombre: item.nombre,
      direccion: item.direccion,
      telefono: item.telefono,
      correo: item.correo,
      empresa: this.empresa,
    };
    this.spinner.show();
    this.servicio.creaProveedor(datos).subscribe((data) => {
      console.log(data);
      this.proveedor = datos;
      this.spinner.hide();
    });
  }

  limpiarInput(item, campo) {
    item[campo] = '';
  }

  verificarInput(item, campo, i) {
    if (item[campo] === '') item[campo] = item[campo + '_ant'];
    if (item[campo] < 0) item[campo] = item[campo + '_ant'];
    item[campo + '_ant'] = item[campo];

    this.calcularTotal(item);
  }

  limitarDecimales(event, decimales, valor) {
    console.log(event.key);
    if (event.key == '.') {
      if (decimales === 0) return false;
      else return true;
    }

    if (isNaN(parseInt(event.key))) return true;
    let val = (valor + '').split('.');
    if (val.length > 1) {
      let decima = val[1];
      if (decima.length > decimales - 1) return false;
    }
    return true;
  }
}
