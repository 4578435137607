import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-gastos',
  templateUrl: './gastos.component.html',
  styleUrls: ['./gastos.component.css'],
})
export class GastosComponent implements OnInit {
  gastos: any = [];

  busqueda = '';

  tipos: any;
  servicios: any;

  campaignOne: FormGroup;
  admin: boolean = false;
  sucursales: any;
  sucursal: string = '';
  empresa: any;
  edita: boolean = false;
  constructor(
    private service: RestauranteService,
    private spinner: NgxSpinnerService
  ) {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    const day = today.getDate();

    this.campaignOne = new FormGroup({
      start: new FormControl(new Date(year, month, day)),
      end: new FormControl(new Date(year, month, day)),
    });
  }

  ngOnInit(): void {
    this.admin = JSON.parse(localStorage.getItem('usuario')).admin;
    this.sucursal = JSON.parse(localStorage.getItem('sucursal')).codigo;
    this.empresa = JSON.parse(localStorage.getItem('empresa')).codigo;

    this.cargarSelectores();
    this.cargarSucursales();
    this.read(1, '');
  }

  actualizarDatos() {
    if (this.campaignOne.value.end) {
      console.log(
        this.campaignOne.value.start.toJSON(),
        this.campaignOne.value.end.toJSON()
      );
      this.buscar();
    }
  }

  buscar() {
    if (this.busqueda == '') this.read();
    else this.read(2, this.busqueda);
  }

  create() {
    let data = {
      id: '',
      fecha: this.formatearFechaQuery(),
      tipo_gasto: this.tipos[0].codigo,
      servicio_gasto: this.servicios[0].codigo,
      descripcion: '',
      valor: 0.0,
      comentario: '',
      bloqueado: false,
      nuevo: true,
      empresa: this.empresa,
      sucursal: this.sucursal,
    };
    this.gastos.push(data);
  }

  read(opcion = 1, filtro = '') {
    let datos = {
      opcion: opcion,
      desde:
        this.formatearFechaQuery(this.campaignOne.value.start.toJSON()) +
        '00:00:00',
      hasta:
        this.formatearFechaQuery(this.campaignOne.value.end.toJSON()) +
        '23:59:59',
      filtro: filtro,
      empresa: this.empresa,
      sucursal: this.sucursal,
    };
    this.spinner.show();
    console.log('daos enviados', datos);
    this.service.getGastos(datos).subscribe(
      (result) => {
        console.log(result);
        for (let i = 0; i < result.length; i++) {
          result[i].bloqueado = true;
          result[i].nuevo = false;
          result[i].fecha = this.formatearFechaQuery(result[i].fecha);
          result[i].descripcion = result[i].descripcion.trim();
          result[i].comentario = result[i].comentario.trim();
        }
        this.gastos = result;
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  async delete(i) {
    console.log(i);
    let confirmar = await this.alertaConfirmacion(
      '¿Desea eliminar este registro?'
    );
    if (!confirmar) return;
    if (this.gastos[i].nuevo) this.gastos.splice(i, 1);
    else {
      let data = {
        id: this.gastos[i].id_gastos,
      };
      this.spinner.show();
      this.service.eliminarGastos(data).subscribe((result) => {
        console.log(result);
        this.gastos.splice(i, 1);
        this.spinner.hide();
      });
    }
  }

  cargarSelectores() {
    // tipo de gasto
    this.service.getConfiguraciones('013', this.empresa).subscribe((result) => {
      console.log(result);
      this.tipos = result;
    });
    //ubicacion del gasto
    this.service.getConfiguraciones('016', this.empresa).subscribe((result) => {
      console.log(result);
      this.servicios = result;
    });
  }

  async grabar(i) {
    let confirmar = await this.alertaConfirmacion(
      '¿Desea guardar este registro?'
    );
    if (!confirmar) return;
    let data = this.gastos[i];
    console.log(data);

    this.spinner.show();
    if (this.gastos[i].nuevo) {
      this.service.registrarGastos(data).subscribe((result) => {
        console.log(result);

        this.spinner.hide();
        this.read();
      });
    } else {
      this.service.actualizarGastos(data).subscribe((result) => {
        console.log(result);
        this.gastos[i].bloqueado = true;
        this.spinner.hide();
      });
    }
  }

  async alertaConfirmacion(titulo) {
    return Swal.fire({
      title: titulo,
      showDenyButton: true,
      confirmButtonText: 'Si',
      denyButtonText: 'No',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        return true;
      } else if (result.isDenied) {
        return false;
      }
    });
  }

  update(i) {
    if (this.gastos[i].bloqueado) {
      this.gastos[i].bloqueado = false;
      this.edita = true;
    } else {
      this.gastos[i].bloqueado = true;
      this.edita = false;
    }
  }

  formatearFechaQuery(fecha = new Date()) {
    let fechaFin = new Date(fecha);
    let fechaTotal = fechaFin.toJSON().slice(0, 11);
    return fechaTotal;
  }

  formatearFechaString(fecha = new Date(), hora = false) {
    let fechaFin = new Date(fecha);
    let fechaString = fechaFin
      .toJSON()
      .slice(0, 10)
      .split('-')
      .reverse()
      .join('/');

    if (hora) {
      //Formatear hora
      return fechaString;
    }
    return fechaString;
  }

  cargarSucursales() {
    this.service.getConfiguraciones('015', this.empresa).subscribe((result) => {
      console.log(result);
      if (result.length > 0) {
        this.sucursales = result;
      }
    });
  }

  limpiarInput(item, campo) {
    item[campo] = '';
  }

  verificarInput(item, campo, i) {
    if (item[campo] === '') item[campo] = item[campo + '_ant'];
    if (item[campo] < 0) item[campo] = item[campo + '_ant'];
    item[campo + '_ant'] = item[campo];

    this.calcularTotal(item);
  }

  calcularTotal(item) {
    console.log(item);
  }

  limitarDecimales(event, decimales, valor) {
    console.log(event.key);
    if (event.key == '.') {
      if (decimales === 0) return false;
      else return true;
    }

    if (isNaN(parseInt(event.key))) return true;
    let val = (valor + '').split('.');
    if (val.length > 1) {
      let decima = val[1];
      if (decima.length > decimales - 1) return false;
    }
    return true;
  }
}
