<!--Cabecera de Documento-->
<div class="container-fluid my-2">
  <div class="row align-items-center">
    <div class="col-12 col-md-4 col-lg-3 col-xl-2" *ngIf="admin">
      <mat-form-field appearance="fill" class="no-padding">
        <mat-label>Sucursal</mat-label>
        <mat-select panelClass="my-select-panel-class" [(value)]="sucursal">
          <mat-option
            *ngFor="let sucursal of sucursales"
            [value]="sucursal.codigo.trim()"
            >{{ sucursal.nombre }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col">
      <h3>
        Provision de Facturas
        <small class="text-muted"></small>
      </h3>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-12 col-md-4">
      <div class="card">
        <div class="card-body">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <button
                type="button"
                class="btn btn-sm btn-outline-success"
                (click)="abrirModalAgregarCliente()"
              >
                <i class="far fa-plus-square"></i>
              </button>
            </div>
            <div class="input-group-prepend">
              <label class="input-group-text" for="tin">Proveedor</label>
            </div>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="RUC | Nombre"
              aria-label="Busqueda"
              aria-describedby="basic-addon1"
              [(ngModel)]="busquedaProveedor"
              (keyup.enter)="buscarProveedores()"
            />

            <div class="input-group-append" (click)="buscarProveedores()">
              <span class="input-group-text" id="basic-addon1"
                ><i class="fas fa-search"></i
              ></span>
            </div>
          </div>
        </div>
      </div>

      <div class="card my-2" *ngIf="buscoProveedor">
        <div class="card-body">
          <h6 class="card-subtitle mb-2 text-muted">Datos del Proveedor</h6>

          <p class="m-0 p-0"><strong>RUC :</strong> {{ proveedor.ruc }}</p>

          <p class="m-0 p-0">
            <strong>Nombre :</strong> {{ proveedor.nombre }}
          </p>

          <p class="m-0 p-0">
            <strong>Correo :</strong> {{ proveedor.correo }}
          </p>

          <p class="m-0 p-0">
            <strong>Teléfonos :</strong> {{ proveedor.telefono }}
          </p>

          <p class="m-0 p-0">
            <strong>Dirección :</strong> {{ proveedor.direccion }}
          </p>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-5" *ngIf="buscoProveedor">
      <div class="card mb-2">
        <div class="card-body">
          <h6 class="card-subtitle mb-2">Datos del Documento</h6>
          <div class="row">
            <div class="col-12 col-md-4">
              <mat-form-field appearance="fill" class="no-padding">
                <mat-label>Tipo de Documento</mat-label>
                <mat-select
                  panelClass="my-select-panel-class"
                  [(value)]="tipo_doc"
                >
                  <mat-option
                    *ngFor="let tipodoc of tiposdocprv"
                    [value]="tipodoc.codigo.trim()"
                    >{{ tipodoc.nombre }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-8">
              <div
                class="form-group my-1"
                style="display: flex; align-items: center"
              >
                <div>
                  <label class="mr-3" style="margin: 0px"
                    >Fecha Documento :
                  </label>
                </div>
                <div>
                  <div
                    class="d-flex form-group"
                    style="margin-bottom: 0px; align-items: center"
                  >
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      id="inputAutorizacion"
                      [(ngModel)]="autoriza_doc"
                      (focusout)="formatearAutorizaNumber()"
                    />
                  </div>
                </div>
              </div>
              <div
                class="form-group my-1"
                style="display: flex; align-items: center"
              >
                <div>
                  <label class="mr-3" style="margin: 0px">Plazo : </label>
                </div>
                <div>
                  <div
                    class="d-flex form-group"
                    style="margin-bottom: 0px; align-items: center"
                  >
                    <input
                      type="numeric"
                      class="form-control form-control-sm text-right"
                      id="inputAutorizacion"
                      [(ngModel)]="autoriza_doc"
                      mask="000"
                      placeholder="30"
                      style="width: 50px"
                      (focusout)="formatearAutorizaNumber()"
                    />
                  </div>
                </div>
                <div>
                  <label class="mx-1" style="margin: 0px">Dias</label>
                </div>
              </div>
            </div>
          </div>

          <div
            class="form-group my-1"
            style="display: flex; align-items: center"
          >
            <div>
              <label class="mr-3" style="margin: 0px">Documento : </label>
            </div>
            <div>
              <div
                class="d-flex form-group"
                style="margin-bottom: 0px; align-items: center"
              >
                <input
                  type="numeric"
                  class="form-control form-control-sm mr-2 input-sm"
                  [(ngModel)]="serie_doc"
                  style="width: 75px"
                  placeholder="001-001"
                  mask="000-000"
                  (focusout)="formatearFacturaNumber()"
                />

                <input
                  type="numeric"
                  class="form-control form-control-sm mr-2 input-sm"
                  [(ngModel)]="numero_doc"
                  style="width: 100px"
                  maxlength="9"
                  mask="0*"
                  placeholder="0123456789"
                  (focusout)="formatearFacturaNumber()"
                />
              </div>
            </div>

            <div>
              <label class="mr-3" style="margin: 0px">Aut. : </label>
            </div>
            <div>
              <div
                class="d-flex form-group"
                style="margin-bottom: 0px; align-items: center"
              >
                <input
                  type="numeric"
                  class="form-control form-control-sm"
                  id="inputAutorizacion"
                  [(ngModel)]="autoriza_doc"
                  maxlength="49"
                  style="width: 375px"
                  placeholder="012345679 o 49 Digitos"
                  mask="0*"
                  (focusout)="formatearAutorizaNumber()"
                />
              </div>
            </div>
          </div>
          <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <label class="input-group-text" for="tin">Cuenta Gasto : </label>
            </div>
            <input
              type="text"
              class="form-control form-control-sm col-2"
              placeholder="Codigo"
              aria-label="Busqueda"
              aria-describedby="basic-addon1"
              [(ngModel)]="busquedaProveedor"
              (keyup.enter)="buscarProveedores()"
            />
            <input
              type="text"
              class="form-control form-control-sm col-10"
              placeholder="Nombre"
              aria-label="Busqueda"
              aria-describedby="basic-addon1"
              [(ngModel)]="busquedaProveedor"
              disabled
            />
            <div class="input-group-append" (click)="buscarProveedores()">
              <span class="input-group-text" id="basic-addon1"
                ><i class="fas fa-search"></i
              ></span>
            </div>
          </div>
        </div>
        <div class="card-footer p-1">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-md-4 text-center">
                <p style="color: #2c3e50" class="m-0 p-0">
                  <strong>Tarifa 0 % </strong>
                </p>
              </div>
              <div class="col-12 col-md-4 text-center">
                <p style="color: #2c3e50" class="m-0 p-0">
                  <strong>Tarifa Imp</strong>
                </p>
              </div>
              <div class="col-12 col-md-4 text-center">
                <p style="color: #2c3e50" class="m-0 p-0">
                  <strong>Impuesto</strong>
                </p>
              </div>

              <div class="col-12 col-md-4 d-flex justify-content-center">
                <input
                  type="numeric"
                  class="form-control form-control-sm mr-2 input-sm text-right"
                  [(ngModel)]="serie_doc"
                  style="width: 100px"
                  placeholder="0,00"
                  mask="0000000,00"
                  
                />
              </div>
              <div class="col-12 col-md-4 d-flex justify-content-center">
                <input
                  type="numeric"
                  class="form-control form-control-sm mr-2 input-sm text-right"
                  [(ngModel)]="serie_doc"
                  style="width: 100px"
                  placeholder="0,00"
                  mask="0000000,00"
              
                />
              </div>
              <div class="col-12 col-md-4 d-flex justify-content-center">
                <input
                  type="numeric"
                  class="form-control form-control-sm mr-2 input-sm text-right"
                  [(ngModel)]="serie_doc"
                  style="width: 100px"
                  placeholder="0,00"
                  mask="0000000,00"
                  
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-3" *ngIf="buscoProveedor">
      <div class="card mb-2">
        <div class="card-body">
          <h6 class="card-subtitle mb-2">Detalle</h6>
          <mat-form-field appearance="fill" class="no-padding w-100">
            <mat-label>Tipo de Documento</mat-label>
            <mat-select panelClass="my-select-panel-class" [(value)]="tipo_doc">
              <mat-option
                *ngFor="let tipodoc of tiposdocprv"
                [value]="tipodoc.codigo.trim()"
                >{{ tipodoc.nombre }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <textarea
            class="form-control form-control-sm"
            placeholder="Ingresar Comentario"
            [(ngModel)]="comentario"
            rows="3"
          ></textarea>
        </div>
        <div
          class="card-footer p-1"
          [style.background-color]="datosempresa['color']"
        >
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-md-6 text-center text-light">
                <p style="font-size: 1.2rem" class="m-0 p-0">
                  <strong>DEBITO</strong>
                </p>
              </div>
              <div class="col-12 col-md-6 text-center text-light">
                <p style="font-size: 1.2rem" class="m-0 p-0">
                  <strong>CREDITO</strong>
                </p>
              </div>
              <div class="col-12 col-md-6 text-center">
                <p style="font-size: 1.2rem; color: #f1c40f" class="m-0 p-0">
                  <strong>$ 00,00</strong>
                </p>
              </div>
              <div class="col-12 col-md-6 text-center">
                <p style="font-size: 1.2rem; color: #f1c40f" class="m-0 p-0">
                  <strong>$ 00,00</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-md-6">
      <button
        type="button"
        class="btn btn-sm btn-outline-success"
        (click)="abrirModalNuevoProducto()"
        *ngIf="buscoProveedor"
      >
        <i class="far fa-plus-square"></i> Cuenta
      </button>
    </div>
    <div class="col-12 col-md-6 text-right">
      <button
        type="button"
        class="btn btn-sm btn-outline-danger"
        (click)="grabarInventario()"
        *ngIf="productos.length > 0"
      >
        <i class="fas fa-cloud-upload-alt"></i> Grabar
      </button>
    </div>
  </div>
</div>

<div class="container-fluid my-2" *ngIf="productos.length > 0">
  <div class="table-responsive">
    <table class="table table-striped table-sm table-hover m-0 p-0">
      <thead>
        <tr>
          <th scope="col" style="width: 110px; text-align: center">#</th>
          <th scope="col" style="width: 150px; text-align: center">Cuenta</th>
          <th scope="col">Nombre</th>
          <th scope="col" style="width: 150px; text-align: left">Documento</th>
          <th scope="col" style="width: 150px; text-align: right">Valor</th>
          <th scope="col" style="width: 100px; text-align: center">D/C</th>

          <th scope="col" style="width: 150px; text-align: center">Cod/Ret</th>
          <th scope="col" style="width: 350px; text-align: center">Concepto</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let producto of productos
              | paginate
                : {
                    itemsPerPage: 25,
                    currentPage: p,
                    totalItems: productos.count
                  };
            index as i
          "
          id="{{ producto.codigo }}"
        >
          <td class="d-flex" style="justify-content: space-around">
            <button
              type="button"
              class="btn btn-outline-danger btn-sm"
              (click)="borrarItem(i)"
            >
              <i class="far fa-trash-alt"></i>
            </button>
          </td>
          <td style="text-align: center; max-height: 40px">
            <p class="m-0 p-0">
              {{ producto.codigo }}
            </p>
          </td>
          <td>
            <p class="m-0 p-0">
              {{ producto.nombre }}
            </p>
          </td>
          <td>
            <p class="m-0 p-0">
              {{ producto.nombre }}
            </p>
          </td>
          <td>
            <input
              type="numeric"
              class="form-control form-control-sm text-right"
              [(ngModel)]="producto.cantidad"
              (keydown)="limitarDecimales($event, 2, producto.cantidad)"
              (focusin)="limpiarInput(producto, 'cantidad')"
              (focusout)="verificarInput(producto, 'cantidad', i)"
              placeholder="0,00"
              mask="0000000,00"
            />
          </td>
          <td>
            <select
              class="custom-select custom-select-sm"
              name="um"
              id="um"
              [(ngModel)]="producto.tarifa"
              (change)="calcularTotal(producto)"
            >
              <option *ngFor="let tarif of tarifas" value="{{ tarif.codigo }}">
                {{ tarif.nombre }}
              </option>
            </select>
          </td>
          <td>
            <select
              class="custom-select custom-select-sm"
              name="um"
              id="um"
              [(ngModel)]="producto.tarifa"
              (change)="calcularTotal(producto)"
            >
              <option *ngFor="let tarif of tarifas" value="{{ tarif.codigo }}">
                {{ tarif.nombre }}
              </option>
            </select>
          </td>

          <td>
            <textarea
              class="form-control form-control-sm m-0"
              [(ngModel)]="producto.nombre"
              rows="1"
            ></textarea>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="alert alert-danger text-center" role="alert">
  <p><strong>ERROR DE CONEXION CUENTAS CONTABLES</strong></p>
</div>
<app-spinner></app-spinner>
