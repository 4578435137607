<div class="modal-header">
  <h4 class="modal-title" id="titulo">Busqueda de Item</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="modalReferencia.hide()"
  >
    <span aria-hidden="true">&times; </span>
  </button>
</div>

<div class="container">
  <div class="mt-3">
    <div class="form-group row">
      <label for="nombre" class="col-sm-5 col-form-label"
        >Ingrese el nombre:
      </label>
      <div class="col-sm-7">
        <input
          [(ngModel)]="buscador_producto"
          type="text"
          class="form-control"
          id="nombre"
          placeholder="Buscar..."
          (keyup)="traerProductos()"
        />
      </div>
    </div>
  </div>
</div>

<div
  class="table table-responsive table-hover table-responsive-sm table-responsive-md table-responsive-xl"
  *ngIf="hayProductos === true; else noItems"
>
  <table class="table table-sm">
    <thead>
      <tr>
        <th scope="col">Ingredientes</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let producto of productos">
        <td id="{{ producto.codigo }}" (click)="cerrarModal($event)">
          {{ producto.nombre }}
        </td>
      </tr>
    </tbody>
  </table>
</div>

<!--Boton-->
<div class="container text-right my-1" *ngIf="hayProductos === true">
  <button
    type="button"
    class="btn btn-outline-danger btn-sm btn_est m-2"
    (click)="cerrarModal()"
  >
    <i class="fas fa-times-circle"></i> Cancelar
  </button>
</div>

<!--Busqueda sin resultados-->
<ng-template #noItems>
  <ng-container>
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-6 mt-5" style="text-align: center">
          <lottie-player
            src="https://assets2.lottiefiles.com/temp/lf20_TOE9MF.json"
            background="transparent"
            speed="1"
            loop
            autoplay
          ></lottie-player>
          <h3>
            Busqueda
            <small class="text-muted"> sin resultados</small>
          </h3>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<app-spinner></app-spinner>
