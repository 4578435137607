declare var require: any; //<-- Para poder importar archivos JS en TS

import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
var validadorCedula = require('src/app/validadores/validarCedula.js');
var validadorRuc = require('src/app/validadores/validarRuc.js');
var validadorCorreo = require('src/app/validadores/validarCorreo.js');

@Component({
  selector: 'app-modal-clienteadd',
  templateUrl: './modal-clienteadd.component.html',
  styleUrls: ['./modal-clienteadd.component.css'],
})
export class ModalClienteaddComponent implements OnInit {
  datos: object;
  tipo: string;
  cliente: any = {
    ruc: '',
    nombre: '',
    direccion: '',
    telefono: '',
    correo: '',
    empresa: '',
    sucursal: '',
  }; //<-- Aqui se guarda el nuevo cliente creado
  public event: EventEmitter<any> = new EventEmitter(); //<-- Para devolver datos a cliente
  banderaSpinner: boolean = false;
  preseleccionado = false;
  empresa: string = '';
  constructor(
    public modalReferencia: BsModalRef,
    private servicio: RestauranteService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
    this.tipo = this.datos[0];
    if (this.datos[1]) {
      this.cliente.ruc = this.datos[1];
      this.preseleccionado = true;
    }

    //Verifica bandera para ocultar spinner
    setInterval(() => {
      if (this.banderaSpinner === true) {
        this.spinner.hide();
        this.banderaSpinner = false;
      }
    }, 100);
  }

  //Guarda el cliente creado
  enviarCliente() {
    //Obtiene clientes

    let nombre = this.cliente.nombre;
    let direccion = this.cliente.direccion;
    let telefono = this.cliente.telefono;
    let correo = this.cliente.correo;

    //Valida
    if (nombre == '' || direccion == '' || telefono == '' || correo == '') {
      //No escogio todo
      Swal.fire('¡Error!', 'Por favor, llene todos los campos', 'warning');
      return;
    }
    if (!this.rucValido) {
      Swal.fire(
        '¡Cédula inválida!',
        'Por favor, ingrese una cédula o RUC válido',
        'warning'
      );
      return;
    }

    if (this.cliente.direccion.length < 15) return;
    if (this.cliente.telefono.length != 8 && this.cliente.telefono.length != 10)
      return;

    if (validadorCorreo.validarEmail(correo) === false) {
      Swal.fire(
        '¡Correo inválido!',
        'Por favor, ingrese un correo válido.',
        'warning'
      );
      return;
    }
    //Devuelve el cliente
    this.spinner.show();
    if (this.tipo == 'Cliente') {
      this.servicio
        .buscarCliente(this.cliente.ruc, this.empresa)
        .subscribe((data) => {
          //Si ya existe
          if (data.length > 0) {
            this.banderaSpinner = true;
            Swal.fire(
              '¡Error!',
              'Este RUC ya se encuentra registrado.',
              'error'
            );
          } else {
            //Si no existe

            this.banderaSpinner = true;
            Swal.fire('¡Éxito!', 'Operación exitosa.', 'success').then(() => {
              this.cerrarModal();
            });
          }
        });
    } else if (this.tipo == 'Proveedor') {
      this.servicio
        .buscarProveedor(this.cliente.ruc, this.empresa)
        .subscribe((data) => {
          //Si ya existe
          if (data.length > 0) {
            this.banderaSpinner = true;
            Swal.fire(
              '¡Error!',
              'Este RUC ya se encuentra registrado.',
              'error'
            );
          } else {
            //Si no existe

            this.banderaSpinner = true;
            Swal.fire('¡Éxito!', 'Operación exitosa.', 'success').then(() => {
              this.cerrarModal();
            });
          }
        });
    }
  }

  rucValido = true;
  cargarUsuario() {
    this.spinner.show();
    this.servicio.obtenerNombreCI(this.cliente.ruc).subscribe((result) => {
      console.log(result);
      this.cliente.nombre = result.nombre;
      this.spinner.hide();
    });
  }

  cerrarModal() {
    this.lanzarEvento(this.cliente); //<-- Envia la data a cliente
    this.modalReferencia.hide(); //<-- Cierra el modal
  }

  lanzarEvento(item) {
    this.event.emit({ data: item, res: 200 });
  }

  cancelar() {
    this.modalReferencia.hide();
  }
}
