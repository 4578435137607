import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalService } from '../global.service';

@Injectable({
  providedIn: 'root',
})
export class ServicioMeseroService {
  constructor(private http: HttpClient, public global: GlobalService) {}

  //Logeo
  login(datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<any>(
      this.global.servidor + '/api/usuarios/login',
      body,
      httpOptions
    );
  }

  //Logeo
  loginEmpleados(datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<any>(
      this.global.servidor + '/api/usuarios/loginEmpleados',
      body,
      httpOptions
    );
  }

  //Obtener meseros
  obtenerMeseros(): Observable<any> {
    return this.http.get<any>(
      this.global.servidor + '/api/motorizados/obtenerMeseros'
    );
  }

  //Traer las sucursales
  obtenerSucursales(): Observable<any> {
    return this.http.get<any>(this.global.servidor + '/api/sucursales');
  }

  //Cargar configuraciones LINEA, GRUPO, etc
  getConfiguraciones(tipo, empresa): Observable<any> {
    return this.http.get<any>(
      this.global.servidor +
        '/api/configuraciones/filtroPorTipo/' +
        tipo +
        '/' +
        empresa
    );
  }

  //CRUD de Distribuidores
  getDistribuidores(campos): Observable<any> {
    return this.http.get<any>(
      this.global.servidor +
        `/api/distribuidores/getDistribuidores?texto=${campos.texto}&empresa=${campos.empresa}`
    );
  }

  buscarCliente(texto, empresa): Observable<any> {
    return this.http.get<any>(
      this.global.servidor +
        '/api/usuarios/buscarCliente/' +
        texto +
        '/' +
        empresa
    );
  }

  //Cargar configuraciones LINEA, GRUPO, etc
  getLineas(campos): Observable<any> {
    return this.http.get<any>(
      `${this.global.servidor}/api/configuraciones/lineas?tipo=${campos.tipo}&empresa=${campos.empresa}`
    );
  }

  eliminaCliente(id, empresa): Observable<any> {
    return this.http.delete<any>(
      this.global.servidor +
        '/api/usuarios/eliminarCliente/' +
        id +
        '/' +
        empresa
    );
  }

  actualizaCliente(id, datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.put<any>(
      this.global.servidor + '/api/usuarios/actualizarCliente/' + id,
      body,
      httpOptions
    );
  }
  //Realizar un pedido
  efectuarPedido(datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<any>(
      this.global.servidor + '/api/pedidos/registrarPedido',
      body,
      httpOptions
    );
  }

  //Obtener todos los items de una linea
  obtenerItemsLinea(campos): Observable<any> {
    return this.http.get<any>(
      this.global.servidor +
        `/api/configuraciones/itemsLinea?codigo=${campos.codigo}&usuario=${campos.usuario}&empresa=${campos.empresa}`
    );
  }

  //Buscar receta en DB
  buscarReceta(id, opc): Observable<any> {
    return this.http.get<any>(
      this.global.servidor + '/api/recetas/buscarReceta/' + id + '/' + opc
    );
  }

  creaCliente(datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<any>(
      this.global.servidor + '/api/usuarios/crearCliente',
      body,
      httpOptions
    );
  }

  //Buscar receta en DB
  getMesas(campos): Observable<any> {
    return this.http.get<any>(
      `${this.global.servidor}/api/pedidos/obtenerMesas?empresa=${campos.empresa}&sucursal=${campos.sucursal}`
    );
  }

  //Visualizar detalle de un pedido
  verFactura(campos): Observable<any> {
    let datos = {
      empresa: campos.empresa,
      sucursal: campos.sucursal,
      modulo: campos.modulo,
      tipo: campos.tipo,
      numero: campos.idFactura,
      distribuidor: '',
      latitud: '',
      longitud: '',
    };
    return this.http.post<any>(
      this.global.servidor + `/api/pedidos/verPedido`,
      datos
    );
  }

  //Borrar un pedido
  eliminarFactura(campos): Observable<any> {
    return this.http.delete<any>(
      this.global.servidor +
        `/api/pedidos/eliminarPedido?numero=${campos.numero}&tipo=${campos.tipo}&sucursal=${campos.sucursal}&modulo=${campos.modulo}&empresa=${campos.empresa}`
    );
  }

  enviarCocina(datos): Observable<any> {
    const body = JSON.stringify(datos);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<any>(
      this.global.servidor + '/api/pedidos/enviarCocina',
      body,
      httpOptions
    );
  }

  //Visualizar detalle de un pedido
  obtenerNotificaciones(campos): Observable<any> {
    return this.http.get<any>(
      this.global.servidor +
        `/api/menus/notificaciones/obtener?empresa=${campos.empresa}&sucursal=${campos.sucursal}&perfil=${campos.perfil}`
    );
  }

  //LISTA DE PEDIDOS QUE NO SE HAN NOTIFICADO
  obtenerNotiPedidosApp(campos): Observable<any> {
    return this.http.get<any>(
      this.global.servidor +
        `/api/menus/notificaciones/pedidosapp?empresa=${campos.empresa}&sucursal=${campos.sucursal}`
    );
  }
}
