<div class="mt-2">
  <div class="container mb-3">
    <div class="row justify-content-center align-items-center">
      <div class="col-5 col-md-3 col-xl-1">
        <button
          type="button"
          class="btn btn-sm btn-outline-success"
          (click)="abrirModalAgregarCliente()"
        >
          <i class="far fa-plus-square"></i> Agregar
        </button>
      </div>

      <div
        class="col-11 col-md-8 col-xl-8 text-center text-md-left text-xl-center"
      >
        <h3>
          Biblioteca
          <small class="text-muted"> de Proveedores</small>
        </h3>
      </div>

      <div class="col-11 col-md-8 col-xl-3 text-center text-md-left">
        <div class="input-group">
          <input
            type="text"
            class="form-control form-control-sm"
            placeholder="Buscar Nombre | Codigo"
            aria-label="Busqueda"
            aria-describedby="basic-addon1"
            (keyup)="busquedaCliente($event)"
          />
          <div class="input-group-append">
            <span class="input-group-text" id="basic-addon1"
              ><i class="fab fa-searchengin"></i
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!---->
<div class="container-fluid">
  <div class="table-responsive" *ngIf="hayCliente === true; else sinClientes">
    <table class="table table-striped table-sm table-hover m-0 p-0">
      <thead>
        <tr>
          <th
            scope="col"
            style="width: 50px !important; text-align: center"
          ></th>

          <th scope="col" style="min-width: 150px">RUC</th>
          <th scope="col" style="min-width: 250px">Nombre</th>
          <th scope="col" style="min-width: 250px">Dirección</th>
          <th scope="col" style="min-width: 150px">Telefono</th>
          <th scope="col" style="min-width: 250px">Correo</th>
        </tr>
      </thead>

      <tbody>
        <tr
          *ngFor="
            let cliente of clientes
              | paginate
                : {
                    itemsPerPage: 25,
                    currentPage: p,
                    totalItems: clientes.count
                  }
          "
        >
          <td>
            <button
              type="button"
              class="btn btn-outline-dark btn-sm"
              (click)="abrirModalDetallesCliente(cliente)"
            >
              <i class="far fa-eye"></i>
            </button>
          </td>

          <td>{{ cliente.ruc }}</td>
          <td>{{ cliente.nombre }}</td>
          <td>{{ cliente.direccion }}</td>
          <td>{{ cliente.telefono }}</td>
          <td>{{ cliente.correo }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="container-fluid" *ngIf="hayCliente === true">
  <div class="row justify-content-center align-items-center mt-4 mb-3">
    <div class="col-12" style="text-align: center">
      <pagination-controls
        (pageChange)="p = $event"
        autoHide="true"
        previousLabel="Anterior"
        nextLabel="Siguiente"
      >
      </pagination-controls>
    </div>
  </div>
</div>

<ng-template #sinClientes>
  <ng-container>
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-6 mt-5" style="text-align: center">
          <lottie-player
            src="https://assets2.lottiefiles.com/temp/lf20_TOE9MF.json"
            background="transparent"
            speed="1"
            loop
            autoplay
          ></lottie-player>
          <h3>
            No hay resultados
            <small class="text-muted"> de Clientes</small>
          </h3>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<app-spinner></app-spinner>
