<!--Cabecera-->
<div class="row justify-content-center align-items-center my-2">
  <div class="col-9 col-md-10 text-center">
    <h3>
      Nuevo
      <small class="text-muted">Cliente</small>
    </h3>
  </div>
  <div
    class="col-2 col-md-1 justify-content-center align-items-center"
    (click)="modalReferencia.hide()"
  >
    <button type="button" class="close pull-right" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>

<!--Datos del Cliente-->
<div class="container">
  <div class="row justify-content-center align-items-center">
    <div class="col-11 col-md-3 text-md-right">
      <span>Ruc : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-8">
          <input
            type="numeric"
            class="form-control form-control-sm"
            placeholder="Ingrese el R.U.C."
            [(ngModel)]="cliente.ruc"
            appOnlynumber
          />
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Nombre : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-8">
          <input
            type="text"
            class="form-control form-control-sm"
            placeholder="Ingrese el Nombre Completo"
            [(ngModel)]="cliente.nombre"
          />
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Dirección : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-8">
          <input
            type="text"
            class="form-control form-control-sm"
            placeholder="Ingrese el Dirección"
            [(ngModel)]="cliente.direccion"
          />
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Teléfono : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-8">
          <input
            type="text"
            class="form-control form-control-sm"
            placeholder="Ingrese el Teléfono"
            [(ngModel)]="cliente.telefono"
            appOnlynumber
          />
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Correo : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-8">
          <input
            type="text"
            class="form-control form-control-sm"
            placeholder="Ingrese el Correo Electrónico"
            [(ngModel)]="cliente.correo"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<!--Botones Inferiores de Transaccion-->
<div class="container text-right my-1">
  <button
    type="button"
    class="btn btn-outline-success btn-sm btn_est m-2"
    (click)="enviarCliente()"
  >
    <i class="fas fa-check-circle"></i> Guardar
  </button>

  <button
    type="button"
    class="btn btn-outline-danger btn-sm btn_est m-2"
    (click)="cancelar()"
  >
    <i class="fas fa-times-circle"></i> Cancelar
  </button>
</div>

<app-spinner></app-spinner>
