<div class="row justify-content-center align-items-center my-2">
  <div class="col-8 text-center">
    <h5>
      Filtrado :
      <small class="text-muted">{{ nombre }}</small>
    </h5>
  </div>
  <div
    class="col-3 justify-content-center align-items-center"
    (click)="modalReferencia.hide()"
  >
    <button type="button" class="close pull-right" aria-label="Close">
      <span aria-hidden="true">&times; </span>
    </button>
  </div>
</div>
<div
  class="row justify-content-center align-items-center mt-2 mb-4 text-center"
>
  <div class="col-6">
    <button
      type="button"
      class="btn btn-outline-success btn-sm"
      (click)="filtrar()"
    >
      <i class="far fa-plus-square"></i> Filtrar
    </button>
  </div>
  <div class="col-6" *ngIf="filtroAplicado === true">
    <button
      type="button"
      class="btn btn-outline-danger btn-sm"
      (click)="quitarFiltro()"
    >
      <i class="far fa-plus-square"></i> Quitar Filtro
    </button>
  </div>
</div>

<div class="row justify-content-center align-items-center my-2">
  <div class="col-10">
    <div class="row">
      <div class="col-6" *ngFor="let datoModal of datosModal">
        <div class="form-check" (click)="marcar(datoModal.valor, $event)">
          <input
            class="form-check-input"
            type="checkbox"
            value="datoModal.marcado"
            id="{{ datoModal.valor }}"
          />
          <label class="form-check-label" for="{{ datoModal.valor }}">
            {{ datoModal.valor }}
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
