<!--CABECERA-->
<div class="container-fluid">
  <div class="row my-2 align-items-center">
    <div class="col-12 col-md-4 col-lg-3 col-xl-2" *ngIf="admin">
      <mat-form-field appearance="fill" class="no-padding">
        <mat-label>Sucursal</mat-label>
        <mat-select panelClass="my-select-panel-class" [(value)]="sucursal">
          <mat-option
            *ngFor="let sucursal of sucursales"
            [value]="sucursal.codigo.trim()"
            >{{ sucursal.nombre }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col">
      <h4>
        Registro de Inventario
        <small class="text-muted"></small>
      </h4>
    </div>
  </div>
</div>

<!--DATOS DEL DOCUMENTO -->
<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-12 col-md-6 col-xl-4">
      <div class="card mb-1">
        <div class="card-body">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <label class="input-group-text" for="tin"
                >Tipo de Movimiento :
              </label>
            </div>
            <select
              class="form-select form-control-sm"
              name="tin"
              [(ngModel)]="tinEscogido"
              id="tin"
              (change)="actualizaTinPersistente()"
            >
              <option *ngFor="let datoTin of datosTin" [ngValue]="datoTin">
                {{ datoTin.nombre }}
              </option>
            </select>
          </div>

          <div class="input-group input-group-sm pt-2">
            <div class="input-group-prepend">
              <label class="input-group-text" for="inv">Motivo : </label>
            </div>
            <select
              class="form-select form-select-sm"
              name="inv"
              [(ngModel)]="invEscogido"
              id="inv"
              (change)="actualizaInvPersistente()"
            >
              <option *ngFor="let datoInv of datosInv" [ngValue]="datoInv">
                {{ datoInv.nombre }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-4">
      <div class="card">
        <div class="card-body">
          <h6 class="card-subtitle mb-2 text-muted">Comentario</h6>
          <div class="input-group">
            <textarea
              class="form-control form-control-sm"
              placeholder="Ingresar Comentario"
              [(ngModel)]="comentario"
              (keyup)="actualizaComentario()"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--BOTONES DE ACCION-->
<div class="container-fluid">
  <div class="row py-4">
    <div class="col-6">
      <button
        type="button"
        class="btn btn-sm btn-outline-success"
        (click)="abrirModalNuevoProducto()"
      >
        <i class="far fa-plus-square"></i> Producto
      </button>
    </div>
    <div class="col-6 text-right">
      <button
        type="button"
        class="btn btn-sm btn-outline-danger mx-1"
        (click)="grabarInventario()"
        *ngIf="productos.length > 0"
      >
        <i class="fas fa-cloud-upload-alt"></i> Grabar
      </button>
    </div>
  </div>
</div>
<!--GRID DE MOVIMIENTOS DEL DOCUMENTO-->
<div class="container-fluid pb-3" *ngIf="productos.length > 0">
  <div class="table-responsive">
    <table class="table table-striped table-sm table-hover m-0 p-0">
      <thead>
        <tr>
          <th scope="col" style="width: 50px; text-align: center"></th>
          <th scope="col" style="width: 100px; text-align: right">Código</th>
          <th scope="col" style="min-width: 250px; text-align: center">
            Nombre
          </th>
          <th
            scope="col"
            style="width: 100px; min-width: 100px; text-align: right"
          >
            Cantidad
          </th>
          <th
            scope="col"
            style="width: 150px; min-width: 150px; text-align: center"
          >
            Medida
          </th>
          <th
            scope="col"
            style="width: 150px; min-width: 150px; text-align: right"
          >
            Costo
          </th>
          <th
            scope="col"
            style="width: 150px; min-width: 150px; text-align: right"
          >
            Subtotal
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let producto of productos
              | paginate
                : {
                    itemsPerPage: 25,
                    currentPage: p,
                    totalItems: productos.count
                  }
          "
          id="{{ producto.codigo }}"
        >
          <td style="text-align: center">
            <button
              type="button"
              class="btn btn-outline-danger btn-sm"
              (click)="borrarItem(producto.codigo)"
            >
              <i class="far fa-trash-alt"></i>
            </button>
          </td>
          <td style="text-align: center">{{ producto.codigo }}</td>
          <td>{{ producto.nombre }}</td>
          <td>
            <input
              type="numeric"
              class="form-control form-control-sm text-right input-sm"
              [(ngModel)]="producto.cantidad"
              mask="0*"
              maxlength="8"
              placeholder="Cantidad"
              (keyup)="verificarInput(producto, 'cantidad')"
            />
          </td>
          <td>
            <div class="input-group">
              <select
                class="custom-select custom-select-sm"
                name="um"
                id="um"
                (change)="actualizaUm($event, producto.codigo)"
              >
                <option
                  *ngFor="let datoUm of producto.umLista"
                  value="{{ datoUm.codigo }}"
                >
                  {{ datoUm.nombre }}
                </option>
              </select>
            </div>
          </td>
          <td>
            <input
              type="numeric"
              class="form-control form-control-sm text-right"
              [(ngModel)]="producto.costo"
              (keydown)="limitarDecimales($event, 4, producto.costo)"
              (focusin)="limpiarInput(producto, 'costo')"
              (focusout)="verificarInput(producto, 'costo', i)"
            />
          </td>
          <td>
            <p
              style="text-align: right; font-size: 1.2rem"
              class="text-dark p-0 m-0"
            >
              <strong>{{ producto.subtotal.toFixed(4) }}</strong>
            </p>
          </td>
        </tr>
        <tr class="table-success">
          <td colspan="6" class="text-right">
            <p style="font-size: 1.2rem" class="text-right p-0 m-0">
              <strong>Total General</strong>
            </p>
          </td>
          <td>
            <p style="font-size: 1.2rem" class="text-right p-0 m-0">
              <strong>{{ total.toFixed(2) }}</strong>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<app-spinner></app-spinner>
