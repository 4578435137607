import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-dividir',
  templateUrl: './modal-dividir.component.html',
  styleUrls: ['./modal-dividir.component.css'],
})
export class ModalDividirComponent implements OnInit {
  datos: Object; //<-- Debe tener el mismo nombre de la variable en factura
  codigo: any; //<-- Trae el codigo de la mesa original
  cuerpo: any; //<-- Datos para la transaccion
  clienteCollapse = 'Datos del Cliente';
  //Cliente traido del modal
  cliente: any = {};
  //Ya puedes realizar la division
  listo: boolean = false;
  //Color de label restantes
  restanteColor: String = 'green';

  public event: EventEmitter<any> = new EventEmitter(); //<-- Para devolver datos a pedidos mesas
  constructor(public modalReferencia: BsModalRef) {}

  ngOnInit(): void {
    this.cuerpo = this.datos[0];
    console.log(this.cuerpo);
    this.codigo = this.datos[2];

    this.cuerpo.forEach((elemento) => {
      let i = 1;
      let divisiones = [];
      while (i <= this.datos[1]) {
        divisiones.push({ numCol: i, indiceFila: i, valor: '0' });
        i = i + 1;
      }
      elemento.divisiones = divisiones;
      elemento.restantes = elemento.cantidad; //<-- Estas son las cantidades restantes
    });
    console.log(this.cuerpo);
    console.log(this.codigo);
  }

  //Abrir el Collapsable del cliente
  abrirCollapseCliente() {
    if (
      document.getElementById('datos_cliente_icon').className ==
      'bi bi-chevron-down'
    ) {
      document.getElementById('datos_cliente_contenido').style.display = 'none';

      document.getElementById('datos_cliente_icon').className =
        'bi bi-chevron-right';
    } else {
      document.getElementById('datos_cliente_contenido').style.display =
        'block';

      document.getElementById('datos_cliente_icon').className =
        'bi bi-chevron-down';
    }
  }

  //Validadores
  mayorAcantidad(division, cantidad) {
    if (parseInt(division.valor) > parseInt(cantidad)) {
      division.valor = '0'; //<--Actualiza la division ya que la toma como referenciar
      return;
    }
  }

  sumaMayorTotal(division, codigo) {
    //Atrapo el item en modelo
    let item;
    for (let i = 0; i < this.cuerpo.length; i++) {
      if (parseInt(this.cuerpo[i].codigo.trim()) === parseInt(codigo.trim())) {
        item = this.cuerpo[i];
        break;
      }
    }
    //Agarro sus divisiones
    let divisiones = item.divisiones;
    let total = 0;
    divisiones.forEach((division) => {
      if (division.valor != '') {
        total = total + parseInt(division.valor);
      }
    });
    //Si el total de las divisiones es mayor a la cantidad seteo el restante para llegar a cantidad
    if (total > item.cantidad) {
      total = total - division.valor; //<--Le resto al total el valor actual
      let valor = item.cantidad - total;
      this.sumaSuperaCantidad(division, valor);
    }
    item.restantes = item.cantidad - total;
  }

  //Te has pasado
  sumaSuperaCantidad(division, valor) {
    this.alerta(
      '¡El total para este item ha superado su cantidad!',
      'Le faltan solamente ' + valor + ' items para completar la cantidad.',
      'warning'
    ).then(() => {
      division.valor = '0';
    });
  }

  //Si el valor es cero lo borra
  borraCero(division) {
    if (division.valor === '0') {
      division.valor = '';
    }
  }

  cancelar() {
    this.modalReferencia.hide();
  }

  cerrarModal(respuesta) {
    this.lanzarEvento(respuesta);
    this.modalReferencia.hide();
  }

  hayItemsSinEscoger() {
    //Validando que no haya items sin escoger
    for (let i = 0; i < this.cuerpo.length; i++) {
      if (this.cuerpo[i].restantes > 0) {
        return true;
      }
    }
    return false;
  }
  /*
  dividir() {
    let sinEscoger = this.hayItemsSinEscoger();
    if (sinEscoger === true) {
      this.alerta(
        '¡Hay productos sin asignar!',
        'Por favor, asigne todos los productos de esta mesa.',
        'warning'
      );

      return;
    }

    //Sustituyendo valores vacios por 0
    for (let i = 0; i < this.cuerpo.length; i++) {
      let divisiones = this.cuerpo[i].divisiones;
      for (let k = 0; k < divisiones.length; k++) {
        if (divisiones[k].valor === '') {
          divisiones[k].valor = '0';
        }
      }
    }


    //Actualizando usuario en LS
    let mesas = JSON.parse(localStorage.getItem('mesas'));

    let numeroClientes = this.cuerpo[0].divisiones.length;
    let mesa = mesas[this.indice];
    let numeros = [];
    console.log(mesa);
    //let numerosOriginales = mesa.id; //<--Recupera numeros originales

    let listaDivisionesProductos = [];
    let valores = [];
    for (let i = 0; i < numeroClientes; i++) {
      valores.push({ cantidad: 0, total: 0, productos: [] });
      let nuevaID = mesa.id * 100 + i;
      numeros.push(nuevaID);
    }

    for (let i = 0; i < this.cuerpo.length; i++) {
      listaDivisionesProductos.push(this.cuerpo[i].divisiones);
    }
    console.log(numeros);
    console.log(listaDivisionesProductos);
    for (let i = 0; i < listaDivisionesProductos.length; i++) {
      for (let j = 0; j < listaDivisionesProductos[i].length; j++) {
        valores[j].cantidad =
          valores[j].cantidad + parseInt(listaDivisionesProductos[i][j].valor);
        valores[j].total =
          valores[j].total +
          this.cuerpo[i].precio *
            parseInt(listaDivisionesProductos[i][j].valor);
        //valores[j].productos.push(this.cuerpo[i]);

        let bandera = true;
        for (let l = 0; l < valores[j].productos.length; l++) {
          if (
            valores[j].productos[l].linea.trim() == this.cuerpo[i].linea.trim()
          ) {
            let producto = {
              codigo: this.cuerpo[i].codigo,
              nombre: this.cuerpo[i].nombre,
              precio: this.cuerpo[i].precio,
              porc_tarifa: this.cuerpo[i].porc_tarifa,
              linea: this.cuerpo[i].linea,
              cantidad: parseInt(listaDivisionesProductos[i][j].valor),
            };
            if (producto.cantidad > 0)
              valores[j].productos[l].productos.push(producto);

            bandera = false;
          }
        }
        if (bandera) {
          let producto = {
            codigo: this.cuerpo[i].codigo,
            nombre: this.cuerpo[i].nombre,
            precio: this.cuerpo[i].precio,
            porc_tarifa: this.cuerpo[i].porc_tarifa,
            linea: this.cuerpo[i].linea,
            cantidad: parseInt(listaDivisionesProductos[i][j].valor),
          };
          let json = {
            linea: this.cuerpo[i].linea,
            productos: [producto],
          };
          if (producto.cantidad > 0) valores[j].productos.push(json);
        }
      }
    }

    for (let i = 0; i < valores.length; i++) {
      mesas.push({
        id: numeros[i],
        total: valores[i].total,
        items: valores[i].cantidad,
        ruc: '9999999999999',
        cliente: 'Consumidor Final',
        estado: 'pink',
        estadoAdentro: 'orange',
        estadoAfuera: 'pink',
        pedido: valores[i].productos,
      });
    }

    mesas.splice(this.indice, 1);

    localStorage.setItem('mesas', JSON.stringify(mesas));
    this.cerrarModal('Dividir');
  }
*/
  lanzarEvento(respuesta) {
    this.event.emit({ data: respuesta, res: 200 });
  }

  async alerta(titulo, mensaje, icono) {
    return Swal.fire({
      title: titulo,
      text: mensaje,
      icon: icono,
    });
  }
}
