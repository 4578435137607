<!--CABECERA-->
<div class="container-fluid my-2">
  <div class="row align-items-center">
    <div class="col-12 col-md-4 col-lg-3 col-xl-2" *ngIf="admin">
      <mat-form-field appearance="fill" class="no-padding">
        <mat-label>Sucursal</mat-label>
        <mat-select panelClass="my-select-panel-class" [(value)]="sucursal">
          <mat-option
            *ngFor="let sucursal of sucursales"
            [value]="sucursal.codigo.trim()"
            >{{ sucursal.nombre }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col">
      <h4>
        Cierre de Caja
        <small class="text-muted"></small>
      </h4>
    </div>
  </div>
</div>

<!-- BOTONES DE ACCION-->
<div class="container-fluid">
  <div class="row">
    <div class="col-12 text-right">
      <button
        type="button"
        class="btn btn-sm btn-outline-danger"
        (click)="grabarCierre()"
        *ngIf="monedas.length > 0"
      >
        <i class="fas fa-cloud-upload-alt"></i> Grabar
      </button>
    </div>
  </div>
</div>

<div class="container-fluid my-1">
  <div class="row">
    <!--MOVIMIENTO DE MONEDAS-->
    <div class="col-12 col-md-3">
      <div class="container-fluid mt-2" *ngIf="monedas.length > 0">
        <div class="table-responsive">
          <table class="table table-sm table-striped table-hover m-0 p-0">
            <thead>
              <tr>
                <th style="width: 250px; text-align: center">Monedas</th>
                <th style="width: 150px; text-align: center">Cantidad</th>
                <th
                  style="width: 150px; text-align: center"
                  [style.background-color]="datosempresa['color']"
                  class="text-light"
                >
                  Subtotal
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let moneda of monedas
                    | paginate
                      : {
                          itemsPerPage: 25,
                          currentPage: p,
                          totalItems: monedas.count
                        };
                  index as i
                "
                id="{{ moneda.codid }}"
              >
                <td>
                  <p class="m-0 p-0">
                    {{ moneda.nombre }}
                  </p>
                </td>

                <td>
                  <input
                    type="numeric"
                    class="form-control form-control-sm text-right"
                    [(ngModel)]="moneda.inicial"
                    (keydown)="limitarDecimales($event, 4, moneda.inicial)"
                    (focusin)="limpiarInput(moneda, 'inicial')"
                    (focusout)="verificarInput(moneda, 'inicial', 'mo')"
                  />
                </td>
                <td
                  class="text-light"
                  [style.background-color]="datosempresa['color']"
                >
                  <p
                    style="text-align: right; font-size: 1.2rem"
                    class="m-0 p-0"
                  >
                    <strong>{{ moneda.subtotal.toFixed(2) }}</strong>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <table class="table table-clear m-0 p-0">
          <tbody>
            <tr [style.background-color]="datosempresa['color']">
              <td class="text-light">
                <strong
                  class="text-dark m-0 p-0"
                  style="color: white !important"
                  >Total Monedas :
                </strong>
              </td>
              <td class="text-right">
                <strong
                  class="text-dark m-0 p-0"
                  style="color: white !important"
                  >$ {{ totalMonedas.toFixed(2) }}</strong
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!--MOVIMIENTO DE BILLETES-->
    <div class="col-12 col-md-3">
      <div class="container-fluid mt-2" *ngIf="billetes.length > 0">
        <div class="table-responsive">
          <table class="table table-sm table-striped table-hover m-0 p-0">
            <thead>
              <tr>
                <th style="width: 250px; text-align: center">Billetes</th>
                <th style="width: 150px; text-align: center">Cantidad</th>
                <th
                  style="width: 150px; text-align: center"
                  [style.background-color]="datosempresa['color']"
                  class="text-light"
                >
                  Subtotal
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let billete of billetes
                    | paginate
                      : {
                          itemsPerPage: 25,
                          currentPage: p,
                          totalItems: billetes.count
                        };
                  index as i
                "
                id="{{ billete.codid }}"
              >
                <td>
                  <p class="m-0 p-0">
                    {{ billete.nombre }}
                  </p>
                </td>

                <td>
                  <input
                    type="numeric"
                    class="form-control form-control-sm text-right"
                    [(ngModel)]="billete.inicial"
                    (keydown)="limitarDecimales($event, 4, billete.inicial)"
                    (focusin)="limpiarInput(billete, 'inicial')"
                    (focusout)="verificarInput(billete, 'inicial', 'bi')"
                  />
                </td>
                <td
                  class="text-light"
                  [style.background-color]="datosempresa['color']"
                >
                  <p
                    style="text-align: right; font-size: 1.2rem"
                    class="m-0 p-0"
                  >
                    <strong>{{ billete.subtotal.toFixed(2) }}</strong>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <table class="table table-clear m-0 p-0">
          <tbody>
            <tr [style.background-color]="datosempresa['color']">
              <td class="text-light">
                <strong
                  class="text-dark m-0 p-0"
                  style="color: white !important"
                  >Total Billetes :
                </strong>
              </td>
              <td class="text-right">
                <strong
                  class="text-dark m-0 p-0"
                  style="color: white !important"
                  >$ {{ totalBilletes.toFixed(2) }}</strong
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!--MOVIMIENTO DE FORMAS DE PAGOS-->
    <div class="col-12 col-md-6">
      <div class="container-fluid mt-2" *ngIf="formaspagos.length > 0">
        <div class="table-responsive">
          <table class="table table-sm table-striped table-hover m-0 p-0">
            <thead>
              <tr>
                <th style="width: 250px; text-align: center">Forma de Pago</th>
                <th style="width: 50px; text-align: center">Valor</th>
                <th
                  style="
                    width: 50px;
                    text-align: center;
                    background-color: #2c3e50;
                  "
                  class="text-light"
                >
                  Subtotal
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let pagos of formaspagos
                    | paginate
                      : {
                          itemsPerPage: 25,
                          currentPage: p,
                          totalItems: formaspagos.count
                        };
                  index as i
                "
                id="{{ pagos.codid }}"
              >
                <td>
                  <p class="m-0 p-0">
                    {{ pagos.nombre }}
                  </p>
                </td>

                <td>
                  <input
                    type="numeric"
                    class="form-control form-control-sm text-right"
                    [(ngModel)]="pagos.inicial"
                    (keydown)="limitarDecimales($event, 4, pagos.inicial)"
                    (focusin)="limpiarInput(pagos, 'inicial')"
                    (focusout)="verificarInput(pagos, 'inicial', 'fp')"
                  />
                </td>
                <td class="text-light" style="background-color: #2c3e50">
                  <p
                    style="
                      text-align: right;
                      font-size: 1.2rem;
                      background-color: #2c3e50;
                    "
                    class="m-0 p-0"
                  >
                    <strong>{{ pagos.subtotal.toFixed(2) }}</strong>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <table class="table table-clear m-0 p-0">
          <tbody>
            <tr style="background-color: #2c3e50">
              <td class="text-light" >
                <strong
                  class="text-dark m-0 p-0"
                  style="color: white !important"
                  >Total :
                </strong>
              </td>
              <td class="text-right">
                <strong
                  class="text-dark m-0 p-0"
                  style="color: white !important"
                  >$ {{ totalGeneral.toFixed(2) }}</strong
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
