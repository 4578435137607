import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuComponent } from './components/menu/menu.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  @ViewChild(MenuComponent) child;
  title = 'NovaExpress Group';
  menu = false;
  notificacion = false;

  constructor(private router: Router) {}

  ngOnInit() {
    setInterval(() => {
      this.verficals();
    }, 100);

    if (!localStorage.getItem('sucursal')) {
      this.menu = false;
      return;
    }

    let pagina = localStorage.getItem('pagina');
    console.warn(pagina);
    this.verficals();
    if (pagina == '002') {
      //CASO DE VENTAS
      if (!localStorage.getItem('usuario')) {
        this.router.navigate(['restaurante/login']);
      } else {
        this.menu = true;
        this.router.navigate(['restaurante/factura']);
      }
    } else if (pagina == '001') {
      // CASO DE MESEROS
      if (localStorage.getItem('usuario')) {
        this.menu = true;
        this.router.navigate(['mesero']);
      } else this.router.navigate(['restaurante/login']);
    } else if (pagina == '006') {
      // CASO DE COCINEROS
      if (!localStorage.getItem('usuario')) {
        this.router.navigate(['restaurante/login']);
      } else {
        this.menu = true;
        this.router.navigate(['cocina']);
      }
    } else if (pagina == '003') {
      //USUARIOS GENERAL
      if (!localStorage.getItem('usuario')) {
        this.router.navigate(['restaurante/login']);
      } else {
        // loguea y va a dashboard y con f5 se queda en pagina actual
        this.menu = true;
        if (window.location.pathname == '/') {
          this.router.navigate(['restaurante/dashboard']);
        }
      }
    } else if (pagina == '004') {
      //ADMINISTRADORES
      if (!localStorage.getItem('usuario')) {
        this.router.navigate(['restaurante/login']);
      } else {
        this.menu = true;
        //location.href = 'restaurante/factura';
        if (window.location.pathname == '/') {
          this.router.navigate(['restaurante/dashboard']);
        }
      }
    } else this.router.navigate(['/']);
  }
  verficals() {
    this.notificacion = false;
    if (JSON.parse(localStorage.getItem('notificacion')) !== null) {
      if (localStorage.getItem('notificacion') == 'true')
        this.notificacion = true;
    }
  }
  cerrarMenu() {
    //console.log(this.child);
    //if (this.child) this.child.hidden = false;
  }
}
