import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { ModalItemsComponent } from 'src/app/components/restaurante/reportes/modal-items/modal-items.component';

@Component({
  selector: 'app-modal-lineas',
  templateUrl: './modal-lineas.component.html',
  styleUrls: ['./modal-lineas.component.css'],
})
export class ModalLineasComponent implements OnInit {
  lineas: any;
  modalReferenciaItem: BsModalRef;
  public event: EventEmitter<any> = new EventEmitter(); //<-- En este caso lo usamos para avisar al componente padre que cerramos (es como un @Output hijo a padre)
  empresa: any;
  constructor(
    public modalReferenciaLineas: BsModalRef,
    private modalServicio: BsModalService,
    private servicio: RestauranteService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
    this.cargarLineas();
  }

  cargarLineas() {
    this.servicio.getConfiguraciones('003', this.empresa).subscribe((data) => {
      console.log(data);
      this.lineas = data;
      this.lineas.forEach((linea) => {
        linea.codigo = linea.codigo.trim();
        linea.nombre = linea.nombre.trim();
      });
      //this.cargarDatosStorage();
    });
  }

  //Cerrar modal y enviar la data a factura
  cerrarModal(data) {
    this.event.emit(data);
    this.modalReferenciaLineas.hide(); //<-- Cierra el modal
  }

  //Abre el modal
  abrirModalItems(linea) {
    let id = linea.codigo;
    let nombre = linea.nombre;
    const initialState: any = {
      datosItems: [id, nombre],
    };
    this.modalReferenciaItem = this.modalServicio.show(ModalItemsComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });
    //Al cerrar el modal recibe la data
    this.modalReferenciaItem.content.event.subscribe((res) => {
      console.log(res);
      this.cerrarModal(res);
    });
  }
}
