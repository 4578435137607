<!--CABECERA-->
<div class="container-fluid my-2">
  <div class="row align-items-center">
    <div class="col-12 col-md-4 col-lg-3 col-xl-2" *ngIf="admin">
      <mat-form-field appearance="fill" class="no-padding">
        <mat-label>Sucursal</mat-label>
        <mat-select
          panelClass="my-select-panel-class"
          [(value)]="sucursal"
          (selectionChange)="read()"
        >
          <mat-option
            *ngFor="let sucursal of sucursales"
            [value]="sucursal.codigo.trim()"
            >{{ sucursal.nombre }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col">
      <h4>
        Gastos Diarios
        <small class="text-muted"></small>
      </h4>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row justify-content-center align-items-center my-2">
    <div class="col-11 col-md-6 col-lg-2 text-center text-md-left pb-sm-2">
      <button
        type="button"
        class="btn btn-outline-success btn-sm"
        (click)="create()"
      >
        <i class="far fa-plus-square"></i> Nuevo Gasto
      </button>
    </div>

    <div class="col-11 col-md-6 col-lg-4 text-center">
      <mat-form-field class="example-form-field" appearance="fill">
        <mat-label>Rango de fecha</mat-label>
        <mat-date-range-input
          [formGroup]="campaignOne"
          [rangePicker]="campaignOnePicker"
        >
          <input matStartDate placeholder="Inicio" formControlName="start" />
          <input
            matEndDate
            placeholder="Fin"
            formControlName="end"
            (dateChange)="actualizarDatos()"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle
          matSuffix
          [for]="campaignOnePicker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
      </mat-form-field>
    </div>

    <div class="col-11 col-lg-4 text-center text-md-left">
      <div class="input-group input-group-sm">
        <input
          type="text"
          class="form-control form-control-sm"
          placeholder="Buscar por descripcion o comentario"
          aria-label="Busqueda"
          aria-describedby="basic-addon1"
          [(ngModel)]="busqueda"
          (keyup)="buscar()"
        />
        <div class="input-group-append">
          <span class="input-group-text" id="basic-addon1"
            ><i class="fab fa-searchengin"></i
          ></span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <table class="table table-sm" *ngIf="gastos.length > 0; else sinRegistros">
    <thead>
      <tr>
        <th
          scope="col"
          style="min-width: 100px; width: 100px"
        ></th>

        <th scope="col" style="min-width: 150px; width: 150px">Tipo Gasto</th>
        <th scope="col" style="min-width: 150px; width: 150px">
          Servicio Gasto
        </th>
        <th scope="col" style="min-width: 250px; width: 250px">Descripcion</th>
        <th
          scope="col"
          style="min-width: 150px; width: 150px; text-align: right"
        >
          Valor
        </th>
        <th scope="col" style="max-width: 250px; width: 250px">Comentario</th>
      </tr>
    </thead>

    <tbody>
      <tr
        *ngFor="
          let gasto of gastos
            | paginate
              : { itemsPerPage: 25, currentPage: p, totalItems: gastos.count };
          index as i
        "
      >
        <td >
          <button
            type="button"
            class="btn btn-outline-danger btn-sm mr-2"
            (click)="delete(i)"
          >
            <i class="fas fa-trash"></i>
          </button>
          <button
            type="button"
            class="btn btn-outline-primary btn-sm"
            *ngIf="!gasto.nuevo"
            (click)="update(i)"
          >
            <i *ngIf="!gasto.bloqueado" class="fas fa-times"></i>
            <i *ngIf="gasto.bloqueado" class="fas fa-edit"></i>
          </button>

          <button
            type="button"
            class="btn btn-success btn-sm"
            (click)="grabar(i)"
            *ngIf="!gasto.bloqueado"
          >
            <i class="far fa-save"></i>
          </button>
        </td>

        <td>
          <select
            class="form-select form-select-sm"
            [(ngModel)]="gasto.tipo_gasto"
            [disabled]="gasto.bloqueado"
          >
            <option *ngFor="let tipo of tipos" [value]="tipo.codigo">
              {{ tipo.nombre }}
            </option>
          </select>
        </td>
        <td>
          <select
            class="form-select form-select-sm"
            [(ngModel)]="gasto.servicio_gasto"
            [disabled]="gasto.bloqueado"
          >
            <option
              *ngFor="let servicio of servicios"
              [value]="servicio.codigo"
            >
              {{ servicio.nombre }}
            </option>
          </select>
        </td>
        <td>
          <textarea
            class="form-control form-control-sm m-0"
            [(ngModel)]="gasto.descripcion"
            rows="1"
            [disabled]="gasto.bloqueado"
          ></textarea>
        </td>

        <td>
          <div class="form-group form-group-sm d-flex m-0">
            <input
              type="numeric"
              class="form-control form-control-sm text-right"
              [(ngModel)]="gasto.valor"
              [disabled]="gasto.bloqueado"
              (keydown)="limitarDecimales($event, 2, gasto.valor)"
              (focusin)="limpiarInput(gasto, 'valor')"
              (focusout)="verificarInput(gasto, 'valor', i)"
            />
          </div>
        </td>

        <td>
          <textarea
            class="form-control form-control-sm m-0"
            [(ngModel)]="gasto.comentario"
            rows="1"
            [disabled]="gasto.bloqueado"
          ></textarea>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<!--PAGINADO-->
<div class="container-fluid" *ngIf="true">
  <div class="d-flex justify-content-center align-items-center mt-4 mb-3">
    <pagination-controls
      (pageChange)="p = $event"
      autoHide="true"
      previousLabel="Anterior"
      nextLabel="Siguiente"
    >
    </pagination-controls>
  </div>
</div>

<!--SIN REGISTROS-->
<ng-template #sinRegistros>
  <ng-container>
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-6 mt-5" style="text-align: center">
          <lottie-player
            src="https://assets2.lottiefiles.com/temp/lf20_TOE9MF.json"
            background="transparent"
            speed="1"
            loop
            autoplay
          ></lottie-player>
          <h3>
            Sin Gastos
            <small class="text-muted"> Registrados</small>
          </h3>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<app-spinner></app-spinner>
