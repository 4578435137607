<div class="container-fluid my-4">
  <div class="row justify-content-center align-items-center">
    <div class="col-9 col-md-10 text-center">
      <h3>
        Movimientos de Empleados
        <small class="text-muted"></small>
      </h3>
    </div>
  </div>
</div>

<div class="container-fluid py-2">
  <div class="row d-flex justify-content-center">
    <div class="col-12 col-md-3">
      <div class="card py-2 text-center">
        <div class="card-content">
          <mat-form-field appearance="fill">
            <mat-label>Año y Mes</mat-label>
            <input matInput [matDatepicker]="dp" [formControl]="date" />
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker
              #dp
              startView="multi-year"
              (yearSelected)="chosenYearHandler($event)"
              (monthSelected)="chosenMonthHandler($event, dp)"
              panelClass="example-month-picker"
            >
            </mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6">
      <div class="card py-4">
        <div class="card-content">
          <div class="row align-items-center p-2">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend text-center">
                <span class="input-group-text" id="mov">Tipo Movimiento: </span>
              </div>
              <select
                class="custom-select custom-select-sm"
                name="mov"
                [(ngModel)]="movEscogido"
                (change)="filtraEmpleadosFecha()"
                id="mov"
              >
                <option *ngFor="let tipo of tipos" [ngValue]="tipo.codigo">
                  {{ tipo.nombre }}
                </option>
              </select>
              <div class="input-group-append">
                <label class="input-group-text" for="mov"
                  ><i class="fab fa-audible"></i
                ></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid my-1">
  <div class="row">
    <div class="col-6">
      <button
        class="btn btn-outline-primary btn-sm btn_est m-2"
        (click)="abrirModalAgregarConfiguracion()"
      >
        <i class="fas fa-plus mr-2"></i>Nuevo Empleado
      </button>
      <button
        class="btn btn-outline-success btn-sm btn_est m-2"
        (click)="abrirModalNuevo()"
        [hidden]="escogioUnaOpcion"
      >
        <i class="fab fa-searchengin mr-2"></i>Buscar Empleado
      </button>
    </div>
    <div class="col-6 text-right">
      <button class="btn btn-warning btn-sm btn_est m-2" (click)="grabar()">
        <i class="far fa-save mr-2"></i>Grabar
      </button>
    </div>
  </div>
</div>

<div class="container-fluid" *ngIf="empleados.length > 0; else vacio">
  <div class="table table-responsive">
    <table class="table table-striped table-sm table-hover m-0 p-0">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Código</th>
          <th scope="col" style="min-width: 150px">Nombre</th>
          <th scope="col" style="width: 175px !important; text-align: center">
            Valor
          </th>
          <th scope="col" style="min-width: 150px">Concepto</th>
          <th scope="col" style="min-width: 150px">Pago</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let empleado of empleados; index as i">
          <td>
            <button class="btn btn-outline-danger btn-sm" (click)="eliminar(i)">
              <i class="far fa-trash-alt"></i>
            </button>
          </td>
          <td>{{ empleado.empleado }}</td>
          <td>{{ empleado.nomemp }}</td>
          <td class="d-inline-flex">
            <input
              type="numeric"
              class="form-control form-control-sm text-right"
              aria-label="cantidadEntero"
              [(ngModel)]="empleado.entero"
              placeholder="{{ empleado.entero }}"
              (keyup)="actualizaEntero(empleado)"
              appOnlynumber
            />

            <input
              type="numeric"
              class="form-control form-control-sm text-right"
              aria-label="cantidadDecimal"
              [(ngModel)]="empleado.decimal"
              placeholder="{{ empleado.decimal }}"
              (keyup)="actualizaDecimal(empleado)"
              appOnlynumber
            />
          </td>
          <td>
            <textarea
              class="form-control form-control-sm"
              id="comentario"
              rows="1"
              [(ngModel)]="empleado.comentario"
              >{{ empleado.comentario }}
              </textarea
            >
          </td>

          <td>
            <select
              class="form-select form-select-sm"
              name="pago"
              [(ngModel)]="empleado.quincena"
            >
              <option
                *ngFor="let opcion of empleado.opciones"
                [ngValue]="opcion.id"
              >
                {{ opcion.nombre }}
              </option>
            </select>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div
  class="container-fluid py-2"
  [style.background-color]="datosempresa['color']"
  *ngIf="total > 0"
>
  <div class="row justify-content-center justify-content-md-end">
    <div class="col-12 text-center" style="color: white !important">
      <h5 class="p-0 m-0">TOTAL : $ {{ total.toFixed(2) }}</h5>
    </div>
  </div>
</div>
<ng-template #vacio> </ng-template>

<app-spinner></app-spinner>
