import { Component, OnInit, EventEmitter, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ServicioMeseroService } from 'src/app/services/servicio-mesero/servicio-mesero.service';
import { ModalFacturaComponent } from '../modal-factura/modal-factura.component';
import { ModalLineasComponent } from '../modal-lineas/modal-lineas.component';
import { ModalPedidoRecetaComponent } from '../modal-pedido-receta/modal-pedido-receta.component';
import { ModalDividirComponent } from '../modal-dividir/modal-dividir.component';
import { ModalClienteaddComponent } from '../modal-clienteadd/modal-clienteadd.component';
import { ModalClientesComponent } from '../../restaurante/modal-clientes/modal-clientes.component';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-factura',
  templateUrl: './factura.component.html',
  styleUrls: ['./factura.component.css'],
})
export class FacturaComponent implements OnInit {
  @HostListener('document:keypress', ['$event'])
  datos: Object; //<-- Debe tener el mismo nombre de la variable en pedidos mesas
  codigo: any; //<--Codigo de la mesa obtenido desde pedidos mesas
  public event: EventEmitter<any> = new EventEmitter(); //<-- Para devolver datos a pedidos mesas
  //Referencia del modal factura
  modalReferenciaCliente: BsModalRef;
  //Referencia del modal lineas
  modalReferenciaLineas: BsModalRef;
  //Referencia del modal pedido receta
  ModalReferenciaPedidoReceta: BsModalRef;
  //Referencia del modal dividir
  modalDividirMesa: BsModalRef;
  //Cliente traido del modal
  cliente: any = {};
  //Lineas traidas de la DB
  lineas: any = [];
  //Datos del localStorage
  datosPersistentes: any = [];
  //Productos desde localStorage
  productos: any = [];
  //Productos del consumidor final
  productosConsumidorFinal: any = [];
  //RUC de consumidor final
  rucConsumidorFinal: any = '9999999999999';

  sumatoria: number = 0;
  descuento: number = 0;
  tarifa0: number = 0;
  tarifa12: number = 0;

  subtotal: number = 0;
  impuesto: number = 0;
  total: number = 0;

  totalImpuesto: number = 0; //<--Total con impuesto para este cliente

  tiposentregas: any;
  tipoentrega: string = '001';

  serviciosentregas: any;
  servicioentrega: string = '';

  distribuidores: any;
  distribuidoresTotal: any;
  distribuidor: string = '';
  busqueda: string = '';

  ventana: any;

  formaspagos: any;
  formapago: string = '001';
  strformapago: string = '';

  clienteCollapse = 'Datos del Cliente';
  efectivo: any = [0, 0, 0, 0, 0]; //<--Efectivo temporal
  cambio: number = 0; //<--efectivo-total
  efectivoEscogido: Number = 0; //<--Valor efectivo escogido
  efectivoColor: any = [true, false, false, false, false]; //<--Efectivo temporal
  //Bandera para spinner
  banderaSpinner: boolean = false;
  //RUC de cliente
  ruc: any;

  mesa: any;

  comentario: string = '';
  cabecera;
  cuerpo;

  mensajePedido: string = '';
  empresa: string = '';
  sucursal: string = '';

  datosempresa: any = {
    nombre: '',
    color: '',
    urlLogo: '',
    cal_artesanal: '',
    acceso: '',
  };

  clienteold: any = {};

  constructor(
    private router: Router,
    public modalReferencia: BsModalRef,
    private servicio: ServicioMeseroService,
    private modalServicio: BsModalService,
    private modalServicioPedidoReceta: BsModalService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.limpiacampos();

    if (!localStorage.getItem('usuario')) this.router.navigate(['login']);

    if (localStorage.getItem('empresa'))
      this.datosempresa = JSON.parse(localStorage.getItem('empresa'));
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
    if (JSON.parse(localStorage.getItem('sucursal')) !== null) {
      this.sucursal = JSON.parse(localStorage.getItem('sucursal'))['codigo'];
    }

    this.mesa = this.datos[0]; //<--Tomando codigo de la mesa
    if (!this.mesa.nuevo) this.cargarMovimiento();
    this.codigo = this.mesa.mesa;
    this.ruc = this.mesa.ruc;
    console.log(this.mesa);
    setInterval(() => {
      if (this.banderaSpinner === true) {
        this.spinner.hide();
        this.banderaSpinner = false;
      }
    }, 100);
    this.traerConsumidor(this.ruc);
    this.cargarTipoEntrega();
    this.cargarServicioEntrega();
    this.cargarFormaPago();
    this.cargarDistribuidores();
    this.cambio = this.calcularCambio();
  }

  cargarMovimiento() {
    let datos = {
      opcion: 1,
      idFactura: this.mesa.numero,
      tipo: this.mesa.tipo,
      sucursal: this.mesa.sucursal,
      modulo: this.mesa.modulo,
      empresa: this.mesa.empresa,
    };
    console.log(datos);
    this.spinner.show();
    this.servicio.verFactura(datos).subscribe((data) => {
      console.log(data);
      this.banderaSpinner = true;

      this.cabecera = data[0][0];
      this.mensajePedido = this.cabecera.mensaje;
      this.cuerpo = data[1];
      this.cuerpo.forEach((producto) => {
        producto.nuevo = false;
        this.total = this.total + producto.cantidad * producto.precio;
      });

      datos['opcion'] = 2;
      this.servicio.verFactura(datos).subscribe((data) => {
        console.log(data);
        let cuerpo = data[1];
        let mini = {};
        for (let i = 0; i < cuerpo.length; i++) {
          const element = cuerpo[i];
          element.cod_receta = element.codigo;
          element.unidad = element.medida.trim();
          if (mini[element.cod_prod_rec]) {
            if (mini[element.cod_prod_rec][element.num_prod_rec]) {
              mini[element.cod_prod_rec][element.num_prod_rec].push(element);
            } else {
              mini[element.cod_prod_rec][element.num_prod_rec] = [element];
            }
          } else {
            mini[element.cod_prod_rec] = {};
            mini[element.cod_prod_rec][element.num_prod_rec] = [element];
          }
        }
        console.log(mini);
        console.log('cuerpo', this.cuerpo);
        /*
        this.cuerpo.forEach((element) => {
          let receta = [];
          if (element.regreceta > 0) {
            for (let i = 0; i < element.cantidad; i++) {
              receta.push(mini[element.codigo][i]);
            }
          }
          element.receta = receta;
        });
*/
        console.log(cuerpo);
        this.productos = this.cuerpo;
        this.calcularValores();
      });
    });
  }

  //Abre el modal
  abrirModalBuscarCliente() {
    //Este modal recibe el RUC del cliente que usa data binding componente hacia template
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<--Datos enviados desde componente hacia modal
        this.cliente.ruc,
      ],
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferenciaCliente = this.modalServicio.show(
      ModalFacturaComponent,
      {
        initialState,
        ignoreBackdropClick: true,
      }
    );
    //Al cerrar el modal recibe la data
    this.modalReferenciaCliente.content.event.subscribe((res) => {
      //Guardando cliente en temporal
      this.cliente = res.data;
    });
  }

  //Abre el modal de linea
  mostrarModalLinea(linea) {
    //Este modal recibe el id de la linea
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [linea, this.productos],
    };

    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferenciaLineas = this.modalServicio.show(ModalLineasComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-xl',
    });
    //Al cerrar el modal recibe la data
    this.modalReferenciaLineas.content.event.subscribe((result) => {
      console.log('MODAL DE LINEAS CERRADO');
      console.log(result);

      this.cargarDatosProducto(result);
    });
  }

  //Restar cantidad
  restarCantidad(i) {
    if (this.productos[i].cantidad > 0) {
      this.productos[i].cantidad--;
      if (this.productos[i].cantidad == 0) this.productos.splice(i, 1);
    }
    this.calcularValores();
  }

  //Aumentar cantidad
  aumentarCantidad(i) {
    this.productos[i].cantidad++;

    this.calcularValores();
  }

  //Imprimir la factura con su comanda
  imprimir(cabeceraimp, clienteimp, cuerpoimp) {
    this.ventana = window.open('', '', 'fullscreen=yes');
    //console.log('datos imp', cabeceraimp, clienteimp, cuerpoimp);
    this.ventana.document.write(
      this.crearHtmlAImprimir(cabeceraimp, clienteimp, cuerpoimp)
    );
    this.ventana.document.close();
    //this.ventana.focus();
    setTimeout(() => {
      this.ventana.print();
      this.ventana.close();
    }, 800);

    //this.ventana.print();
    //this.ventana.close();
    return;
  }

  //Aqui se crea el HTML (la factura) a imprimir
  crearHtmlAImprimir(cabecerafac, clientefac, cuerpofac) {
    //Factura
    let htmlParte1 = `<html><head>
      <title>FACTURA # ${cabecerafac.numero} </title>
      <style type="text/css">
      .cabecera, .item { margin:0; padding:0 }
      .detalle {width: 80%;}
      .subtotal {width: 20%;}
      .mitad {width: 50%;}
      .tabla {width: 100%;}
      .resumen {text-align: right;}
      .boton {text-align: center;}
      p {font-size:12px}
      .no-margin{margin:0}
      input {background-color: green; color: white;}
      tr.bordeado td { border-bottom: 1px dotted black; }
      @media print{ .no-print, .no-print * { display: none !important; } }
      </style></head><body><p class="boton"><input type="button" value="Imprimir" onclick="recargar()" class="no-print"></p>`;

    let rucempr = `<p class="cabecera"><b>RUC : ${cabecerafac.ruc_suc}</b></p>
    <p class="cabecera"><b>${cabecerafac.per_suc}</b></p>`;

    if (cabecerafac.ruc_suc.trim().length < 13) rucempr = ``;

    let datempr = `
    <p class="cabecera"><b> Dir :${cabecerafac.dir_suc} </b></p>
    <p class="cabecera"><b> Tel :${cabecerafac.tel_suc} </b></p>
    <p class="cabecera"><b> Email :${cabecerafac.ema_suc} </b></p>
    `;

    if (cabecerafac.ruc_suc.trim().length < 13) datempr = ``;

    let ca_empr = `<p class="cabecera"><b>CALIFICACION ARTESANAL : ${this.datosempresa.cal_artesanal}</b></p>`;

    if (this.datosempresa.cal_artesanal.trim().length < 3) ca_empr = ``;

    let htmlPartefe1 = `
    <p class="cabecera"><b>${cabecerafac.nom_emp} -  ${cabecerafac.nom_suc} # ${cabecerafac.numero} </b></p><hr>
    <p class="cabecera"><b>${cabecerafac.fecha_fac}   ${cabecerafac.hora_fac}</b></p>`;

    let htmlRIMPE = `<p class="cabecera"><b>CONTRIBUYENTE REGIMEN GENERAL</b></p>`;

    if (cabecerafac.ruc_suc.trim().length < 13) htmlRIMPE = ``;

    let htmlPartefe2 = `<p class="cabecera"><b>${cabecerafac.numsri}</b></p>
    <p class="cabecera"><b> ${cabecerafac.autorizacion} </b></p>`;

    if (cabecerafac.autorizacion.length < 10) htmlPartefe2 = ``;

    let htmlParte2 = ` <hr> DATOS DEL CLIENTE <hr><p class="cabecera"><b>RUC : </b> ${cabecerafac.ruc} </p>
      <p class="cabecera"><b>Nombre :</b>  ${clientefac.nombre} </p>`;

    let htmlParte3 = `<p class="cabecera"><b>Dirección :</b> ${clientefac.direccion} </p>
       <p class="cabecera"><b>Teléfono :</b> ${clientefac.telefono} </p>
       <p class="cabecera"><b>Correo : </b> ${clientefac.correo} </p>
       <hr> `;

    let htmlParte4 =
      '<table class="tabla"><tr><th class="detalle">Detalles</th><th class="subtotal">Total</th></tr>';

    cuerpofac.forEach((itemfact) => {
      if (itemfact.cantidad > 0) {
        htmlParte4 =
          htmlParte4 +
          `<tr "><td class="detalle">
          <p class="item">${itemfact.cantidad} X ${
            itemfact.nombre
          } $ ${itemfact.precio.toFixed(2)}</p>
          </td>

          <td class="subtotal resumen">
          <p>
          ${(
            itemfact.cantidad *
            itemfact.precio *
            (1 + itemfact.porc_tarifa / 100)
          ).toFixed(2)}
          </p</td></tr>`;
      }
    });
    htmlParte4 = htmlParte4 + '</table>';

    let htmlParte5 = `<table class="tabla">
      <tr>
        <td class="detalle resumen">
          <p class="item"><b>Total : $</b></p>
        </td>
        <td class="subtotal resumen">
          <p class="item"> ${cabecerafac.total.toFixed(2)}</p>
        </td>
      </tr>
      </table>`;

    let htmlParte6 = `<table class="tabla">
      <tr>
        <td class="detalle resumen">
          <p class="item"><b>Efectivo : $</b></p>
          <p class="item"><b>Cambio : $</b></p>
        </td>
        <td class="subtotal resumen">
          <p class="item"> ${cabecerafac.efectivo.toFixed(2)}</p>
          <p class="item"> ${cabecerafac.cambio.toFixed(2)}</p>
        </td>
      </tr>
      </table>`;

    if (this.formapago != '001') htmlParte6 = '';

    let htmlParte7 = `<table class="tabla">
    <tr>
      <td class="detalle">
        <p class="item"> ${this.comentario}</p>
      </td>
    </tr>
    </table>`;

    let htmlParte8 =
      '<script>function recargar(){window.print()}</script></body></html>';

    let htmlParte9 = `<table class="tabla">
      <tr>
        <td class="detalle resumen">
          <p class="item"><b>Forma de Pago : </b></p>
          <p class="item"><b>Tipo Entrega : </b></p>
          <p class="item"><b>Servicio Entrega : </b></p>
          <p class="item"><b>Distribuidor : </b></p>
        </td>
        <td class="subtotal resumen">
          <p class="item"> ${cabecerafac.nomfp}</p>
          <p class="item"> ${cabecerafac.nomtipo}</p>
          <p class="item"> ${cabecerafac.nomserv}</p>
          <p class="item"> ${cabecerafac.nomdistri}</p>
        </td>
      </tr>
      </table>`;

    return (
      rucempr +
      datempr +
      htmlParte1 +
      htmlPartefe1 +
      ca_empr +
      htmlRIMPE +
      htmlPartefe2 +
      htmlParte2 +
      htmlParte3 +
      htmlParte4 +
      htmlParte5 +
      htmlParte6 +
      htmlParte7 +
      htmlParte8 +
      htmlParte9
    );
  }

  //Borrar producto
  borrarItem(i) {
    Swal.fire({
      title: '¿Desea eliminar este producto?',
      showDenyButton: true,
      confirmButtonText: `Sí`,
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(i);
        this.productos.splice(i, 1);
        this.calcularValores();
      }
    });
  }

  pintarEfectivo(number) {
    this.efectivoColor = [false, false, false, false, false];
    this.efectivoColor[number - 1] = true;
    this.efectivoEscogido = this.efectivo[number - 1];

    this.actualizarEfectivo();
  }

  actualizarEfectivo() {
    this.efectivo = [0, 0, 0, 0, 0];

    this.efectivo[0] = this.totalImpuesto;
    //Operaciones auxiliares
    let totalText = this.totalImpuesto.toFixed(2).toString();
    let entero = totalText.split('.')[0];
    let decimal = totalText.split('.')[1];
    // 2) Segundo boton (redondeo por exceso)
    if (parseInt(decimal) > 0) {
      this.efectivo[1] = parseInt(entero) + 1;
    } else {
      this.efectivo[1] = 0;
    }
    // 3) Tercer boton (redondeo decimales)
    let decimalTercerBoton = decimal;
    let enteroTercerBoton = entero;
    if (parseInt(decimalTercerBoton) > 0 && parseInt(decimalTercerBoton) < 25) {
      decimalTercerBoton = '25';
    } else {
      if (
        parseInt(decimalTercerBoton) > 25 &&
        parseInt(decimalTercerBoton) < 50
      ) {
        decimalTercerBoton = '50';
      } else {
        if (
          parseInt(decimalTercerBoton) > 50 &&
          parseInt(decimalTercerBoton) < 75
        ) {
          decimalTercerBoton = '75';
        } else {
          if (parseInt(decimalTercerBoton) > 75) {
            decimalTercerBoton = '00';
            enteroTercerBoton = (parseInt(enteroTercerBoton) + 1).toString();
          }
        }
      }
    }
    this.efectivo[2] = parseFloat(enteroTercerBoton + '.' + decimalTercerBoton);
    // 4) y 5) Cuarto y quinto boton (primer y segundo valor multiplo)
    let enteroCuartoBoton = entero;
    let enteroQuintoBoton = entero;
    if (enteroCuartoBoton.length === 1) {
      enteroCuartoBoton = '10';
      enteroQuintoBoton = '20';
    } else {
      if (enteroCuartoBoton.length === 2) {
        enteroCuartoBoton = (
          parseInt(enteroCuartoBoton[0] + '0') + 10
        ).toString();
        enteroQuintoBoton = (
          parseInt(enteroQuintoBoton[0] + '0') + 20
        ).toString();
      }
      if (enteroCuartoBoton.length === 3) {
        enteroCuartoBoton = (
          parseInt(enteroCuartoBoton[0] + '00') + 10
        ).toString();
        enteroQuintoBoton = (
          parseInt(enteroQuintoBoton[0] + '00') + 20
        ).toString();
      }
      if (enteroCuartoBoton.length === 4) {
        enteroCuartoBoton = (
          parseInt(enteroCuartoBoton[0] + '000') + 10
        ).toString();
        enteroQuintoBoton = (
          parseInt(enteroQuintoBoton[0] + '000') + 20
        ).toString();
      }
    }
    this.efectivo[3] = parseFloat(enteroCuartoBoton + '.00');
    this.efectivo[4] = parseFloat(enteroQuintoBoton + '.00');
    //Borrando duplicados en arreglo efectivo
    this.efectivo = [...new Set(this.efectivo)];
    //Calculamos el cambio
    this.cambio = this.calcularCambio();
  }

  calcularCambio() {
    return Number(this.efectivoEscogido) - Number(this.totalImpuesto);
  }

  //Abrir el Collapsable del cliente
  abrirCollapseCliente() {
    if (
      document.getElementById('datos_cliente_icon').className ==
      'fas fa-chevron-up'
    ) {
      document.getElementById('datos_cliente_contenido').style.display = 'none';

      document.getElementById('datos_cliente_icon').className =
        'fas fa-chevron-down';
    } else {
      document.getElementById('datos_cliente_contenido').style.display =
        'block';

      document.getElementById('datos_cliente_icon').className =
        'fas fa-chevron-up';
    }
  }

  //Cerrar modal y enviar la data a pedidos mesas
  cerrarModal(respuesta) {
    console.log('Cerrar Modal');
    this.lanzarEvento(respuesta);
    this.modalReferencia.hide(); //<-- Cierra el modal
  }

  lanzarEvento(respuesta) {
    this.event.emit({ data: respuesta, res: 200 });
  }

  consultarReceta(i) {
    console.log(this.productos[i]);
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: this.productos[i], //<--Datos enviados desde componente hacia modal
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.ModalReferenciaPedidoReceta = this.modalServicioPedidoReceta.show(
      ModalPedidoRecetaComponent,
      {
        initialState,
        ignoreBackdropClick: true,
        class: 'modal-lg',
      }
    );
    //Al cerrar el modal recibe la data
    this.ModalReferenciaPedidoReceta.content.event.subscribe((resultado) => {
      console.log('MODAL DE PEDIDOS RECETAS CERRADO');

      console.log(resultado.data);

      this.productos[i] = resultado.data; //<--Actualiza producto
    });
  }

  traerConsumidor(ruc) {
    this.spinner.show();
    this.servicio.buscarCliente(ruc, this.empresa).subscribe((data) => {
      console.log(data);
      if (data.length > 0) {
        this.cliente = data[0];
        this.cliente.nombre = this.cliente.nombre.trim();
        this.cliente.direccion = this.cliente.direccion.trim();
        this.cliente.telefono = this.cliente.telefono.trim();
        this.cliente.correo = this.cliente.correo.trim();
      }
      this.spinner.hide();
      this.clienteCollapse = this.cliente.nombre.trim();

      let datos = {
        tipo: '003',
        empresa: JSON.parse(localStorage.getItem('empresa')).codigo,
      };

      this.servicio.getLineas(datos).subscribe((data) => {
        console.log(this.lineas);
        this.lineas = data;
        this.lineas.forEach((linea) => {
          linea.codigo = linea.codigo.trim();
          linea.nombre = linea.nombre.trim();
        });
      });
    });
  }

  //Si consumidor final esta en el input lo borra y guarda sus datos en LS
  borrarConsumidorFinal() {
    console.log(this.cliente.ruc);
    if (this.cliente.ruc === '9999999999999') {
      //Copio los productos del consumidor final
      this.productosConsumidorFinal = this.productos;
      //Borro consumidor final
      this.cliente = {};
    }
  }

  //Da la opcion de facturar o dividir la mesa
  alertaFacturaOdivide() {
    Swal.fire({
      icon: 'info',
      title: '¿Desea realizar la facturación o dividir la mesa?',
      text: 'Por favor, escoja una opción',
      showDenyButton: true,
      confirmButtonText: `Realizar Pedido`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.facturar();
      }
      /* } else if (result.isDenied) {
        this.numeroDivisiones();
      }*/
    });
  }

  //Realiza la facturacion
  async facturar() {
    //Llenando cabecera
    let cabecera = {
      ruc: this.cliente.ruc.toString(),
      comentario: this.comentario,
      sucursal: JSON.parse(localStorage.getItem('sucursal')).codigo,
      cambio: this.cambio,
      efectivo: this.efectivoEscogido,
      tipo: '002',
      usuario: JSON.parse(localStorage.getItem('usuario')).codigo,
      empresa: JSON.parse(localStorage.getItem('empresa')).codigo,
      mesa: this.mesa.mesa,
    };

    console.log('1');
    await this.verificarRecetas();
    console.log('4');
    //Construyendo solicitud
    let cuerpo = this.productos;
    let datos = { cabecera, cuerpo };
    console.log(datos);
    this.spinner.show();
    this.servicio.efectuarPedido(datos).subscribe((data) => {
      console.log(data);
      this.banderaSpinner = true;
      if (data.data == 'OK') {
        this.alerta(
          '¡Transacción exitosa!',
          'El numero del documento es #' + data.factura,
          'success'
        ).then(() => {
          //this.imprimir(data.factura);
          this.cerrarModal('Facturar');
          this.productos = [];
        });
      } else {
        this.alerta(
          'Transacción no realizada',
          'Ha ocurrido un error inesperado',
          'error'
        );
      }
    });

    return;
  }

  //Abre el modal dividir cuenta
  abrirModalDividir(divisiones) {
    //Este modal recibe el RUC del cliente que usa data binding componente hacia template
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<--Datos enviados desde componente hacia modal
        this.productos,
        divisiones,
        this.codigo,
      ],
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalDividirMesa = this.modalServicio.show(ModalDividirComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-xl',
    });
    //Al cerrar el modal recibe la data
    this.modalDividirMesa.content.event.subscribe((respuesta) => {
      console.log(respuesta);
      if (respuesta.data == 'Dividir') {
        this.cerrarModal('Facturar');
      }
    });
  }

  //Escoje el numero de divisiones de la mesa
  numeroDivisiones() {
    Swal.fire({
      icon: 'info',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
      },
      title: 'Por favor, escoja el número de divisiones para la mesa',
      text: 'Ingrese el número de divisiones',
      confirmButtonText: 'ACEPTAR',
      showCancelButton: true,
      cancelButtonText: 'CANCELAR',
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value === '') {
          Swal.fire({
            icon: 'error',
            title: '¡Escoja un valor por favor!',
            text: 'Por favor, indique el número de divisiones a utilizar.',
          }).then(() => {
            this.numeroDivisiones();
          });
        } else {
          if (this.esEntero(result.value) === false) {
            Swal.fire({
              icon: 'error',
              title: '¡Por favor, ingrese un valor válido!',
              text: 'Debe ingresar un número.',
            }).then(() => {
              this.numeroDivisiones();
            });
          } else {
            if (parseInt(result.value) > 5) {
              Swal.fire({
                icon: 'error',
                title: '¡Número de divisiones inválido!',
                text: 'Puede escoger hasta máximo 5 divisiones.',
              }).then(() => {
                this.numeroDivisiones();
              });
            } else {
              this.abrirModalDividir(parseInt(result.value));
            }
          }
        }
      }
    });
  }

  //Valida entero de manera primitiva
  esEntero(numero) {
    var numeros = /^[1-9]+$/;
    if (numero.match(numeros)) {
      return true;
    } else {
      return false;
    }
  }

  //Crea el elemento en el array
  creaItemArray(item) {
    let datos = {
      ruc: item.ruc,
      nombre: item.nombre,
      direccion: item.direccion,
      telefono: item.telefono,
      correo: item.correo,
      empresa: this.empresa,
      sucursal: this.sucursal,
    };
    this.spinner.show();
    this.servicio.creaCliente(datos).subscribe((data) => {
      console.log(data);

      this.banderaSpinner = true;
    });
  }

  //Abre el modal
  abrirModalAgregarCliente(ruc = '') {
    const initialState: any = {
      datos: ['Cliente', ruc],
    };
    this.modalReferencia = this.modalServicio.show(ModalClienteaddComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });

    this.modalReferencia.content.event.subscribe((res) => {
      this.creaItemArray(res.data);
      this.cliente = res.data;
      this.clienteCollapse = this.cliente.nombre.trim();
    });
  }

  //Abre el modal
  abrirModalDetallesCliente(cliente) {
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<--Datos enviados desde componente hacia modal
        cliente,
        'Cliente',
      ],
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferencia = this.modalServicio.show(ModalClientesComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });
    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((res) => {
      console.log('MODAL CERRADO, DATA RECIBIDA', res.data);
      if (res.data[0] === 1) {
        //Crea el elemento en el temporal y en la DB
        this.creaItemArray(res.data[1]);
        this.cliente = res.data[1];
        this.clienteCollapse = this.cliente.nombre.trim();
      }

      if (res.data[0] === 2) {
        //Actualiza el elemento en el temporal y en la DB
        this.actualizaItemArray(res.data[1]);
        this.cliente = res.data[1];
        this.clienteCollapse = this.cliente.nombre.trim();
      }
      if (res.data[0] === 3) {
        //Elimina el elemento en el temporal y en la DB
        this.eliminaItemArray(res.data[1], this.empresa);
        this.traerConsumidorFinal();
      }
    });
  }
  traerConsumidorFinal() {
    this.spinner.show();
    this.servicio
      .buscarCliente(this.rucConsumidorFinal, this.empresa)
      .subscribe(
        (data) => {
          if (data.length > 0) {
            this.cliente = data[0];
            this.cliente.nombre = this.cliente.nombre.trim();
            this.cliente.direccion = this.cliente.direccion.trim();
            this.cliente.telefono = this.cliente.telefono.trim();
            this.cliente.correo = this.cliente.correo.trim();
          }
          this.banderaSpinner = true;
          this.clienteCollapse = this.cliente.nombre.trim();

          let datos = {
            tipo: '003',
            empresa: JSON.parse(localStorage.getItem('empresa')).codigo,
          };

          this.servicio.getLineas(datos).subscribe((data) => {
            //console.log(data);
            this.lineas = data;
            this.lineas.forEach((linea) => {
              linea.codigo = linea.codigo.trim();
              linea.nombre = linea.nombre.trim();
            });
            this.cargarDatosStorage();
          });
        },
        (err) => {
          this.banderaSpinner = true;
          this.alerta(
            'Error de conexión',
            'Se perdió la conexión con el servidor',
            'error'
          );
          console.log(err);
        }
      );
  }

  //Traemos los datos del localStorage
  cargarDatosStorage() {
    this.productos = []; //<-- Traemos los datos para el cliente actual
    this.limpiacampos();

    if (localStorage.getItem(this.cliente.ruc.toString().trim())) {
      this.pasarDatosClienteaotroCliente();
      //Ahora carga los datos persistentes
      this.datosPersistentes = JSON.parse(
        localStorage.getItem(this.cliente.ruc.toString().trim())
      );
      if (this.datosPersistentes != null) {
        this.datosPersistentes.forEach((dato) => {
          dato.productos.forEach((item) => {
            if (item.cantidad > 0) {
              let comentario = item.cantidad + ' Completo';
              this.productos.push({
                codigo: item.codigo,
                nombre: item.nombre,
                precio: item.precio,
                cantidad: item.cantidad,
                linea: dato.linea,
                receta: [],
                comentario,
                porc_tarifa: item.porc_tarifa,
                descuento: item.descuento,
                medida: item.medida,
              });
            }
          });
        });
      } else {
        this.datosPersistentes = [];
        localStorage.setItem(
          this.cliente.ruc.toString().trim(),
          JSON.stringify(this.datosPersistentes)
        );
      }

      this.pintarEfectivo(1);
    } else {
      this.datosPersistentes = [];
      localStorage.setItem(
        this.cliente.ruc.toString().trim(),
        JSON.stringify(this.datosPersistentes)
      );
      this.pasarDatosClienteaotroCliente();
    }
    this.calculaTotales();
    this.actualizarEfectivo();
  }

  pasarDatosClienteaotroCliente() {
    if (!(Object.keys(this.clienteold).length === 0)) {
      if (
        this.clienteold.ruc.toString().trim() !=
        this.clienteold.rucanterior.toString().trim()
      ) {
        //<--Solo tiene sentido si es otro cliente
        //Primero actualiza los datos persistentes
        this.datosPersistentes = JSON.parse(
          localStorage.getItem(this.cliente.ruc.toString().trim())
        );
        if (this.datosPersistentes != null) {
          if (localStorage.getItem(this.clienteold.rucanterior.trim())) {
            let datosConsumidorFinal = JSON.parse(
              localStorage.getItem(this.clienteold.rucanterior.trim())
            );

            for (let i = 0; i < datosConsumidorFinal.length; i++) {
              let existeLinea = -1; //<--Indica si la linea existe en datos (indice en consumidor final)
              let linea = datosConsumidorFinal[i].linea; //<--Itera cada una de las lineas de consumidor final
              for (let j = 0; j < this.datosPersistentes.length; j++) {
                if (linea === this.datosPersistentes[j].linea) {
                  existeLinea = j;
                  break;
                }
              }

              //La linea existe
              if (existeLinea > -1) {
                let productosConsumidorFinal =
                  datosConsumidorFinal[i].productos; //<--Productos consumidor final
                let productosCliente =
                  this.datosPersistentes[existeLinea].productos; //<--Productos nuevo cliente
                for (let k = 0; k < productosConsumidorFinal.length; k++) {
                  let existeProducto = -1; //<--Indica si el producto existe en datos (indice en consumidor final)
                  for (let m = 0; m < productosCliente.length; m++) {
                    if (
                      productosConsumidorFinal[k].codigo ===
                      productosCliente[m].codigo
                    ) {
                      //Buscamos si el producto existe
                      existeProducto = m;
                      break;
                    }
                  }
                  //El producto existe
                  if (existeProducto > -1) {
                    productosCliente[existeProducto].cantidad =
                      productosCliente[existeProducto].cantidad +
                      productosConsumidorFinal[k].cantidad;
                  }
                  //El producto no existe
                  else {
                    productosCliente.push(productosConsumidorFinal[k]);
                  }
                }
              }
              //La linea no existe
              else {
                this.datosPersistentes.push(datosConsumidorFinal[i]);
              }
            }
          }
        } else {
          this.datosPersistentes = [];
        }
        localStorage.setItem(
          this.cliente.ruc.toString().trim(),
          JSON.stringify(this.datosPersistentes)
        );
        localStorage.removeItem(this.clienteold.rucanterior.trim());
      }
    }
  }
  
  verificarRUC() {
    this.clienteold = this.cliente;
    this.spinner.show();
    this.servicio
      .buscarCliente(this.cliente.ruc, this.empresa)
      .subscribe((data) => {
        this.spinner.hide();
        //Si ya existe
        if (data.length > 0) this.abrirModalBuscarCliente();
        else this.abrirModalAgregarCliente(this.cliente.ruc);
      });
  }
  //Abrir el Collapsable del cliente
  abrirCollapseTotales() {
    if (
      document.getElementById('datos_totales_icon').className ==
      'fas fa-chevron-up'
    ) {
      document.getElementById('datos_totales_contenido').style.display = 'none';

      document.getElementById('datos_totales_icon').className =
        'fas fa-chevron-down';
    } else {
      document.getElementById('datos_totales_contenido').style.display =
        'block';

      document.getElementById('datos_totales_icon').className =
        'fas fa-chevron-up';
    }
  }

  calcularValores() {
    this.total = 0;
    this.impuesto = 0;
    this.totalImpuesto = 0;
    console.log(this.productos);
    for (let i = 0; i < this.productos.length; i++) {
      this.total += this.productos[i].precio * this.productos[i].cantidad;

      this.impuesto +=
        (this.productos[i].precio *
          this.productos[i].cantidad *
          this.productos[i].porc_tarifa) /
        100;
    }
    this.totalImpuesto = Number(this.total) + Number(this.impuesto);
    console.log(this.total);
    console.log(this.impuesto);
    console.log(this.totalImpuesto);
    //Guardando en localStorage
    this.actualizarEfectivo();
    this.pintarEfectivo(1);
  }

  //Traemos los datos del localStorage
  cargarDatosProducto(producto) {
    producto.productos.forEach((item) => {
      let comentario = item.cantidad + ' Completo';
      let b = true;
      this.productos.forEach((element) => {
        if (item.codigo == element.codigo) {
          element.cantidad = item.cantidad;
          b = false;
        }
      });
      if (b) {
        this.productos.push({
          codigo: item.codigo,
          nombre: item.nombre,
          precio: item.precio,
          cantidad: item.cantidad,
          linea: producto.linea,
          receta: [],
          comentario: comentario,
          porc_tarifa: item.porc_tarifa,
          regreceta: item.regreceta,
        });
      }
    });
    this.calcularValores();
  }

  async actualizarFactura() {
    let cabecera = {
      ruc: this.cliente.ruc.toString(),
      comentario: this.comentario,
      sucursal: JSON.parse(localStorage.getItem('sucursal')).codigo,
      cambio: this.cambio,
      efectivo: this.efectivoEscogido,
      usuario: JSON.parse(localStorage.getItem('usuario')).codigo,
      empresa: JSON.parse(localStorage.getItem('empresa')).codigo,
      tipo: '002',
      mesa: this.mesa.mesa,
      tipoentrega: this.tipoentrega,
      servicioentrega: this.servicioentrega,
      distribuidor: this.distribuidor,
      formapago: this.formapago,
      sumatoria: this.sumatoria,
      descuento: this.descuento,
      tarifa0: this.tarifa0,
      tarifa12: this.tarifa12,
      subtotal: this.subtotal,
      impuesto: this.impuesto,
      total: this.total,

      numero: this.mesa.numero,
      modulo: this.mesa.modulo,
    };

    console.log(cabecera);
    console.log('1');
    await this.verificarRecetas();
    console.log('4');
    //Construyendo solicitud
    let cuerpo = this.productos;
    let datos = { cabecera, cuerpo };
    console.log(datos);
    this.spinner.show();
    this.servicio.eliminarFactura(cabecera).subscribe((data) => {
      console.log(data);
      this.servicio.efectuarPedido(datos).subscribe((data) => {
        console.log(data);
        this.banderaSpinner = true;
        if (data.data == 'OK') {
          this.alerta(
            '¡Transacción exitosa!',
            'El numero del documento es #' + data.factura,
            'success'
          ).then(() => {
            //this.imprimir(data, this.cliente, cuerpo);
            this.cerrarModal('Actualizar');
            this.productos = [];
          });
        } else {
          this.alerta(
            'Transacción no realizada',
            'Ha ocurrido un error inesperado',
            'error'
          );
        }
      });
    });
  }

  facturacion() {
    console.log('Aqui va el endpoint');
  }

  calculaTotales() {
    this.limpiacampos();

    this.productos.forEach((producto) => {
      let val_desc = (producto.precio * producto.descuento) / 100;

      this.sumatoria += producto.cantidad * producto.precio;
      this.descuento += producto.cantidad * val_desc;

      let tar = producto.cantidad * (producto.precio - val_desc);
      this.subtotal += tar;

      if (producto.porc_tarifa == '0') {
        this.tarifa0 += tar;
      } else {
        this.tarifa12 += tar;
      }
    });

    this.impuesto = this.tarifa12 * 0.12;
    this.total = this.subtotal + this.impuesto;
    this.calcularCambio();
  }

  limpiacampos() {
    this.efectivoEscogido = 0;
    this.cambio = 0;
    this.sumatoria = 0;
    this.tarifa0 = 0;
    this.tarifa12 = 0;
    this.descuento = 0;
    this.subtotal = 0;
    this.impuesto = 0;
    this.total = 0;
  }

  cargarTipoEntrega() {
    this.servicio.getConfiguraciones('029', this.empresa).subscribe(
      (result) => {
        //console.log(result);
        if (result.length > 0) {
          this.tiposentregas = result;
        }
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
  }

  cargarServicioEntrega() {
    this.servicio.getConfiguraciones('027', this.empresa).subscribe(
      (result) => {
        //console.log(result);
        if (result.length > 0) {
          this.serviciosentregas = result;
        }
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
  }

  cargarFormaPago() {
    this.servicio.getConfiguraciones('028', this.empresa).subscribe(
      (result) => {
        //console.log(result);
        if (result.length > 0) {
          this.formaspagos = result;
          for (let clave in this.formaspagos) {
            if (this.formaspagos[clave].codigo.trim() == this.formapago.trim())
              this.strformapago = this.formaspagos[clave].nombre.trim();
          }
        }
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
  }

  cargarDistribuidores() {
    let consulta = {
      texto: ' ',
      empresa: this.empresa,
    };

    this.servicio.getDistribuidores(consulta).subscribe(
      (result) => {
        //console.log(result);
        if (result.length > 0) {
          this.distribuidores = result;
          this.distribuidoresTotal = result;
        }
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
  }

  cargarRecetas(producto, resta = 0) {
    return new Promise((resolve, reject) => {
      this.servicio
        .buscarReceta(producto.codigo, 'TODOS')
        .subscribe((result) => {
          console.log(result);
          for (let e = 0; e < result['length']; e++) {
            result[e]['solicitado'] = true;
          }

          for (let i = 0; i < producto.cantidad - resta; i++) {
            let newResult = [];
            result.forEach((val) => newResult.push(Object.assign({}, val)));
            producto.receta.push(newResult);
          }
          console.log('intermedio');
          console.log(producto);
          resolve(producto);
        });
    });
  }

  async verificarRecetas() {
    let promesas = [];
    console.log(this.productos);
    await this.productos.forEach((element) => {
      let longitud = element.receta.length;

      console.log('2');
      if (element.receta.length > 0) {
        if (element.receta.length > element.cantidad) {
          for (let i = 0; i < longitud - element.cantidad; i++) {
            console.log(i);
            element.receta.pop();
          }
        } else if (element.receta.length < element.cantidad)
          promesas.push(this.cargarRecetas(element, longitud));
      } else {
        promesas.push(this.cargarRecetas(element));
      }
    });
    console.log('3');
    await Promise.all(promesas).then((resultados) => {
      console.log(resultados);

      return;
    });
  }

  enviarCocina() {
    let datos = {
      empresa: this.mesa.empresa,
      sucursal: this.mesa.sucursal,
      modulo: this.mesa.modulo,
      tipo: this.mesa.tipo,
      numero: this.mesa.numero,
      envio: 1,
    };
    console.log(datos);
    this.servicio.enviarCocina(datos).subscribe((result) => {
      console.log(result);
    });
  }

  //Actualiza elemento en el array
  actualizaItemArray(item) {
    const ruc = item.ruc;
    let datos = {
      ruc: item.ruc,
      nombre: item.nombre,
      direccion: item.direccion,
      telefono: item.telefono,
      correo: item.correo,
      empresa: this.empresa,
      sucursal: this.sucursal,
    };
    let consulta = {
      texto: '',
      empresa: this.empresa,
    };
    this.spinner.show();
    this.servicio.actualizaCliente(ruc, datos).subscribe((data) => {
      if (data.data === 'Dato actualizado exitosamente') {
        //this.getClientes();
      }
      this.banderaSpinner = true;
    });
  }

  //Borra elemento del array
  eliminaItemArray(item, empresa) {
    this.spinner.show();
    this.servicio.eliminaCliente(item.ruc, empresa).subscribe((data) => {
      if (data.data === 'Dato eliminado exitosamente') {
        //this.getClientes();
      }
      this.banderaSpinner = true;
    });
  }

  alerta(titulo, texto, icono) {
    return Swal.fire({
      icon: icono,
      title: titulo,
      text: texto,
      allowOutsideClick: false,
    });
  }
}
