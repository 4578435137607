<div class="modal-header">
    <h4 class="modal-title">{{titulo}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalReferencia.hide()">
        <span aria-hidden="true">&times;
        </span>
    </button>
</div>

<div class="container">

    <form>
        <div class="form-group">
          <label for="exampleFormControlInput1">Nombre Cupo</label>
          <input type="email" class="form-control" id="nombre">
        </div>

        <div class="form-group">
            <label for="exampleFormControlInput1">Valor</label>
            <input type="email" class="form-control" id="valor">
          </div>
       
        <div class="form-group">
          <label for="exampleFormControlTextarea1">Comentario</label>
          <textarea class="form-control" id="comentario" rows="3"></textarea>
        </div>
    </form>

    <div class="text-center my-3">
        <button class="btn btn-success">Registrar</button>
    </div>
    

</div>