import { Component, OnInit } from '@angular/core';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';

@Component({
  selector: 'app-modal-reporte-gastos',
  templateUrl: './modal-reporte-gastos.component.html',
  styleUrls: ['./modal-reporte-gastos.component.css'],
})
export class ModalReporteGastosComponent implements OnInit {
  datos: Object; //<-- Debe tener el mismo nombre de la variable en factura
  fecha: string;
  cabecera: any;
  movimiento: any;
  tipo: string;
  servicio: string;
  sucursal: string;
  nomsucursal: string;
  empresa: any;
  datosempresa: any;
  total: number = 0;
  constructor(
    public modalReferencia: BsModalRef,
    private modalServicio: BsModalService,
    private service: RestauranteService
  ) {}

  ngOnInit(): void {
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.datosempresa = JSON.parse(localStorage.getItem('empresa'));
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
    this.fecha = this.datos[0];
    this.tipo = this.datos[1];
    this.servicio = this.datos[2];
    this.sucursal = this.datos[3];
    this.nomsucursal = this.datos[4];
    this.cargarDatos();
  }

  cargarDatos() {
    this.total = 0;
    let datos = {
      tipo: 2,
      tipo_gasto: this.tipo,
      servicio_gasto: this.servicio,
      desde: this.formatearFechaQuery(this.fecha) + '00:00:00',
      hasta: this.formatearFechaQuery(this.fecha) + '23:59:59',
      tabla: 1,
      sucursal: this.sucursal,
      empresa: JSON.parse(localStorage.getItem('empresa')).codigo,
    };
    console.log(datos);
    this.service.reporteGastos(datos).subscribe((cabeceras) => {
      console.log(cabeceras);

      this.cabecera = cabeceras;
      datos['tabla'] = 2;
      this.service.reporteGastos(datos).subscribe((movimientos) => {
        movimientos.forEach((item) => {
          this.total = this.total + item.valor;
        });

        for (let i = 0; i < cabeceras.length; i++) {
          for (let e = 0; e < movimientos.length; e++) {
            const element = cabeceras[i];
            switch (element.tipodato.trim()) {
              case 'char':
                movimientos[e][element['campo']] =
                  movimientos[e][element['campo']].trim();
                break;
              case 'numerico':
                movimientos[e][element['campo']] = parseFloat(
                  movimientos[e][element['campo']]
                ).toFixed(parseInt(element['longitud']));

                break;
              case 'date':
                movimientos[e][element['campo']] = this.formatearFechaString(
                  movimientos[e][element['campo']]
                );
                break;
              case 'datetime':
                movimientos[e][element['campo']] = this.formatearFechaString(
                  movimientos[e][element['campo']],
                  true
                );
                break;
              default:
                //console.log(element);
                break;
            }
          }
        }

        this.movimiento = movimientos;
      });
    });
  }

  formatearFechaQuery(fecha) {
    let fechaFormat = fecha.split('/').reverse().join('-');
    console.log(fechaFormat);
    let fechaFin = new Date(fechaFormat);
    console.log(fechaFin);
    let fechaTotal = fechaFin.toJSON().slice(0, 11);
    return fechaTotal;
  }

  formatearFechaString(fecha = new Date(), hora = false) {
    //new Date().toJSON().slice(0,10).split('-').reverse().join('/')
    let fechaFin = new Date(fecha);
    let fechaString = fechaFin
      .toJSON()
      .slice(0, 10)
      .split('-')
      .reverse()
      .join('/');
    if (hora) {
      //Formatear hora
      return fechaString + ' ' + fechaFin.toJSON().slice(11, 19);
    }
    return fechaString;
  }
}
