<div class="container-fluid my-4">
  <div class="row justify-content-center align-items-center">
    <div class="col-9 col-md-10 text-center">
      <h3>
        Reporte de Faltas
        <small class="text-muted"> </small>
      </h3>
    </div>
  </div>
</div>
<!--
<div class="container-fluid py-2">
  <div class="row d-flex justify-content-center">
    <div class="col-12 col-md-3">
      <div class="card py-2 text-center">
        <div class="card-content">
          <mat-form-field appearance="fill">
            <mat-label>Año y Mes</mat-label>
            <input matInput [matDatepicker]="dp" [formControl]="date" />
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker
              #dp
              startView="multi-year"
              (yearSelected)="chosenYearHandler($event)"
              (monthSelected)="chosenMonthHandler($event, dp)"
              panelClass="example-month-picker"
            >
            </mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>
-->
<div class="container mb-3">
  <div class="card mt-3">
    <div class="card-content">
      <div class="row">
        <div
          class="col-11 col-md-6 d-flex justify-content-center"
          style="align-items: center"
        >
          <div class="mr-2"><strong>Desde : </strong></div>
          <mat-form-field appearance="fill">
            <mat-label>Año y Mes</mat-label>
            <input
              matInput
              [matDatepicker]="dp1"
              [formControl]="dateInit"
              [max]="maxDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="dp1"
            ></mat-datepicker-toggle>
            <mat-datepicker
              #dp1
              startView="multi-year"
              (yearSelected)="chosenYearHandlerBegin($event)"
              (monthSelected)="chosenMonthHandlerBegin($event, dp1)"
              panelClass="example-month-picker"
            >
            </mat-datepicker>
          </mat-form-field>
        </div>
        <div
          class="col-11 col-md-6 text-right d-flex justify-content-center"
          style="align-items: center"
        >
          <div class="mr-2"><strong>Hasta : </strong></div>
          <mat-form-field appearance="fill">
            <mat-label>Año y Mes</mat-label>
            <input
              matInput
              [matDatepicker]="dp2"
              [formControl]="dateEnd"
              [min]="minDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="dp2"
            ></mat-datepicker-toggle>
            <mat-datepicker
              #dp2
              startView="multi-year"
              (yearSelected)="chosenYearHandlerEnd($event)"
              (monthSelected)="chosenMonthHandlerEnd($event, dp2)"
              panelClass="example-month-picker"
            >
            </mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div
          class="col-11 col-md-6 col-lg-6 d-flex justify-content-center"
          style="align-items: center"
        >
          <div class="mr-2"><strong>Empleado :</strong></div>
          <mat-form-field appearance="fill" class="no-padding">
            <mat-label>Seleccione un empleado</mat-label>
            <mat-select
              panelClass="my-select-panel-class"
              [(value)]="empleado"
              (selectionChange)="cargarDatos()"
            >
              <div style="display: flex; justify-content: center">
                <mat-form-field
                  class="example-full-width busqueda"
                  style="padding-top: 10px"
                >
                  <mat-label>Buscar</mat-label>
                  <input
                    matInput
                    [(ngModel)]="busqueda"
                    (keyup)="onKey()"
                    (keydown)="$event.stopPropagation()"
                  />
                </mat-form-field>
              </div>
              <mat-option value="todos"> Todos </mat-option>
              <mat-option
                *ngFor="let usuario of empleados"
                [value]="usuario.codigo"
                >{{ usuario.nombre }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-11 col-md-4 col-lg-3 d-flex justify-content-center">
          <mat-radio-group
            aria-labelledby="example-radio-group-label"
            class="example-radio-group"
            [(ngModel)]="opcion"
            (change)="cambiar()"
          >
            <mat-radio-button
              class="example-radio-button"
              *ngFor="let opc of opciones"
              [value]="opc"
            >
              {{ opc }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div
          class="col-11 col-md-6 col-lg-6 d-flex justify-content-center"
          style="align-items: center"
          *ngIf="opcion == 'Movimiento'"
        >
          <div class="mr-2"><strong>Motivo : </strong></div>
          <mat-form-field appearance="fill" class="no-padding">
            <mat-label>Seleccione un motivo</mat-label>
            <mat-select
              panelClass="my-select-panel-class"
              [(value)]="motivo"
              (selectionChange)="cargarDatos()"
            >
              <mat-option value="todos"> Todos </mat-option>
              <mat-option
                *ngFor="let motiv of motivos"
                [value]="motiv.codigo"
                >{{ motiv.nombre }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt-3">
  <table class="table table-striped table-sm table-hover m-0 p-0">
    <thead>
      <tr>
        <th
          scope="col"
          *ngFor="let dato of cabecera"
          [style.text-align]="dato.align"
        >
          {{ dato.alias }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let mov of movimiento">
        <td [style.text-align]="dato.align" *ngFor="let dato of cabecera">
          <div>{{ mov[dato.campo.trim()] }}</div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
