import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';

@Component({
  selector: 'app-informe-perdida-ganancia',
  templateUrl: './informe-perdida-ganancia.component.html',
  styleUrls: ['./informe-perdida-ganancia.component.css'],
})
export class InformePerdidaGananciaComponent implements OnInit {
  campaignOne: FormGroup;
  sucursales: any;
  sucursal: string = 'TODOS';

  @ViewChild('tarjeta1') child1;
  @ViewChild('tarjeta2') child2;
  @ViewChild('tarjeta3') child3;
  @ViewChild('tarjeta4') child4;
  childs: any = [];

  utilidad_bruta = 0;
  buscarUtilidad: any;

  cabecera: any;
  movimiento: any;

  selectorTabla: string = '';
  selectores: any;

  titulos = ['Inventario y Compras', 'Facturación', 'Gastos', 'Nómina'];
  empresa: any;
  constructor(private servicio: RestauranteService) {}

  ngOnInit(): void {
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    const day = today.getDate();
    console.log(today);

    this.campaignOne = new FormGroup({
      start: new FormControl(new Date(year, month, 1)),
      end: new FormControl(new Date(year, month, day)),
    });
    this.cargarSucursales();
    this.cargarSelector();
  }
  ngAfterViewInit() {
    this.childs = [this.child1, this.child2, this.child3, this.child4];
    //this.childs = [this.child1];
    this.cargarModulos();
    this.buscarUtilidad = setInterval(() => {
      let u = 0;
      let bandera = true;
      for (let i = 0; i < this.childs.length; i++) {
        let elemento = this.childs[i];
        bandera = bandera && elemento.total > 0;
        if (i == 1) {
          u += elemento.total;
        } else if (elemento.total > 0) {
          u -= elemento.total;
        }
      }
      if (bandera) {
        this.utilidad_bruta = u;
      }
    }, 1000);
  }

  cargarModulos() {
    for (let i = 0; i < this.childs.length; i++) {
      this.childs[i].tituloGrafico = this.titulos[i];
      if (i == 1) this.childs[i].tipoGrafico = 'line';
      else this.childs[i].tipoGrafico = 'bar';
      console.log(this.childs[i]);
      this.childs[i].modulo = i + 1;
      this.childs[i].fechaDesde = this.formatearFechaQuery(
        this.campaignOne.value.start.toJSON()
      );
      this.childs[i].fechaHasta = this.formatearFechaQuery(
        this.campaignOne.value.end.toJSON(),
        '23:59:59'
      );
      this.childs[i].sucursal = this.sucursal;
      this.childs[i].inicializarDatos();
      this.childs[i].cargarTotal();
    }
  }

  buscarFecha() {
    if (this.campaignOne.value.end) {
      this.cargarModulos();
    }
  }
  cargarSucursales() {
    this.servicio
      .getConfiguraciones('015', this.empresa)
      .subscribe((result) => {
        console.log(result);
        if (result.length > 0) {
          this.sucursales = result;
        }
      });
  }
  formatearFechaQuery(fecha = new Date(), hora = '00:00:00') {
    let fechaFin = new Date(fecha);
    let fechaTotal = fechaFin.toJSON().slice(0, 10);
    return fechaTotal + 'T' + hora;
  }

  cargarSelector() {
    let datos = {
      modulo: '5',
      selector: '2',
      tabla: '1',
      contenido: '0',
      desde: this.formatearFechaQuery(this.campaignOne.value.start.toJSON()),
      hasta: this.formatearFechaQuery(this.campaignOne.value.end.toJSON()),
      sucursal: this.sucursal,
    };
    this.servicio.informe_dashboard(datos).subscribe((result) => {
      console.log(result);
      this.selectores = result;
      this.selectorTabla = this.selectores[0].orden;

      this.cargarTabla();
    });
  }

  cargarTabla() {
    let datos = {
      modulo: '5',
      selector: '3',
      tabla: this.selectorTabla,
      contenido: '1',
      desde: this.formatearFechaQuery(this.campaignOne.value.start.toJSON()),
      hasta: this.formatearFechaQuery(this.campaignOne.value.end.toJSON()),
      sucursal: this.sucursal,
    };
    console.log(this.selectorTabla);

    this.servicio.informe_dashboard(datos).subscribe((cabeceras) => {
      console.log(cabeceras);

      this.cabecera = cabeceras;
      datos['contenido'] = '0';
      console.log(datos);
      this.servicio.informe_dashboard(datos).subscribe((movimientos) => {
        console.log(movimientos);

        for (let i = 0; i < cabeceras.length; i++) {
          for (let e = 0; e < movimientos.length; e++) {
            const element = cabeceras[i];
            switch (element.tipodato.trim()) {
              case 'char':
                movimientos[e][element['campo']] =
                  movimientos[e][element['campo']].trim();
                break;
              case 'numerico':
                movimientos[e][element['campo']] = parseFloat(
                  movimientos[e][element['campo']]
                ).toFixed(parseInt(element['longitud']));
                break;
              default:
                console.log(element);
                break;
            }
          }
        }

        this.movimiento = movimientos;
      });
    });
  }
}
