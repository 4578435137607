<div class="mt-2">
  <div class="container mb-3">
    <div class="row justify-content-center align-items-center">
      <!--
      <div class="col-5 col-md-3 col-xl-1">
        <button
          type="button"
          class="btn btn-sm btn-outline-success"
          (click)="abrirModalAgregarCuenta()"
        >
          <i class="far fa-plus-square"></i> Agregar
        </button>
      </div>
      -->
      <div
        class="col-11 col-md-8 col-xl-8 text-center text-md-left text-xl-center"
      >
        <h3>
          Biblioteca
          <small class="text-muted"> de Cuentas Contables</small>
        </h3>
      </div>

      <div class="col-11 col-md-8 col-xl-3 text-center text-md-left">
        <div class="input-group">
          <input
            type="text"
            class="form-control form-control-sm"
            placeholder="Buscar Nombre | Codigo"
            aria-label="Busqueda"
            aria-describedby="basic-addon1"
            (keyup)="busquedaCuenta($event)"
          />
          <div class="input-group-append">
            <span class="input-group-text" id="basic-addon1"
              ><i class="fab fa-searchengin"></i
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!---->
<div class="container-fluid">
  <div class="table-responsive" *ngIf="hayCuenta === true; else sinClientes">
    <table class="table table-striped table-sm table-hover m-0 p-0">
      <thead>
        <tr>
          <th
            scope="col text-center"
            style="width: 50px !important; text-align: center"
          ></th>

          <th scope="col" style="min-width: 150px">Codigo</th>
          <th scope="col" style="min-width: 250px">Nombre</th>
          <th scope="col" style="min-width: 250px">Superior</th>
          <th scope="col" style="min-width: 150px">Categoria</th>
          <th scope="col" style="min-width: 150px">Tipo Cuenta</th>
          <th scope="col" style="min-width: 150px">Clase</th>
        </tr>
      </thead>

      <tbody>
        <tr
          *ngFor="
            let cuenta of cuentas
              | paginate
                : {
                    itemsPerPage: 25,
                    currentPage: p,
                    totalItems: cuentas.count
                  }
          "
        >
          <td>
            <button
              type="button"
              class="btn btn-outline-dark btn-sm"
              (click)="abrirModalDetallesCuenta(cuenta)"
            >
              <i class="far fa-eye"></i>
            </button>
          </td>

          <td>{{ cuenta.codigo }}</td>
          <td>{{ cuenta.nombre }}</td>
          <td>{{ cuenta.nom_superior }}</td>
          <td>{{ cuenta.nom_tipo }}</td>
          <td>{{ cuenta.nom_tipcta }}</td>
          <td>{{ cuenta.nom_clase }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="container-fluid" *ngIf="hayCuenta === true">
  <div class="row justify-content-center align-items-center mt-4 mb-3">
    <div class="col-12" style="text-align: center">
      <pagination-controls
        (pageChange)="p = $event"
        autoHide="true"
        previousLabel="Anterior"
        nextLabel="Siguiente"
      >
      </pagination-controls>
    </div>
  </div>
</div>

<ng-template #sinClientes>
  <ng-container>
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-12 col-md-6 col-lg-4 mt-5" style="text-align: center">
          <h3>
            <small class="text-muted">Búsqueda sin resultados</small>
          </h3>

          <lottie-player
            src="https://assets2.lottiefiles.com/temp/lf20_TOE9MF.json"
            background="transparent"
            speed="1"
            loop
            autoplay
          ></lottie-player>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<app-spinner></app-spinner>
