import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

import { ModalListadoProductosComponent } from '../../../modal-listado-productos/modal-listado-productos.component';

import { ModalDetalleFacturaComponent } from '../../../ventas/modales/modal-detalle-factura/modal-detalle-factura.component';

import { ModalFacturaComponent } from '../../../modal-factura/modal-factura.component';

@Component({
  selector: 'app-diario-pedidos-pm',
  templateUrl: './diario-pedidos-pm.component.html',
  styleUrls: ['./diario-pedidos-pm.component.css'],
})
export class DiarioPedidosPmComponent implements OnInit {
  campaignOne: FormGroup;
  //Referencia del modal
  modalReferencia: BsModalRef;
  //Datos traidos de la DB
  clientes: any = [];
  //Verifica que hay cliente
  hayCliente: boolean;
  //Variable de evento de pagina
  p: Number = 1;
  //Bandera para spinner
  banderaSpinner: boolean = false;
  //Bandera para saber si son datos filtrados o todos
  banderaClientes: boolean; //<-- true todos y false filtro
  busco_pedido = false;
  producto = '';
  producto_codigo = '';
  pedidos: any = [];
  totales: any = {
    label: 'TOTALES',
    subtotal: 0.0,
    impuesto: 0.0,
    total: 0.0,
    acumulado: 0.0,
  };
  cliente: any = {
    ruc: '',
  };
  sucursales: any;
  sucursal: string = 'TODOS';
  admin: boolean = false;

  rucConsumidorFinal: any = '9999999999999';

  cabecera: any;
  movimiento: any;
  regmov: number = 0;

  empresa: any;
  datosempresa: any;
  usuario: any;
  busqueda: string = '';
  empleados: any;
  empleadosTotal: any;

  constructor(
    private service: RestauranteService,
    private modalServicio: BsModalService,
    private spinner: NgxSpinnerService
  ) {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    const day = today.getDate();
    console.log(today);

    this.campaignOne = new FormGroup({
      start: new FormControl(new Date(year, month, day)),
      end: new FormControl(new Date(year, month, day)),
    });
  }

  ngOnInit(): void {
    this.usuario = JSON.parse(localStorage.getItem('usuario')).codigo;
    this.admin = JSON.parse(localStorage.getItem('usuario')).admin;

    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.datosempresa = JSON.parse(localStorage.getItem('empresa'));
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }

    if (this.admin) {
      this.cargarEmpleados();
      this.cargarSucursales();
    } else {
      this.sucursal = JSON.parse(localStorage.getItem('sucursal')).codigo;
    }

    setInterval(() => {
      if (this.banderaSpinner === true) {
        this.spinner.hide();
        this.banderaSpinner = false;
      }
    }, 100);

    this.cargarDatos();
  }

  onKey() {
    let mini: any = [];
    this.empleadosTotal.forEach((usuario: any) => {
      //console.log(usuario);

      if (usuario.nombre.toLowerCase().includes(this.busqueda.toLowerCase()))
        mini.push(usuario);
    });
    this.empleados = mini;
  }

  cargarDatos() {
    //console.log('FECHA:', this.campaignOne.value);
    if (this.campaignOne.value.end) {
      let campos = {
        orden: 1,
        opcion: 1,
        desde: this.formatearFechaQuery(this.campaignOne.value.start.toJSON()),
        hasta: this.formatearFechaQuery(
          this.campaignOne.value.end.toJSON(),
          '23:59:59'
        ),
        sucursal: this.sucursal,
        usuario: this.usuario,
        empresa: this.empresa,
        producto: this.producto_codigo,
        cliente: this.cliente.ruc.trim(),
      };
      //console.log(campos);
      this.service.obtenerDiarioPedidos(campos).subscribe((cabeceras) => {
        //console.log(cabeceras);
        campos['opcion'] = 2;
        this.cabecera = cabeceras;
        this.service.obtenerDiarioPedidos(campos).subscribe((movimientos) => {
          this.movimiento = this.formatearDatos(cabeceras, movimientos);
          this.regmov = movimientos.length;
          this.movimiento = movimientos;
          // console.log('MOVIMIENTO', this.movimiento);
          //this.spinner.hide();
        });
      });
    }
  }

  //Trae todos los clientes de la DB
  getClientes() {
    this.spinner.show();
    let consulta = {
      texto: ' ',
      empresa: this.empresa,
    };
    //console.log(consulta);
    this.service.getClientes(consulta).subscribe((data) => {
      if (data.length === 0) {
        this.hayCliente = false;
      } else {
        this.clientes = data;
        this.clientes.forEach((cliente) => {
          cliente.ruc = cliente.ruc.trim();
          cliente.nombre = cliente.nombre.trim();
          cliente.direccion = cliente.direccion.trim();
          cliente.telefono = cliente.telefono.trim();
          cliente.correo = cliente.correo.trim();
        });
        this.hayCliente = true;
      }
      this.banderaSpinner = true;
      this.banderaClientes = true; //<--Los datos actuales son todos
    });
  }

  abrirModalBuscarProducto() {
    console.log('modal productos');
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<--Datos enviados desde componente hacia modal
        this.producto,
      ],
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferencia = this.modalServicio.show(
      ModalListadoProductosComponent,
      {
        initialState,
        ignoreBackdropClick: true,
      }
    );
    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((res) => {
      console.log(res.data);
      this.producto = res.data.nombre.trim();
      this.producto_codigo = res.data.codigo;
      this.cargarDatos();
    });
  }

  formatearDatos(cabeceras, movimientos) {
    for (let i = 0; i < cabeceras.length; i++) {
      for (let e = 0; e < movimientos.length; e++) {
        const element = cabeceras[i];
        switch (element.tipodato.trim()) {
          case 'char':
            movimientos[e][element['campo']] =
              movimientos[e][element['campo']].trim();
            break;
          case 'numerico':
            movimientos[e][element['campo']] = parseFloat(
              movimientos[e][element['campo']]
            ).toFixed(parseInt(element['longitud']));
            break;
          case 'date':
            movimientos[e][element['campo']] = this.formatearFechaString(
              movimientos[e][element['campo']]
            );
            break;
          case 'datetime':
            movimientos[e][element['campo']] = this.formatearFechaString(
              movimientos[e][element['campo']],
              true
            );
            break;
          default:
            console.log(element);
            break;
        }
      }
    }

    return movimientos;
  }

  formatearFechaString(fecha = new Date(), hora = false) {
    //new Date().toJSON().slice(0,10).split('-').reverse().join('/')
    let fechaFin = new Date(fecha);
    let fechaString = fechaFin
      .toJSON()
      .slice(0, 10)
      .split('-')
      .reverse()
      .join('/');
    if (hora) {
      //Formatear hora
      return fechaString + ' ' + fechaFin.toJSON().slice(11, 19);
    }
    return fechaString;
  }

  detalleFactura(pedido) {
    console.warn('MODAL DE PEDIDO', pedido);
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<--Datos enviados desde componente hacia modal
        pedido,
        this.sucursal,
      ],
    };
    this.modalReferencia = this.modalServicio.show(
      ModalDetalleFacturaComponent,
      {
        initialState,
        ignoreBackdropClick: true,
        class: 'modal-lg',
      }
    );
    this.modalReferencia.content.event.subscribe((res) => {
      console.log(res.data);
      this.cargarDatos();
    });
  }

  borrarFactura(factura) {
    Swal.fire({
      title: '¿Está seguro de eliminar esta factura?',
      showDenyButton: true,
      confirmButtonText: `Sí`,
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        let campos = {
          numero: factura.numero,
          tipo: factura.tipo,
          sucursal: factura.sucursal,
          modulo: factura.modulo,
          empresa: factura.empresa,
        };
        console.log(campos);
        this.service.eliminarFactura2(campos).subscribe(
          (data) => {
            if ((data.data = 'Dato eliminado exitosamente')) {
              for (let i = 0; i < this.pedidos.length; i++) {
                if (this.pedidos[i].numero === campos.numero) {
                  this.pedidos.splice(i, 1);
                  break;
                }
              }
              Swal.fire('¡Factura eliminada!', '', 'success');
            } else {
              Swal.fire('¡Ha ocurrido un error!', '', 'error');
            }
            this.banderaSpinner = true;
          },
          (err) => {
            this.banderaSpinner = true;
            this.alerta(
              'Error de conexión',
              'Se perdió la conexión con el servidor',
              'error'
            );
            console.log(err);
          }
        );
      }
    });
  }

  //Si consumidor final esta en el input lo borra y guarda sus datos en LS
  borrarConsumidorFinal() {
    if (this.cliente.ruc === this.rucConsumidorFinal) {
      //Borro consumidor final
      this.cliente = {};
    }
  }

  //Abre el modal
  abrirModalBuscarCliente() {
    //Este modal recibe el RUC del cliente que usa data binding componente hacia template
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<--Datos enviados desde componente hacia modal
        this.cliente.ruc,
        'Movimientos',
      ],
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferencia = this.modalServicio.show(ModalFacturaComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-md',
    });
    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((res) => {
      this.cliente = res.data;
      this.cargarDatos();
    });
  }

  cargarSucursales() {
    this.service.getConfiguraciones('015', this.empresa).subscribe(
      (result) => {
        console.log(result);
        if (result.length > 0) {
          this.sucursales = result;
        }
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
  }

  cargarEmpleados() {
    this.service.getConfiguraciones('011', this.empresa).subscribe(
      (result) => {
        //console.log(result);
        if (result.length > 0) {
          this.empleados = result;
          this.empleadosTotal = result;
        }
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
  }

  formatearFechaQuery(fecha = new Date(), hora = '00:00:00') {
    let fechaFin = new Date(fecha);
    let fechaTotal = fechaFin.toJSON().slice(0, 10);
    return fechaTotal + ' ' + hora;
  }

  alerta(titulo, texto, icono) {
    return Swal.fire({
      icon: icono,
      title: titulo,
      text: texto,
      allowOutsideClick: false,
    });
  }
}
