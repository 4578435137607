<div class="container-fluid">
  <div class="row justify-content-center align-items-center mb-3">
    <div class="col-3 col-md-3 col-xl-1">
      <button
        type="button"
        class="btn btn-outline-success btn-sm"
        (click)="modalActivo('create')"
      >
        <i class="far fa-plus-square"></i> Agregar
      </button>
    </div>

    <div
      class="col-8 col-md-8 col-xl-4 text-center text-md-left text-xl-center"
    >
      <h3>
        Registro
        <small class="text-muted"> de Activos Fijos</small>
      </h3>
    </div>

    <div class="col-11 col-md-8 col-xl-3 mt-2 text-center text-md-left">
      <div class="input-group">
        <input
          type="text"
          class="form-control form-control-sm"
          placeholder="Buscar Nombre | Codigo"
          aria-label="Busqueda"
          aria-describedby="basic-addon1"
          [(ngModel)]="busqueda"
          (keyup)="buscar()"
        />
        <div class="input-group-append">
          <span class="input-group-text" id="basic-addon1"
            ><i class="fab fa-searchengin"></i
          ></span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div
    class="table table-responsive table-hover table-responsive-sm table-responsive-md table-responsive-xl"
    *ngIf="activos.length > 0; else sinRegistros"
  >
    <table class="table table-sm">
      <thead>
        <tr>
          <th
            scope="col"
            style="width: 50px !important; text-align: center"
          ></th>

          <th scope="col" style="min-width: 150px">Tipos Activos Fijos</th>
          <th scope="col" style="min-width: 250px">Descripción</th>
          <th scope="col" style="min-width: 250px">Serie</th>
          <th scope="col" style="min-width: 250px">Año compra</th>
          <th class="text-center" scope="col" style="min-width: 100px">
            Habilitado
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          *ngFor="
            let activo of activos
              | paginate
                : {
                    itemsPerPage: 25,
                    currentPage: p,
                    totalItems: activos.count
                  }
          "
        >
          <td>
            <button
              type="button"
              class="btn btn-outline-dark btn-sm"
              (click)="modalActivo('read', activo)"
            >
              <i class="far fa-eye"></i>
            </button>
          </td>

          <td>{{ activo.nomtipoactivo }}</td>
          <td>{{ activo.nombre }}</td>
          <td>{{ activo.serie }}</td>
          <td>{{ activo.anio_compra }}</td>
          <td *ngIf="!activo.habilitado" class="text-center">
            <i class="far fa-times-circle fa-lg" style="color: #4c6ef5"></i>
          </td>
          <td *ngIf="activo.habilitado" class="text-center">
            <i class="far fa-check-circle fa-lg" style="color: #82c91e"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="container-fluid" *ngIf="true">
  <div class="row justify-content-center align-items-center mt-4 mb-3">
    <div class="col-12" style="text-align: center">
      <pagination-controls
        (pageChange)="p = $event"
        autoHide="true"
        previousLabel="Anterior"
        nextLabel="Siguiente"
      >
      </pagination-controls>
    </div>
  </div>
</div>

<ng-template #sinRegistros>
  <ng-container>
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-12 col-md-6 col-lg-4 mt-5" style="text-align: center">
          <h3>
            <small class="text-muted">Sin resultados</small>
          </h3>

          <lottie-player
            src="https://assets2.lottiefiles.com/temp/lf20_TOE9MF.json"
            background="transparent"
            speed="1"
            loop
            autoplay
          ></lottie-player>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<app-spinner></app-spinner>
