<div class="row justify-content-center align-items-center my-2">
  <div class="col-9 col-md-10 text-center">
    <h3>Fecha caducidad :</h3>
  </div>
  <div class="col-2 col-md-1 justify-content-center align-items-center">
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalReferencia.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>

<div class="container mb-3">
  <div class="form-group row">
    <label for="example-date-input" class="col-2 col-form-label">Date</label>
    <div class="col-10">
      <input class="form-control" type="date" [(ngModel)]="fecha" />
    </div>
  </div>
  <div class="text-center">
    <button class="btn btn-success" (click)="lanzarEvento()">Aceptar</button>
  </div>
</div>
