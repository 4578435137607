import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-factura',
  templateUrl: './modal-factura.component.html',
  styleUrls: ['./modal-factura.component.css'],
})
export class ModalFacturaComponent implements OnInit {
  datos: Object; //<-- Debe tener el mismo nombre de la variable en factura
  clientes: any = []; //<-- Clientes traidos desde DB
  hayClientes: boolean = false; //<--Valida si existe almenos un cliente
  cliente: any; //<-- Cliente escogido
  rucCliente: any; //<--RUC cliente
  public event: EventEmitter<any> = new EventEmitter(); //<-- Para devolver datos a cliente
  //Bandera para spinner
  banderaSpinner: boolean = false;
  empresa: string = '';

  constructor(
    public modalReferencia: BsModalRef,
    public service: RestauranteService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
    let RUCcliente = this.datos[0]; //<--Tomando RUC de cliente
    //Verifica bandera para ocultar spinner
    setInterval(() => {
      if (this.banderaSpinner === true) {
        this.spinner.hide();
        this.banderaSpinner = false;
      }
    }, 100);
    if (RUCcliente !== undefined) {
      this.traerClientes(1, RUCcliente);
    }
  }

  traerClientes(caso, valor) {
    let texto;
    if (caso === 1) {
      texto = valor; //<--Caso 1 coge RUC
    } else {
      texto = valor.target.value; //<--Caso 2 coge valor del objetivo del evento
    }
    if (texto != '') {
      //<-- Si no está vacío coge el texto y busca
      if (texto.length > 3) {
        this.spinner.show();
        this.service.buscarCliente(texto, this.empresa).subscribe(
          (data) => {
            if (data.length > 0) {
              this.clientes = data;
              this.clientes.forEach((cliente) => {
                cliente.ruc = cliente.ruc.trim();
                cliente.nombre = cliente.nombre.trim();
                cliente.direccion = cliente.direccion.trim();
                cliente.telefono = cliente.telefono.trim();
                cliente.correo = cliente.correo.trim();
              });
              this.hayClientes = true;
            } else {
              this.hayClientes = false;
            }
            this.banderaSpinner = true;
          },
          (err) => {
            this.banderaSpinner = true;
            this.alerta(
              'Error de conexión',
              'Se perdió la conexión con el servidor',
              'error'
            );
            console.log(err);
          }
        );
      }
    } else {
      this.hayClientes = false;
    }
  }

  pintar(event) {
    event.target.setAttribute('style', 'background-color: yellow;');
  }

  blanco(event) {
    event.target.removeAttribute('style');
  }

  //Busca cliente en array de clientes y lo devuelve
  buscarCliente(id) {
    for (let indice in this.clientes) {
      //<-- Itera todos los indices de cliente
      let cliente = this.clientes[indice]; //<-- Toma el i-esimo cliente
      if (cliente.codigo == id) {
        //Compara el id cliente con el id del cliente a buscar
        return cliente;
      }
    }
  }

  //Cerrar modal y enviar la data a factura
  cerrarModal(cliente) {
    this.lanzarEvento(cliente);
    this.modalReferencia.hide(); //<-- Cierra el modal
  }

  lanzarEvento(item) {
    this.event.emit({ data: item, res: 200 });
  }

  alerta(titulo, texto, icono) {
    return Swal.fire({
      icon: icono,
      title: titulo,
      text: texto,
      allowOutsideClick: false,
    });
  }
}
