<!--Cabecera-->
<div class="row justify-content-center align-items-center my-2">
  <div class="col-9 col-md-10 text-center">
    <h3>
      {{ titulo }}
      <small class="text-muted">{{ subtitulo }}</small>
    </h3>
  </div>
  <div
    class="col-2 col-md-1 justify-content-center align-items-center"
    (click)="modalReferencia.hide()"
  >
    <button type="button" class="close pull-right" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>

<!--Botones Superiores para Inciar las Transaaciones-->
<div class="container my-1" [hidden]="ocultarBotones">
  <button
    type="button"
    class="btn btn-outline-success btn-sm btn_est m-2"
    (click)="crearDistribuidor()"
  >
    <i class="far fa-plus-square"></i> Crear
  </button>

  <button
    type="button"
    class="btn btn-outline-warning btn-sm btn_est btn_est m-2"
    (click)="actualizarDistribuidor()"
  >
    <i class="fas fa-spinner"></i> Actualizar
  </button>

  <button
    type="button"
    class="btn btn-outline-danger btn-sm btn_est m-2"
    (click)="confirmaBorrar()"
  >
    <i class="fas fa-times-circle"></i> Eliminar
  </button>
</div>

<!--Datos del Distribuidor-->
<div class="container">
  <div class="row justify-content-center align-items-center">
    <div class="col-11 col-md-3 text-md-right">
      <span>Ruc : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-8">
          <input
            [disabled]="habilitarEstado"
            type="numeric"
            class="form-control form-control-sm"
            placeholder="Ingrese Identificación"
            [(ngModel)]="distribuidor.cedula"
            appOnlynumber
          />
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Nombre : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-8">
          <input
            [disabled]="habilitarEstado"
            type="text"
            class="form-control form-control-sm"
            placeholder="Ingrese el Nombre Completo"
            [(ngModel)]="distribuidor.nombre"
          />
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Dirección : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-8">
          <input
            [disabled]="habilitarEstado"
            type="text"
            class="form-control form-control-sm"
            placeholder="Ingrese el Dirección"
            [(ngModel)]="distribuidor.direccion"
          />
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Teléfono : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-8">
          <input
            [disabled]="habilitarEstado"
            type="numeric"
            class="form-control form-control-sm"
            placeholder="Ingrese el Teléfono"
            [(ngModel)]="distribuidor.telefono"
            appOnlynumber
          />
        </div>
      </div>
    </div>

    <div class="col-11 col-md-3 text-md-right">
      <span>Correo : </span>
    </div>
    <div class="col-11 col-md-8 my-1">
      <div class="row">
        <div class="col-12 col-lg-8">
          <input
            [disabled]="habilitarEstado"
            type="text"
            class="form-control form-control-sm"
            placeholder="Ingrese el Correo Electrónico"
            [(ngModel)]="distribuidor.correo"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<!--Botones Inferiores de Transaccion-->
<div class="container text-right my-1" [hidden]="ocultarBotonesPie">
  <button
    type="button"
    class="btn btn-outline-success btn-sm btn_est m-2"
    (click)="enviarDistribuidor()"
  >
    <i class="fas fa-check-circle"></i> Guardar
  </button>

  <button
    type="button"
    class="btn btn-outline-danger btn-sm btn_est m-2"
    (click)="cancelar()"
  >
    <i class="fas fa-times-circle"></i> Cancelar
  </button>
</div>

<app-spinner></app-spinner>
