import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cambio-clave',
  templateUrl: './cambio-clave.component.html',
  styleUrls: ['./cambio-clave.component.css'],
})
export class CambioClaveComponent implements OnInit {
  usuario: any;
  datosUser: any;
  banderaSpinner: boolean = false;
  empresa: any;
  datosempresa: any;
  inhabilitado: boolean = true;

  claveNueva: string = '';
  claveConfirma: string = '';
  sucursal: string = '';

  constructor(
    private servicio: RestauranteService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.usuario = JSON.parse(localStorage.getItem('usuario')).codigo;
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.datosempresa = JSON.parse(localStorage.getItem('empresa'));
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }

    if (JSON.parse(localStorage.getItem('sucursal')) !== null) {
      this.sucursal = JSON.parse(localStorage.getItem('sucursal'))['codigo'];
    }

    setInterval(() => {
      if (this.banderaSpinner === true) {
        this.spinner.hide();
        this.banderaSpinner = false;
      }
    }, 100);
    this.cargaDatos();
  }

  cargaDatos() {
    this.spinner.show();
    let query1 = `exec [con_configuraciones] 4,'011','${this.usuario}',''`;
    console.log(query1);

    this.servicio.ejecutarQuery(query1).subscribe(
      (result) => {
        console.log(result);
        if (result.length > 0) {
          this.datosUser = result;
        }
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
  }

  cambio() {
    if (this.claveNueva === this.claveConfirma) {
      let campos = {
        tipo: '011',
        codigo: this.usuario,
        sucursal: this.sucursal,
        clave: this.claveNueva,
      };

      this.spinner.show();

      this.servicio.upd_Clave(campos).subscribe(
        (result) => {
          console.log(result);
          if (result.length > 0) {
            this.claveConfirma = '';
            this.claveNueva = '';
            this.alerta(
              'Actualizacion del Usuario',
              'Se cambio la clave correctamente',
              'success'
            );
          }
        },
        (err) => {
          this.banderaSpinner = true;
          this.alerta(
            'Error de conexión',
            'Se perdió la conexión con el servidor',
            'error'
          );
          console.log(err);
        }
      );
    } else {
      this.alerta(
        'Error en datos',
        'Clave nueva y confirmada no son iguales',
        'warning'
      );
    }
  }

  alerta(titulo, texto, icono) {
    return Swal.fire({
      icon: icono,
      title: titulo,
      text: texto,
      allowOutsideClick: false,
    });
  }
}
