<!--CABECERA-->
<div class="container-fluid my-2">
  <div class="row align-items-center">
    <div class="col-12 col-md-4 col-lg-3 col-xl-2" *ngIf="admin">
      <mat-form-field appearance="fill" class="no-padding">
        <mat-label>Sucursal</mat-label>
        <mat-select panelClass="my-select-panel-class" [(value)]="sucursal">
          <mat-option
            *ngFor="let sucursal of sucursales"
            [value]="sucursal.codigo.trim()"
            >{{ sucursal.nombre }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col">
      <h4>
        Compra de Materia Prima
        <small class="text-muted"></small>
      </h4>
    </div>
  </div>
</div>

<!--DATOS DEL DOCUMENTO-->
<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-12 col-md-6">
      <div class="card">
        <div class="card-body">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <button
                type="button"
                class="btn btn-sm btn-outline-success"
                (click)="abrirModalAgregarCliente()"
              >
                <i class="far fa-plus-square"></i>
              </button>
            </div>
            <div class="input-group-prepend">
              <label class="input-group-text" for="tin">Proveedor</label>
            </div>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="RUC | Nombre"
              aria-label="Busqueda"
              aria-describedby="basic-addon1"
              [(ngModel)]="busquedaProveedor"
              (keyup.enter)="buscarProveedores()"
            />

            <div class="input-group-append">
              <button
                type="button"
                class="btn btn-sm btn-outline-primary"
                (click)="buscarProveedores()"
              >
                <i class="fas fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="card my-2" *ngIf="buscoProveedor">
        <div class="card-body">
          <h6 class="card-subtitle mb-2 text-muted">Datos del Proveedor</h6>

          <p class="m-0 p-0"><strong>RUC :</strong> {{ proveedor.ruc }}</p>

          <p class="m-0 p-0">
            <strong>Nombre :</strong> {{ proveedor.nombre }}
          </p>

          <p class="m-0 p-0">
            <strong>Correo :</strong> {{ proveedor.correo }}
          </p>

          <p class="m-0 p-0">
            <strong>Teléfonos :</strong> {{ proveedor.telefono }}
          </p>

          <p class="m-0 p-0">
            <strong>Dirección :</strong> {{ proveedor.direccion }}
          </p>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6" *ngIf="buscoProveedor">
      <div class="card mb-2">
        <div class="card-body">
          <h6 class="card-subtitle mb-2 text-muted">Datos de la Factura</h6>

          <div class="row align-items-center my-1">
            <div class="col-5 col-lg-4 col-xl-3">
              <p class="m-0 p-0">Doc. Compra :</p>
            </div>
            <div class="col-7 col-lg-8 col-xl-5 p-0 d-flex">
              <input
                type="numeric"
                class="form-control form-control-sm mr-2 input-sm"
                [(ngModel)]="serie_doc"
                style="width: 75px"
                placeholder="001-001"
                mask="000-000"
                (focusout)="formatearFacturaNumber()"
              />

              <input
                type="numeric"
                class="form-control form-control-sm mr-2 input-sm"
                [(ngModel)]="numero_doc"
                style="width: 100px"
                maxlength="9"
                mask="0*"
                placeholder="0123456789"
                (focusout)="formatearFacturaNumber()"
              />
            </div>
          </div>

          <div class="row align-items-center my-1">
            <div class="col-12 col-lg-4 col-xl-3">
              <p class="m-0 p-0">Autorización :</p>
            </div>
            <div class="col-12 col-lg-8 col-xl-7 p-0 d-flex">
              <input
                type="numeric"
                class="form-control form-control-sm"
                id="inputAutorizacion"
                [(ngModel)]="autoriza_doc"
                maxlength="49"
                placeholder="012345679 o 49 Digitos"
                mask="0*"
                (focusout)="formatearAutorizaNumber()"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-1" *ngIf="buscoProveedor">
        <div class="card-body">
          <h6 class="card-subtitle mb-2 text-muted">Comentario</h6>
          <div class="input-group">
            <textarea
              class="form-control form-control-sm"
              placeholder="Ingresar Comentario"
              [(ngModel)]="comentario"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- BOTONES DE ACCION-->
<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-md-6">
      <button
        type="button"
        class="btn btn-sm btn-outline-success"
        (click)="abrirModalNuevoProducto()"
        *ngIf="buscoProveedor"
      >
        <i class="far fa-plus-square"></i> Producto
      </button>
    </div>
    <div class="col-12 col-md-6 text-right">
      <button
        type="button"
        class="btn btn-sm btn-outline-danger"
        (click)="grabarInventario()"
        *ngIf="productos.length > 0"
      >
        <i class="fas fa-cloud-upload-alt"></i> Grabar
      </button>
    </div>
  </div>
</div>

<!--MOVIMIENTO DE LA COMPRA-->
<div class="container-fluid mt-2" *ngIf="productos.length > 0">
  <div class="table-responsive">
    <table class="table table-striped table-hover m-0 p-0">
      <thead>
        <tr>
          <th style="width: 110px; text-align: center">#</th>
          <th style="width: 150px; text-align: center">Código</th>
          <th style="width: 250px; text-align: center">Nombre</th>
          <th style="width: 100px; text-align: center">Tarifa</th>
          <th style="width: 150px; text-align: right">Cantidad</th>
          <th style="width: 150px; text-align: center">Medida</th>
          <th style="width: 150px; text-align: right">Costo</th>
          <th style="width: 50px; text-align: right">Descuento</th>
          <th style="width: 150px; text-align: right">Subtotal</th>
          <th
            style="width: 150px; text-align: right"
            [style.background-color]="datosempresa['color']"
            class="text-light"
          >
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let producto of productos
              | paginate
                : {
                    itemsPerPage: 25,
                    currentPage: p,
                    totalItems: productos.count
                  };
            index as i
          "
          id="{{ producto.codigo }}"
        >
          <td class="d-flex" style="justify-content: space-around">
            <button
              type="button"
              class="btn btn-outline-danger btn-sm"
              (click)="borrarItem(i)"
            >
              <i class="far fa-trash-alt"></i>
            </button>

            <button
              type="button"
              class="btn btn-outline-success btn-sm"
              (click)="modalFecha(i)"
            >
              <i class="far fa-calendar-plus"></i>
            </button>
          </td>
          <td style="text-align: center; max-height: 40px">
            <p class="m-0 p-0">
              {{ producto.codigo }}
            </p>
          </td>
          <td>
            <p class="m-0 p-0">
              {{ producto.nombre }}
            </p>
          </td>
          <td>
            <select
              class="custom-select custom-select-sm"
              name="um"
              id="um"
              [(ngModel)]="producto.tarifa"
              (change)="calcularTotal(producto)"
            >
              <!--<option *ngFor="let datoTin of datosTin" [ngValue]="datoTin">{{datoTin.nombre}}</option>    -->
              <option *ngFor="let tarif of tarifas" value="{{ tarif.codigo }}">
                {{ tarif.nombre }}
              </option>
            </select>
          </td>
          <td>
            <input
              type="numeric"
              class="form-control form-control-sm text-right"
              [(ngModel)]="producto.cantidad"
              (keydown)="limitarDecimales($event, 4, producto.cantidad)"
              (focusin)="limpiarInput(producto, 'cantidad')"
              (focusout)="verificarInput(producto, 'cantidad', i)"
            />
          </td>
          <td>
            <div class="input-group" style="width: 120px">
              <select
                class="custom-select custom-select-sm"
                name="um"
                id="um"
                [(ngModel)]="producto.umEscogida"
                (input)="calcularTotal(i)"
              >
                <!--<option *ngFor="let datoTin of datosTin" [ngValue]="datoTin">{{datoTin.nombre}}</option>    -->
                <option
                  *ngFor="let datoUm of datosUm"
                  value="{{ datoUm.codigo }}"
                >
                  {{ datoUm.nombre }}
                </option>
              </select>
            </div>
          </td>
          <td>
            <input
              type="numeric"
              class="form-control form-control-sm text-right"
              [(ngModel)]="producto.costo"
              (keydown)="limitarDecimales($event, 4, producto.costo)"
              (focusin)="limpiarInput(producto, 'costo')"
              (focusout)="verificarInput(producto, 'costo', i)"
            />
          </td>

          <td>
            <input
              type="numeric"
              class="form-control form-control-sm text-right"
              appOnlynumber
              [(ngModel)]="producto.descuento"
              (keydown)="limitarDecimales($event, 2, producto.descuento)"
              (focusin)="limpiarInput(producto, 'descuento')"
              (focusout)="verificarInput(producto, 'descuento', i)"
            />
          </td>
          <td>
            <p style="text-align: right; font-size: 1.2rem" class="m-0 p-0">
              $ {{ producto.subtotal.toFixed(2) }}
            </p>
          </td>

          <td
            class="text-light"
            [style.background-color]="datosempresa['color']"
          >
            <p style="text-align: right; font-size: 1.2rem" class="m-0 p-0">
              <strong>$ {{ producto.total.toFixed(2) }}</strong>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<!--Pie de Pagina SUMATORIAS-->
<div class="container-fluid pb-3" *ngIf="productos.length > 0">
  <div class="row">
    <div class="col"></div>
    <div class="col-md-6 col-lg-4 col-xl-3">
      <table class="table table-clear m-0 p-0">
        <tbody>
          <tr>
            <td class="m-0 py-1">
              <strong class="text-dark">Sumatoria</strong>
            </td>
            <td class="text-right m-0 py-1">
              {{ sumatoria.toFixed(2) }}
            </td>
          </tr>
          <tr>
            <td class="m-0 py-1">
              <strong class="text-dark">Descuento</strong>
            </td>
            <td class="text-right m-0 py-1">
              {{ descuento.toFixed(2) }}
            </td>
          </tr>
          <tr>
            <td class="m-0 py-1">
              <strong class="text-dark">Tarifa 0%</strong>
            </td>
            <td class="text-right m-0 py-1">
              {{ tarifa0.toFixed(2) }}
            </td>
          </tr>
          <tr>
            <td class="m-0 py-1">
              <strong class="text-dark">Tarifa 12%</strong>
            </td>
            <td class="text-right m-0 py-1">
              {{ tarifa12.toFixed(2) }}
            </td>
          </tr>
          <tr>
            <td class="m-0 py-1">
              <strong class="text-dark">Subtotal</strong>
            </td>
            <td class="text-right m-0 py-1">
              {{ subtotal.toFixed(2) }}
            </td>
          </tr>

          <tr>
            <td class="m-0 py-1">
              <strong class="text-dark">Impuestos</strong>
            </td>
            <td class="text-right m-0 py-1">
              {{ impuesto.toFixed(2) }}
            </td>
          </tr>

          <tr [style.background-color]="datosempresa['color']">
            <td class="text-light">
              <strong class="text-dark m-0 p-0" style="color: white !important"
                >Total</strong
              >
            </td>
            <td class="text-right">
              <strong class="text-dark m-0 p-0" style="color: white !important"
                >$ {{ total.toFixed(2) }}</strong
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<app-spinner></app-spinner>
