<div class="mt-2">
  <div class="container-fluid mb-3">
    <div class="row justify-content-center align-items-center">
      <div class="col-5 col-md-3 col-xl-1">
        <button
          type="button"
          class="btn btn-sm btn-outline-success"
          (click)="abrirModalAgregarConfiguracion()"
        >
          <i class="far fa-plus-square"></i> Nueva Configuración
        </button>
      </div>

      <div
        class="col-11 col-md-8 col-xl-8 text-center text-md-left text-xl-center"
      >
        <h3>
          Configuraciones
          <small class="text-muted"> Generales</small>
        </h3>
      </div>

      <div class="col-11 col-md-8 col-xl-3 text-center text-md-left">
        <div class="input-group input-group-sm">
          <input
            type="text"
            class="form-control form-control-sm"
            placeholder="Buscar Nombre | Codigo"
            aria-label="Busqueda"
            aria-describedby="basic-addon1"
            (keyup)="busquedaItem()"
            [(ngModel)]="comentario"
          />
          <div class="input-group-append">
            <span class="input-group-text" id="basic-addon1"
              ><i class="fab fa-searchengin"></i
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!---->
<div class="container-fluid">
  <div class="row justify-content-center align-items-center mb-3">
    <div
      class="col-11 col-md-8 col-xl-5"
      style="width: 50px; text-align: center"
    >
      <div class="card mb-1">
        <div class="card-body">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <label class="input-group-text" for="tin">Tipo : </label>
            </div>

            <select
              class="custom-select custom-select-sm"
              name="tco"
              [(ngModel)]="tcoEscogido"
              id="tco"
              (change)="actualizaTcoEscogido()"
            >
              <option *ngFor="let datoTco of datosTco" [ngValue]="datoTco">
                {{ datoTco.nombre }}
              </option>
            </select>

            <div class="input-group-append">
              <label class="input-group-text" for="tin"
                ><i class="fab fa-audible"></i
              ></label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="table-responsive" *ngIf="hayItem === true; else sinItems">
    <table class="table table-striped table-sm table-hover m-0 p-0">
      <thead>
        <tr>
          <th scope="col" style="width: 50px; text-align: center">#</th>
          <th scope="col" style="width: 80px">Código</th>
          <th scope="col">Nombre</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let item of items
              | paginate
                : {
                    itemsPerPage: 25,
                    currentPage: p,
                    totalItems: items.count
                  };
            index as i
          "
          class="boton"
        >
          <td>
            <button
              type="button"
              class="btn btn-outline-dark btn-sm"
              (click)="abrirModalOpciones(item)"
            >
              <i class="far fa-eye"></i>
            </button>
          </td>
          <td (click)="cargarConfiguracion(i)">{{ item.codigo }}</td>
          <td (click)="cargarConfiguracion(i)">{{ item.nombre }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="container-fluid">
  <div class="row justify-content-center align-items-center mt-4 mb-3">
    <div class="col-12" style="text-align: center">
      <pagination-controls
        (pageChange)="p = $event"
        autoHide="true"
        previousLabel="Anterior"
        nextLabel="Siguiente"
      >
      </pagination-controls>
    </div>
  </div>
</div>

<ng-template #sinItems>No hay resultados.</ng-template>
<app-spinner></app-spinner>
