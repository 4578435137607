declare var require: any; //<-- Para poder importar archivos JS en TS
import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import Swal from 'sweetalert2';
var validadorCedula = require('src/app/validadores/validarCedula.js');
@Component({
  selector: 'app-modal-configuracion',
  templateUrl: './modal-configuracion.component.html',
  styleUrls: ['./modal-configuracion.component.css'],
})
export class ModalConfiguracionComponent implements OnInit {
  datos: Object; //<-- Debe tener el mismo nombre de la variable en configuracion
  item: any = {}; //<-- Aqui se guardan los datos traidos desde configuracion

  habilitarEstado: boolean = true; //<-- Si es true en la template se deshabilitan los inputs, interpola de aqui hacia template []
  operacion: Number; //<--Indica si es 1 actualizar o 2 borrar
  public event: EventEmitter<any> = new EventEmitter(); //<-- Para devolver datos a configuracion
  titulo: String = 'Detalle : ';
  ocultarBotones = false;
  ocultarBotonesPie = true;

  nombre: string = '';
  sucursales: any;
  sucursal: string = '';
  perfiles: any;
  perfil: string = '';
  clave: string = '';
  admin: boolean = false;

  vigencia: number;
  depreciacion: any = [0, 0];
  quincena: any = [0, 0];
  finm: any = [0, 0];
  unidades: any;
  empresa: any;
  constructor(
    public modalReferencia: BsModalRef,
    private servicio: RestauranteService
  ) {}

  ngOnInit(): void {
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
    this.item = this.datos[0];

    //this.item.quincena_str = this.item.quincena.toFixed('2').split('.');
    //this.item.fin_mes = this.item.fin_mes.toFixed('2').split('.');

    if (this.item.fecha_ing)
      this.item.fecha_ingreso = this.formatearFechaSelector(
        this.item.fecha_ing
      );
    if (this.item.porc) {
      let arr = this.item.porc.toFixed(2).split('.');
      arr[0] = parseInt(arr[0]);
      arr[1] = parseInt(arr[1]);
      this.depreciacion = arr;
    }

    console.log('DATOS TRAIDOS DESDE CONFIGURACION', this.item);
    console.log(this.item);
    //if (this.item.codigoTco.trim() == '023') 
    this.cargarLibras();
    //else 
    this.cargarSucursales();
  }

  //Activo la operacion de actualizar
  actualizarConfiguracion() {
    this.habilitarEstado = false;
    this.operacion = 1;
    this.titulo = 'Actualizar : ';
    this.ocultarBotones = true;
    this.ocultarBotonesPie = false;
  }

  confirmaBorrar() {
    Swal.fire({
      icon: 'error',
      title: '¿Deseas eliminar?',
      text: '' + this.item.nombre + ' de ' + this.item.nombreTco,
      showDenyButton: true,
      confirmButtonText: `Sí`,
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.operacion = 2;
        Swal.fire({
          icon: 'success',
          title: 'Eliminaste ' + this.item.nombre,
          text: ' de ' + this.item.nombreTco,
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          this.cerrarModal(this.operacion); //<-- 2 significa eliminar
        });
      }
    });
  }

  verificarEmpleado() {
    console.log('verificarEmpleado', this.item);

    if (this.item.ruc.length != 10)
      return 'El campo cédula debe tener 10  digitos.';

    if (validadorCedula.validar_CED(this.item.ruc) === 0)
      return 'Por favor, ingrese una cédula válida.';

    if (this.item.direccion == '') return 'Por favor, ingrese una direccion';
    if (this.item.telefono == '') return 'Por favor ingrese el telefono';
    if (this.item.correo == '') return 'Por favor ingrese el correo';

    if (this.item.quincena[0] === '' || this.item.quincena[1] === '') {
      return 'Ingrese una quincena';
    }
    let valor1 = parseFloat(this.item.quincena.join('.'));
    if (valor1 == 0) return 'Ingrese una quincena mayor a 0';

    if (this.item.findemes[0] === '' || this.item.findemes[1] === '') {
      return 'Ingrese un fin de mes ';
    }
    let valor2 = parseFloat(this.item.findemes.join('.'));
    if (valor2 == 0) return 'Ingrese un fin de mes mayor a 0';
    if (this.item.fecha_ingreso == '') return 'Ingrese una fecha de ingreso';

    if (this.item.clave == '' || this.item.clave === 'undefined')
      return 'Por favor ingrese una clave';
    if (this.item.perfil == '' || this.item.perfil === 'undefined')
      return 'Por favor Seleccione el Perfil Asignado';
    if (this.item.sucursal == '' || this.item.sucursal === 'undefined')
      return 'Por favor ingrese una sucursal';

    return 'OK';
  }

  verificarUsuario() {
    if (this.item.clave == '' || this.item.clave === 'undefined')
      return 'Por favor ingrese una clave';
    if (this.item.perfil == '' || this.item.perfil === 'undefined')
      return 'Por favor Seleccione el Perfil Asignado';
    if (this.item.sucursal == '' || this.item.sucursal === 'undefined')
      return 'Por favor ingrese una sucursal';

    return 'OK';
  }

  //Guarda el item actualizado
  enviarItem() {
    let nombre = this.item.nombre;
    this.item.porc = parseFloat(
      this.depreciacion[0] + '.' + this.depreciacion[1]
    );
    if (nombre == '') {
      Swal.fire('¡Error!', 'Por favor, llene todos los campos', 'error');
      return;
    }
    //this.item.clave = this.clave;
    this.item.empresa = this.empresa;
    //this.item.sucursal = this.sucursal;
    //this.item.perfil = this.perfil;

    if (this.item.codigoTco.trim() === '011') {
      let respuesta = this.verificarEmpleado();
      if (respuesta != 'OK') {
        Swal.fire('¡Espere!', respuesta, 'warning');
        return;
      }
    }
    /* SOLO APLICA CUANDO LOS EMPLEADOS NO SON USUARIOS
    if (this.item.codigo.trim() == '005') {
      let respuesta = this.verificarUsuario();
      if (respuesta != 'OK') {
        Swal.fire('¡Espere!', respuesta, 'warning');
        return;
      }
    }
    */

    if (this.item.quincena_str)
      this.item.quincena = this.item.quincena_str.join('.');
    if (this.item.fin_mes) this.item.fin_mes = this.item.fin_mes.join('.');

    if (this.operacion === 1) {
      this.item.nombre = nombre;
    }
    Swal.fire({
      icon: 'success',
      title: '¡Éxito, Agregamos!',
      text: '' + this.item.nombre + ' en ' + this.item.nombreTco,
      showConfirmButton: false,
      timer: 2000,
    }).then(() => {
      this.cerrarModal(this.operacion); //<-- 1 significa actualizar
    });
  }

  cerrarModal(valor) {
    this.lanzarEvento(valor, this.item); //<-- Envia la data a configuracion
    this.modalReferencia.hide(); //<-- Cierra el modal
  }

  lanzarEvento(valor, item) {
    this.event.emit({ data: [valor, item], res: 200 });
  }

  cancelar() {
    this.titulo = 'Detalle : ';
    this.ocultarBotonesPie = true;
    this.ocultarBotones = false;
    this.habilitarEstado = true;
  }

  cargarSucursales() {
    this.servicio
      .getConfiguraciones('015', this.empresa)
      .subscribe((result) => {
        console.log(result);
        if (result.length > 0) {
          this.sucursal = result[0].codigo.trim();
          this.sucursales = result;
        }
      });
    this.servicio
      .getConfiguraciones('020', this.empresa)
      .subscribe((result) => {
        console.log(result);
        if (result.length > 0) {
          this.perfil = result[0].codigo.trim();
          this.perfiles = result;
        }
      });
  }

  formatearFechaString(fecha = new Date(), hora = false) {
    //new Date().toJSON().slice(0,10).split('-').reverse().join('/')
    let fechaFin = new Date(fecha);
    let fechaString = fechaFin
      .toJSON()
      .slice(0, 10)
      .split('-')
      .reverse()
      .join('/');
    if (hora) {
      //Formatear hora
      return fechaString + ' ' + fechaFin.toJSON().slice(11, 19);
    }
    return fechaString;
  }

  formatearFechaSelector(fecha = new Date()) {
    let fechaFin = new Date(fecha);
    let fechaString = fechaFin.toJSON().slice(0, 10);

    return fechaString;
  }

  cargarLibras() {
    this.servicio
      .getConfiguraciones('008', this.empresa)
      .subscribe((result) => {
        console.log(result);
        if (result.length > 0) {
          this.perfil = result[0].codigo.trim();
          this.sucursal = result[0].codigo.trim();
          this.unidades = result;
        }
      });
  }
}
