<div class="row justify-content-center align-items-center my-2">
  <div class="col-9 col-md-10 text-center">
    <h3>
      Nuevo Producto
      <small class="text-muted"></small>
    </h3>
  </div>
  <div
    class="col-2 col-md-1 justify-content-center align-items-center"
    (click)="modalReferenciaLineas.hide()"
  >
    <button type="button" class="close pull-right" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-10 col-sm-6 col-xl-3 p-1" *ngFor="let linea of lineas">
    <button
      type="button"
      class="btn btn-outline-dark my-1 text-center font-weight-bold"
      style="height: 90px; width: 90%"
      (click)="abrirModalItems(linea)"
    >
      {{ linea.nombre }}
    </button>
  </div>
</div>
