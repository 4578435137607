import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { ModalMateriaPrimaComponent } from '../modal-materia-prima/modal-materia-prima.component';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-receta',
  templateUrl: './modal-receta.component.html',
  styleUrls: ['./modal-receta.component.css'],
})
export class ModalRecetaComponent implements OnInit {
  datos: Object[]; //<-- Debe tener el mismo nombre de la variable en producto
  producto: any = {}; //<-- Aqui se guardan los datos traidos desde producto
  items: any = []; //<--Productos que se van a pintar aqui desde la DB
  datosUm: any = []; //<--Aqui se guardan las unidades de medida traidas desde producto
  costoReceta: number = 0;
  //Bandera para spinner
  banderaSpinner: boolean = false;
  cantidad_base: string = '0.00';

  //modalReferencia se comunica con producto
  //modalReferencia2 y modalServicio se comunican con modal materia prima
  //Constructor siempre se carga antes del OnInit
  constructor(
    public modalReferencia: BsModalRef,
    private modalServicio: BsModalService,
    private servicio: RestauranteService,
    private modalReferencia2: BsModalRef,
    private spinner: NgxSpinnerService
  ) {}

  //OnInit siempre se carga despues del Constructor
  ngOnInit(): void {
    console.log(this.datos);
    this.producto = this.datos[0];
    this.datosUm = this.datos[1];
    console.log(
      'MODAL ABIERTO, DATOS OBTENIDOS DESDE PRODUCTO: ',
      this.producto
    );
    this.spinner.show();
    //Cargo datos en la temporal
    this.servicio
      .buscarReceta(this.producto.codigo, 'TODOS')
      .subscribe((data) => {
        data.forEach((receta) => {
          receta.porcentaje_limpio = 0.0;
          receta.porcentaje_merma = 0.0;
          receta.porcentaje_crudo = 100.0;

          receta.unidad_limpio = 0.0;
          receta.unidad_merma = 0.0;
          receta.unidad_crudo = 0.0;

          receta.costo_limpio = 0.0;
          receta.costo_merma = 0.0;
          receta.costo_crudo = 0.0;

          receta.mostrar = false;
          //receta.opcional = false;
        });

        this.items = data;
        for (let i = 0; i < this.items.length; i++) {
          this.calculos(i);
        }
        console.log(this.items);
        /*if (data.length > 0) {
        //Actualizamos la temporal
        data.forEach((receta) => {
          let cantidadTexto = receta.cantidad.toString().split('.');
          let listaAuxiliar = this.datosUm.slice(); //<--Lista de la combo para cada producto
          for (let i = 0; i < listaAuxiliar.length; i++) {
            if (listaAuxiliar[i].codigo === receta.unidad.trim()) {
              const item = listaAuxiliar[i];
              listaAuxiliar.splice(i, 1);
              let nuevaLista = [item];
              listaAuxiliar.forEach((item) => {
                nuevaLista.push(item);
              });
              listaAuxiliar = nuevaLista;
              break;
            }
          }
          receta.cod_receta = receta.cod_receta;
          receta.nombre = receta.nombre.trim();
          receta.umEscogida = receta.unidad.trim();
          receta.umLista = listaAuxiliar;

          receta.cantidad_base = receta.cantidad.toFixed(2);
          receta.porcentaje_utilizable = receta.porc_util.toFixed(2);
          receta.costo_base = receta.costo_base.toFixed(6);
          receta.cantidad_desperdicio = 0;
          receta.cantidad_final = 0;
          receta.porcentaje_perdida = 0;
          receta.costo_desperdicio = 0;
          receta.costo_final = 0;
          receta.costo_receta = 0;

          console.log(receta);
          this.items.push(receta);
        });
        for (let i = 0; i < data.length; i++) {
          this.calculos(i);
        }
        this.calcularTotal();
      }*/
        this.banderaSpinner = true;
      });
    //Rastreador de bandera spinner
    setInterval(() => {
      if (this.banderaSpinner === true) {
        this.spinner.hide();
        this.banderaSpinner = false;
      }
    }, 100);
  }

  agregar() {
    this.abrirModalMateriaPrima();
  }

  //Abre el modal materia prima
  abrirModalMateriaPrima() {
    //Este modal no envia datos
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferencia2 = this.modalServicio.show(
      ModalMateriaPrimaComponent,
      {
        ignoreBackdropClick: true,
        class: 'modal-lg',
      }
    );
    //Al cerrar el modal materia prima recibe la data
    this.modalReferencia2.content.event.subscribe((res) => {
      console.log(
        'MODAL MATERIA PRIMA CERRADO, DATA DEL PRODUCTO RECIBIDA: ',
        res.data
      );
      res.data.cod_receta = res.data.codigo;
      res.data.grupo = res.data.grupo.trim();
      res.data.linea = res.data.linea.trim();
      res.data.nomgru = res.data.nomgru.trim();
      res.data.nomlin = res.data.nomlin.trim();
      res.data.porcentaje_limpio = 0.0;
      res.data.merma = 0.0;
      res.data.porcentaje_crudo = 100.0;

      res.data.cantidad = 0.0;
      res.data.unidad_merma = 0.0;
      res.data.unidad_crudo = 0.0;

      res.data.costo_limpio = 0.0;
      res.data.costo_merma = 0.0;
      res.data.costo_crudo = 0.0;
      res.data.costo_unitario = res.data.precio;

      res.data.mostrar = false;
      res.data.opcional = false;

      res.data.umEscogida = this.datosUm[0].codigo; //<--Um escogida para este producto
      res.data.umLista = this.datosUm.slice(); //<--Lista de la combo para cada producto

      //Guardando la materia prima en temporal
      if (this.items.length === 0) {
        this.items.push(res.data);
        console.log(this.items);
      } else {
        let control = false;

        for (let i = 0; i < this.items.length; i++) {
          if (parseInt(this.items[i].cod_receta) === res.data.cod_receta) {
            control = true;
            Swal.fire({
              icon: 'error',
              title: 'Ingrediente : ' + this.items[i].nombre,
              text: ' YA! esta en la receta',
              allowOutsideClick: false,
            }).then(() => {
              return;
            });
          }
        }
        if (control === false) {
          this.items.push(res.data);
        }
      }
    });
  }

  actualizaCantidadEntero(event, idActual) {
    //Actualiza en la temporal
    this.items.forEach((producto) => {
      if (parseInt(producto.cod_receta) === parseInt(idActual)) {
        if (event.target.value.length === 0) {
          producto.cantidadEntero = '0';
        } else {
          producto.cantidadEntero = event.target.value;
        }
      }
    });
  }

  actualizaCantidadDecimal(event, idActual) {
    //Actualiza en la temporal
    this.items.forEach((producto) => {
      if (parseInt(producto.cod_receta) === parseInt(idActual)) {
        if (event.target.value.length === 0) {
          producto.cantidadDecimal = '0';
        } else {
          producto.cantidadDecimal = event.target.value;
        }
      }
    });
  }

  //Actualiza la um para el producto
  actualizaUm(event, idActual) {
    let id = idActual; //<--Recupero el id del producto
    let codigoUm = event.target.value; //<--Codigo de la um
    //Actualizo la um en el temporal
    this.items.forEach((producto) => {
      if (parseInt(producto.cod_receta) === parseInt(id)) {
        producto.umEscogida = codigoUm;
        for (let i = 0; i < producto.umLista.length; i++) {
          if (producto.umLista[i].codigo === codigoUm) {
            const item = producto.umLista[i];
            producto.umLista.splice(i, 1);
            let nuevaLista = [item];
            producto.umLista.forEach((item) => {
              nuevaLista.push(item);
            });
            producto.umLista = nuevaLista;
            break;
          }
        }
      }
    });
  }

  grabar() {
    let datos = [];
    this.items.forEach((producto) => {
      datos.push({
        receta: this.producto.codigo,
        codigo: producto.cod_receta,
        cantidad: producto.cantidad,
        merma: producto.merma,
        opcional: producto.opcional,
      });
    });
    console.log('Items => ');
    console.log(this.items);
    let campos = {
      productos: datos,
      id: this.producto.codigo,
    };
    console.log('Campos => ');
    console.log(campos), this.spinner.show();
    this.servicio.guardarReceta(campos).subscribe((data) => {
      console.log(data);
      if (data.data == 'exito') {
        this.banderaSpinner = true;
        Swal.fire({
          icon: 'success',
          title: '¡Operación exitosa!',
          text: 'La receta se ha registrado exitosamente',
        });
        this.items = [];
        this.modalReferencia.hide();
      } else {
        this.banderaSpinner = true;
        Swal.fire({
          icon: 'error',
          title: 'Operación fallida.',
          text: 'Ha ocurrido un error inesperado.',
        });
      }
    });
  }

  borrarItem(idActual) {
    let indiceItem; //<--Indice del item a borrar
    for (let i = 0; i < this.items.length; i++) {
      if (parseInt(this.items[i].cod_receta) === parseInt(idActual)) {
        indiceItem = i;
        break;
      }
    }
    Swal.fire({
      title: '¿Desea quitar',
      text:
        ' el ingrediente ' + this.items[indiceItem].nombre + ' de la receta?',
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: `Sí`,
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        this.servicio
          .eliminarReceta(this.producto.codigo, idActual)
          .subscribe((data) => {
            if (data.data === 'Dato eliminado exitosamente') {
              Swal.fire({
                icon: 'success',
                title: 'El ingrediente ' + this.items[indiceItem].nombre,
                text:
                  'ha sido eliminado de la receta ' +
                  this.producto.nombre +
                  ' correctamente.',
                allowOutsideClick: false,
              }).then(() => {
                this.items.splice(indiceItem, 1);
              });
            }
            this.banderaSpinner = true;
          });
      }
    });
  }

  borrarTodaReceta() {
    Swal.fire({
      title: '¿Desea eliminar toda esta receta?',
      showDenyButton: true,
      confirmButtonText: `Sí`,
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        this.servicio
          .eliminarTodaReceta(this.producto.codigo)
          .subscribe((data) => {
            if (data.data === 'Dato eliminado exitosamente') {
              this.items = [];
              Swal.fire({
                icon: 'success',
                title: 'Eliminación exitosa',
                text: 'La receta se ha eliminado correctamente.',
              });
            }
            this.banderaSpinner = true;
          });
      }
    });
  }

  //Cerrar modal y enviar la data a factura
  cerrarModal() {
    this.modalReferencia.hide(); //<-- Cierra el modal
  }

  /*validarDecimal(decimales, i, campo) {
    let reg;
    switch (decimales) {
      case 2:
        reg = /^\d+\.\d{0,2}$/;
        break;
      case 4:
        reg = /^\d+\.\d{0,4}$/;
        break;
      case 6:
        reg = /^\d+\.\d{0,6}$/;
        break;
    }
    console.log(reg);
    console.log(this.items[i][campo]);
    if (!reg.test(this.items[i][campo])) {
      this.items[i][campo] = '0.' + '0'.repeat(decimales);
    }

    this.calculos(i);
    this.calcularTotal();
  }
*/
  calculos(i) {
    this.items[i].porcentaje_limpio =
      this.items[i].porcentaje_crudo - this.items[i].merma;

    this.items[i].unidad_crudo =
      (this.items[i].cantidad * this.items[i].porcentaje_crudo) /
      this.items[i].porcentaje_limpio;

    this.items[i].unidad_merma =
      this.items[i].unidad_crudo - this.items[i].cantidad;

    this.items[i].costo_limpio =
      this.items[i].cantidad * this.items[i].costo_unitario;

    this.items[i].costo_crudo =
      (this.items[i].costo_limpio * this.items[i].porcentaje_crudo) /
      this.items[i].porcentaje_limpio;

    this.items[i].costo_merma =
      this.items[i].costo_crudo - this.items[i].costo_limpio;
  }

  /*
  calculos(i) {
    this.items[i].cantidad_final =
      (parseFloat(this.items[i].cantidad_base) *
        parseFloat(this.items[i].porcentaje_utilizable)) /
      100;

    this.items[i].cantidad_desperdicio =
      parseFloat(this.items[i].cantidad_base) - this.items[i].cantidad_final;

    this.items[i].porcentaje_perdida =
      100 - parseFloat(this.items[i].porcentaje_utilizable);

    this.items[i].costo_final =
      (parseFloat(this.items[i].costo_base) /
        this.items[i].porcentaje_utilizable) *
      100;

    this.items[i].costo_receta =
      parseFloat(this.items[i].cantidad_base) * this.items[i].costo_final;

    this.items[i].costo_receta = parseFloat(
      this.items[i].costo_receta.toFixed(2)
    );
  }
*/
  calcularTotal() {
    let totalCosto = 0;
    this.items.forEach((element) => {
      totalCosto += element.costo_receta;
    });
    this.costoReceta = totalCosto;
  }

  verDetalle(index) {
    this.items[index].mostrar = !this.items[index].mostrar;
  }
}
