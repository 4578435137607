import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-modal-materia-prima',
  templateUrl: './modal-materia-prima.component.html',
  styleUrls: ['./modal-materia-prima.component.css'],
})
export class ModalMateriaPrimaComponent implements OnInit {
  hayProductos: boolean = false; //<--Valida si existe almenos un producto
  productos: any = []; //<-- Productos traidos desde DB
  producto: any; //<-- Producto escogido
  //Bandera para spinner
  banderaSpinner: boolean = false;
  public event: EventEmitter<any> = new EventEmitter(); //<-- Para devolver datos a inventario
  empresa: string = '';

  constructor(
    public modalReferencia: BsModalRef,
    private service: RestauranteService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
    //Verifica bandera para ocultar spinner
    setInterval(() => {
      if (this.banderaSpinner === true) {
        this.spinner.hide();
        this.banderaSpinner = false;
      }
    }, 100);
  }

  traerMateriasPrimas(event) {
    let texto = event.target.value; //<--Obtenemos el texto
    if (texto != '') {
      //<-- Si no está vacío coge el texto y busca
      if (texto.length > 3) {
        this.spinner.show();
        let campos = {
          opcion: 4,
          texto: texto.trim(),
          usuario: JSON.parse(localStorage.getItem('usuario')).codigo,
          empresa: this.empresa,
        };

        this.service.obtenerItemsPorTexto(campos).subscribe((data) => {
          if (data.length > 0) {
            this.productos = data;
            this.productos.forEach((producto) => {
              producto.nombre = producto.nombre.trim();
            });
            this.hayProductos = true;
          } else {
            this.hayProductos = false;
          }
          this.banderaSpinner = true;
        });
      }
    } else {
      this.hayProductos = false;
    }
  }

  pintar(event) {
    event.target.setAttribute('style', 'background-color: yellow;');
  }

  blanco(event) {
    event.target.removeAttribute('style');
  }

  //Busca producto en array de productos y lo devuelve
  buscarProducto(id) {
    for (let indice in this.productos) {
      //<-- Itera todos los indices de producto
      let producto = this.productos[indice]; //<-- Toma el i-esimo producto
      if (producto.codigo == id) {
        //Compara el id producto con el id del producto a buscar
        return producto;
      }
    }
  }

  //Cerrar modal y enviar la data a factura
  cerrarModal(event) {
    this.producto = this.buscarProducto(event.target.id);
    this.lanzarEvento(this.producto);
    this.modalReferencia.hide(); //<-- Cierra el modal
  }

  lanzarEvento(item) {
    this.event.emit({ data: item, res: 200 });
  }
}
