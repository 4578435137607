import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';

@Component({
  selector: 'app-modal-listado-cuentas',
  templateUrl: './modal-listado-cuentas.component.html',
  styleUrls: ['./modal-listado-cuentas.component.css'],
})
export class ModalListadoCuentasComponent implements OnInit {
  datos: Object;
  cuentas: any = []; //<-- cuentas traidos desde DB
  haycuentas: boolean = false; //<--Valida si existe almenos un cliente

  public event: EventEmitter<any> = new EventEmitter(); //<-- Para devolver datos a cliente
  //Bandera para spinner
  banderaSpinner: boolean = false;
  buscador_cuenta = '';
  empresa: string = '';
  datosempresa: any;

  constructor(
    public modalReferencia: BsModalRef,
    public servicio: RestauranteService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    //REVISAR SI NO AFECTO
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.datosempresa = JSON.parse(localStorage.getItem('empresa'));
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }

    this.buscador_cuenta = this.datos[0];
    this.traercuentas();
    //Verifica bandera para ocultar spinner
    setInterval(() => {
      if (this.banderaSpinner === true) {
        this.spinner.hide();
        this.banderaSpinner = false;
      }
    }, 100);
  }

  traercuentas() {
    //let texto = event.target.value //<--Obtenemos el texto
    let texto = this.buscador_cuenta;
    console.log(texto);
    if (texto != '') {
      //<-- Si no está vacío coge el texto y busca
      if (texto.length > 0) {
        this.spinner.show();
        let campos = {
          texto: texto,
          usuario: JSON.parse(localStorage.getItem('usuario')).codigo,
          empresa: this.empresa,
        };
        this.servicio.buscarCuenta(campos).subscribe((data) => {
          if (data.length > 0) {
            this.cuentas = data;
            console.log(data);
            this.haycuentas = true;
          } else {
            this.haycuentas = false;
          }
          this.banderaSpinner = true;
        });
      }
    } else {
      this.haycuentas = false;
    }
  }

  pintar(event) {
    event.target.setAttribute('style', 'background-color: yellow;');
  }

  blanco(event) {
    event.target.removeAttribute('style');
  }

  //Busca cuenta en array de cuentas y lo devuelve
  buscarcuenta(id) {
    for (let indice in this.cuentas) {
      //<-- Itera todos los indices de cliente
      let cliente = this.cuentas[indice]; //<-- Toma el i-esimo cliente
      if (cliente.codigo == id) {
        //Compara el id cliente con el id del cliente a buscar
        return cliente;
      }
    }
  }

  //Cerrar modal y enviar la data a factura
  cerrarModal(event) {
    let cuenta = this.buscarcuenta(event.target.id);
    this.lanzarEvento(cuenta);
    this.modalReferencia.hide(); //<-- Cierra el modal
  }

  lanzarEvento(item) {
    this.event.emit({ data: item, res: 200 });
  }
}
