import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalListadoProductosComponent } from '../../modal-listado-productos/modal-listado-productos.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-informe-dinamico',
  templateUrl: './informe-dinamico.component.html',
  styleUrls: ['./informe-dinamico.component.css'],
})
export class InformeDinamicoComponent implements OnInit {
  campaignOne: FormGroup;
  //Referencia del modal
  modalReferencia: BsModalRef;
  //Datos traidos de la DB
  clientes: any = [];
  //Verifica que hay cliente
  hayCliente: boolean;
  //Variable de evento de pagina
  p: Number = 1;
  //Bandera para spinner
  banderaSpinner: boolean = false;
  //Bandera para saber si son datos filtrados o todos
  banderaClientes: boolean; //<-- true todos y false filtro
  busco_producto = false;
  producto = '';
  producto_codigo = '';
  productos: any = [];
  totales: any = {
    label: 'TOTALES',
    cantidad: 0,
    precio: 0.0,
    subtotal: 0.0,
    impuesto: 0.0,
    total: 0.0,
    acumulado: 0.0,
  };
  tinEscogido: any;
  invEscogido: any;

  sucursales: any;
  sucursal: string = 'TODOS';

  admin: boolean = false;

  cabecera: any;
  movimiento: any;
  regmov: number = 0;

  opcion: string = '';
  opciones: any;

  cliente: any = {
    ruc: '',
  };
  rucConsumidorFinal: any = '9999999999999';
  empresa: string = '';
  datosempresa: any;
  datosTin: any;
  datosInv: any;

  constructor(
    private router: Router,
    private servicio: RestauranteService,
    private modalServicio: BsModalService,
    private spinner: NgxSpinnerService
  ) {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    const day = today.getDate();
    console.log(today);

    this.campaignOne = new FormGroup({
      //start: new FormControl(new Date(year, month, 1)),
      start: new FormControl(new Date(year, month, day)),
      end: new FormControl(new Date(year, month, day)),
    });
  }

  ngOnInit(): void {
    this.admin = JSON.parse(localStorage.getItem('usuario')).admin;
    this.cargarINV();
    this.cargarTIN();

    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.datosempresa = JSON.parse(localStorage.getItem('empresa'));
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }

    if (this.admin) {
      this.cargarSucursales();
    } else {
      this.sucursal = JSON.parse(localStorage.getItem('sucursal')).codigo;
    }

    this.cargarRadioButtons();
    setInterval(() => {
      if (this.banderaSpinner === true) {
        this.spinner.hide();
        this.banderaSpinner = false;
      }
    }, 100);
  }

  abrirModalBuscarProducto() {
    console.log('modal productos');
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<--Datos enviados desde componente hacia modal
        this.producto,
      ],
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferencia = this.modalServicio.show(
      ModalListadoProductosComponent,
      {
        initialState,
        ignoreBackdropClick: true,
      }
    );
    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((res) => {
      console.log(res.data);
      this.producto = res.data.nombre.trim();
      this.producto_codigo = res.data.codigo;
      this.cargarDatos();
    });
  }

  cargarRadioButtons() {
    this.servicio.consultarOpciones('1', '001').subscribe((result) => {
      console.log(result);

      result.forEach((element) => {
        if (element.valor == 1) this.opcion = element.orden;
      });
      this.opciones = result;
      console.log(this.opcion);
      this.cargarDatos();
    });
  }

  cargarDatos() {
    this.spinner.show();
    let procedimiento = 'exec ';
    let tin: string = 'TODOS';
    let inv: string = 'TODOS';

    if (typeof this.tinEscogido !== 'undefined') {
      tin = this.tinEscogido['codigo'];
    }

    if (typeof this.invEscogido !== 'undefined') {
      inv = this.invEscogido['codigo'];
    }

    if (tin == 'undefined' || typeof tin == 'undefined') tin = 'TODOS';
    if (inv == 'undefined' || typeof inv == 'undefined') inv = 'TODOS';

    console.log('ESCOGIDO', tin, inv);

    let fechaini = this.formatearFechaQuery(
      this.campaignOne.value.start.toJSON()
    );

    let fechafin = this.formatearFechaQuery(
      this.campaignOne.value.end.toJSON(),
      ' 23:59:59'
    );

    this.opciones.forEach((opcion) => {
      if (opcion.orden == this.opcion) procedimiento += opcion.procedimiento;
    });

    //this.campaignOne.value.end.toJSON()
    let query1 = `${procedimiento} ${this.opcion},1,'${this.empresa}','${this.sucursal}','${fechaini}','${fechafin}','${tin}','${inv}';`;

    console.log(query1);

    let query2 = `${procedimiento} ${this.opcion},2,'${this.empresa}','${this.sucursal}','${fechaini}','${fechafin}','${tin}','${inv}';`;

    this.servicio.ejecutarQuery(query1).subscribe((cabeceras) => {
      this.cabecera = cabeceras;
      this.servicio.ejecutarQuery(query2).subscribe((movimientos) => {
        this.movimiento = this.formatearDatos(cabeceras, movimientos);
        this.regmov = movimientos.length;
        this.movimiento = movimientos;
        this.banderaSpinner = true;
      });
    });
  }

  cargarSucursales() {
    this.servicio
      .getConfiguraciones('015', this.empresa)
      .subscribe((result) => {
        console.log(result);
        if (result.length > 0) {
          this.sucursales = result;
        }
      });
  }

  cargarTIN() {
    this.servicio
      .getConfiguraciones('007', this.empresa)
      .subscribe((result) => {
        console.log(result);
        if (result.length > 0) {
          this.datosTin = result;
        }
      });
  }

  cargarINV() {
    this.servicio
      .getConfiguraciones('009', this.empresa)
      .subscribe((result) => {
        console.log(result);
        if (result.length > 0) {
          this.datosInv = result;
        }
      });
  }

  formatearFechaQuery(fecha = new Date(), hora = '00:00:00') {
    let fechaFin = new Date(fecha);
    let fechaTotal = fechaFin.toJSON().slice(0, 10);
    return fechaTotal + ' ' + hora;
  }

  formatearFechaString(fecha = new Date(), hora = false) {
    //new Date().toJSON().slice(0,10).split('-').reverse().join('/')
    let fechaFin = new Date(fecha);
    let fechaString = fechaFin
      .toJSON()
      .slice(0, 10)
      .split('-')
      .reverse()
      .join('/');
    if (hora) {
      //Formatear hora
      return fechaString + ' ' + fechaFin.toJSON().slice(11, 19);
    }
    return fechaString;
  }

  formatearDatos(cabeceras, movimientos) {
    for (let i = 0; i < cabeceras.length; i++) {
      for (let e = 0; e < movimientos.length; e++) {
        const element = cabeceras[i];
        switch (element.tipodato.trim()) {
          case 'char':
            movimientos[e][element['campo']] =
              movimientos[e][element['campo']].trim();
            break;
          case 'numerico':
            movimientos[e][element['campo']] = parseFloat(
              movimientos[e][element['campo']]
            ).toFixed(parseInt(element['longitud']));
            break;
          case 'date':
            movimientos[e][element['campo']] = this.formatearFechaString(
              movimientos[e][element['campo']]
            );
            break;
          case 'datetime':
            movimientos[e][element['campo']] = this.formatearFechaString(
              movimientos[e][element['campo']],
              true
            );
            break;
          default:
            console.log(element);
            break;
        }
      }
    }

    return movimientos;
  }
}
