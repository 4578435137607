<div class="mt-2">
  <div class="container mb-3">
    <div class="row justify-content-center align-items-center">
      <div class="col-6 col-md-3 col-xl-2">
        <button
          type="button"
          class="btn btn-sm btn-outline-success"
          (click)="abrirModalAgregarProducto()"
        >
          <i class="far fa-plus-square"></i> Agregar
        </button>
      </div>

      <div
        class="col-11 col-md-8 col-xl-7 text-center text-md-left text-xl-center"
      >
        <h3>
          Biblioteca
          <small class="text-muted"> de Productos</small>
        </h3>
      </div>
      <!--
      <div
        class="col-11 col-md-8 col-xl-3 d-flex mb-3"
        style="justify-content: center"
        *ngIf="admin"
      >
        <select
          class="form-select form-select-sm"
          aria-label="Default select example"
          [(ngModel)]="empresa"
          style="width: 220px"
          (change)="getProductos()"
        >
          <option
            *ngFor="let empresa of empresas"
            [value]="empresa.codigo.trim()"
          >
            {{ empresa.nombre }}
          </option>
        </select>
      </div>
    -->
      <div class="col-11 col-md-8 col-xl-3 text-center text-md-left">
        <div class="input-group">
          <input
            type="text"
            class="form-control form-control-sm"
            placeholder="Buscar Nombre | Codigo"
            aria-label="Busqueda"
            aria-describedby="basic-addon1"
            (keyup)="busquedaProducto($event)"
          />
          <div class="input-group-append">
            <span class="input-group-text" id="basic-addon1"
              ><i class="fab fa-searchengin"></i
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div
    class="table-responsive"
    *ngIf="hayProductos === true; else sinProductos"
  >
    <table class="table table-striped table-sm table-hover m-0 p-0">
      <thead>
        <tr>
          <th
            scope="col text-center"
            style="width: 100px !important; min-width: 100px; max-width: 100px"
          ></th>
          <th scope="col" *ngFor="let elem of nombresAlias">
            <div style="min-width: 150px">
              <button
                type="button"
                class="btn btn-outline-dark btn-sm"
                (click)="abrirModalFiltro(elem.nombre)"
              >
                <i class="fas fa-sort-amount-up"></i>
              </button>
              {{ elem.nombre }}
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let producto of productos
              | paginate
                : {
                    itemsPerPage: 25,
                    currentPage: p,
                    totalItems: productos.count
                  }
          "
          scope="row"
        >
          <td>
            <div class="row" style="max-width: 100px">
              <div class="col-6">
                <button
                  class="btn btn-sm btn-outline-dark"
                  (click)="abrirModalDetalles(producto.codigo)"
                >
                  <i class="fas fa-box-open"></i>
                </button>
              </div>
              <div class="col-6">
                <button
                  class="btn btn-sm btn-outline-primary"
                  (click)="abrirModalReceta(producto.codigo)"
                >
                  <i class="fas fa-balance-scale"></i>
                </button>
              </div>
            </div>
          </td>

          <td>
            <p style="margin: 0">{{ producto.nombre }}</p>
          </td>
          <td>
            <p style="margin: 0">$ {{ producto.precio }}</p>
          </td>
          <td>
            <p style="margin: 0">{{ producto.porc_tarifa }} %</p>
          </td>
          <td>
            <p style="margin: 0">{{ producto.nomlin }}</p>
          </td>
          <td>
            <p style="margin: 0">{{ producto.nommed }}</p>
          </td>
          <td>
            <p style="margin: 0">{{ producto.nomgru }}</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="container-fluid" *ngIf="hayProductos === true">
  <div class="row justify-content-center align-items-center mt-4 mb-3">
    <div class="col-12" style="text-align: center">
      <pagination-controls
        (pageChange)="p = $event"
        autoHide="true"
        previousLabel="Anterior"
        nextLabel="Siguiente"
      >
      </pagination-controls>
    </div>
  </div>
</div>

<ng-template #sinProductos>
  <ng-container>
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-12 col-md-6 col-lg-4 mt-5" style="text-align: center">
          <h3>
            <small class="text-muted">Búsqueda sin resultados</small>
          </h3>

          <lottie-player
            src="https://assets2.lottiefiles.com/temp/lf20_TOE9MF.json"
            background="transparent"
            speed="1"
            loop
            autoplay
          ></lottie-player>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<app-spinner></app-spinner>
